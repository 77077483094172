import { BookingTableForm } from "../features/monitoring";
import { Container } from "@mui/material";
import Page from "../components/Page";

export default function Booking() {
    return (
        <Page title="Bookings | HCC">
            <Container maxWidth="xl">
                <BookingTableForm />
            </Container>
        </Page>
    );
}
