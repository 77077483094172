import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
} from "lexical";
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
} from "@lexical/list";
import { ListItemIcon, MenuItem, TextField, Typography } from "@mui/material";

import { $setBlocksType } from "@lexical/selection";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import NotesIcon from "@mui/icons-material/Notes";
import PropTypes from "prop-types";
import { blockTypeToBlockName } from "./constants";

export const BlockFormat = ({ blockType, disabled, editor }) => {
    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $setBlocksType(selection, $createParagraphNode);
            }
        });
    };

    const formatHeading = (headingSize) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () =>
                    $createHeadingNode(headingSize)
                );
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatQuote = () => {
        if (blockType !== "quote") {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createQuoteNode());
            });
        }
    };

    return (
        <TextField
            disabled={disabled}
            id="block-format"
            inputProps={{ sx: { display: "flex" } }}
            select
            value={blockType}
        >
            <MenuItem value="paragraph" onClick={formatParagraph}>
                <ListItemIcon>
                    <NotesIcon />
                </ListItemIcon>
                Normal
            </MenuItem>
            <MenuItem value="h1" onClick={() => formatHeading("h1")}>
                <ListItemIcon>
                    <Typography>H1</Typography>
                </ListItemIcon>
                Heading 1
            </MenuItem>
            <MenuItem value="h2" onClick={() => formatHeading("h2")}>
                <ListItemIcon>
                    <Typography>H2</Typography>
                </ListItemIcon>
                Heading 2
            </MenuItem>
            <MenuItem value="h3" onClick={() => formatHeading("h3")}>
                <ListItemIcon>
                    <Typography>H3</Typography>
                </ListItemIcon>
                Heading 3
            </MenuItem>
            <MenuItem value="bullet" onClick={formatBulletList}>
                <ListItemIcon>
                    <FormatListBulletedIcon />
                </ListItemIcon>
                Bullet List
            </MenuItem>
            <MenuItem value="number" onClick={formatNumberedList}>
                <ListItemIcon>
                    <FormatListNumberedIcon />
                </ListItemIcon>
                Numbered List
            </MenuItem>
            <MenuItem value="quote" onClick={formatQuote}>
                <ListItemIcon>
                    <FormatQuoteRoundedIcon />
                </ListItemIcon>
                Quote
            </MenuItem>
        </TextField>
    );
};

BlockFormat.propTypes = {
    blockType: PropTypes.oneOf(Object.keys(blockTypeToBlockName)).isRequired,
    disabled: PropTypes.bool.isRequired,
    // should be `PropTypes.instanceOf(LexicalEditor).isRequired` but the class is not exported
    editor: PropTypes.object.isRequired,
};
