import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { ClientInfoGrid, SensorInfoGrid } from "../../acquisition-report";

import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import { flagsSetter } from "./";
import { useMemo } from "react";

InboundTrafficMissionData.propTypes = {
    mission: PropTypes.shape(MODEL_PROPTYPES.Mission).isRequired,
};

export function InboundTrafficMissionData({ mission }) {
    const flagArray = useMemo(
        () => flagsSetter(mission, mission.rawDatasetStatistics),
        [mission]
    );

    return (
        <Stack direction="row" spacing={1}>
            <Card sx={{ width: 1 }}>
                <CardHeader title="Client information" />
                <CardContent>
                    <ClientInfoGrid mission={mission} flagArray={flagArray} />
                </CardContent>
            </Card>
            <Card sx={{ width: 1 }}>
                <CardHeader title="Sensor information" />
                <CardContent>
                    <SensorInfoGrid
                        acquisitionVector={mission.AcquisitionVector}
                        focalLength35mmEqv={mission.focalLength35mmEqv}
                    />
                </CardContent>
            </Card>
        </Stack>
    );
}
