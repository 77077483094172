import {
    Autocomplete,
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { CONTRACT_STATUS, DEADLINE_TYPE, PAGINATION } from "../../../constants";
import { Controller, useForm, useWatch } from "react-hook-form";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { BookingTable } from ".";
import { BookingTableFormSkeleton } from "./BookingTableFormSkeleton";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const deadlineTypeValues = Object.values(DEADLINE_TYPE);

export function BookingTableForm() {
    const { control, setValue } = useForm({
        defaultValues: {
            year: new Date().getFullYear(),
            company: null,
            contract: null,
            supervisor: null,
            deadlineType: null,
            numberDisplayed: true,
            status: CONTRACT_STATUS.ORDERED,
        },
    });

    const [yearWatch, companyWatch, contractWatch, numberDisplayedWatch] =
        useWatch({
            name: ["year", "company", "contract", "numberDisplayed"],
            control,
        });

    const { data: companies, error: companiesFetchError } = useSWR(
        `${BACKEND_ROUTES.COMPANY}?sort=name&limit=${PAGINATION.COMPANY.LIMIT.MAX}`
    );
    const { data: contracts, error: contractsFetchError } = useSWR(
        `${BACKEND_ROUTES.CONTRACT}?sort=name&limit=${PAGINATION.CONTRACT.LIMIT.MAX}`
    );
    const { data: users, error: usersFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.USER}?sort=firstName`
    );

    const mergedFetchError =
        contractsFetchError ?? companiesFetchError ?? usersFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!contracts || !companies || !users) {
        return <BookingTableFormSkeleton />;
    }

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Bookings
            </Typography>

            <Card>
                <CardHeader
                    title={
                        <Stack direction="column">
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Stack direction="row" alignItems="center">
                                    <IconButton
                                        size="large"
                                        onClick={() =>
                                            setValue("year", yearWatch - 1)
                                        }
                                        disabled={yearWatch <= 2000}
                                    >
                                        <NavigateBeforeIcon
                                            fontSize="inherit"
                                            color={
                                                yearWatch <= 2000
                                                    ? ""
                                                    : "primary"
                                            }
                                        />
                                    </IconButton>
                                    <Typography variant="h5">
                                        {yearWatch}
                                    </Typography>
                                    <IconButton
                                        size="large"
                                        onClick={() =>
                                            setValue("year", yearWatch + 1)
                                        }
                                        disabled={yearWatch >= 2999}
                                    >
                                        <NavigateNextIcon
                                            fontSize="inherit"
                                            color={
                                                yearWatch >= 2999
                                                    ? ""
                                                    : "primary"
                                            }
                                        />
                                    </IconButton>
                                </Stack>
                                <Controller
                                    control={control}
                                    name="numberDisplayed"
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            name,
                                            ...fieldProps
                                        },
                                    }) => (
                                        <FormControlLabel
                                            {...fieldProps}
                                            id="numberDisplayed"
                                            name="numberDisplayed"
                                            control={<Switch />}
                                            label="Display numbers"
                                            labelPlacement="end"
                                            checked={numberDisplayedWatch}
                                            onChange={(event) => {
                                                onChange(event.target.checked);
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <Controller
                                        control={control}
                                        name="company"
                                        render={({
                                            field: {
                                                ref,
                                                onChange,
                                                name,
                                                ...fieldProps
                                            },
                                        }) => (
                                            <Autocomplete
                                                {...fieldProps}
                                                id="company"
                                                options={companies.rows}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.uuid === value.uuid}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                onChange={(event, value) => {
                                                    onChange(value);
                                                    if (
                                                        value &&
                                                        contractWatch?.companyUuid !==
                                                            value.uuid
                                                    )
                                                        setValue(
                                                            "contract",
                                                            null
                                                        );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={ref}
                                                        name={name}
                                                        label="Filter on company"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Controller
                                        control={control}
                                        name="contract"
                                        render={({
                                            field: {
                                                ref,
                                                onChange,
                                                name,
                                                ...fieldProps
                                            },
                                        }) => (
                                            <Autocomplete
                                                {...fieldProps}
                                                id="contract"
                                                options={contracts.rows.filter(
                                                    (contract) =>
                                                        companyWatch
                                                            ? companyWatch.uuid ===
                                                              contract.companyUuid
                                                            : true
                                                )}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.uuid === value.uuid}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                onChange={(event, value) => {
                                                    onChange(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={ref}
                                                        name={name}
                                                        label="Filter on campaign"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Controller
                                        control={control}
                                        name="supervisor"
                                        render={({
                                            field: {
                                                ref,
                                                onChange,
                                                name,
                                                ...fieldProps
                                            },
                                        }) => (
                                            <Autocomplete
                                                {...fieldProps}
                                                id="supervisor"
                                                options={users}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => option.uuid === value.uuid}
                                                getOptionLabel={(option) =>
                                                    `${option.firstName} ${option.lastName}`
                                                }
                                                onChange={(event, value) => {
                                                    onChange(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={ref}
                                                        name={name}
                                                        label="Filter on supervisor"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="deadlineType"
                                        render={({
                                            field: {
                                                ref,
                                                onChange,
                                                name,
                                                ...fieldProps
                                            },
                                        }) => (
                                            <Autocomplete
                                                {...fieldProps}
                                                id="deadlineType"
                                                options={deadlineTypeValues}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) =>
                                                    option.value === value.value
                                                }
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                onChange={(event, value) => {
                                                    onChange(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputRef={ref}
                                                        name={name}
                                                        label="Filter on deadline timing"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Controller
                                        control={control}
                                        name="status"
                                        render={({
                                            field: { ref, ...fieldProps },
                                        }) => (
                                            <TextField
                                                {...fieldProps}
                                                fullWidth
                                                id="outlined-select-status"
                                                select
                                                label="Filter by status"
                                                inputRef={ref}
                                            >
                                                {Object.values(
                                                    CONTRACT_STATUS
                                                ).map((status) => (
                                                    <MenuItem
                                                        key={status}
                                                        value={status}
                                                    >
                                                        {status}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    }
                />
                <CardContent>
                    <BookingTable control={control} />
                </CardContent>
            </Card>
        </>
    );
}
