import {
    Box,
    Card,
    Dialog,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { Link } from "react-router-dom";
import { SiteCreateForm } from ".";
import { SiteTableSkeleton } from "./SiteTableSkeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { iso31661 as countriesArray } from "iso-3166";
import { getDataGridHeight } from "../utils/getDataGridHeight";
import { laggy } from "../../utils/SWRLaggy";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const pageItemCount = 100;

export function SiteTable({ contractUuid }) {
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(0);
    const {
        data: sites,
        error: sitesFetchError,
        mutate,
        isLagging,
    } = useSWR(
        `${
            BACKEND_ROUTES.SITE
        }?parentInfo=true&contractUuid=${contractUuid}&limit=${pageItemCount}&offset=${
            page * pageItemCount
        }`,
        { use: [laggy] }
    );

    const { data: users, error: usersFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.USER}?sort=firstName`
    );

    const gridRows = useMemo(
        () =>
            sites
                ? sites.rows.map((site) => {
                      return {
                          id: site.uuid,
                          uuid: site.uuid,
                          name: site.name,
                          supervisor: `${site.Supervisor.firstName} ${site.Supervisor.lastName}`,
                          supervisorUuid: site.supervisorUuid,
                          country: site.countryCode
                              ? countriesArray.find(
                                    (country) =>
                                        country.alpha3 === site.countryCode
                                )?.name ?? "Unknown country"
                              : "No country",
                      };
                  })
                : [],
        [sites]
    );

    const mergedFetchError = usersFetchError ?? sitesFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!sites || !users) return <SiteTableSkeleton />;

    const closeModal = () => {
        setIsOpen(false);
    };

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "editButton",
            headerName: "",
            sortable: false,
            hideable: false,
            filterable: false,
            minWidth: 65,
            flex: 0.5,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <Tooltip title="View details">
                    <IconButton
                        sx={{
                            "&:hover": {
                                backgroundColor: "primary.lighter",
                            },
                        }}
                        component={Link}
                        to={`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}/${contractUuid}/${FRONTEND_ROUTES.SITE}/${params.row.id}`}
                    >
                        <VisibilityIcon fontSize="small" color="primary" />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            field: "name",
            headerName: "Site",
            minWidth: 250,
            flex: 3,
        },
        {
            field: "country",
            headerName: "Country",
            minWidth: 250,
            flex: 3,
        },
        {
            field: "supervisor",
            headerName: "Supervisor",
            minWidth: 250,
            flex: 2,
        },
    ];

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" gutterBottom>
                    Sites
                </Typography>
                <Stack direction="row" spacing={1}>
                    <IconButton size="large" onClick={() => setIsOpen(true)}>
                        <AddCircleOutlinedIcon
                            fontSize="inherit"
                            color="primary"
                        />
                    </IconButton>
                    <Dialog open={isOpen} fullWidth maxWidth="sm">
                        <SiteCreateForm
                            contractUuid={contractUuid}
                            closeModal={closeModal}
                            mutate={mutate}
                            users={users}
                        />
                    </Dialog>
                </Stack>
            </Stack>

            <Card>
                <Box
                    sx={{
                        height: getDataGridHeight(400, sites.rows.length),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        rowCount={sites.count}
                        paginationMode="server"
                        paginationModel={{ page, pageSize: pageItemCount }}
                        onPaginationModelChange={(newPageModel) => {
                            setPage(newPageModel.page);
                        }}
                        loading={isLagging}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                    />
                </Box>
            </Card>
        </Stack>
    );
}
