import { fromUrl } from "geotiff";

/**
 * Calculates the required GSD for a BBCH stage and a data type of a pipeline template.
 * Returns null if no trait group of the data type is selected for the BBCH stage in the pipeline template.
 * @param {object} pipelineTemplate - The pipeline template with its PipelineTemplateTraitGroups and SOP eager-loaded.
 * @param {string} bbchStageUuid - The uuid of the bbch stage.
 * @param {string} dataType - Data type for the GSD.
 * @returns {number|null}
 */
export function getPipelineTemplateGsdOfStage(
    pipelineTemplate,
    bbchStageUuid,
    dataType
) {
    const pttgOfStage = pipelineTemplate.PipelineTemplateTraitGroups.filter(
        (PTTG) =>
            PTTG.bbchStageUuid === bbchStageUuid &&
            PTTG.TraitGroupDataType.dataType === dataType
    );

    if (pttgOfStage.length) {
        const gsdListOfStage = pttgOfStage.map(
            (PTTG) =>
                pipelineTemplate.gsdPerTraitGroupDataType.find(
                    (gsdOfTraitGroup) =>
                        gsdOfTraitGroup.traitGroupDataTypeUuid ===
                        PTTG.traitGroupDataTypeUuid
                )?.gsd // Normally the conditional chaining is unnecessary
        );

        return Math.min(...gsdListOfStage);
    } else return null;
}

/**
 * basicStatistics is an object containing the mean, minimum, maximum, and standard deviation of an array
 * @typedef {Object} basicStatistics
 * @property {number} mean - Mean value
 * @property {number} min - Minimum value
 * @property {number} max - Maximum value
 * @property {number} std - Standard deviation
 */

/**
 * Returns mean value, min value, max value, and standard deviation from an array of values
 * @param {number[]} array - Array of data we want to calculate statistics on
 * @returns {basicStatistics} - Returned basic statistics for the input array
 */
export function calculateBasicStatistics(array) {
    if (array.some((item) => item == null))
        return {
            mean: NaN,
            min: NaN,
            max: NaN,
            std: NaN,
        };

    return {
        mean: mean(array),
        min: Math.min(...array),
        max: Math.max(...array),
        std: standardDeviation(array),
    };
}

export function mean(array) {
    const n = array.length;
    if (n === 0) return NaN;
    return array.reduce((a, b) => a + b) / n;
}

export function standardDeviation(array) {
    const n = array.length;
    if (n === 0) return NaN;
    const average = mean(array);
    return Math.sqrt(
        array.map((x) => Math.pow(x - average, 2)).reduce((a, b) => a + b) / n
    );
}

export async function drawCanvas(
    imageData,
    canvasElt,
    canvasEltWidth,
    rotate = false,
    alpha = false
) {
    const canvasEltHeight = rotate
        ? (imageData.width / imageData.height) * canvasEltWidth
        : (imageData.height / imageData.width) * canvasEltWidth;
    const dWidth = rotate ? canvasEltHeight : canvasEltWidth;
    const dHeight = rotate ? canvasEltWidth : canvasEltHeight;

    canvasElt.setAttribute("width", canvasEltWidth);
    canvasElt.setAttribute("height", canvasEltHeight);
    const ctx = canvasElt.getContext("2d", { alpha: alpha });

    const imgBitmap = await createImageBitmap(imageData);

    if (rotate) {
        ctx.save();
        ctx.translate(dHeight, 0);
        ctx.rotate((90 * Math.PI) / 180);
    }

    ctx.drawImage(imgBitmap, 0, 0, dWidth, dHeight);

    if (rotate) ctx.restore();

    return imgBitmap;
}

export async function readGrayscaleTifNormalized(url) {
    const geoTiffFile = await fromUrl(url);
    const mostDetailedImage = await geoTiffFile.getImage(0);

    const rasters = await mostDetailedImage.readRasters();
    const { width, height } = rasters;

    const maxRastersValue = rasters[0].reduce(
        (max, current) => (current > max ? current : max),
        1
    );

    const dataUint8 = new Uint8ClampedArray(width * height * 4);
    rasters[0].forEach((elt, idx) => {
        const normalizedValue = (elt / maxRastersValue) * 255;
        dataUint8[idx * 4] = normalizedValue; // red
        dataUint8[idx * 4 + 1] = normalizedValue; // green
        dataUint8[idx * 4 + 2] = normalizedValue; // blue
        dataUint8[idx * 4 + 3] = 255; // alpha
    });

    return new ImageData(dataUint8, width, height);
}

export async function getImageDataFromNativeImageUrl(imageUrl) {
    const image = new Image();
    const canvas = document.createElement("canvas");

    image.crossOrigin = "anonymous"; // Needed to use CORS in requests by the image element and to prevent error with canvas.getImageData
    image.src = imageUrl;
    await image.decode();

    canvas.setAttribute("width", image.naturalWidth);
    canvas.setAttribute("height", image.naturalHeight);
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0);

    return ctx.getImageData(0, 0, canvas.width, canvas.height);
}
