export const userNamesComparator = (
    userUuidA,
    userUuidB,
    users,
    noUserFakeUuid,
    noUserString
) => {
    const userA = users.find((user) => userUuidA === user.uuid);
    const userNameA = userA
        ? `${userA.firstName} ${userA.lastName}`
        : userUuidA === noUserFakeUuid
          ? noUserString
          : "Error";

    const userB = users.find((user) => userUuidB === user.uuid);
    const userNameB = userB
        ? `${userB.firstName} ${userB.lastName}`
        : userUuidB === noUserFakeUuid
          ? noUserString
          : "Error";

    return userNameA.localeCompare(userNameB);
};
