import { createContext } from "react";

const defaultFetchContextFunction = () =>
    Promise.reject(
        "FetchContext provider not implemented or values not supplied"
    );

export const FetchContext = createContext({
    get: defaultFetchContextFunction,
    post: defaultFetchContextFunction,
    patch: defaultFetchContextFunction,
    put: defaultFetchContextFunction,
    destroy: defaultFetchContextFunction,
});
