import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";

import { DATATYPES_ENUM } from "../../../constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";

OutputsForm.propTypes = {
    results: PropTypes.object.isRequired,
};

export function OutputsForm({ results }) {
    const perBandText = results.dataType === DATATYPES_ENUM.MULTISPECTRAL && (
        <Box component="span" sx={{ fontWeight: "bold" }}>
            per band
        </Box>
    );

    return (
        <Stack spacing={2} sx={{ p: 2 }}>
            <Box>
                <Typography variant="h5">Outputs</Typography>
                <Divider />
            </Box>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Flight altitude (m)</Typography>

                <Typography>{results.height ? results.height : "-"}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Maximum speed (m/s)</Typography>

                <Typography>
                    {results.maxSpeedMS ? results.maxSpeedMS : "-"}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Maximum speed (miles/hour)</Typography>

                <Typography>
                    {results.maxSpeedMPH ? results.maxSpeedMPH : "-"}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Number of tracks needed</Typography>

                <Typography>
                    {results.trackCount ? results.trackCount : "-"}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>
                    Number of images per track {perBandText}
                </Typography>

                <Typography>
                    {results.imagesPerTrack ? results.imagesPerTrack : "-"}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Minimum total of images {perBandText}</Typography>

                <Typography>
                    {results.minTotalImages ? results.minTotalImages : "-"}
                </Typography>
            </Stack>

            <Stack direction="row" spacing={1}>
                <Typography>Estimated data size {perBandText} (MB)</Typography>

                <Tooltip
                    title={
                        results.dataType === DATATYPES_ENUM.RGB ? (
                            <Stack>
                                <Typography>
                                    Based on the empirical average of an RGB
                                    image in JPG format:{" "}
                                </Typography>
                                <Typography>
                                    - An image of 12 megapixels: around 8.6 MB
                                </Typography>
                                <Typography>
                                    - An image of 20 megapixels: around 11 MB
                                </Typography>
                                <Typography>
                                    - An image of 45 megapixels: around 25 MB
                                </Typography>
                                <Typography>
                                    - An image of 100 megapixels: around 100 MB
                                </Typography>
                            </Stack>
                        ) : results.dataType ===
                          DATATYPES_ENUM.MULTISPECTRAL ? (
                            <Stack>
                                <Typography>
                                    Based on the empirical average of a
                                    multispectral image in TIF format:{" "}
                                </Typography>
                                <Typography>
                                    - An image (one band) of 1.2 megapixels:
                                    around 2.5 MB
                                </Typography>
                                <Typography>
                                    - An image (one band) of 5 megapixels:
                                    around 10 MB
                                </Typography>
                            </Stack>
                        ) : (
                            <Typography>
                                Data size estimation currently only available
                                for RGB and multispectral sensors
                            </Typography>
                        )
                    }
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <InfoOutlinedIcon fontSize="small" />
                    </Box>
                </Tooltip>

                <Typography>
                    {results.dataSizeMB ? results.dataSizeMB : "-"}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Estimated flight duration (minutes)</Typography>

                <Typography>
                    {results.estimatedTime ? results.estimatedTime : "-"}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
                <Typography>Estimated number of batteries</Typography>

                <Tooltip
                    title={
                        <Typography>
                            Based on an average battery lasting 20 minutes
                        </Typography>
                    }
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <InfoOutlinedIcon fontSize="small" />
                    </Box>
                </Tooltip>

                <Typography>
                    {results.estimatedBatteries
                        ? results.estimatedBatteries
                        : "-"}
                </Typography>
            </Stack>
        </Stack>
    );
}
