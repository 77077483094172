import {
    Breadcrumbs,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { PAGINATION, TRAIT_GROUP_ASSOCIABLE_TYPES } from "../../../constants";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { BreadcrumbChip } from "../../../components/BreadcrumbChip";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { LoadingButton } from "@mui/lab";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import { TraitGroupList } from "./TraitGroupList";
import { TraitGroupListSkeleton } from "./TraitGroupListSkeleton";
import { formatDateISO } from "../../../utils/formatTime";
import useSWR from "swr";

export const TraitGroupModal = ({ closeModal, experimentMission }) => {
    const associatedTraitGroupSearchParams = new URLSearchParams({
        associableType: TRAIT_GROUP_ASSOCIABLE_TYPES.EXPERIMENT_MISSION,
        associableUuid: experimentMission?.uuid,
        // There shouldn't be more than 200 trait groups associated to an experiment mission
        limit: PAGINATION.GENERIC.LIMIT.MAX,
        pipelineInstance: true,
        validatedPipelineInstance: true,
        traitGroup: true,
    });

    const {
        data: experimentMissionTraitGroups,
        error: experimentMissionTraitGroupsFetchError,
        isLoading: experimentMissionTraitGroupsIsLoading,
    } = useSWR(
        experimentMission
            ? `${BACKEND_ROUTES.ASSOCIATED_TRAIT_GROUP}?${associatedTraitGroupSearchParams}`
            : null
    );

    const {
        data: pipelines,
        error: pipelinesFetchError,
        isLoading: pipelinesIsLoading,
    } = useSWR(
        experimentMissionTraitGroups
            ? `${BACKEND_ROUTES.PIPELINE}?${createListPipelineSearchParams({
                  experimentMission,
                  experimentMissionTraitGroups:
                      experimentMissionTraitGroups.rows,
              })}`
            : null,
        { keepPreviousData: true }
    );

    const formMethods = useForm();
    const {
        formState: { isSubmitting },
    } = formMethods;

    const mergedFetchError =
        experimentMissionTraitGroupsFetchError ?? pipelinesFetchError;
    const hasError = Boolean(!experimentMission || mergedFetchError);

    const isLoading =
        experimentMissionTraitGroupsIsLoading || pipelinesIsLoading;

    return (
        <FormProvider {...formMethods}>
            {experimentMission && (
                <DialogTitle>
                    <Breadcrumbs separator="/" mb={1}>
                        <BreadcrumbChip
                            label={
                                experimentMission.Experiment.Site.Contract
                                    .Company.name
                            }
                        />
                        <BreadcrumbChip
                            label={
                                experimentMission.Experiment.Site.Contract.name
                            }
                        />
                        <BreadcrumbChip
                            label={experimentMission.Experiment.Site.name}
                        />
                        <BreadcrumbChip
                            label={experimentMission.Experiment.name}
                        />
                        <BreadcrumbChip
                            label={formatDateISO(
                                experimentMission.Mission.date
                            )}
                        />
                    </Breadcrumbs>
                </DialogTitle>
            )}
            <DialogContent>
                {hasError ? (
                    <FetchErrorAlert
                        error={mergedFetchError ?? { status: 404 }}
                    />
                ) : isLoading ? (
                    <TraitGroupListSkeleton />
                ) : (
                    <TraitGroupList
                        closeModal={closeModal}
                        experimentMissionTraitGroups={
                            experimentMissionTraitGroups.rows
                        }
                        pipelines={pipelines.rows}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeModal}>Close</Button>
                <Button variant="outlined">Cloverfield</Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    form="traitGroups"
                    disabled={
                        hasError ||
                        isLoading ||
                        // If not loading and no error `experimentMissionTraitGroups` is defined
                        !experimentMissionTraitGroups.rows.length
                    }
                    loading={isSubmitting}
                >
                    Launch
                </LoadingButton>
            </DialogActions>
        </FormProvider>
    );
};

const createListPipelineSearchParams = ({
    experimentMission,
    experimentMissionTraitGroups,
}) =>
    `system=${
        experimentMission.Mission.AcquisitionVector.SystemModel.system
    }&${experimentMissionTraitGroups
        .map(
            ({ TraitGroupDataType: { uuid } }) =>
                `traitGroupDataTypeUuids[]=${uuid}`
        )
        .join("&")}`;

TraitGroupModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    experimentMission: PropTypes.shape(MODEL_PROPTYPES.ExperimentMission),
};
