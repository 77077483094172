import { AlignmentModal, TraitGroupModal } from ".";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Dialog, Tooltip, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import {
    createSortSearchParams,
    getGridOperators,
    objectArrayStringify,
    userNamesComparator,
} from "../utils";
import { useCallback, useMemo, useState } from "react";
import { useFetch, useSnackbar } from "../../hooks";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import { MODES } from "../acquisition-report/constants";
import PropTypes from "prop-types";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Task from "@mui/icons-material/Task";
import { TrafficTableSkeleton } from "./TrafficTableSkeleton";
import { formatDateTime } from "../../utils/formatTime";
import useSWR from "swr";

// `filter` prop not ideal but temporary. It should be removed in https://hiphen.atlassian.net/browse/SE-827
export const TrafficTable = ({ filter }) => {
    const { patch } = useFetch();
    const { openSnackbar } = useSnackbar();
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedExperimentMissionUuid = searchParams.get(
        selectedExperimentMissionQueryString
    );

    const [selectedMission, setSelectedMission] = useState(null);
    const [isTraitGroupModalOpen, setIsTraitGroupModalOpen] = useState(
        Boolean(selectedExperimentMissionUuid)
    );
    const [{ sortModel, paginationModel }, setGridState] = useState({
        ...initialState.sorting,
        ...initialState.pagination,
    });
    const [isAlignmentModalOpen, setIsAlignmentModalOpen] = useState(false);

    const experimentMissionSearchParams = new URLSearchParams({
        parentInfo: true,
        limit: pageItemCount,
        offset: paginationModel.page * pageItemCount,
        ...createSortSearchParams(sortModel, sortMap),
    });

    const {
        data: experimentMissions,
        error: experimentMissionsFetchError,
        mutate,
        isLoading: experimentMissionsIsLoading,
    } = useSWR(
        `${BACKEND_ROUTES.EXPERIMENT_MISSION}?${experimentMissionSearchParams}`
    );

    const {
        data: users,
        error: usersFetchError,
        isLoading: usersIsLoading,
    } = useSWR(`${BACKEND_ROUTES.USER}?sort=firstName`);

    const handleProcessRowUpdateError = useCallback(
        (error) => openSnackbar(error.message, "error"),
        [openSnackbar]
    );

    const updatePagination = useCallback(
        (newPaginationModel) =>
            setGridState((prevState) => ({
                ...prevState,
                paginationModel: newPaginationModel,
            })),
        []
    );

    const updateSort = useCallback(
        (newSortModel) =>
            setGridState((prevState) => ({
                ...prevState,
                sortModel: newSortModel,
            })),
        []
    );

    const processRowUpdate = useCallback(
        async (newRow) => {
            const updateMission = patch(
                `${BACKEND_ROUTES.MISSION}/${newRow.missionUuid}`,
                {
                    body: {
                        supervisorUuid:
                            newRow.supervisorUuid === invalidUuid
                                ? null
                                : newRow.supervisorUuid,
                    },
                    forwardError: true,
                }
            );
            await mutate(updateMission, { populateCache: false });

            openSnackbar(
                `Updated Mission of ${formatDateTime(
                    newRow.acquisitionDate
                )} successfully.`,
                "success"
            );

            return newRow;
        },
        [mutate, openSnackbar, patch]
    );

    const rows = useMemo(
        () =>
            experimentMissions?.rows
                .filter(filter)
                .map((experimentMission) => ({
                    id: experimentMission.uuid,
                    uuid: experimentMission.uuid,
                    experiment: experimentMission.Experiment.name,
                    site: experimentMission.Experiment.Site.name,
                    pipelineTemplate: experimentMission.PipelineTemplate.name,
                    bbchStage: experimentMission.BBCHStage.stage,
                    dataType: objectArrayStringify(
                        experimentMission.Mission.AcquisitionVector
                            .SensorBundles,
                        ["Sensor", "dataType"]
                    ),
                    acquisitionDate: new Date(experimentMission.Mission.date),
                    missionUuid: experimentMission.missionUuid,
                    supervisorUuid:
                        experimentMission.Mission.supervisorUuid ?? invalidUuid,
                    campaign: experimentMission.Experiment.Site.Contract.name,
                    contractUuid:
                        experimentMission.Experiment.Site.contractUuid,
                    company:
                        experimentMission.Experiment.Site.Contract.Company.name,
                    status: experimentMission.status,
                })),
        [experimentMissions, filter]
    );

    // DataGrid column definitions
    const columns = useMemo(
        () =>
            users && [
                {
                    field: "actions",
                    headerName: "Actions",
                    type: "actions",
                    minWidth: 90,
                    flex: 2,
                    getActions: (params) => {
                        const openAlignmentModal = () => {
                            setSelectedMission(params.row.missionUuid);
                            setIsAlignmentModalOpen(true);
                        };

                        const openValidationTraitModal = () => {
                            setSearchParams({
                                [selectedExperimentMissionQueryString]:
                                    params.row.uuid,
                            });
                            setIsTraitGroupModalOpen(true);
                        };

                        return [
                            <Tooltip title="Process">
                                <GridActionsCellItem
                                    label="Process"
                                    icon={<Task />}
                                    onClick={openValidationTraitModal}
                                />
                            </Tooltip>,
                            <GridActionsCellItem
                                label="Acquisition report"
                                icon={<QueryStatsIcon />}
                                component={Link}
                                to={`/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.ACQUISITION_REPORT}/${params.row.missionUuid}?experimentMissionUuid=${params.row.id}&mode=${MODES.NORMAL}`}
                                showInMenu
                            />,
                            <GridActionsCellItem
                                label="Alignment report"
                                icon={<CropOriginalIcon />}
                                onClick={openAlignmentModal}
                                showInMenu
                            />,
                            <GridActionsCellItem
                                label="Go to Association"
                                icon={<LinkIcon />}
                                component={Link}
                                to={`/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC_DETAILS}/${params.row.missionUuid}`}
                                showInMenu
                            />,
                            <GridActionsCellItem
                                label="Go to Campaign"
                                icon={<ArticleOutlinedIcon />}
                                component={Link}
                                to={`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}/${params.row.contractUuid}`}
                                showInMenu
                            />,
                        ];
                    },
                },
                {
                    field: "company",
                    headerName: "Company",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                },
                {
                    field: "campaign",
                    headerName: "Campaign",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                },
                {
                    field: "site",
                    headerName: "Site",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                },
                {
                    field: "acquisitionDate",
                    type: "date",
                    headerName: "Acquisition Date",
                    minWidth: 140,
                    flex: 2,
                },
                {
                    field: "experiment",
                    headerName: "Experiment",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                },
                {
                    field: "dataType",
                    headerName: "Type",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                },
                {
                    field: "pipelineTemplate",
                    headerName: "Pipeline Template",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                },
                {
                    field: "bbchStage",
                    headerName: "BBCH",
                    type: "number",
                    minWidth: 65,
                    flex: 2,
                    sortable: false,
                },
                {
                    field: "supervisorUuid",
                    renderHeader: () => (
                        // this class is the one used for default datagrid headers
                        <div className="css-t89xny-MuiDataGrid-columnHeaderTitle">
                            {"Supervisor"}
                            <Tooltip
                                title={
                                    <Typography>
                                        Warning: Changing the mission supervisor
                                        of one row affects all the other rows of
                                        the same mission.
                                    </Typography>
                                }
                            >
                                <InfoIcon fontSize="small" color="warning" />
                            </Tooltip>
                        </div>
                    ),
                    minWidth: 160,
                    flex: 2,
                    type: "singleSelect",
                    filterOperators: getGridOperators("select"),
                    editable: true,
                    sortComparator: (userUuidA, userUuidB) =>
                        userNamesComparator(
                            userUuidA,
                            userUuidB,
                            users,
                            invalidUuid,
                            "No Supervisor"
                        ),
                    valueOptions: [
                        ...users.map((user) => ({
                            value: user.uuid,
                            label: `${user.firstName} ${user.lastName}`,
                        })),
                        { value: invalidUuid, label: "No supervisor" },
                    ],
                    sortable: false,
                },
                {
                    field: "status",
                    headerName: "Status",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                },
            ],
        [setSearchParams, users]
    );

    const mergedFetchError = usersFetchError ?? experimentMissionsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    const isLoading = usersIsLoading || experimentMissionsIsLoading;

    if (isLoading) {
        return <TrafficTableSkeleton />;
    }

    // Find experiment mission by UUID defined in the query params
    const selectedExperimentMission = experimentMissions.rows.find(
        ({ uuid }) => uuid === selectedExperimentMissionUuid
    );

    return (
        <>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSizeOptions={pageSizeOptions}
                initialState={initialState}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={updatePagination}
                // count is incorrect as data is filtered in frontend. Will be fixed in https://hiphen.atlassian.net/browse/SE-827
                rowCount={rows.length}
                sortingMode="server"
                onSortModelChange={updateSort}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                loading={isLoading}
            />

            <Dialog
                open={isTraitGroupModalOpen}
                fullWidth
                maxWidth="md"
                onTransitionExited={setSearchParams}
            >
                <TraitGroupModal
                    experimentMission={selectedExperimentMission}
                    closeModal={() => setIsTraitGroupModalOpen(false)}
                />
            </Dialog>
            <Dialog open={isAlignmentModalOpen} fullWidth maxWidth="sm">
                <AlignmentModal
                    missionUuid={selectedMission}
                    setIsOpen={setIsAlignmentModalOpen}
                />
            </Dialog>
        </>
    );
};

const pageItemCount = 50;
const pageSizeOptions = [pageItemCount];

const invalidUuid = "invalid uuid";

const initialState = {
    sorting: { sortModel: [{ field: "acquisitionDate", sort: "desc" }] },
    columns: { columnVisibilityModel: { status: false } },
    pagination: { paginationModel: { page: 0, pageSize: pageSizeOptions[0] } },
};

const sortMap = { acquisitionDate: "Mission_date" };

const selectedExperimentMissionQueryString = "selectedExperimentMission";

TrafficTable.propTypes = {
    filter: PropTypes.func.isRequired,
};
