import { DEFAULT_ERROR_MESSAGE } from "../constants";

const fetcher = async (url, init) => {
    let error;
    let data;
    let response;
    try {
        // If fetch promise reject (i.e network error) throw generic error
        response = await fetch(url, { ...init });

        // 204 is No-Content (empty body) checked first before looking at Content-Type
        if (response.status === 204) {
            data = null;
        } else {
            /* If JSON conversion throws it is either not json or invalid json
            If no body a 204 should be returned from API */
            data = await response.json();
        }
    } catch {
        // If `fetch` ou `response.json()` fails set error to default error message
        throw new Error(DEFAULT_ERROR_MESSAGE);
    }

    if (response.ok) {
        return data;
    }
    // If status is not 2XX, set error object using error returned by API
    else {
        error = new Error(data.message || DEFAULT_ERROR_MESSAGE);
        // Attach extra info to the error object.
        error.status = response.status;
        error.errorCode = data.errorCode;

        throw error;
    }
};

export const get = fetcher;

export const post = (url, { body, ...init } = {}) =>
    fetcher(url, {
        ...init,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: "POST",
    });

export const put = (url, { body, ...init } = {}) =>
    fetcher(url, {
        ...init,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: "PUT",
    });

export const patch = (url, { body, ...init } = {}) =>
    fetcher(url, {
        ...init,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: "PATCH",
    });

export const destroy = (url, init = {}) =>
    fetcher(url, { ...init, method: "DELETE" });
