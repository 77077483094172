import {
    DATATYPES_ENUM,
    DEADLINE_TYPE,
    EXPERIMENT_MISSION_STATUS,
    MISSION_PRE_PROCESSING_STATUS,
    PIPELINE_INSTANCE_STATUS,
    PLOT_MAP_PROVIDER,
    PROCESS_STATUS,
    REJECTION_REASON,
    REPORT_STATUS,
    SYSTEMS,
    TRAIT_GROUP_ASSOCIABLE_TYPES,
    TRAIT_GROUP_CATEGORY,
    TRAIT_TYPE,
    USER_TYPES,
    VALIDATION_STATUS,
} from "./constants";

import PropTypes from "prop-types";

/**
 * Models
 * Note that only "belongs-to" eagerloads are included in the proptypes below.
 */
const PlantProfile = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sizeRange: PropTypes.string.isRequired,
    sizeMean: PropTypes.number.isRequired,
});

const Sensor = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dataType: PropTypes.oneOf(Object.values(DATATYPES_ENUM)).isRequired,
    sensorWidth: PropTypes.number.isRequired,
    sensorHeight: PropTypes.number.isRequired,
    pixelWidth: PropTypes.number.isRequired,
    pixelHeight: PropTypes.number.isRequired,
    focalLength35mmEqv: PropTypes.number,
    cmos: PropTypes.string.isRequired,
    megapixels: PropTypes.number.isRequired,
});

const SystemModel = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    system: PropTypes.oneOf(Object.values(SYSTEMS)).isRequired,
});

const AcquisitionVector = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    systemModelUuid: PropTypes.string.isRequired,
    SystemModel: PropTypes.shape(SystemModel),
});

const SensorBundle = Object.freeze({
    uuid: PropTypes.string.isRequired,
    sensorUuid: PropTypes.string.isRequired,
    acquisitionVectorUuid: PropTypes.string.isRequired,
    Sensor: PropTypes.shape(Sensor),
    AcquisitionVector: PropTypes.shape(AcquisitionVector),
});

const BBCHStage = Object.freeze({
    uuid: PropTypes.string.isRequired,
    stage: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
});

const Region = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

const Segment = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

const Crop = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

const User = Object.freeze({
    uuid: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    // TODO: set kcId to .isRequired when everyone logged in on the actual HCC and filled their kcId that way
    kcId: PropTypes.string,
    email: PropTypes.string.isRequired,
    userType: PropTypes.oneOf(Object.values(USER_TYPES)).isRequired,
});

const TraitGroup = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.oneOf(Object.values(TRAIT_GROUP_CATEGORY)).isRequired,
    deliverable: PropTypes.bool.isRequired,
    deprecated: PropTypes.bool.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string,
    moduleUuid: PropTypes.string,
});
const ReferenceObject = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

const TraitReference = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    expectedSize: PropTypes.number.isRequired,
    referenceObjectUuid: PropTypes.string.isRequired,
    ReferenceObject: PropTypes.shape(ReferenceObject),
});

const TraitGroupDataType = Object.freeze({
    uuid: PropTypes.string.isRequired,
    dataType: PropTypes.oneOf(Object.values(DATATYPES_ENUM)).isRequired,
    traitGroupUuid: PropTypes.string.isRequired,
    traitReferenceUuid: PropTypes.string.isRequired,
    TraitGroup: PropTypes.shape(TraitGroup),
    TraitReference: PropTypes.shape(TraitReference),
});

const Company = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    regionUuid: PropTypes.string.isRequired,
    segmentUuid: PropTypes.string.isRequired,
    acceptedEmailHandles: PropTypes.arrayOf(PropTypes.string).isRequired,
    cloverfieldUuid: PropTypes.string,
    Region: PropTypes.shape(Region),
    Segment: PropTypes.shape(Segment),
});

const Contract = Object.freeze({
    uuid: PropTypes.string.isRequired,
    companyUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired, // date
    endDate: PropTypes.string.isRequired, // date
    supervisorUuid: PropTypes.string.isRequired,
    salespersonUuid: PropTypes.string.isRequired,
    cloverfieldUuid: PropTypes.string,
    Company: PropTypes.shape(Company),
    Supervisor: PropTypes.shape(User),
    Salesperson: PropTypes.shape(User),
});

const Site = Object.freeze({
    uuid: PropTypes.string.isRequired,
    contractUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    countryCode: PropTypes.string,
    supervisorUuid: PropTypes.string.isRequired,
    cloverfieldUuid: PropTypes.string,
    Contract: PropTypes.shape(Contract),
});

const Experiment = Object.freeze({
    uuid: PropTypes.string.isRequired,
    siteUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cropUuid: PropTypes.string.isRequired,
    Site: PropTypes.shape(Site),
    Crop: PropTypes.shape(Crop),
});

const Mission = Object.freeze({
    uuid: PropTypes.string.isRequired,
    siteUuid: PropTypes.string.isRequired,
    acquisitionVectorUuid: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired, // datetime
    deadline: PropTypes.number.isRequired,
    preProcessingStatus: PropTypes.oneOf(
        Object.values(MISSION_PRE_PROCESSING_STATUS)
    ).isRequired,
    focalLength35mmEqv: PropTypes.number,
    supervisorUuid: PropTypes.string,
    cloverfieldUuid: PropTypes.string,
    metadata: PropTypes.any, // metadata is set to "any" because it's not sure "object" would work for a json value
    comment: PropTypes.string,
    Site: PropTypes.shape(Site),
    AcquisitionVector: PropTypes.shape(AcquisitionVector),
    Supervisor: PropTypes.shape(User),
    reportStatus: PropTypes.oneOf(Object.values(REPORT_STATUS)),
    reportDate: PropTypes.string,
    reportComment: PropTypes.object,
});

const PipelineTemplate = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    contractUuid: PropTypes.string.isRequired,
    cropUuid: PropTypes.string.isRequired,
    acquisitionVectorUuid: PropTypes.string.isRequired,
    siteCount: PropTypes.number.isRequired,
    featureCount: PropTypes.number.isRequired,
    deadlineType: PropTypes.oneOf(
        Object.values(DEADLINE_TYPE).map((deadline) => deadline.value)
    ).isRequired,
    plotMapProvider: PropTypes.oneOf(
        Object.values(PLOT_MAP_PROVIDER).map((provider) => provider.value)
    ).isRequired,
    focalLength35mmEqv: PropTypes.number,
    comment: PropTypes.string,
    Contract: PropTypes.shape(Contract),
    Crop: PropTypes.shape(Crop),
    AcquisitionVector: PropTypes.shape(AcquisitionVector),
});

const PipelineTemplateCalendar = Object.freeze({
    uuid: PropTypes.string.isRequired,
    pipelineTemplateUuid: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired, // date
    count: PropTypes.number.isRequired,
    PipelineTemplate: PropTypes.shape(PipelineTemplate),
});

const PipelineTemplateTraitGroup = Object.freeze({
    uuid: PropTypes.string.isRequired,
    pipelineTemplateUuid: PropTypes.string.isRequired,
    traitGroupDataTypeUuid: PropTypes.string.isRequired,
    bbchStageUuid: PropTypes.string.isRequired,
    PipelineTemplate: PropTypes.shape(PipelineTemplate),
    TraitGroupDataType: PropTypes.shape(TraitGroupDataType),
    BBCHStage: PropTypes.shape(BBCHStage),
});

const FlightSequence = Object.freeze({
    uuid: PropTypes.string.isRequired,
    pipelineTemplateUuid: PropTypes.string.isRequired,
    bbchStageUuid: PropTypes.string.isRequired,
    sequence: PropTypes.string.isRequired,
    PipelineTemplate: PropTypes.shape(PipelineTemplate),
    BBCHStage: PropTypes.shape(BBCHStage),
});

const ExperimentMission = Object.freeze({
    uuid: PropTypes.string.isRequired,
    experimentUuid: PropTypes.string.isRequired,
    missionUuid: PropTypes.string.isRequired,
    pipelineTemplateUuid: PropTypes.string.isRequired,
    bbchStageUuid: PropTypes.string.isRequired,
    deadline: PropTypes.number.isRequired,
    status: PropTypes.oneOf(Object.values(EXPERIMENT_MISSION_STATUS))
        .isRequired,
    Mission: PropTypes.shape(Mission),
    Experiment: PropTypes.shape(Experiment),
    PipelineTemplate: PropTypes.shape(PipelineTemplate),
    BBCHStage: PropTypes.shape(BBCHStage),
});

const ExperimentMissionEvent = Object.freeze({
    uuid: PropTypes.string.isRequired,
    experimentMissionUuid: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(EXPERIMENT_MISSION_STATUS))
        .isRequired,
    userUuid: PropTypes.string.isRequired,
    ExperimentMission: PropTypes.shape(ExperimentMission),
});

const Pipeline = Object.freeze({
    uuid: PropTypes.string.isRequired,
    traitGroupDataTypeUuid: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    system: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    default: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    validationPageUuid: PropTypes.string,
});

const PipelineTraitGroup = Object.freeze({
    uuid: PropTypes.string.isRequired,
    pipelineInstanceUuid: PropTypes.string.isRequired,
    associatedTraitGroupUuid: PropTypes.string.isRequired,
    comment: PropTypes.string,
    pipelineUuid: PropTypes.string.isRequired,
});

const PipelineInstance = Object.freeze({
    uuid: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(PIPELINE_INSTANCE_STATUS)).isRequired,
    PipelineTraitGroup: PropTypes.shape(PipelineTraitGroup),
});

const ExperimentMissionTraitGroup = {
    uuid: PropTypes.string.isRequired,
    associableType: PropTypes.oneOf([
        TRAIT_GROUP_ASSOCIABLE_TYPES.EXPERIMENT_MISSION,
    ]).isRequired,
    validatedPipelineInstanceUuid: PropTypes.string.isRequired,
    associableUuid: PropTypes.string.isRequired,
    traitGroupDataTypeUuid: PropTypes.string.isRequired,
    Associable: PropTypes.shape(ExperimentMission),
    TraitGroupDataType: PropTypes.shape(TraitGroupDataType),
    ValidatedPipelineInstance: PropTypes.shape(PipelineInstance),
    PipelineInstances: PropTypes.arrayOf(
        PropTypes.shape(PipelineInstance).isRequired
    ),
};

const MissionTraitGroup = {
    uuid: PropTypes.string.isRequired,
    associableType: PropTypes.oneOf([TRAIT_GROUP_ASSOCIABLE_TYPES.MISSION])
        .isRequired,
    validatedPipelineInstanceUuid: PropTypes.string.isRequired,
    associableUuid: PropTypes.string.isRequired,
    traitGroupDataTypeUuid: PropTypes.string.isRequired,
    Associable: PropTypes.shape(Mission),
    TraitGroupDataType: PropTypes.shape(TraitGroupDataType),
};

const Trait = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(TRAIT_TYPE)).isRequired,
    minBbchStageUuid: PropTypes.string.isRequired,
    maxBbchStageUuid: PropTypes.string.isRequired,
    traitGroupUuid: PropTypes.string.isRequired,
    unit: PropTypes.string,
    illustration: PropTypes.string,
    description: PropTypes.string,
    style: PropTypes.any.isRequired, // "any" because json
    technicalName: PropTypes.string.isRequired,
    MinBBCHStage: PropTypes.shape(BBCHStage),
    MaxBBCHStage: PropTypes.shape(BBCHStage),
    TraitGroup: PropTypes.shape(TraitGroup),
});

const Module = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
});

const ModuleVersion = Object.freeze({
    uuid: PropTypes.string.isRequired,
    moduleUuid: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    imageName: PropTypes.string.isRequired,
    definition: PropTypes.any.isRequired, // "any" because json
    requiredModules: PropTypes.any,
    Module: PropTypes.shape(Module),
});

const Feature = Object.freeze({
    uuid: PropTypes.string.isRequired,
    experimentUuid: PropTypes.string.isRequired,
    parentFeatureUuid: PropTypes.string,
    name: PropTypes.string.isRequired,
    block: PropTypes.string,
    quarantine: PropTypes.bool.isRequired,
    // "any" for point, geometry, and json
    center: PropTypes.any,
    geometry: PropTypes.any,
    others: PropTypes.any,
    granularity: PropTypes.string.isRequired,
    comment: PropTypes.string,
});

const PlantScale = Object.freeze({
    uuid: PropTypes.string.isRequired,
    cropUuid: PropTypes.string.isRequired,
    referenceObjectUuid: PropTypes.string.isRequired,
    size: PropTypes.number,
    Crop: PropTypes.shape(Crop),
    ReferenceObject: PropTypes.shape(ReferenceObject),
});

const RawDatasets = Object.freeze({
    uuid: PropTypes.string.isRequired,
    missionUuid: PropTypes.string.isRequired,
    dataType: PropTypes.oneOf(Object.values(DATATYPES_ENUM)).isRequired,
    s3Location: PropTypes.string,
    localLocation: PropTypes.string,
    comment: PropTypes.string,
    // "any" for json
    metadata: PropTypes.any,
    others: PropTypes.any,
});

const Process = Object.freeze({
    uuid: PropTypes.string.isRequired,
    missionUuid: PropTypes.string.isRequired,
    moduleVersionUuid: PropTypes.string.isRequired,
    launcherUuid: PropTypes.string.isRequired,
    reviewerUuid: PropTypes.string,
    status: PropTypes.oneOf(Object.values(PROCESS_STATUS)).isRequired,
    // "any" for json
    parameters: PropTypes.any.isRequired,
    inputRawData: PropTypes.any,
    inputData: PropTypes.any,
    inputBinaryData: PropTypes.any,
    plannedDatetime: PropTypes.string, // datetime
    startDatetime: PropTypes.string, // datetime
    endDatetime: PropTypes.string, // datetime
    location: PropTypes.string,
    log: PropTypes.string,
    granularity: PropTypes.string,
    comment: PropTypes.string,
    returnCode: PropTypes.number,
    validated: PropTypes.bool,
    validatedDatetime: PropTypes.string, // datetime
    rejectionReason: PropTypes.oneOf(Object.values(REJECTION_REASON)),
    Mission: PropTypes.shape(Mission),
    ModuleVersion: PropTypes.shape(ModuleVersion),
});

const OutputData = Object.freeze({
    uuid: PropTypes.string.isRequired,
    processUuid: PropTypes.string.isRequired,
    featureUuid: PropTypes.string,
    // "any" for json
    data: PropTypes.any.isRequired,
    binaryData: PropTypes.any,
    status: PropTypes.oneOf(Object.values(VALIDATION_STATUS)),
    statusdDatetime: PropTypes.string, // datetime
    comment: PropTypes.string,
});

const OutputBinaryData = Object.freeze({
    uuid: PropTypes.string.isRequired,
    processUuid: PropTypes.string.isRequired,
    featureUuid: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(VALIDATION_STATUS)),
    statusDatetime: PropTypes.string, // datetime
    s3Location: PropTypes.string,
    localLocation: PropTypes.string,
    comment: PropTypes.string,
    // "any" for json
    others: PropTypes.any,
});

/**
 * Eager-loaded models
 */

export const MODEL_PROPTYPES = Object.freeze({
    PlantProfile,
    Sensor,
    SystemModel,
    AcquisitionVector,
    SensorBundle,
    BBCHStage,
    Region,
    Segment,
    Crop,
    User,
    TraitGroup,
    Company,
    Contract,
    Site,
    Experiment,
    Mission,
    PipelineTemplate,
    PipelineTemplateCalendar,
    PipelineTemplateTraitGroup,
    FlightSequence,
    ExperimentMission,
    ExperimentMissionEvent,
    ReferenceObject,
    TraitReference,
    MissionTraitGroup,
    ExperimentMissionTraitGroup,
    Trait,
    TraitGroupDataType,
    Module,
    ModuleVersion,
    Feature,
    PlantScale,
    RawDatasets,
    Process,
    OutputData,
    OutputBinaryData,
    Pipeline,
    PipelineInstance,
    PipelineTraitGroup,
});
