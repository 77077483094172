import "leaflet/dist/leaflet.css";

import {
    Box,
    Button,
    Dialog,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES,
    LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
    MUI_DEFAULT_SIZES,
} from "../../constants";
import {
    GeoJSON,
    MapContainer,
    Polyline,
    TileLayer,
    WMSTileLayer,
} from "react-leaflet";
import {
    PARCELLAIRE_REDUCER_ACTIONS,
    initialParcellaireState,
    parcellaireReducer,
} from "./parcellaireReducer";
import { readGeoJson, readMetadata, removeToolId } from "./utils/fileIoUtils";
import { useCallback, useReducer, useRef, useState } from "react";

import { APPBAR_HEIGHT } from "../../layouts/orders/constants";
import AccountPopover from "../../layouts/orders/AccountPopover";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Geoman } from "./Geoman";
import L from "leaflet";
import LockIcon from "@mui/icons-material/Lock";
import Logo from "../../components/Logo";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import { ParcellaireCreationModal } from ".";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { applyTransform } from "./utils/applyTransform";
import { geoJSONUtil } from "./utils/geoJSONUtil";
import { useSnackbar } from "../../hooks";

export function LeafletMap() {
    const { openSnackbar } = useSnackbar();

    const mapRef = useRef(null);

    const [parcellaireState, parcellaireDispatch] = useReducer(
        parcellaireReducer,
        initialParcellaireState
    );

    const [geoJsonKey, setGeoJsonKey] = useState(new Date().getTime());

    const [isParcellaireModalOpen, setIsParcellaireModalOpen] = useState(false);

    const [toggles, setToggles] = useState([
        GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE,
    ]);

    const [hiddenPlotMode, setHiddenPlotMode] = useState(false);

    const [displayBlue, setDisplayBlue] = useState(false);

    const [blueRectangleData, setBlueRectangleData] = useState(null);

    const [orthoimageName, setOrthoimageName] = useState("plain");

    const [plotColor, setPlotColor] = useState("#3388ff");

    const clearAllGeoJsons = () => {
        parcellaireDispatch({
            type: PARCELLAIRE_REDUCER_ACTIONS.CLEAR_ALL_GEOJSON,
        });
    };

    // Show a confirmation dialog when leaving the page
    // const beforeUnloadListener = (event) => {
    //     event.preventDefault();
    //     return (event.returnValue = "");
    // };

    // useEffect(() => {
    //     window.addEventListener("beforeunload", beforeUnloadListener, {
    //         capture: true,
    //     });

    //     return () => {
    //         window.removeEventListener("beforeunload", beforeUnloadListener, {
    //             capture: true,
    //         });
    //     };
    // }, []);

    const handleToggleGroupChange = useCallback(
        (event, values) => {
            try {
                const transform = applyTransform(parcellaireState, values);
                setToggles(values);
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.PM_EDIT,
                    transform,
                });
            } catch (error) {
                // TODO: error boundary https://hiphen.atlassian.net/browse/HCC-822
                openSnackbar(
                    error instanceof RangeError
                        ? rangeErrorString
                        : unknownErrorString,
                    "error"
                );
            }
            setGeoJsonKey(new Date().getTime());
        },
        [openSnackbar, parcellaireState]
    );

    const geoJsonPointToLayer = (feature, latlng) => {
        // this transforms markers for points into circle features (that cannot be interacted with, for now)
        return feature.id
            ? L.circleMarker(latlng, {
                  interactive: false,
                  radius: 5,
              }).bindTooltip(feature.id, {
                  permanent: true,
                  direction: "top",
              })
            : L.circleMarker(latlng, {
                  interactive: false,
                  radius: 5,
              });
    };

    const geoJsonOnEachFeature = (feature, layer) => {
        layer.setStyle({ color: plotColor });
        if (parcellaireState.selectedLayers.length) {
            const style = parcellaireState.selectedLayers.find(
                (selectedLayer) =>
                    geoJSONUtil.getIdOfFeature(selectedLayer.feature) ===
                    geoJSONUtil.getIdOfFeature(feature)
            )
                ? {
                      color: plotColor,
                      opacity: 1.0,
                      interactive: true,
                  }
                : {
                      color: "black",
                      opacity: 0.4,
                      interactive: false,
                  };

            layer.setStyle(style);
        } else {
            if (
                parcellaireState.lockedLayers.find(
                    (lockedLayer) =>
                        geoJSONUtil.getIdOfFeature(lockedLayer.feature) ===
                        geoJSONUtil.getIdOfFeature(feature)
                )
            )
                layer.setStyle({
                    color: "black",
                    opacity: 1.0,
                    interactive: false,
                });
        }

        // sets layer color to white if it has been moved
        if (
            parcellaireState.transformModeVectors.find(
                (vector) =>
                    vector.shapeObjectId === geoJSONUtil.getIdOfFeature(feature)
            )
        ) {
            layer.setStyle({ color: "white" });
        }

        // triggers when the layer is moved
        layer.on("pm:edit", (e) => {
            const shapeObjectId = geoJSONUtil.getIdOfFeature(feature);

            const oldFeature = geoJSONUtil.findFeatureById(
                parcellaireState.checkpointGeoJsonData,
                shapeObjectId
            );
            const oldCenter = geoJSONUtil.getCenterOfFeature(oldFeature);

            const layerCenter = layer.getCenter();
            const newCenter = [layerCenter.lng, layerCenter.lat];

            const vector = {
                shapeObjectId,
                longitude: newCenter[0] - oldCenter[0],
                latitude: newCenter[1] - oldCenter[1],
            };

            // shapeObjectId, vector, toggles, setTransformError are required
            const vectorIndex = parcellaireState.transformModeVectors.findIndex(
                (vector) => vector.shapeObjectId === shapeObjectId
            );

            const newTransformModeVectors =
                vectorIndex === -1
                    ? parcellaireState.transformModeVectors.concat(vector)
                    : parcellaireState.transformModeVectors.toSpliced(
                          vectorIndex,
                          1,
                          vector
                      );

            try {
                const transform = applyTransform(
                    parcellaireState,
                    toggles,
                    newTransformModeVectors
                );

                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.PM_EDIT,
                    transform,
                });
            } catch (error) {
                // TODO: error boundary https://hiphen.atlassian.net/browse/HCC-822
                openSnackbar(
                    error instanceof RangeError
                        ? rangeErrorString
                        : unknownErrorString,
                    "error"
                );
            }

            setGeoJsonKey(new Date().getTime());
        });
    };

    const blueRectangleOnFeature = (feature, layer) => {
        layer.setStyle({
            color: "black",
            opacity: 0.1,
            interactive: false,
        });
        layer.bringToBack();
    };

    return (
        <Stack direction="column">
            <Box
                sx={{ minHeight: APPBAR_HEIGHT, px: 3 }}
                display="flex"
                alignItems="center"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ width: 1 }}
                >
                    <Logo
                        sx={{
                            height: MUI_DEFAULT_SIZES.BUTTON.MEDIUM,
                            width: (MUI_DEFAULT_SIZES.BUTTON.MEDIUM * 5) / 3,
                        }}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<AddCircleIcon />}
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                            setIsParcellaireModalOpen(true);
                        }}
                    >
                        Create new parcellaire
                    </Button>

                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadIcon />}
                        sx={{ textTransform: "none" }}
                    >
                        Geojson plot file
                        <input
                            hidden
                            type="file"
                            accept=".geojson"
                            onChange={(event) => {
                                const geoJsonFile = event.target.files[0];

                                if (geoJsonFile) {
                                    if (parcellaireState.initialGeoJsonData)
                                        clearAllGeoJsons();

                                    readGeoJson(
                                        geoJsonFile,
                                        parcellaireDispatch,
                                        mapRef,
                                        null,
                                        openSnackbar,
                                        false
                                    );

                                    setBlueRectangleData(null);
                                }
                            }}
                        />
                    </Button>
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadIcon />}
                        sx={{ textTransform: "none" }}
                    >
                        Json session-metadata
                        <input
                            hidden
                            type="file"
                            accept=".json"
                            onChange={(event) => {
                                readMetadata(
                                    event,
                                    parcellaireDispatch,
                                    setGeoJsonKey
                                );
                            }}
                        />
                    </Button>
                    <TextField
                        label="Name of the orthoimage"
                        value={orthoimageName}
                        onChange={(event) => {
                            setOrthoimageName(event.target.value);
                        }}
                    />
                    <TextField
                        label="Microplot color"
                        id="microplotColorPicker"
                        type="color"
                        sx={{ width: "120px" }}
                        value={plotColor}
                        onChange={(e) => {
                            setPlotColor(e.target.value);
                            setGeoJsonKey(new Date().getTime());
                        }}
                    />
                    <Button
                        variant="contained"
                        component="a"
                        startIcon={<FileDownloadIcon />}
                        color="info"
                        sx={{ textTransform: "none" }}
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(
                                removeToolId(
                                    parcellaireState.transformedGeoJson
                                )
                            )
                        )}`}
                        download="parcellaire.geojson"
                        disabled={!parcellaireState.transformedGeoJson}
                    >
                        Geojson plot file
                    </Button>
                    <Box
                        sx={{
                            bgcolor: "white",
                            position: "absolute",
                            bottom: 140,
                            left: 0,
                            zIndex: LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
                        }}
                        style={{ borderRadius: "8px" }}
                    >
                        <Stack>
                            <ToggleButton
                                color="primary"
                                value={blueRectangleData ? displayBlue : false}
                                size="small"
                                selected={
                                    blueRectangleData ? displayBlue : false
                                }
                                onChange={() => {
                                    setDisplayBlue(!displayBlue);
                                    setGeoJsonKey(new Date().getTime());
                                }}
                                disabled={!blueRectangleData}
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        <Typography>
                                            Display blue rectangle
                                        </Typography>
                                    }
                                >
                                    <CropSquareIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                                color="primary"
                                value={hiddenPlotMode}
                                size="small"
                                selected={hiddenPlotMode}
                                onChange={() => {
                                    setHiddenPlotMode(!hiddenPlotMode);
                                }}
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        <Typography>
                                            Hide all microplots
                                        </Typography>
                                    }
                                >
                                    <VisibilityOffIcon />
                                </Tooltip>
                            </ToggleButton>
                        </Stack>
                    </Box>
                    <ToggleButtonGroup
                        color="secondary"
                        value={toggles}
                        orientation="vertical"
                        onChange={handleToggleGroupChange}
                        sx={{
                            bgcolor: "white",
                            position: "absolute",
                            bottom: 10,
                            left: 0,
                            zIndex: LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
                        }}
                    >
                        <ToggleButton
                            value={
                                GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SINGLE_TARGET_MODE
                            }
                            size="small"
                            color="primary"
                        >
                            <Tooltip
                                placement="right"
                                title={
                                    <>
                                        <Typography>
                                            Apply only single translations
                                        </Typography>
                                        <Typography>
                                            (Overrides other modes)
                                        </Typography>
                                    </>
                                }
                            >
                                <LooksOneIcon />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton
                            value={GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SHIFT_MODE}
                            size="small"
                            color="primary"
                        >
                            <Tooltip
                                placement="right"
                                title={
                                    <Typography>
                                        Apply translation starting from the 1st
                                        vector
                                    </Typography>
                                }
                            >
                                <ControlCameraIcon />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton
                            value={
                                GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE
                            }
                            size="small"
                            color="primary"
                        >
                            <Tooltip
                                placement="right"
                                title={
                                    <Typography>
                                        Do not change microplot shape
                                    </Typography>
                                }
                            >
                                <LockIcon />
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box sx={{ flexGrow: 1 }} />
                    <AccountPopover />
                </Stack>
            </Box>

            <div
                id="map"
                style={{
                    width: "100%",
                    height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
                }}
            >
                <MapContainer
                    center={[26.0, 30.0]}
                    maxZoom={25}
                    minZoom={2}
                    zoom={2}
                    style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: "url(/static/mapbg.png)",
                        backgroundSize: "1300px",
                    }}
                    ref={mapRef}
                >
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        attribution="Powered by Esri"
                        minZoom={2}
                        maxNativeZoom={17}
                        maxZoom={25}
                    />
                    {parcellaireState.transformModeVectors.map((vector) => (
                        <VectorPolyline
                            key={vector.shapeObjectId}
                            vector={vector}
                            checkpointGeoJsonData={
                                parcellaireState.checkpointGeoJsonData
                            }
                        />
                    ))}
                    {displayBlue && blueRectangleData && (
                        <GeoJSON
                            key={`${geoJsonKey}-blueRectangle`}
                            data={blueRectangleData}
                            bubblingMouseEvents={false}
                            onEachFeature={blueRectangleOnFeature}
                            pointToLayer={geoJsonPointToLayer}
                        />
                    )}
                    {!hiddenPlotMode && parcellaireState.transformedGeoJson && (
                        <GeoJSON
                            key={geoJsonKey}
                            data={parcellaireState.transformedGeoJson}
                            bubblingMouseEvents={false}
                            onEachFeature={geoJsonOnEachFeature}
                            pointToLayer={geoJsonPointToLayer}
                        />
                    )}
                    {parcellaireState.metadata && (
                        <WMSTileLayer
                            key={`cloverfield:${parcellaireState.metadata.site.id}-${parcellaireState.metadata.date}-${orthoimageName}`}
                            layers={`cloverfield:${parcellaireState.metadata.site.id}-${parcellaireState.metadata.date}-${orthoimageName}`}
                            url={process.env.REACT_APP_GEOSERVER_URL}
                            format="image/png"
                            transparent
                            opacity={1}
                            maxZoom={25}
                            tiled
                        />
                    )}
                    <Geoman
                        parcellaireState={parcellaireState}
                        parcellaireDispatch={parcellaireDispatch}
                        setGeoJsonKey={setGeoJsonKey}
                    />
                </MapContainer>
            </div>
            <Dialog open={isParcellaireModalOpen} fullWidth maxWidth="sm">
                <ParcellaireCreationModal
                    setIsOpen={setIsParcellaireModalOpen}
                    parcellaireDispatch={parcellaireDispatch}
                    mapRef={mapRef}
                    setGeoJsonKey={setGeoJsonKey}
                    openSnackbar={openSnackbar}
                    setBlueRectangleData={setBlueRectangleData}
                />
            </Dialog>
        </Stack>
    );
}

function VectorPolyline({ vector, checkpointGeoJsonData }) {
    const oldFeature = geoJSONUtil.findFeatureById(
        checkpointGeoJsonData,
        vector.shapeObjectId
    );
    const oldCenter = geoJSONUtil.getCenterOfFeature(oldFeature);

    const polyline = [
        [oldCenter[1], oldCenter[0]],
        [oldCenter[1] + vector.latitude, oldCenter[0] + vector.longitude],
    ];

    return (
        <Polyline
            // TODO: find why interactive doesn't work here
            pathOptions={{ color: "silver", interactive: false }}
            positions={polyline}
        />
    );
}

const rangeErrorString =
    "Move not allowed since transform calculation impossible.";

const unknownErrorString =
    "An unknown error has occured. Please try again later.";
