import {
    gsdFromHeight,
    realFocalLengthFromFocalLength35,
} from "./sopCalculation";

import { roundNumber } from "./roundNumber";

/**
 * Returns the minimum GSD based on the minimum height of the AV. It must calculate each potential min GSD per sensor and pick the highest
 * @param {object} pipelineTemplate - The pipeline template for which we need to figure its min GSD
 * @returns {object} - The resulting min GSD. It's an object because we have one minGSD per data type.
 */
export const getMinGsd = (pipelineTemplate) => {
    return pipelineTemplate.AcquisitionVector.SensorBundles.reduce(
        (previous, bundle) => {
            const thisBundleGsd = pipelineTemplate.AcquisitionVector.minHeight
                ? roundNumber(
                      gsdFromHeight(
                          pipelineTemplate.AcquisitionVector.minHeight,
                          bundle.Sensor.sensorWidth,
                          bundle.Sensor.pixelWidth,
                          realFocalLengthFromFocalLength35(
                              bundle.Sensor.sensorWidth,
                              bundle.Sensor.sensorHeight,
                              bundle.Sensor.focalLength35mmEqv ??
                                  pipelineTemplate.focalLength35mmEqv
                          )
                      ),
                      1
                  )
                : 0;
            if (
                !previous[bundle.Sensor.dataType] ||
                // we pick the highest to make sure all cameras in the same bundle and the same data type can do it
                previous[bundle.Sensor.dataType] < thisBundleGsd
            )
                previous[bundle.Sensor.dataType] = thisBundleGsd;

            return previous;
        },
        {}
    );
};
