import { Skeleton } from "@mui/material";

export const TrafficTableSkeleton = () => (
    <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="30em"
    />
);
