import { Autocomplete, Grid, TextField } from "@mui/material";

import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";

AssociationBBCHStageSelect.propTypes = {
    pipelineTemplates: PropTypes.shape({
        rows: PropTypes.arrayOf(
            PropTypes.shape(MODEL_PROPTYPES.PipelineTemplate)
        ).isRequired,
        count: PropTypes.number.isRequired,
    }).isRequired,
    bbchStages: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.shape(MODEL_PROPTYPES.BBCHStage))
            .isRequired,
        count: PropTypes.number.isRequired,
    }).isRequired,
    selectedPipelineTemplate: PropTypes.arrayOf(PropTypes.string),
    selectedBBCHStage: PropTypes.shape(MODEL_PROPTYPES.BBCHStage),
    setInboundStates: PropTypes.func.isRequired,
};

export function AssociationBBCHStageSelect({
    pipelineTemplates,
    bbchStages,
    selectedPipelineTemplate,
    selectedBBCHStage,
    setInboundStates,
}) {
    const availableStagesUuid = [
        ...new Set(
            pipelineTemplates.rows
                .find(
                    (pipelineTemplate) =>
                        pipelineTemplate.uuid === selectedPipelineTemplate[0]
                )
                ?.PipelineTemplateTraitGroups.map((PTTG) => PTTG.bbchStageUuid)
        ),
    ];

    const options = selectedPipelineTemplate[0]
        ? bbchStages.rows.filter((bbch) =>
              availableStagesUuid.includes(bbch.uuid)
          )
        : [];

    return (
        <Grid container>
            <Grid item xs={5}>
                <Autocomplete
                    id="selectedCrop"
                    options={options}
                    isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                    }
                    getOptionLabel={(option) =>
                        `${option.stage}: ${option.label}`
                    }
                    disableClearable
                    disabled={!selectedPipelineTemplate[0]}
                    value={selectedBBCHStage}
                    onChange={(_, value) => {
                        setInboundStates((previousState) => ({
                            ...previousState,
                            selectedBBCHStage: value,
                        }));
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select BBCH Stage" />
                    )}
                />
            </Grid>
        </Grid>
    );
}
