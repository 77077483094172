import { PAGINATION, RAW_DATASET_TYPES } from "../../../constants";
import { useEffect, useMemo } from "react";

import { AcquisitionReportCardLayout } from "./AcquisitionReportCardLayout";
import { AcquisitionReportFormButton } from "../acquisition-report-form";
import { AcquisitionReportSkeleton } from "./AcquisitionReportSkeleton";
import { BACKEND_ROUTES } from "../../../backendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { compareAsc } from "date-fns";
import { useMission } from "../useMission";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import useSWRInfinite from "swr/infinite";

const maxParallelRequests = 4;

export function AcquisitionReport({ experimentMission }) {
    const { missionUuid } = useParams();
    const { data: mission } = useMission(missionUuid);

    const {
        data: pipelineTemplatesData,
        error: pipelineTemplatesFetchError,
        isLoading: isPipelineTemplatesLoading,
    } = useSWR(
        experimentMission
            ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${experimentMission.pipelineTemplateUuid}?traitGroups=true&sop=true`
            : mission
              ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}?contractUuid=${mission.Site.contractUuid}&traitGroups=true&sop=true`
              : null
    );

    const {
        data: bbchStageData,
        error: bbchStageFetchError,
        isLoading: isBbchStageLoading,
    } = useSWRImmutable(BACKEND_ROUTES.BBCH_STAGE);

    const getUrl = (pageIndex) => {
        const searchParams = new URLSearchParams({
            missionUuid: missionUuid,
            limit: PAGINATION.RAW_DATASET.LIMIT.MAX,
            offset: PAGINATION.RAW_DATASET.LIMIT.MAX * pageIndex,
            type: RAW_DATASET_TYPES.ACQUISITION_PICTURE,
        });

        return `${BACKEND_ROUTES.RAW_DATASET}?${searchParams}`;
    };

    const {
        data: rawDataSets,
        setSize,
        size,
        error: rawDataSetsFetchError,
        isLoading: isRawDataSetsLoading,
        mutate: mutateRawDataSets,
    } = useSWRInfinite(getUrl, {
        parallel: true,
        revalidateFirstPage: false,
    });

    useEffect(() => {
        const count = rawDataSets?.[0].count;
        const fetchedDataLength = size * PAGINATION.RAW_DATASET.LIMIT.MAX;

        if (count && fetchedDataLength < count && rawDataSets[size - 1]) {
            setSize(
                size +
                    Math.min(
                        maxParallelRequests,
                        Math.ceil(
                            (count - fetchedDataLength) /
                                PAGINATION.RAW_DATASET.LIMIT.MAX
                        )
                    )
            );
        }
    }, [rawDataSets, setSize, size]);

    const rawdataSetsConcat = useMemo(
        () =>
            rawDataSets
                ?.flatMap((rawDataSet) => rawDataSet.rows)
                .toSorted((a, b) => {
                    const datetimeA = a.metadata?.datetime;
                    const datetimeB = b.metadata?.datetime;

                    if (!datetimeA && datetimeB) return 1;
                    if (datetimeA && !datetimeB) return -1;
                    if (!datetimeA && !datetimeB) return 0;

                    return compareAsc(new Date(datetimeA), new Date(datetimeB));
                }),
        [rawDataSets]
    );

    const mergedFetchError =
        pipelineTemplatesFetchError ||
        bbchStageFetchError ||
        rawDataSetsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (
        isPipelineTemplatesLoading ||
        isBbchStageLoading ||
        isRawDataSetsLoading
    )
        return <AcquisitionReportSkeleton />;

    return (
        <>
            <AcquisitionReportCardLayout
                bbchStageData={bbchStageData.rows}
                experimentMission={experimentMission}
                rawDataSets={rawdataSetsConcat}
                pipelineTemplatesData={
                    experimentMission
                        ? [pipelineTemplatesData]
                        : pipelineTemplatesData.rows
                }
                mutateRawDataSets={mutateRawDataSets}
            />

            <AcquisitionReportFormButton
                fabStyle={{
                    position: "fixed",
                    bottom: 32,
                    right: 32,
                }}
            />
        </>
    );
}
