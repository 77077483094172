import { IconButton, Tooltip } from "@mui/material";
import { REDO_COMMAND, UNDO_COMMAND } from "lexical";

import { IS_APPLE } from "./constants";
import PropTypes from "prop-types";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";

export const HistoryButtons = ({
    activeEditor,
    canRedo,
    canUndo,
    disabled,
}) => (
    <>
        <Tooltip title={IS_APPLE ? "Undo (⌘Z)" : "Undo (Ctrl+Z)"}>
            <span>
                <IconButton
                    disabled={!canUndo || disabled}
                    onClick={() => {
                        activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
                    }}
                >
                    <UndoIcon />
                </IconButton>
            </span>
        </Tooltip>
        <Tooltip title={IS_APPLE ? "Redo (⌘Y)" : "Redo (Ctrl+Y)"}>
            <span>
                <IconButton
                    disabled={!canRedo || disabled}
                    onClick={() => {
                        activeEditor.dispatchCommand(REDO_COMMAND, undefined);
                    }}
                >
                    <RedoIcon />
                </IconButton>
            </span>
        </Tooltip>
    </>
);

HistoryButtons.propTypes = {
    // should be `PropTypes.instanceOf(LexicalEditor).isRequired` but the class is not exported
    activeEditor: PropTypes.object.isRequired,
    canRedo: PropTypes.bool.isRequired,
    canUndo: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
};
