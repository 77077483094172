import { Skeleton, Tooltip, Typography } from "@mui/material";

import { ALLOWED_FACTOR_BELOW_MINIMUM_GSD } from "../../../constants";
import { BACKEND_ROUTES } from "../../../backendRoutes";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DataGrid } from "@mui/x-data-grid";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";
import { getPipelineTemplateGsdOfStage } from "../utils";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";

PipelineTemplateTraitTable.propTypes = {
    pipelineTemplate: PropTypes.object.isRequired, // TODO
    acquisitionGsdMax: PropTypes.object.isRequired,
    dataType: PropTypes.string.isRequired,
    selectedBbchStageUuid: PropTypes.string,
};

/**
 * selectedBbchStageUuid: The uuid of the BBCH stage to highlight in the table.
 */
export function PipelineTemplateTraitTable({
    pipelineTemplate,
    acquisitionGsdMax,
    dataType,
    selectedBbchStageUuid,
}) {
    const { data: bbchStageData, bbchStageFetchError } = useSWRImmutable(
        BACKEND_ROUTES.BBCH_STAGE
    );

    const bbchStages = bbchStageData?.rows;

    const mergedFetchError = bbchStageFetchError;

    // Restructure PipelineTemplateTraitGroups to a list of distinct TraitGroups,
    // each with their corresponding list of BBCHStage uuids
    const gridRows = useMemo(() => {
        if (bbchStages) {
            const traitGroupDataTypeList = [];

            pipelineTemplate.PipelineTemplateTraitGroups.filter(
                (PTTG) => PTTG.TraitGroupDataType.dataType === dataType
            ).forEach((PTTG) => {
                const traitGroupDataTypeWithStages =
                    traitGroupDataTypeList.find(
                        (traitGrp) =>
                            traitGrp.traitGroupDataType.uuid ===
                            PTTG.traitGroupDataTypeUuid
                    );
                if (traitGroupDataTypeWithStages) {
                    // The trait group is already in the list
                    traitGroupDataTypeWithStages.bbchStageUuids.push(
                        PTTG.bbchStageUuid
                    );
                } else {
                    // The trait group is not yet in the list
                    traitGroupDataTypeList.push({
                        traitGroupDataType: PTTG.TraitGroupDataType,
                        bbchStageUuids: [PTTG.bbchStageUuid],
                    });
                }
            });

            const tempGridRows = traitGroupDataTypeList.map(
                (traitGroupDataTypeWithStages) => {
                    const traitGroupGsd =
                        pipelineTemplate.gsdPerTraitGroupDataType.find(
                            (gsdOfTraitGroup) =>
                                gsdOfTraitGroup.traitGroupDataTypeUuid ===
                                traitGroupDataTypeWithStages.traitGroupDataType
                                    .uuid
                        )?.gsd; // Normally the conditional chaining is unnecessary

                    const row = {
                        id: traitGroupDataTypeWithStages.traitGroupDataType
                            .uuid,
                        traitGroupName: `${traitGroupDataTypeWithStages.traitGroupDataType.TraitGroup.name}`,
                        dataType:
                            traitGroupDataTypeWithStages.traitGroupDataType
                                .dataType,
                        sortValue: 1,
                    };

                    bbchStages.forEach((bbchStage) => {
                        row[bbchStage.uuid] =
                            traitGroupDataTypeWithStages.bbchStageUuids.includes(
                                bbchStage.uuid
                            )
                                ? traitGroupGsd
                                : null;
                    });

                    return row;
                }
            );

            // Add last row that summarizes the minimum required GSD
            const minGSDRow = {
                id: `requiredGsd${dataType}`,
                traitGroupName: `Required GSD (mm/px)`,
                dataType: dataType,
                sortValue: 2,
            };
            bbchStages.forEach((bbchStage) => {
                minGSDRow[bbchStage.uuid] = getPipelineTemplateGsdOfStage(
                    pipelineTemplate,
                    bbchStage.uuid,
                    dataType
                );
            });

            return tempGridRows.concat(minGSDRow);
        }
    }, [bbchStages, dataType, pipelineTemplate]);

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (!bbchStageData) return <PipelineTemplateTraitTableSkeleton />;

    const gridColDef = [
        {
            field: "traitGroupName",
            headerName: `GSD validation (${dataType})`,
            minWidth: 200,
            flex: 8,
            sortable: false,
        },
    ];

    bbchStages.forEach((bbchStage) => {
        gridColDef.push({
            field: bbchStage.uuid,
            headerName: bbchStage.stage,
            minWidth: 40,
            flex: 1,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderCell: ({ id, value }) => {
                if (!id.startsWith("requiredGsd")) {
                    if (value !== null)
                        return (
                            <Tooltip
                                title={
                                    <Typography>{`Required GSD: ${value}mm`}</Typography>
                                }
                            >
                                {
                                    // if acquisitionGsdMax[dataType] is NaN, we want a warning icon, but if it's undefined, we want a special icon (here, a grey "no entry" icon)
                                    // we filtered acquisitionGsdMax in such a way that it cannot be null, but it can be undefined or NaN
                                    acquisitionGsdMax[dataType] ===
                                    undefined ? (
                                        <DoDisturbOnIcon
                                            sx={{ color: "grey.500" }}
                                        />
                                    ) : acquisitionGsdMax[dataType] <= value ? (
                                        <CheckCircleIcon color="success" />
                                    ) : Number.isNaN(
                                          acquisitionGsdMax[dataType]
                                      ) ||
                                      acquisitionGsdMax[dataType] <=
                                          value *
                                              ALLOWED_FACTOR_BELOW_MINIMUM_GSD ? (
                                        <WarningIcon color="warning" />
                                    ) : (
                                        <CancelIcon color="error" />
                                    )
                                }
                            </Tooltip>
                        );
                    else return "";
                } else {
                    return value !== null ? value : "";
                }
            },
            headerClassName:
                bbchStage.uuid === selectedBbchStageUuid
                    ? "selected-bbch-stage"
                    : undefined,
            cellClassName:
                bbchStage.uuid === selectedBbchStageUuid
                    ? "selected-bbch-stage"
                    : undefined,
        });
    });

    return (
        <DataGrid
            autoHeight
            rows={gridRows}
            columns={gridColDef}
            disableColumnMenu
            hideFooter
            sx={{
                "& .selected-bbch-stage": {
                    backgroundColor: "primary.lighter",
                },
            }}
        />
    );
}

function PipelineTemplateTraitTableSkeleton() {
    return <Skeleton animation="wave" variant="rectangular" height="15em" />;
}
