import { Grid, Skeleton, Typography } from "@mui/material";

export const SiteEditFormSkeleton = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    Site management
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="50%"
                    height="2em"
                />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
