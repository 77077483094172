import {
    ALIGNMENT_MODULE_NAME,
    ALIGNMENT_REPORT_FILE_NAME,
} from "../../constants";
import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Skeleton,
    Stack,
} from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { ClientInfoGrid } from "../acquisition-report";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import PropTypes from "prop-types";
import useSWR from "swr";

AlignmentModal.propTypes = {
    missionUuid: PropTypes.string,
    setIsOpen: PropTypes.func.isRequired,
};

export function AlignmentModal({ missionUuid, setIsOpen }) {
    const { data: mission, error: missionFetchError } = useSWR(
        missionUuid
            ? `${BACKEND_ROUTES.MISSION}/${missionUuid}?parentInfo=true&rawDatasets=true&rawDatasetStatistics=true`
            : null
    );
    const { data: processes, error: processesFetchError } = useSWR(
        missionUuid
            ? `${BACKEND_ROUTES.PROCESS}?missionUuid=${missionUuid}&ModuleVersion_Module_name=${ALIGNMENT_MODULE_NAME}&limit=1&sort=-createdAt`
            : null
    );
    const { data: outputBinaryData, error: outputBinaryDataFetchError } =
        useSWR(
            processes?.rows[0]
                ? `${BACKEND_ROUTES.PROCESS}/${processes.rows[0]?.uuid}${BACKEND_ROUTES.OUTPUT_BINARY_DATA_LIST}?name=${ALIGNMENT_REPORT_FILE_NAME}`
                : null
        );
    const { data: presignedUrl, error: presignedUrlFetchError } = useSWR(
        outputBinaryData?.rows[0]
            ? `${BACKEND_ROUTES.PUBLIC_SIGNED_URL}?key=${outputBinaryData.rows[0].s3Location}`
            : null
    );
    const mergedFetchError =
        missionFetchError ??
        processesFetchError ??
        outputBinaryDataFetchError ??
        presignedUrlFetchError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (
        !mission ||
        !processes ||
        (processes.count > 0 && !outputBinaryData) ||
        (processes.count > 0 && !presignedUrl)
    )
        return <AlignmentModalSkeleton setIsOpen={setIsOpen} />;
    const onClickDownload = () => {
        window.open(presignedUrl, "_blank");
    };
    // TODO: add metadata from the report into the dialog
    return (
        <>
            <DialogTitle>Alignment Report</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Stack spacing={1}>
                    {!presignedUrl && (
                        <Alert severity="error">
                            No alignment report available
                        </Alert>
                    )}
                    <ClientInfoGrid mission={mission} />
                </Stack>
            </DialogContent>
            <DialogActions>
                {presignedUrl ? (
                    <Button variant="outlined" onClick={onClickDownload}>
                        View report PDF
                    </Button>
                ) : (
                    <Button variant="outlined" disabled>
                        No Report Available
                    </Button>
                )}
                <Button type="button" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}

function AlignmentModalSkeleton({ setIsOpen }) {
    return (
        <>
            <DialogTitle>Alignment Report</DialogTitle>
            <Divider variant="middle" />
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="15em"
            />
            <DialogActions>
                <Button type="button" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}
