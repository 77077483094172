import { Container } from "@mui/material";
import Page from "../components/Page";

export default function Contract({ children }) {
    return (
        <Page title="Campaigns | HCC">
            <Container maxWidth="xl">{children}</Container>
        </Page>
    );
}
