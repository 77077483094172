import { Divider, ListItemIcon, MenuItem, TextField } from "@mui/material";
import {
    FORMAT_ELEMENT_COMMAND,
    INDENT_CONTENT_COMMAND,
    OUTDENT_CONTENT_COMMAND,
} from "lexical";

import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import PropTypes from "prop-types";

export const TextAlignment = ({ alignment, disabled, editor }) => (
    <TextField
        disabled={disabled}
        id="text-alignment"
        inputProps={{ sx: { display: "flex" } }}
        select
        value={alignment}
    >
        <MenuItem
            onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
            }}
            value="left"
        >
            <ListItemIcon>
                <FormatAlignLeftIcon />
            </ListItemIcon>
            Left Align
        </MenuItem>
        <MenuItem
            onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
            }}
            value="center"
        >
            <ListItemIcon>
                <FormatAlignCenterIcon />
            </ListItemIcon>
            Center Align
        </MenuItem>
        <MenuItem
            onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
            }}
            value="right"
        >
            <ListItemIcon>
                <FormatAlignRightIcon />
            </ListItemIcon>
            Right Align
        </MenuItem>
        <MenuItem
            onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
            }}
            value="justify"
        >
            <ListItemIcon>
                <FormatAlignJustifyIcon />
            </ListItemIcon>
            Justify Align
        </MenuItem>
        <Divider />
        <MenuItem
            onClick={() => {
                editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
            }}
        >
            <ListItemIcon>
                <FormatIndentIncreaseIcon />
            </ListItemIcon>
            Indent
        </MenuItem>
        <MenuItem
            onClick={() => {
                editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
            }}
        >
            <ListItemIcon>
                <FormatIndentDecreaseIcon />
            </ListItemIcon>
            Outdent
        </MenuItem>
    </TextField>
);

TextAlignment.propTypes = {
    alignment: PropTypes.oneOf(["left", "center", "right", "justify"])
        .isRequired,
    disabled: PropTypes.bool.isRequired,
    // should be `PropTypes.instanceOf(LexicalEditor).isRequired` but the class is not exported
    editor: PropTypes.object.isRequired,
};
