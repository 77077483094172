import { DataSourceForm, InputsForm, OutputsForm } from ".";

import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";

export function ProtocolSimulatorForm() {
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            system: "",
            systemModelUuid: "",
            acquisitionVectorUuid: "",
            sensorUuid: "",
            focalLength35mmManual: null, // focal length used when and only when sensor does not have one fixed
            gsd: null,
            trialLength: 100,
            trialWidth: 100,
            overlap: 80,
            acquisitionPeriod: 2,
        },
    });

    const [results, setResults] = useState({});

    return (
        <Grid container spacing={5}>
            <Grid item xs={4}>
                <DataSourceForm
                    control={control}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={4}>
                <InputsForm
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    setResults={setResults}
                />
            </Grid>
            <Grid item xs={4}>
                <OutputsForm results={results} />
            </Grid>
        </Grid>
    );
}
