import {
    CHART_MODEBAR_BUTTONS_TO_REMOVE,
    TRACE_1_COLOR,
    TRACE_2_COLOR,
} from "../constants";

import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { mean } from "../utils";

AltitudeLinePlot.propTypes = {
    xValues: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ).isRequired,
    altitudesAGL: PropTypes.arrayOf(PropTypes.number).isRequired,
    altitudesMSL: PropTypes.arrayOf(PropTypes.number).isRequired,
    altitudeVariationThreshold: PropTypes.number.isRequired,
    onSelected: PropTypes.func,
    entities: (props, propName, componentName) => {
        if (Boolean(props.onSelected) && !Array.isArray(props[propName])) {
            return new Error(
                `Invalid prop '${propName}' supplied to '${componentName}'. Validation failed.`
            );
        }
    },
    uirevision: PropTypes.any,
};

export function AltitudeLinePlot({
    xValues,
    altitudesAGL,
    altitudesMSL,
    altitudeVariationThreshold,
    onSelected,
    entities,
    uirevision,
}) {
    const altitudeAGLMean = mean(altitudesAGL);
    const altitudeAGLMin = Math.min(...altitudesAGL);
    const altitudeAGLMax = Math.max(...altitudesAGL);
    const altitudeAGLVariation = altitudeAGLMax - altitudeAGLMin;

    const altitudeMSLMin = Math.min(...altitudesMSL);
    const altitudeMSLMax = Math.max(...altitudesMSL);
    const altitudeMSLVariation = altitudeMSLMax - altitudeMSLMin;

    const maxVariation = Math.max(altitudeAGLVariation, altitudeMSLVariation);

    const yaxisMargin = maxVariation * 0.1; // Margin to add to yaxis range to avoid some elements being cut visually

    const traceAGL = {
        x: xValues,
        y: altitudesAGL,
        name: "AGL Altitude",
        type: "scatter",
        mode: "lines+markers",
        marker: {
            color: TRACE_1_COLOR,
        },
        line: {
            color: TRACE_1_COLOR,
        },
    };

    const traceMSL = {
        x: xValues,
        y: altitudesMSL,
        name: "MSL Altitude",
        type: "scatter",
        mode: "lines+markers",
        yaxis: "y2",
        marker: {
            color: TRACE_2_COLOR,
        },
        line: {
            color: TRACE_2_COLOR,
        },
    };

    const shapes = [
        {
            type: "line",
            x0: xValues.at(0),
            y0: altitudeAGLMean,
            x1: xValues.at(-1),
            y1: altitudeAGLMean,
            line: {
                dash: "dot",
                width: 1,
            },
            label: {
                text: "AGL mean",
                textposition: "end",
                yanchor: "top",
            },
        },
        {
            type: "line",
            x0: xValues.at(0),
            y0: altitudeAGLMean - altitudeVariationThreshold,
            x1: xValues.at(-1),
            y1: altitudeAGLMean - altitudeVariationThreshold,
            line: {
                color: "rgb(255, 0, 0)",
                dash: "dash",
                width: 1,
            },
            label: {
                text: `AGL mean - ${altitudeVariationThreshold}m`,
                font: { color: "rgb(255, 0, 0)" },
                textposition: "end",
                yanchor: "top",
            },
        },
        {
            type: "line",
            x0: xValues.at(0),
            y0: altitudeAGLMean + altitudeVariationThreshold,
            x1: xValues.at(-1),
            y1: altitudeAGLMean + altitudeVariationThreshold,
            line: {
                color: "rgb(255, 0, 0)",
                dash: "dash",
                width: 1,
            },
            label: {
                text: `AGL mean + ${altitudeVariationThreshold}m`,
                font: { color: "rgb(255, 0, 0)" },
                textposition: "end",
                yanchor: "top",
            },
        },
    ];

    return (
        <Plot
            data={[traceAGL, traceMSL]}
            layout={{
                dragmode: "select",
                autosize: true,
                legend: { orientation: "h", x: 0.5, xanchor: "center", y: 1.3 },
                uirevision: uirevision,
                shapes: shapes,
                yaxis: {
                    title: "AGL Altitude",
                    titlefont: { color: TRACE_1_COLOR },
                    tickfont: { color: TRACE_1_COLOR },
                    range: [
                        altitudeAGLMin - yaxisMargin,
                        altitudeAGLMin + maxVariation + yaxisMargin,
                    ],
                },
                yaxis2: {
                    title: "MSL Altitude",
                    titlefont: { color: TRACE_2_COLOR },
                    tickfont: { color: TRACE_2_COLOR },
                    overlaying: "y",
                    side: "right",
                    range: [
                        altitudeMSLMin - yaxisMargin,
                        altitudeMSLMin + maxVariation + yaxisMargin,
                    ],
                },
            }}
            onSelected={(eventData) => {
                if (onSelected) {
                    // eventData is undefined in the case of an "unselection" (a double-click for example)
                    if (eventData !== undefined) {
                        const selectedEntities = [];

                        // There is no need to look at point.curveNumber since the two curves "correspond"
                        // However, there can be duplicates therefore.
                        eventData.points.forEach((point) => {
                            selectedEntities.push(entities[point.pointNumber]);
                        });

                        onSelected(selectedEntities);
                    } else onSelected(undefined);
                }
            }}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
            config={{
                displaylogo: false,
                modeBarButtonsToRemove: CHART_MODEBAR_BUTTONS_TO_REMOVE,
            }}
        />
    );
}
