import { Tooltip, Typography } from "@mui/material";

import { ALLOWED_FACTOR_BELOW_MINIMUM_GSD } from "../../../constants";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DataGrid } from "@mui/x-data-grid";
import HelpIcon from "@mui/icons-material/Help";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";
import { calculateBasicStatistics } from "../utils";

const kpiGridColDef = [
    {
        field: "kpiName",
        headerName: "",
        minWidth: 240,
        flex: 1.5,
        sortable: false,
    },
    {
        field: "average",
        headerName: "Average",
        minWidth: 90,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => value.toFixed(2),
    },
    {
        field: "minimum",
        headerName: "Minimum",
        minWidth: 90,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => value.toFixed(2),
    },
    {
        field: "maximum",
        headerName: "Maximum",
        minWidth: 90,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => value.toFixed(2),
    },
    {
        field: "standardDeviation",
        headerName: "Standard deviation",
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => value.toFixed(2),
    },
    {
        field: "limitValue",
        headerName: "Limit value",
        minWidth: 90,
        flex: 1,
        sortable: false,
        renderCell: ({ id, value }) => {
            if (id.startsWith("gsd"))
                return (
                    value ?? (
                        <Tooltip
                            title={
                                <Typography>
                                    Associate a pipeline template and BBCH stage
                                    with traits of this datatype to obtain the
                                    GSD limit value.
                                </Typography>
                            }
                        >
                            <HelpIcon sx={{ color: "grey.500" }} />
                        </Tooltip>
                    )
                );
            else return value;
        },
    },
    {
        field: "validated",
        headerName: "Validated",
        minWidth: 90,
        flex: 1,
        renderCell: ({ value }) =>
            value === 1 || value === true ? (
                <CheckCircleIcon color="success" />
            ) : value === 0 ? (
                <WarningIcon color="warning" />
            ) : value === -1 || value === false ? (
                <CancelIcon color="error" />
            ) : (
                "-"
            ),
        sortable: false,
    },
];

BasicKpiTable.propTypes = {
    filteredDatasetsPerDataType: PropTypes.object.isRequired,
    filteredDatasetsPerChannel: PropTypes.object.isRequired,
    requiredGSD: PropTypes.object.isRequired,
    saturationThreshold: PropTypes.number.isRequired,
    exposureTimeThreshold: PropTypes.number.isRequired,
};

export function BasicKpiTable({
    filteredDatasetsPerDataType,
    filteredDatasetsPerChannel,
    requiredGSD,
    saturationThreshold,
    exposureTimeThreshold,
}) {
    const kpiGridRows = kpiGridRowMaker(
        filteredDatasetsPerDataType,
        filteredDatasetsPerChannel,
        requiredGSD,
        saturationThreshold,
        exposureTimeThreshold
    );

    return (
        <DataGrid
            autoHeight
            rows={kpiGridRows}
            columns={kpiGridColDef}
            disableColumnMenu
            hideFooter
        />
    );
}

function kpiGridRowMaker(
    filteredDatasetsPerDataType,
    filteredDatasetsPerChannel,
    requiredGSD,
    saturationThreshold,
    exposureTimeThreshold
) {
    const kpiGridRows = [];

    Object.keys(filteredDatasetsPerDataType).forEach((dataType) => {
        const {
            mean: altitudeAGLMean,
            min: altitudeAGLMin,
            max: altitudeAGLMax,
            std: altitudeAGLStd,
        } = calculateBasicStatistics(
            filteredDatasetsPerDataType[dataType].altitudesAGL
        );

        const {
            mean: altitudeMSLMean,
            min: altitudeMSLMin,
            max: altitudeMSLMax,
            std: altitudeMSLStd,
        } = calculateBasicStatistics(
            filteredDatasetsPerDataType[dataType].altitudesMSL
        );

        const {
            mean: gsdMean,
            min: gsdMin,
            max: gsdMax,
            std: gsdStd,
        } = calculateBasicStatistics(
            filteredDatasetsPerDataType[dataType].gsds
        );

        kpiGridRows.push(
            {
                id: `altitudeAGL${dataType}`,
                sortId: `a`,
                kpiName: `Altitude AGL (m) (${dataType})`,
                average: altitudeAGLMean,
                minimum: altitudeAGLMin,
                maximum: altitudeAGLMax,
                standardDeviation: altitudeAGLStd,
                limitValue: "-",
                validated: null,
            },
            {
                id: `altitudeMSL${dataType}`,
                sortId: `b`,
                kpiName: `Altitude MSL (m) (${dataType})`,
                average: altitudeMSLMean,
                minimum: altitudeMSLMin,
                maximum: altitudeMSLMax,
                standardDeviation: altitudeMSLStd,
                limitValue: "-",
                validated: null,
            },
            {
                id: `gsd${dataType}`,
                sortId: `c`,
                kpiName: `GSD (mm/px) (${dataType})`,
                average: gsdMean,
                minimum: gsdMin,
                maximum: gsdMax,
                standardDeviation: gsdStd,
                limitValue: requiredGSD[dataType],
                validated:
                    requiredGSD[dataType] != null
                        ? gsdMax <= requiredGSD[dataType]
                            ? 1
                            : gsdMax <=
                                    requiredGSD[dataType] *
                                        ALLOWED_FACTOR_BELOW_MINIMUM_GSD ||
                                Number.isNaN(gsdMax)
                              ? 0
                              : -1
                        : null,
            }
        );
    });

    Object.keys(filteredDatasetsPerChannel).forEach((channel) => {
        const {
            mean: saturationMean,
            min: saturationMin,
            max: saturationMax,
            std: saturationStd,
        } = calculateBasicStatistics(
            filteredDatasetsPerChannel[channel].saturations
        );

        const {
            mean: exposureTimeMean,
            min: exposureTimeMin,
            max: exposureTimeMax,
            std: exposureTimeStd,
        } = calculateBasicStatistics(
            filteredDatasetsPerChannel[channel].exposureTimes
        );

        kpiGridRows.push(
            {
                id: `saturation${channel}`,
                sortId: `d`,
                kpiName: `Saturation (%) (${channel})`,
                average: saturationMean,
                minimum: saturationMin,
                maximum: saturationMax,
                standardDeviation: saturationStd,
                limitValue: saturationThreshold,
                validated: Boolean(saturationMax <= saturationThreshold),
            },
            {
                id: `exposureTime${channel}`,
                sortId: `e`,
                kpiName: `Exposure time (ms) (${channel})`,
                average: exposureTimeMean,
                minimum: exposureTimeMin,
                maximum: exposureTimeMax,
                standardDeviation: exposureTimeStd,
                limitValue: exposureTimeThreshold,
                validated: Boolean(exposureTimeMax <= exposureTimeThreshold),
            }
        );
    });

    kpiGridRows.sort((a, b) =>
        a.sortId.toLowerCase().localeCompare(b.sortId.toLowerCase())
    );

    return kpiGridRows;
}
