/**
 * Returns a string that contains sorted values from a specific key in an array of object, joined with ", ".
 * @param {object[]} array - Array of objects. Objects MUST contain parameters that match the content of keys.
 * @param {string[]} keys - Array of keys, sorted in order of deepness in the object. (Like this: array[0][keys[0]][keys[1]])
 * @returns {string} - The resulting string.
 */
export function objectArrayStringify(array, keys) {
    return [
        ...new Set(
            array.map((item) => keys.reduce((result, key) => result[key], item))
        ),
    ]
        .sort()
        .join(", ");
}
