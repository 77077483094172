import { Box, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { TRACE_1_COLOR, TRACE_2_COLOR } from "../constants";

import { AltitudeLinePlot } from "./AltitudeLinePlot";
import { GraphActionTooltip } from "./GraphActionTooltip";
import { LinePlot } from "./LinePlot";
import PropTypes from "prop-types";
import { useMemo } from "react";

AcquisitionReportAltitudeCard.propTypes = {
    filteredDatasetsPerDataType: PropTypes.object.isRequired,
    dataType: PropTypes.string.isRequired,
    altitudeVariationThreshold: PropTypes.number.isRequired,
    onSelected: PropTypes.func,
    uirevision: PropTypes.any,
};

export function AcquisitionReportAltitudeCard({
    filteredDatasetsPerDataType,
    dataType,
    altitudeVariationThreshold,
    onSelected,
    uirevision,
}) {
    const altitudeAGLDeltas = useMemo(() => {
        // first value must be undefined to make sure the graph is "synced" with the altitude graph
        const finalAltitudeArray = [undefined];
        for (
            let i = 1;
            i < filteredDatasetsPerDataType[dataType].altitudesAGL.length;
            i++
        ) {
            finalAltitudeArray.push(
                filteredDatasetsPerDataType[dataType].altitudesAGL[i] -
                    filteredDatasetsPerDataType[dataType].altitudesAGL[i - 1]
            );
        }
        return finalAltitudeArray;
    }, [dataType, filteredDatasetsPerDataType]);

    const altitudeMSLDeltas = useMemo(() => {
        // first value must be undefined to make sure the graph is "synced" with the altitude graph
        const finalAltitudeArray = [undefined];
        for (
            let i = 1;
            i < filteredDatasetsPerDataType[dataType].altitudesMSL.length;
            i++
        ) {
            finalAltitudeArray.push(
                filteredDatasetsPerDataType[dataType].altitudesMSL[i] -
                    filteredDatasetsPerDataType[dataType].altitudesMSL[i - 1]
            );
        }
        return finalAltitudeArray;
    }, [dataType, filteredDatasetsPerDataType]);
    // first value must be undefined to make sure the graph is "synced" with the altitude graph

    return (
        <Card>
            <CardHeader
                title={
                    <Stack direction="row" justifyContent="space-between">
                        <span>{`Altitude (m) (${dataType})`}</span>
                        <GraphActionTooltip />
                    </Stack>
                }
            />
            <CardContent>
                <Box
                    sx={{
                        position: "relative",
                        width: 1,
                        height: "400px",
                    }}
                >
                    <AltitudeLinePlot
                        xValues={
                            filteredDatasetsPerDataType[dataType].datetimes
                        }
                        altitudesAGL={
                            filteredDatasetsPerDataType[dataType].altitudesAGL
                        }
                        altitudesMSL={
                            filteredDatasetsPerDataType[dataType].altitudesMSL
                        }
                        altitudeVariationThreshold={altitudeVariationThreshold}
                        onSelected={onSelected}
                        entities={
                            filteredDatasetsPerDataType[dataType].datasets
                        }
                        uirevision={uirevision}
                    />
                </Box>
                <Box
                    sx={{
                        position: "relative",
                        width: 1,
                        height: "300px",
                    }}
                >
                    <LinePlot
                        xValues={
                            filteredDatasetsPerDataType[dataType].datetimes
                        }
                        yValues={altitudeAGLDeltas}
                        isMeanDisplayed={false}
                        traceName="AGL Altitude Variation (m): (N - N-1)"
                        color={TRACE_1_COLOR}
                        onSelected={onSelected}
                        entities={
                            filteredDatasetsPerDataType[dataType].datasets
                        }
                        uirevision={uirevision}
                    />
                </Box>

                <Box
                    sx={{
                        position: "relative",
                        width: 1,
                        height: "300px",
                    }}
                >
                    <LinePlot
                        xValues={
                            filteredDatasetsPerDataType[dataType].datetimes
                        }
                        yValues={altitudeMSLDeltas}
                        traceName="MSL Altitude Variation (m): (N - N-1)"
                        color={TRACE_2_COLOR}
                        onSelected={onSelected}
                        entities={
                            filteredDatasetsPerDataType[dataType].datasets
                        }
                        uirevision={uirevision}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}
