import { Divider, List } from "@mui/material";
import { Fragment, useState } from "react";

import { MODEL_PROPTYPES } from "../../../models";
import { MissionListItem } from "./MissionListItem";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

export const MissionList = ({ experimentMissionTraitGroups, showError }) => {
    const { experimentMissionUuid } = useParams();

    const [drawerFilter, setDrawerFilter] = useState(() =>
        experimentMissionTraitGroups.reduce(
            (
                tempState,
                {
                    uuid,
                    Associable: { uuid: associableUuid },
                    PipelineTraitGroups,
                }
            ) => {
                tempState[uuid] = {
                    checked: true,
                    pipelineInstance:
                        associableUuid === experimentMissionUuid
                            ? // Cannot navigate to a validation page if there is no PI
                              PipelineTraitGroups[0].PipelineInstance.uuid
                            : "",
                };

                return tempState;
            },
            {}
        )
    );

    return (
        <List>
            {experimentMissionTraitGroups.map(
                (experimentMissionTraitGroup, index) => (
                    <Fragment key={experimentMissionTraitGroup.uuid}>
                        <MissionListItem
                            experimentMissionTraitGroup={
                                experimentMissionTraitGroup
                            }
                            missionRowState={
                                drawerFilter[experimentMissionTraitGroup.uuid]
                            }
                            setDrawerFilter={setDrawerFilter}
                            showError={showError}
                        />
                        {index + 1 !== experimentMissionTraitGroups.length && (
                            <Divider />
                        )}
                    </Fragment>
                )
            )}
        </List>
    );
};

MissionList.propTypes = {
    experimentMissionTraitGroups: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.ExperimentMissionTraitGroup).isRequired
    ).isRequired,
    showError: PropTypes.bool.isRequired,
};
