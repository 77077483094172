import { absoluteHeight, getGsdPerStage, roundNumber } from "../../utils";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { DATATYPES_ENUM } from "../../../constants";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";

/* Type gaugeValuesByDataType:
 *
 * type dataObject = { sum: number; count: number; value: number | null }
 *
 * Partial<
 *   Record<
 *     Exclude<DATATYPES_ENUM, DATATYPES_ENUM.MULTISPECTRAL>,
 *     { GSD: dataObject; saturation: dataObject; blackSaturation: dataObject; altitude: dataObject }
 *   > & {
 *     [DATATYPES_ENUM.MULTISPECTRAL]: {
 *       GSD: dataObject;
 *       altitude: dataObject;
 *       saturation: dataObject & { channels: Record<string, dataObject> };
 *       blackSaturation: dataObject & { channels: Record<string, dataObject> };
 *     };
 *   }
 * >
 */
export const useMetricsValues = ({
    datasetsByDataType,
    filteredDataTypes,
    requiredGSDByDatatype,
    selectedBbchStage,
    selectedPipelineTemplate,
}) => {
    const { data: bbchStageData } = useSWRImmutable(BACKEND_ROUTES.BBCH_STAGE);

    return useMemo(() => {
        // we can't have a bbchstage without a pipeline template
        if (!selectedBbchStage) {
            return undefined;
        }
        const gaugeValuesByDataType = {};
        const gsdPerStage = getGsdPerStage(
            selectedPipelineTemplate,
            bbchStageData.rows
        );

        filteredDataTypes.forEach((dataType) => {
            const gaugeValues = {
                altitude: { sum: 0, count: 0 },
                GSD: { sum: 0, count: 0, max: null },
                saturation: { sum: 0, count: 0, channels: {} },
                blackSaturation: { sum: 0, count: 0, channels: {} },
                altitudeOutlier: { sum: 0, count: 0 },
            };
            // `valueLimit` cannot be null as `filteredDataTypes` is created with the non null keys of `requiredGSDByDatatype`
            const valueLimit = requiredGSDByDatatype[dataType];
            const currentDatasets = datasetsByDataType[dataType];

            // `height` should never be null but handle case anyway
            const height = absoluteHeight({
                gsd: gsdPerStage.find(
                    (gsd) => gsd.order === selectedBbchStage.order
                )?.gsd[dataType],
                pipelineTemplate: selectedPipelineTemplate,
                dataType,
            });
            const targetAltitude = height ? roundNumber(height, 0) : null;

            currentDatasets.forEach((dataset) => {
                if (!dataset.metadata) {
                    return;
                }

                const {
                    channel = dataType,
                    altitude_agl: altitude,
                    GSD,
                    saturation,
                    black_saturation: blackSaturation,
                } = dataset.metadata;

                if (GSD != null) {
                    gaugeValues.GSD.sum += GSD < valueLimit ? 1 : 0;
                    gaugeValues.GSD.count++;
                    if (
                        gaugeValues.GSD.max === null ||
                        GSD > gaugeValues.GSD.max
                    ) {
                        gaugeValues.GSD.max = GSD;
                    }
                }

                if (saturation != null) {
                    gaugeValues.saturation.channels[channel] ??= {
                        sum: 0,
                        count: 0,
                    };
                    gaugeValues.saturation.channels[channel].sum += saturation;
                    gaugeValues.saturation.channels[channel].count++;
                }

                if (blackSaturation != null) {
                    gaugeValues.blackSaturation.channels[channel] ??= {
                        sum: 0,
                        count: 0,
                    };
                    gaugeValues.blackSaturation.channels[channel].sum +=
                        blackSaturation;
                    gaugeValues.blackSaturation.channels[channel].count++;
                }

                // `targetAltitude` can be 0 or null
                if (targetAltitude && altitude != null) {
                    const deviation = 1 - altitude / targetAltitude;
                    gaugeValues.altitude.sum += deviation;
                    gaugeValues.altitude.count++;
                    gaugeValues.altitudeOutlier.count++;
                    if (Math.abs(deviation) > 0.2) {
                        gaugeValues.altitudeOutlier.sum++;
                    }
                }
            });

            [gaugeValues.saturation, gaugeValues.blackSaturation].forEach(
                (saturationObject) => {
                    Object.keys(saturationObject.channels).forEach(
                        (channel) => {
                            const { sum, count } =
                                saturationObject.channels[channel];

                            if (count) {
                                saturationObject.channels[channel].average =
                                    sum / count;
                                saturationObject.sum += sum;
                                saturationObject.count += count;
                            } else {
                                saturationObject.channels[channel].average =
                                    null;
                            }
                        }
                    );
                }
            );

            // If not multispectral there is only one channel which is the datatype
            if (dataType !== DATATYPES_ENUM.MULTISPECTRAL) {
                delete gaugeValues.saturation.channels;
                delete gaugeValues.blackSaturation.channels;
            }

            Object.keys(gaugeValues).forEach((key) => {
                const metadataGaugeValue = gaugeValues[key];

                metadataGaugeValue.average = metadataGaugeValue.count
                    ? Math.round(
                          (metadataGaugeValue.sum / metadataGaugeValue.count) *
                              100
                      )
                    : null;
            });

            gaugeValuesByDataType[dataType] = gaugeValues;
        });

        return gaugeValuesByDataType;
    }, [
        bbchStageData,
        datasetsByDataType,
        filteredDataTypes,
        requiredGSDByDatatype,
        selectedBbchStage,
        selectedPipelineTemplate,
    ]);
};
