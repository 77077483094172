export const tooltipStyleOverride = {
    MuiTooltip: {
        styleOverrides: {
            tooltip: ({ theme }) => ({
                backgroundColor: theme.palette.grey[800],
                maxWidth: "550px",
            }),
            arrow: ({ theme }) => ({
                color: theme.palette.grey[800],
            }),
        },
    },
};
