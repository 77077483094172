import {
    Container,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Skeleton,
} from "@mui/material";

export const CompanyFormSkeleton = () => {
    return (
        <Container maxWidth="xl">
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="3em"
            />
            <Divider variant="middle" />
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height="2em"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height="2em"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height="2em"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height="2em"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="20%"
                    height="2em"
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="20%"
                    height="2em"
                />
            </DialogActions>
        </Container>
    );
};
