import {
    Checkbox,
    Collapse,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    TextField,
} from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { compareDesc, parseISO } from "date-fns";
import { useMemo, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { MODEL_PROPTYPES } from "../../../models";
import { PipelineInstance } from "./PipelineInstance";
import PropTypes from "prop-types";
import { rcompare } from "semver";

export const TraitGroup = ({
    fieldArrayName,
    name,
    pipelines,
    pipelineInstances,
    validatedPipelineInstance,
}) => {
    const { setValue } = useFormContext();
    const [open, setOpen] = useState(false);

    const noPipelines = !pipelines.length;
    const versionedModes = useMemo(
        () => getVersionedModes(pipelines),
        [pipelines]
    );

    // Sort PI by createdAt DESC and unshift validated PI
    const sortedPipelineInstances = useMemo(() => {
        const tempSortedPI = pipelineInstances.toSorted(
            ({ createdAt: createdAt1 }, { createdAt: createdAt2 }) =>
                compareDesc(parseISO(createdAt1), parseISO(createdAt2))
        );

        if (validatedPipelineInstance) {
            const index = tempSortedPI.findIndex(
                ({ uuid }) => uuid === validatedPipelineInstance.uuid
            );

            // The validated pipeline instance should always be find in the pipeline instance list
            // Do not unshift if already at the top
            if (index > 0) {
                const pipelineInstanceToUnshift = tempSortedPI[index];

                tempSortedPI.splice(index, 1);
                tempSortedPI.unshift(pipelineInstanceToUnshift);
            }
        }

        return tempSortedPI;
    }, [pipelineInstances, validatedPipelineInstance]);

    const modes = Object.keys(versionedModes);
    const defaultMode = noPipelines
        ? ""
        : // There should always be a default mode this is a failsafe
          modes.find((key) => versionedModes[key].isDefault) ?? modes[0];

    const { mode: selectedMode, checked } = useWatch({
        name: fieldArrayName,
        defaultValue: { mode: defaultMode, checked: true },
    });

    const versions = versionedModes[selectedMode]?.versions ?? [];

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton
                    disabled={noPipelines}
                    onClick={() => setOpen(!open)}
                >
                    <ListItemIcon>
                        <Controller
                            name={`${fieldArrayName}.checked`}
                            defaultValue={!noPipelines}
                            disabled={noPipelines}
                            render={({
                                field: { onChange, value, ...field },
                            }) => (
                                <Checkbox
                                    {...field}
                                    edge="end"
                                    checked={value}
                                    onChange={(event) =>
                                        onChange(event.target.checked)
                                    }
                                    onMouseDown={(event) =>
                                        event.stopPropagation()
                                    }
                                    // A user cannot uncheck a trait group until further notice
                                    disabled
                                />
                            )}
                        />
                    </ListItemIcon>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-evenly"
                        sx={{
                            color: checked ? "currentcolor" : "action.disabled",
                        }}
                    >
                        <Grid item xs={3}>
                            <ListItemText primary={name} />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name={`${fieldArrayName}.mode`}
                                defaultValue={defaultMode}
                                disabled={noPipelines}
                                render={({
                                    field: { onChange, disabled, ...field },
                                }) => (
                                    <TextField
                                        {...field}
                                        id={`${fieldArrayName}.mode`}
                                        disabled={disabled || !checked}
                                        label="Mode"
                                        fullWidth
                                        select
                                        onMouseDown={(event) =>
                                            event.stopPropagation()
                                        }
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        onChange={(event) => {
                                            onChange(event.target.value);
                                            setValue(
                                                `${fieldArrayName}.version`,
                                                versionedModes[
                                                    event.target.value
                                                ].versions[0]
                                            );
                                        }}
                                    >
                                        {Object.keys(versionedModes).map(
                                            (mode) => (
                                                <MenuItem
                                                    key={mode}
                                                    value={mode}
                                                >
                                                    {mode}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name={`${fieldArrayName}.version`}
                                defaultValue={versions[0] ?? ""}
                                disabled={noPipelines}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id={`${fieldArrayName}.version`}
                                        label="Version"
                                        fullWidth
                                        select
                                        disabled
                                        onMouseDown={(event) =>
                                            event.stopPropagation()
                                        }
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                    >
                                        {versions.map((version) => (
                                            <MenuItem
                                                key={version}
                                                value={version}
                                            >
                                                {version}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        </Grid>
                    </Grid>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    sx={{
                        mx: 7,
                        maxHeight: 220,
                        overflowY:
                            sortedPipelineInstances.length > 4
                                ? "scroll"
                                : "auto",
                    }}
                    disablePadding
                    dense
                >
                    {sortedPipelineInstances.map((pipelineInstance) => (
                        <PipelineInstance
                            key={pipelineInstance.uuid}
                            isValidated={
                                validatedPipelineInstance?.uuid ===
                                pipelineInstance.uuid
                            }
                            pipelineInstance={pipelineInstance}
                            // Once associated to a pipeline instance a pipeline can't be deleted, there should always be one
                            pipeline={pipelines.find(
                                ({ uuid }) =>
                                    uuid ===
                                    pipelineInstance.PipelineTraitGroup
                                        .pipelineUuid
                            )}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
};

// Returns an object of the form { [mode]: { versions[], defaultMode?: string } }
const getVersionedModes = (pipelines) => {
    const versionedModes = pipelines.reduce(
        (tempVersionedModes, { mode, version, default: isDefaultMode }) => {
            if (tempVersionedModes[mode]) {
                tempVersionedModes[mode].versions.push(version);
            } else {
                tempVersionedModes[mode] = {
                    versions: [version],
                    // A mode is the default one for a TGDT/system pair, it should not depend on the version
                    isDefault: isDefaultMode,
                };
            }

            return tempVersionedModes;
        },
        {}
    );

    // Sort versions in each mode for each trait group
    Object.values(versionedModes).forEach(({ versions }) => {
        versions.sort(rcompare);
    });

    return versionedModes;
};

TraitGroup.propTypes = {
    fieldArrayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pipelines: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.Pipeline).isRequired
    ).isRequired,
    pipelineInstances:
        MODEL_PROPTYPES.ExperimentMissionTraitGroup.PipelineInstances
            .isRequired,
    validatedPipelineInstance:
        MODEL_PROPTYPES.ExperimentMissionTraitGroup.ValidatedPipelineInstance,
};
