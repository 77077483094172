import { Skeleton, Stack } from "@mui/material";

export const MissionTableSkeleton = () => {
    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="15%"
                    height="2em"
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="20%"
                    height="2em"
                />
            </Stack>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="30em"
            />
        </>
    );
};
