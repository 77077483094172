import { eachMonthOfInterval, format, parseISO } from "date-fns";

import { TRAIT_GROUP_CATEGORY } from "../../../constants";
import { useMemo } from "react";

/**
 * If isCreation true and no pipelineTemplate: we are creating a new pipeline template.
 * If isCreation true and pipelineTemplate provided: we are cloning an existing pipeline template.
 * If isCreation false: pipelineTemplate must be provided and we are modifying an existing pipeline template.
 */
export const useDefaultValues = ({
    pipelineTemplate,
    isCreation,
    traitGroupDataTypeData,
    bbchStageData,
    contract,
}) =>
    useMemo(() => {
        // If isCreation false, pipelineTemplate must be provided
        if (!isCreation && !pipelineTemplate)
            throw new TypeError(
                "pipelineTemplate must be provided if isCreation is false"
            );

        const calendarsValue = pipelineTemplate
            ? pipelineTemplate.PipelineTemplateCalendars.map((calendar) => ({
                  count: calendar.count,
                  date: calendar.date,
              }))
            : // we use date-fns' parseISO to create date objects for local-time date inputs of eachMonthOfInterval
              // since it parses according to local time even for date-only strings
              eachMonthOfInterval({
                  start: parseISO(contract.startDate),
                  end: parseISO(contract.endDate),
              }).map((month) => ({
                  count: undefined,
                  date: format(month, "yyyy-MM-dd"),
              }));

        const dateCount = pipelineTemplate
            ? calendarsValue.reduce(
                  (previousValue, currentValue) =>
                      previousValue + currentValue.count,
                  0
              )
            : 0;

        /**
         * bbchTraitArrays is an object whose keys are a traitGroupDataTypeUuid, with each value corresponding to an array of bbchStageUuids.
         * bbchTraitArrays={[traitGroupDataTypeUuid]: [bbch1, bbch2, etc.]}
         */
        const bbchTraitsValue = pipelineTemplate
            ? pipelineTemplate.PipelineTemplateTraitGroups.reduce(
                  (previous, PTTG) => {
                      if (previous[PTTG.traitGroupDataTypeUuid]) {
                          previous[PTTG.traitGroupDataTypeUuid].push(
                              PTTG.bbchStageUuid
                          );
                      } else {
                          previous[PTTG.traitGroupDataTypeUuid] = [
                              PTTG.bbchStageUuid,
                          ];
                      }
                      return previous;
                  },
                  {}
              )
            : {};

        /**
         * flightSequences is an object where each key is a bbch stage uuid and the value is the corresponding sequence value.
         * The default value will have a key for each bbch stage, whose value then comes from the pipeline template's flightSequences,
         * or an empty string if it does not exist.
         */
        const flightSequences = Object.fromEntries(
            bbchStageData.rows.map((bbchStage) => {
                const sequence =
                    pipelineTemplate?.FlightSequences.find(
                        (flightSequence) =>
                            flightSequence.bbchStageUuid === bbchStage.uuid
                    )?.sequence ?? "";
                return [bbchStage.uuid, sequence];
            })
        );

        return pipelineTemplate
            ? {
                  name: isCreation
                      ? `${pipelineTemplate.name} - Clone`
                      : pipelineTemplate.name,
                  cropUuid: pipelineTemplate.cropUuid,
                  system: pipelineTemplate.AcquisitionVector.SystemModel.system,
                  systemModelUuid:
                      pipelineTemplate.AcquisitionVector.SystemModel.uuid,
                  acquisitionVectorUuid: pipelineTemplate.acquisitionVectorUuid,
                  siteCount: pipelineTemplate.siteCount,
                  dateCount: dateCount.toString(),
                  featureCount: pipelineTemplate.featureCount,
                  deadlineType: pipelineTemplate.deadlineType,
                  plotMapProvider: pipelineTemplate.plotMapProvider,
                  focalLength35mmEqv: pipelineTemplate.focalLength35mmEqv,
                  /**
                   * canopyDevelopment, biomassProxy, trialQuality, harvestIndex, and plantStress, are arrays of selected trait group data types, per trait category
                   * canopyDevelopment contains all selected trait group data types for the category "Canopy Development", and so on.
                   * */
                  canopyDevelopment: traitGroupDataTypeData.rows.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.CANOPY_DEVELOPMENT &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  biomassProxy: traitGroupDataTypeData.rows.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.BIOMASS_PROXY &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  trialQuality: traitGroupDataTypeData.rows.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.TRIAL_QUALITY &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  harvestIndex: traitGroupDataTypeData.rows.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.HARVEST_INDEX_AND_QUALITY &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  plantStress: traitGroupDataTypeData.rows.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.PLANT_STRESS &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  calendars: calendarsValue,
                  bbchTraits: bbchTraitsValue,
                  flightSequences,
              }
            : {
                  name: "",
                  cropUuid: "",
                  system: "",
                  systemModelUuid: "",
                  acquisitionVectorUuid: "",
                  siteCount: null,
                  dateCount: dateCount.toString(),
                  featureCount: null,
                  deadlineType: "",
                  plotMapProvider: "",
                  focalLength35mmEqv: null,
                  calendars: calendarsValue,
                  bbchTraits: bbchTraitsValue,
                  canopyDevelopment: [],
                  biomassProxy: [],
                  trialQuality: [],
                  harvestIndex: [],
                  plantStress: [],
                  flightSequences,
              };
    }, [
        contract,
        pipelineTemplate,
        traitGroupDataTypeData,
        bbchStageData,
        isCreation,
    ]);
