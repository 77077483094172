import { Card, Stack, Typography } from "@mui/material";
import { PAGINATION, REFERENCE_OBJECT } from "../../constants";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CropTableSkeleton } from "./CropTableSkeleton";
import { DataGrid } from "@mui/x-data-grid";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import Scrollbar from "../../components/Scrollbar";
import { useMemo } from "react";
import useSWR from "swr";

const pageItemCount = 100;

export function CropTable() {
    const { data: crops, error: cropsFetchError } = useSWR(
        `${BACKEND_ROUTES.CROP}?plantScales=true&limit=${PAGINATION.GENERIC.LIMIT.MAX}`
    );
    const { data: plantProfiles, error: plantProfilesFetchError } = useSWR(
        BACKEND_ROUTES.PLANT_PROFILE
    );
    const { data: referenceObjects, error: referenceObjectsFetchError } =
        useSWR(BACKEND_ROUTES.REFERENCE_OBJECT);

    const gridRows = useMemo(() => {
        if (crops && plantProfiles && referenceObjects) {
            const leafWidthObject = referenceObjects.rows.find(
                (refObject) => refObject.name === REFERENCE_OBJECT.LEAF_WIDTH
            );

            const plantSizeObject = referenceObjects.rows.find(
                (refObject) => refObject.name === REFERENCE_OBJECT.PLANT_SIZE
            );

            const organWidthObject = referenceObjects.rows.find(
                (refObject) => refObject.name === REFERENCE_OBJECT.ORGAN_WIDTH
            );
            return crops.rows.map((crop) => {
                const leafWidth = crop.PlantScales.find(
                    (plantScale) =>
                        leafWidthObject?.uuid === plantScale.referenceObjectUuid
                );
                const leafWidthProfile = leafWidth
                    ? plantProfiles.rows.find(
                          (profile) => profile.sizeMean === leafWidth.size
                      )
                    : null;
                const plantSize = crop.PlantScales.find(
                    (plantScale) =>
                        plantSizeObject?.uuid === plantScale.referenceObjectUuid
                );
                const plantSizeProfile = plantSize
                    ? plantProfiles.rows.find(
                          (profile) => profile.sizeMean === plantSize.size
                      )
                    : null;
                const organWidth = crop.PlantScales.find(
                    (plantScale) =>
                        organWidthObject?.uuid ===
                        plantScale.referenceObjectUuid
                );
                const organWidthProfile = organWidth
                    ? plantProfiles.rows.find(
                          (profile) => profile.sizeMean === organWidth.size
                      )
                    : null;
                return {
                    id: crop.uuid,
                    uuid: crop.uuid,
                    name: crop.name,
                    leafWidth: leafWidth
                        ? leafWidthProfile
                            ? leafWidthProfile.name
                            : leafWidth.size
                        : "",
                    plantSize: plantSize
                        ? plantSizeProfile
                            ? plantSizeProfile.name
                            : plantSize.size
                        : "",
                    organWidth: organWidth
                        ? organWidthProfile
                            ? organWidthProfile.name
                            : organWidth.size
                        : "",
                };
            });
        } else {
            return [];
        }
    }, [crops, plantProfiles, referenceObjects]);

    const mergedFetchError =
        cropsFetchError ??
        plantProfilesFetchError ??
        referenceObjectsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!crops || !plantProfiles || !referenceObjects) {
        return <CropTableSkeleton />;
    }

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "name",
            headerName: "Crop name",
            minWidth: 250,
            flex: 2,
        },
        {
            field: "leafWidth",
            headerName: "Leaf Width",
            minWidth: 125,
            flex: 2,
        },

        {
            field: "plantSize",
            headerName: "Plant Size",
            minWidth: 125,
            flex: 2,
        },

        {
            field: "organWidth",
            headerName: "Organ Width",
            minWidth: 125,
            flex: 2,
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h4" gutterBottom>
                    Crops
                </Typography>
            </Stack>

            <Card>
                <Scrollbar>
                    <DataGrid
                        autoHeight
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                        rowCount={crops.count}
                    />
                </Scrollbar>
            </Card>
        </>
    );
}
