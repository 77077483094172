import { Alert, Container } from "@mui/material";
import { DEFAULT_ERROR_MESSAGE, NOT_FOUND_ERROR_MESSAGE } from "../constants";

import PropTypes from "prop-types";

FetchErrorAlert.propTypes = {
    error: PropTypes.shape({ status: PropTypes.number.isRequired }).isRequired,
};

/**
 * Error alert message to show in case of a GET (useSWR) error.
 * The main interest is to show "Not found" for a GET operation
 * in the case of a 400 or 404 error, instead of showing "Invalid value" or "An error occurred".
 * @param {object} props.error - Error object returned by the fetcher (in fetchUtils).
 * @returns {ReactComponent}
 */
export function FetchErrorAlert({ error }) {
    let errorMessage;
    if (error.status === 400 || error.status === 404)
        errorMessage = NOT_FOUND_ERROR_MESSAGE;
    else errorMessage = DEFAULT_ERROR_MESSAGE;

    return (
        <Container maxWidth="xl">
            <Alert severity="error">{errorMessage}</Alert>
        </Container>
    );
}
