import {
    Checkbox,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Paper,
    Typography,
} from "@mui/material";
import { forwardRef, memo, useCallback, useMemo, useState } from "react";

import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import Scrollbar from "../../../components/Scrollbar";
import { Virtuoso } from "react-virtuoso";

export const ImageList = ({
    checkedImages,
    images,
    listType,
    setCheckedImages,
    title,
    onImageSelect,
}) => {
    const [simplebar, setSimplebar] = useState(null);

    const handleToggle = useCallback(
        (image) =>
            setCheckedImages((prevState) => {
                const index = prevState[listType].findIndex(
                    ({ uuid }) => uuid === image.uuid
                );

                return {
                    ...prevState,
                    [listType]:
                        index === -1
                            ? [...prevState[listType], image]
                            : prevState[listType].toSpliced(index, 1),
                };
            }),
        [listType, setCheckedImages]
    );

    const components = useMemo(
        () => ({
            List: forwardRef((props, ref) => (
                <List
                    {...props}
                    dense
                    component="div"
                    subheader={
                        <ListSubheader sx={{ lineHeight: "inherit" }}>
                            <Typography variant="h6" color="text.primary">
                                {title}
                            </Typography>
                            {`${checkedImages.length}/${images.length} selected`}
                        </ListSubheader>
                    }
                    ref={ref}
                />
            )),
        }),
        [checkedImages.length, images.length, title]
    );

    return (
        <Paper
            elevation={1}
            sx={{ width: 220, height: "100%", pb: 1, pl: 0.5 }}
        >
            <Scrollbar sx={{ height: "100%" }} ref={setSimplebar}>
                <Virtuoso
                    computeItemKey={(_, image) => image.uuid}
                    data={images}
                    customScrollParent={simplebar?.contentWrapperEl}
                    components={components}
                    itemContent={(_, image) => (
                        <ImageListItem
                            image={image}
                            checked={checkedImages.some(
                                (checkedImage) =>
                                    checkedImage.uuid === image.uuid
                            )}
                            handleToggle={handleToggle}
                            onImageSelect={onImageSelect}
                        />
                    )}
                />
            </Scrollbar>
        </Paper>
    );
};

const ImageListItem = memo(
    ({ image, checked, handleToggle, onImageSelect }) => (
        <ListItemButton
            onClick={() => onImageSelect(image)}
            sx={{ padding: 0 }}
        >
            <Checkbox
                checked={checked}
                id={image.uuid}
                tabIndex={-1}
                disableRipple
                sx={{ padding: 0 }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleToggle(image);
                }}
            />
            <ListItemText
                primary={image.metadata?.datetime || "N/A"}
                sx={{ marginLeft: 1 }}
            />
        </ListItemButton>
    )
);

ImageList.propTypes = {
    checkedImages: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    images: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    listType: PropTypes.oneOf(["left", "right"]).isRequired,
    setCheckedImages: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    onImageSelect: PropTypes.func.isRequired,
};
