import {
    DialogContent,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { MissionProtocolSensorData, MissionProtocolStageTable } from ".";

import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";

MissionProtocolDetails.propTypes = {
    pipelineTemplate: PropTypes.object.isRequired,
    bbchStageData: PropTypes.object.isRequired,
    traitGroupDataTypeArrays: PropTypes.object.isRequired,
    gsdPerStage: PropTypes.array.isRequired,
    minGSD: PropTypes.object.isRequired,
    displayTraits: PropTypes.bool.isRequired,
    maxTraitCount: PropTypes.number.isRequired,
    errors: PropTypes.any.isRequired,
    register: PropTypes.func.isRequired,
    printed: PropTypes.bool,
};

export function MissionProtocolDetails({
    pipelineTemplate,
    bbchStageData,
    traitGroupDataTypeArrays,
    gsdPerStage,
    minGSD,
    displayTraits,
    maxTraitCount,
    errors,
    register,
    printed = false,
}) {
    return (
        <DialogContent>
            <Grid container spacing={5}>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={3}>
                        <Stack spacing={2}>
                            <MissionProtocolSensorData
                                pipelineTemplate={pipelineTemplate}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={9}>
                        <MissionProtocolStageTable
                            pipelineTemplate={pipelineTemplate}
                            bbchStageData={bbchStageData}
                            traitGroupDataTypeArrays={traitGroupDataTypeArrays}
                            gsdPerStage={gsdPerStage}
                            minGSD={minGSD}
                            displayTraits={displayTraits}
                            maxTraitCount={maxTraitCount}
                            printed={printed}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Typography>
                            <WarningIcon color="warning" /> means the targeted
                            flight altitude is slightly lower than the minimum
                            altitude the selected UAV can fly at. The trait will
                            still be calculated.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <CloseIcon color="error" /> means the targeted
                            flight altitude is much lower than the minimum
                            altitude the selected UAV can fly at. The trait will
                            not be calculated.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Minimum overlap expected should be 75% (side
                            overlap) / 80% (front overlap) if the drone allows
                            it.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Minimum number of ground control points (GCP) should
                            be 6 per hectare (or 6 per 2 acres).
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            For plant count, plant leaves should not overlap
                            each other so that individual plants are clearly
                            distinguishable.
                        </Typography>
                    </Grid>
                </Grid>
                {!printed && (
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={12}>
                            <Typography>Comments</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="comment"
                                fullWidth
                                type="text"
                                error={Boolean(errors.comment)}
                                helperText={errors.comment?.message}
                                multiline
                                placeholder="Type additional information here"
                                rows={6}
                                InputProps={{ "white-space": "pre-wrap" }}
                                {...register("comment")}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </DialogContent>
    );
}
