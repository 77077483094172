import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    MenuItem,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CountryFlag } from "../../components/CountryFlag";
import { INPUT_VALIDATION } from "../../constants";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { Stack } from "@mui/system";
import { iso31661 } from "iso-3166";
import { useMemo } from "react";

SiteCreateForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
};

export function SiteCreateForm({ contractUuid, closeModal, mutate, users }) {
    const { openSnackbar } = useSnackbar();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            name: "",
            supervisorUuid: "",
            countryCode: null,
        },
    });

    const countriesArray = useMemo(
        () =>
            iso31661.sort((countryA, countryB) =>
                countryA.name > countryB.name ? 1 : -1
            ),
        []
    );

    const { post } = useFetch();

    const onSubmit = async (payload) => {
        const createdSite = await post(BACKEND_ROUTES.SITE, {
            body: {
                ...payload,
                contractUuid,
                countryCode: payload.countryCode?.alpha3 ?? null,
            },
        });

        if (createdSite) {
            mutate();
            closeModal();
            openSnackbar(
                `Site ${createdSite.name} created successfully.`,
                "success"
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create a new site</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Stack spacing={3}>
                    <TextField
                        id="name"
                        fullWidth
                        type="text"
                        label="Name *"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...register("name", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                    <Controller
                        control={control}
                        name="supervisorUuid"
                        render={({ field: { ref, ...fieldProps } }) => (
                            <TextField
                                {...fieldProps}
                                fullWidth
                                id="supervisorUuid"
                                select
                                label="Supervisor *"
                                inputRef={ref}
                                error={Boolean(errors.supervisorUuid)}
                                helperText={errors.supervisorUuid?.message}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user.uuid} value={user.uuid}>
                                        {`${user.firstName} ${user.lastName}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                        }}
                    />
                    <Controller
                        control={control}
                        name="countryCode"
                        render={({
                            field: { ref, onChange, ...fieldProps },
                        }) => (
                            <Autocomplete
                                {...fieldProps}
                                id="countryCode"
                                options={countriesArray}
                                isOptionEqualToValue={(option, value) =>
                                    option.alpha3 === value.alpha3
                                }
                                fullWidth
                                onChange={(_, value) => {
                                    onChange(value);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box {...props} component="li">
                                        <Stack direction="row" spacing={1}>
                                            <CountryFlag country={option} />
                                            <span>{option.name}</span>
                                        </Stack>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={ref}
                                        label="Country"
                                    />
                                )}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
