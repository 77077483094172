import { Tooltip, Typography } from "@mui/material";

import PropTypes from "prop-types";

FlagTooltipIcon.propTypes = {
    Icon: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
};

export function FlagTooltipIcon({ Icon, message }) {
    return (
        <Tooltip
            slotProps={{
                tooltip: {
                    sx: {
                        maxWidth: "none",
                    },
                },
            }}
            title={<Typography>{message}</Typography>}
        >
            <Icon fontSize="small" color="warning" />
        </Tooltip>
    );
}
