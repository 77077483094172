import { Container, Skeleton, Stack } from "@mui/material";

import Page from "../../components/Page";

export const ProcessTableSkeleton = () => {
    return (
        <Page title="Process Table | HCC">
            <Container maxWidth="xl">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                >
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="15%"
                        height="2em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="20%"
                        height="2em"
                    />
                </Stack>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="30em"
                />
            </Container>
        </Page>
    );
};
