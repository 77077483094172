export const initialState = {
    isBold: false,
    isItalic: false,
    isUnderline: false,
    isStrikethrough: false,
    isSubscript: false,
    isSuperscript: false,
    isCode: false,
    canUndo: false,
    canRedo: false,
    blockType: "paragraph",
    fontSize: "15px",
    fontColor: "#000000",
    bgColor: "#ffffff",
    alignment: "left",
};

export const SET_STYLES = "SET_STYLES";
export const SET_CAN_UNDO = "SET_CAN_UNDO";
export const SET_CAN_REDO = "SET_CAN_REDO";
export const SET_FONT_SIZE = "SET_FONT_SIZE";

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_STYLES:
            return { ...state, ...action.styles };
        case SET_CAN_UNDO:
            return { ...state, canUndo: action.canUndo };
        case SET_CAN_REDO:
            return { ...state, canRedo: action.canRedo };
        case SET_FONT_SIZE:
            return { ...state, fontSize: action.fontSize };
        default:
            return state;
    }
};
