import {
    ContractDetails,
    ContractForm,
    ContractTable,
} from "./features/contract";
import {
    InboundTrafficAssociation,
    InboundTrafficTable,
} from "./features/traffic/inbound";
import { Navigate, useRoutes } from "react-router-dom";

import AcquisitionReportPage from "./pages/AcquisitionReportPage";
import Booking from "./pages/Booking";
import Company from "./pages/Company";
import { CompletedTraffic } from "./features/traffic";
import CompletedTrafficPage from "./pages/CompletedTrafficPage";
import Contract from "./pages/Contract";
import CropGlossary from "./pages/CropGlossary";
import { FRONTEND_ROUTES } from "./frontendRoutes";
import InboundTraffic from "./pages/InboundTraffic";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import NotFound from "./pages/Page404";
import OrdersLayout from "./layouts/orders";
import Parcellaire from "./pages/Parcellaire";
import { ProcessTable } from "./features/process/ProcessTable";
import { ProcessingTraffic } from "./features/traffic";
import ProcessingTrafficPage from "./pages/ProcessingTrafficPage";
import { RequireAuth } from "./components/RequireAuth";
import Routing from "./pages/Routing";
import SensorGlossary from "./pages/SensorGlossary";
import Site from "./pages/Site";
import { SiteDetails } from "./features/site";
import SystemModelGlossary from "./pages/SystemModelGlossary";
import TraitGlossary from "./pages/TraitGlossary";
import Validation from "./pages/Validation";

export default function Router() {
    return useRoutes([
        {
            path: FRONTEND_ROUTES.ORDERS,
            element: (
                <RequireAuth>
                    <OrdersLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={FRONTEND_ROUTES.COMPANY} />,
                },
                {
                    path: FRONTEND_ROUTES.COMPANY,
                    element: <Company />,
                },
                {
                    path: FRONTEND_ROUTES.CONTRACT_CREATE,
                    element: (
                        <Contract>
                            <ContractForm />
                        </Contract>
                    ),
                },
                {
                    path: FRONTEND_ROUTES.CONTRACT,
                    element: (
                        <Contract>
                            <ContractTable />
                        </Contract>
                    ),
                },
                {
                    path: `${FRONTEND_ROUTES.CONTRACT}/:uuid`,
                    element: (
                        <Contract>
                            <ContractDetails />
                        </Contract>
                    ),
                },
                {
                    path: `${FRONTEND_ROUTES.CONTRACT}/:uuid/${FRONTEND_ROUTES.SITE}/:siteUuid`,
                    element: (
                        <Site>
                            <SiteDetails />
                        </Site>
                    ),
                },
            ],
        },
        {
            path: FRONTEND_ROUTES.MONITORING,
            element: (
                <RequireAuth>
                    <OrdersLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={`${FRONTEND_ROUTES.BOOKINGS}`} />,
                },
                {
                    path: `${FRONTEND_ROUTES.BOOKINGS}`,
                    element: <Booking />,
                },
                {
                    path: `${FRONTEND_ROUTES.ROUTING}`,
                    element: <Routing />,
                },
            ],
        },
        {
            path: FRONTEND_ROUTES.TRAFFIC,
            element: (
                <RequireAuth>
                    <OrdersLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: (
                        <Navigate to={`${FRONTEND_ROUTES.INBOUND_TRAFFIC}`} />
                    ),
                },
                {
                    path: `${FRONTEND_ROUTES.INBOUND_TRAFFIC}`,
                    element: (
                        <InboundTraffic>
                            <InboundTrafficTable />
                        </InboundTraffic>
                    ),
                },
                {
                    path: `${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC_DETAILS}/:uuid`,
                    element: (
                        <InboundTraffic>
                            <InboundTrafficAssociation />
                        </InboundTraffic>
                    ),
                },
                {
                    path: `${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.ACQUISITION_REPORT}/:missionUuid`,
                    element: <AcquisitionReportPage />,
                },
                {
                    path: `${FRONTEND_ROUTES.PROCESSING_TRAFFIC}`,
                    element: (
                        <ProcessingTrafficPage>
                            <ProcessingTraffic />
                        </ProcessingTrafficPage>
                    ),
                },
                {
                    path: `${FRONTEND_ROUTES.COMPLETED_TRAFFIC}`,
                    element: (
                        <CompletedTrafficPage>
                            <CompletedTraffic />
                        </CompletedTrafficPage>
                    ),
                },
            ],
        },
        {
            path: FRONTEND_ROUTES.VALIDATION,
            element: (
                <RequireAuth>
                    <OrdersLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: <Validation />,
                },
                {
                    path: `:experimentMissionUuid/:pipelineUuid`,
                    element: <Validation />,
                },
                {
                    path: FRONTEND_ROUTES.PROCESS,
                    element: <ProcessTable />,
                },
            ],
        },
        {
            path: FRONTEND_ROUTES.GLOSSARY,
            element: (
                <RequireAuth>
                    <OrdersLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={FRONTEND_ROUTES.TRAIT} />,
                },
                {
                    path: FRONTEND_ROUTES.TRAIT,
                    element: <TraitGlossary />,
                },
                {
                    path: FRONTEND_ROUTES.SENSOR,
                    element: <SensorGlossary />,
                },
                {
                    path: FRONTEND_ROUTES.SYSTEM_MODEL,
                    element: <SystemModelGlossary />,
                },
                {
                    path: FRONTEND_ROUTES.CROP,
                    element: <CropGlossary />,
                },
            ],
        },
        {
            path: FRONTEND_ROUTES.PARCELLAIRE,
            element: (
                <RequireAuth>
                    <Parcellaire />
                </RequireAuth>
            ),
        },
        {
            path: "/",
            element: <LogoOnlyLayout />,
            children: [
                {
                    path: "/",
                    element: (
                        <Navigate
                            to={`${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.COMPANY}`}
                        />
                    ),
                },
                { path: "404", element: <NotFound /> },
            ],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}
