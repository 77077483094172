// https://bitbucket.org/hiphendrone/hiphen-utils/src/c8baa4af0c7dd88d2e080484d03a65f263d4fb2b/src/hiphenutils/geo.py#lines-124
export function epsgConverter(latitude, longitude) {
    let zoneNumber = Math.floor(((longitude + 180) / 6) % 60) + 1;

    // Norway
    if (latitude >= 56 && latitude < 64 && longitude >= 3 && longitude < 12)
        zoneNumber = 32;

    // Svalbard
    if (latitude >= 72 && latitude < 84) {
        if (longitude >= 0 && longitude < 9) zoneNumber = 31;
        if (longitude >= 9 && longitude < 21) zoneNumber = 33;
        if (longitude >= 21 && longitude < 33) zoneNumber = 35;
        if (longitude >= 33 && longitude < 42) zoneNumber = 37;
    }

    const zoneIsNorth = latitude > 0;

    return {
        epsgCode: `EPSG:32${zoneIsNorth ? "6" : "7"}${zoneNumber}`,
        epsgString: `+proj=utm +zone=${zoneNumber} ${zoneIsNorth ? "" : "+south "}+datum=WGS84 +units=m +no_defs +type=crs`,
    };
}
