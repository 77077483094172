import { ContractForm } from "./ContractForm";
import { Divider } from "@mui/material";
import { PipelineTemplateTable } from "../pipeline-template/PipelineTemplateTable";
import { SiteTable } from "../site";
import { Stack } from "@mui/system";
import { useParams } from "react-router-dom";

export const ContractDetails = () => {
    const { uuid } = useParams();

    return (
        <Stack spacing={2}>
            <ContractForm uuid={uuid} />
            <Divider />
            <PipelineTemplateTable contractUuid={uuid} />
            <Divider />
            <SiteTable contractUuid={uuid} />
        </Stack>
    );
};
