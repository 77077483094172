export const CHART_MODEBAR_BUTTONS_TO_REMOVE = ["autoScale2d", "toImage"];

export const TRACE_1_COLOR = "rgb(128, 182, 244)";
export const TRACE_2_COLOR = "rgb(17, 208, 125)";
export const CARD_HEIGHT = "30vh";

export const EVALUATION_ENTITY_LIST = "evaluationEntityList";
export const SELECTED_ENTITY_LIST = "selectedEntityList";

export const ENTER_KEY_NAME = "Enter";
export const ENTITY_SELECT_OPTION_FONT_FAMILY = "Incosolata";
export const ENTITY_SELECT_SIZE = 4;
export const MODES = Object.freeze({
    NORMAL: "normal",
    ADVANCED: "advanced",
});

export const GSD_VALUE_LIMIT = Object.freeze([0, 100]);
export const SATURATION_VALUE_LIMIT = Object.freeze([0, 20]);
export const BLACK_SATURATION_VALUE_LIMIT = Object.freeze([0, 20]);
export const AlTITUDE_VALUE_LIMIT = Object.freeze([-20, 20]);
export const ALTUTUDE_OUTLIER_VALUE_LIMIT = Object.freeze([0, 20]);
