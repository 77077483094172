import {
    Alert,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import {
    PIPELINE_INSTANCE_STATUS,
    TRAIT_GROUP_ASSOCIABLE_TYPES,
} from "../../../constants";
import { useFetch, useSnackbar } from "../../../hooks";

import AddIcon from "@mui/icons-material/Add";
import { BACKEND_ROUTES } from "../../../backendRoutes";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import { TraitGroup } from "./TraitGroup";
import { useFormContext } from "react-hook-form";

export const TraitGroupList = ({
    closeModal,
    experimentMissionTraitGroups,
    pipelines,
}) => {
    const { post } = useFetch();
    const { openSnackbar } = useSnackbar();

    const {
        handleSubmit,
        formState: { errors },
        setError,
    } = useFormContext();

    const onSubmit = async (data) => {
        const checkedTraitGroups = Object.entries(data.traitGroups).filter(
            ([, { checked }]) => checked
        );

        if (checkedTraitGroups.length) {
            const body = {
                associatedTraitGroupType:
                    TRAIT_GROUP_ASSOCIABLE_TYPES.EXPERIMENT_MISSION,
                associatedTraitGroups: checkedTraitGroups.map(
                    ([key, { mode }]) => ({ uuid: key, mode })
                ),
            };
            const pipelineInstance = await post(
                BACKEND_ROUTES.PIPELINE_INSTANCE,
                { body }
            );

            if (pipelineInstance) {
                closeModal();
                openSnackbar("Processing planned", "success");
            }
        } else {
            setError("root.traitGroups", {
                message: "At least one trait group must be selected",
            });
        }
    };

    return (
        <>
            {errors.root?.traitGroups.message && (
                <Alert severity="error">
                    {errors.root.traitGroups.message}
                </Alert>
            )}
            <List
                component="form"
                id="traitGroups"
                onSubmit={handleSubmit(onSubmit)}
            >
                {experimentMissionTraitGroups.map(
                    ({
                        TraitGroupDataType,
                        uuid,
                        PipelineInstances,
                        ValidatedPipelineInstance,
                    }) => (
                        <TraitGroup
                            key={uuid}
                            fieldArrayName={`traitGroups.${uuid}`}
                            name={TraitGroupDataType.TraitGroup.name}
                            pipelines={pipelines.filter(
                                ({ traitGroupDataTypeUuid }) =>
                                    traitGroupDataTypeUuid ===
                                    TraitGroupDataType.uuid
                            )}
                            pipelineInstances={PipelineInstances.filter(
                                ({ status }) =>
                                    [
                                        PIPELINE_INSTANCE_STATUS.ERROR,
                                        PIPELINE_INSTANCE_STATUS.FINISHED,
                                    ].includes(status)
                            )}
                            validatedPipelineInstance={
                                ValidatedPipelineInstance
                            }
                        />
                    )
                )}
                <ListItem disableGutters sx={{ width: "30%" }}>
                    <ListItemButton>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: "primary.main" }}>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Add trait group" />
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    );
};

TraitGroupList.propTypes = {
    closeModal: PropTypes.func.isRequired,
    experimentMissionTraitGroups: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.ExperimentMissionTraitGroup).isRequired
    ).isRequired,
    pipelines: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.Pipeline).isRequired
    ).isRequired,
};
