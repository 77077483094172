import {
    Box,
    FormControlLabel,
    Grid,
    Modal,
    Slider,
    Stack,
    Switch,
    Typography,
} from "@mui/material";

import { VisualisationCanvas } from "./VisualisationCanvas";
import { useState } from "react";

export function EntityVisualizer({ entity, initialWidth = 200 }) {
    const [width, setWidth] = useState(initialWidth);
    const [mustRotate, setMustRotate] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    return entity ? (
        <>
            <Modal
                sx={{ m: 3, overflow: "scroll" }}
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box>
                    <VisualisationCanvas entity={entity} />
                </Box>
            </Modal>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="caption">{entity.name}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Stack direction={"column"}>
                        <Typography>Image width</Typography>

                        <Box sx={{ px: 1 }}>
                            <Slider
                                name="width"
                                id="width"
                                orientation="horizontal"
                                valueLabelDisplay="auto"
                                value={width}
                                onChange={(event) => {
                                    setWidth(event.target.value);
                                }}
                                min={100}
                                max={1000}
                                step={50}
                            />
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        id="mustRotate"
                        name="mustRotate"
                        control={<Switch />}
                        label="Rotate"
                        labelPlacement="top"
                        checked={mustRotate}
                        onChange={(event) => {
                            setMustRotate(event.target.checked);
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Box sx={{ cursor: "pointer" }}>
                        <VisualisationCanvas
                            entity={entity}
                            forcedWidth={width}
                            mustRotate={mustRotate}
                            onClick={() => setOpenModal(true)}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    ) : null;
}
