import {
    Box,
    Button,
    Card,
    CardContent,
    FormControlLabel,
    Switch,
    Tab,
    Tabs,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import { CHART_MODEBAR_BUTTONS_TO_REMOVE } from "../constants";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { MODEL_PROPTYPES } from "../../../models";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

export function MetadataCharts({
    dataset,
    includeQuarantine,
    selectedGauge,
    transferListImages,
    setIncludeQuarantine,
    setTransferListImages,
}) {
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = useState(0);

    const { histogramData, scatterData } = useMemo(() => {
        const datetimes = [];
        const quarantinedImages = [];
        const nonQuarantinedImages = [];
        const allValues = [];

        const gaugeKey =
            selectedGauge === "altitude outlier"
                ? "altitude_agl"
                : selectedGauge;

        dataset.forEach(({ metadata, quarantine }) => {
            if (metadata) {
                const datetime = new Date(metadata.datetime);
                const value = metadata[gaugeKey] ?? null;

                datetimes.push(datetime);
                allValues.push(value);

                if (quarantine) {
                    quarantinedImages.push(value);
                } else {
                    nonQuarantinedImages.push(value);
                }
            }
        });

        return {
            histogramData: [
                {
                    x: quarantinedImages,
                    type: "histogram",
                    name: "Quarantine",
                    marker: { color: theme.palette.warning.main },
                },
                {
                    x: nonQuarantinedImages,
                    type: "histogram",
                    name: "Non-Quarantine",
                    marker: { color: theme.palette.info.dark },
                },
            ],
            scatterData: [
                {
                    x: datetimes,
                    y: allValues,
                    type: "scattergl",
                    mode: "lines+markers",
                },
            ],
        };
    }, [dataset, selectedGauge, theme.palette]);

    const handleHistogramSelection = useCallback(
        (eventData) =>
            setTransferListImages(
                eventData?.points
                    .flatMap(({ pointIndices }) =>
                        pointIndices.map((pointNumber) => dataset[pointNumber])
                    )
                    .filter(({ quarantine }) => !quarantine) ?? []
            ),
        [dataset, setTransferListImages]
    );

    const handleDynamicPlotSelection = useCallback(
        (eventData) =>
            setTransferListImages(
                eventData?.points
                    .map(({ pointNumber }) => dataset[pointNumber])
                    .filter(({ quarantine }) => !quarantine) ?? []
            ),

        [dataset, setTransferListImages]
    );

    return (
        <Card>
            <CardContent sx={{ py: 1 }}>
                <Box display="flex" borderBottom={1} borderColor="divider">
                    <Tabs
                        value={selectedTab}
                        onChange={(_, newValue) => setSelectedTab(newValue)}
                        aria-label="tabs"
                    >
                        <Tab label="Histogram" />
                        <Tab label="Dynamics" />
                    </Tabs>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={includeQuarantine}
                                onChange={() =>
                                    setIncludeQuarantine(!includeQuarantine)
                                }
                            />
                        }
                        id="quarantine-images"
                        sx={{ ml: "auto" }}
                        label="Quarantine images"
                    />
                    <Button
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={() => setTransferListImages([])}
                        disabled={transferListImages.length === 0}
                        startIcon={<DeleteOutlinedIcon />}
                        sx={{ ml: "auto", mb: 1 }}
                    >
                        Clear Selection
                    </Button>
                </Box>
                {selectedTab === 0 && (
                    <Plot
                        data={histogramData}
                        useResizeHandler
                        config={plotConfig}
                        layout={defaultLayout}
                        style={style}
                        onSelected={handleHistogramSelection}
                    />
                )}
                {selectedTab === 1 && (
                    <Plot
                        data={scatterData}
                        useResizeHandler
                        config={plotConfig}
                        layout={dynamicPlotDatalayout}
                        style={style}
                        onSelected={handleDynamicPlotSelection}
                    />
                )}
            </CardContent>
        </Card>
    );
}

const style = { width: "100%", height: "100%" };

const plotConfig = {
    displaylogo: false,
    modeBarButtonsToRemove: CHART_MODEBAR_BUTTONS_TO_REMOVE,
};

const defaultLayout = {
    autosize: true,
    dragmode: "select",
    showlegend: true,
    margin: { t: 16, b: 16 },
};
const dynamicPlotDatalayout = {
    ...defaultLayout,
    xaxis: {
        type: "date",
    },
};

MetadataCharts.propTypes = {
    dataset: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    includeQuarantine: PropTypes.bool.isRequired,
    selectedGauge: PropTypes.string.isRequired,
    transferListImages: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    setIncludeQuarantine: PropTypes.func.isRequired,
    setTransferListImages: PropTypes.func.isRequired,
};
