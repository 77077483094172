import {
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Dialog,
    IconButton,
    Zoom,
} from "@mui/material";
import { addSeconds, differenceInMilliseconds, parseISO } from "date-fns";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import CloseIcon from "@mui/icons-material/Close";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import useSWRImmutable from "swr/immutable";
import { useState } from "react";

const getRefreshInterval = (data) => {
    if (!data) return 0;

    const searchParams = new URLSearchParams(data);
    const signedDate = parseISO(searchParams.get("X-Amz-Date"));
    const expiresIn = Number(searchParams.get("X-Amz-Expires"));

    const expirationDate = addSeconds(signedDate, expiresIn);
    const timeLeft = differenceInMilliseconds(expirationDate, new Date());

    return Math.max(timeLeft, 0);
};

export function ImageDisplay({ image }) {
    const [open, setOpen] = useState(false);

    const { data: imageSrc } = useSWRImmutable(
        `${BACKEND_ROUTES.PUBLIC_SIGNED_URL}?key=${image.s3Location}`,
        {
            refreshInterval: getRefreshInterval,
        }
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card
                sx={{
                    height: "100%",
                }}
            >
                <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={handleClickOpen}
                >
                    {imageSrc && (
                        <CardMedia
                            component="img"
                            image={imageSrc}
                            alt={image.metadata?.datetime || "N/A"}
                            sx={{
                                height: "100%",
                            }}
                        />
                    )}
                </CardActionArea>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen
                PaperProps={{
                    sx: {
                        backgroundColor: "black",
                    },
                }}
            >
                {imageSrc && (
                    <Zoom in>
                        <Box
                            component="img"
                            height="100%"
                            src={imageSrc}
                            alt={image.metadata?.datetime || "N/A"}
                            margin="auto"
                        />
                    </Zoom>
                )}
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </>
    );
}

ImageDisplay.propTypes = {
    image: PropTypes.shape(MODEL_PROPTYPES.RawDatasets),
};
