import { Box, Tooltip, Typography } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export function GraphActionTooltip() {
    return (
        <Tooltip
            title={
                <>
                    <Typography>
                        <Box component="span" sx={{ fontWeight: "bold" }}>
                            ZOOM
                        </Box>
                        : Click, hold and drag to zoom on a region
                    </Typography>
                    <Typography>
                        <Box component="span" sx={{ fontWeight: "bold" }}>
                            PAN
                        </Box>
                        : Hold control key then click, hold and drag to pan
                    </Typography>
                    <Typography>
                        <Box component="span" sx={{ fontWeight: "bold" }}>
                            RESET
                        </Box>
                        : Double click to reset zoom and pan
                    </Typography>
                </>
            }
        >
            <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
    );
}
