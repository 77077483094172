import { GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES } from "../../../constants";
import { geoJSONUtil } from "./geoJSONUtil";
import { transformUtil } from "./transformUtil";

/**
 * This function is essentially copied from the Slant Range repo
 * (from the function tryRefreshTransformGeoJSON).
 */
export function transformGeoJsonFromVectors(
    geoJSON,
    transformModeVectors,
    toggles = [GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE]
) {
    const buttons = {
        singleTarget: toggles.includes(
            GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SINGLE_TARGET_MODE
        ),
        shift: toggles.includes(GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SHIFT_MODE),
        lockedShape: toggles.includes(
            GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE
        ),
    };
    if (
        !buttons.singleTarget &&
        (transformModeVectors.length >= 2 ||
            (buttons.shift && transformModeVectors.length >= 1))
    ) {
        const oldCenters = [];
        const newCenters = [];
        transformModeVectors.forEach((vector) => {
            const feature = geoJSONUtil.findFeatureById(
                geoJSON,
                vector.shapeObjectId
            );
            const oldCenter = geoJSONUtil.getCenterOfFeature(feature);
            oldCenters.push(oldCenter);
            const newGeometry = geoJSONUtil.shiftGeometry(
                feature.geometry,
                vector.longitude,
                vector.latitude
            );
            const newCenter = geoJSONUtil.getCenterOfGeometry(newGeometry);
            newCenters.push(newCenter);
        });

        // const { transform, referenceCoord } = TransformUtil.calculateAffineTransform(oldCenters, newCenters)
        const { transform, referenceCoord } = transformUtil.calculateTransform(
            oldCenters,
            newCenters,
            null
        );
        const transformedGeoJSON = geoJSONUtil.transformGeoJSON(
            geoJSON,
            transform,
            referenceCoord,
            buttons.lockedShape
        );

        return transformedGeoJSON;
    } else {
        const transformedGeoJSON = geoJSONUtil.cloneGeoJSON(geoJSON);

        transformModeVectors.forEach((vector) => {
            const feature = geoJSONUtil.findFeatureById(
                transformedGeoJSON,
                vector.shapeObjectId
            );
            feature.geometry = geoJSONUtil.shiftGeometry(
                feature.geometry,
                vector.longitude,
                vector.latitude
            );
        });

        return transformedGeoJSON;
    }
}
