import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useFieldArray, useForm, useWatch } from "react-hook-form";

import { INPUT_VALIDATION } from "../../../constants";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const GlobalError = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
}));

PipelineTemplateCalendarForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.any.isRequired,
    control: PropTypes.object.isRequired,
};

export function PipelineTemplateCalendarForm({
    closeModal,
    setValue: parentFormSetValue,
    control: parentFormControl,
}) {
    const parentFormCalendars = useWatch({
        name: "calendars",
        control: parentFormControl,
    });
    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitted },
    } = useForm({
        defaultValues: { calendars: parentFormCalendars },
    });
    const { fields } = useFieldArray({
        name: "calendars",
        control,
    });
    const calendarsWatch = useWatch({ name: "calendars", control });

    const totalDateCount = calendarsWatch
        // We need to use the || operator instead of the ?? operator here
        // because NaN is considered usable by the ?? operator. Meanwhile the default
        // value 0 makes it ok that the || operator considers 0 unusable.
        .map((calendar) => parseInt(calendar.count, 10) || 0)
        .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
        );

    const onSubmit = (data) => {
        if (totalDateCount > 0) {
            parentFormSetValue("calendars", data.calendars);
            parentFormSetValue("dateCount", totalDateCount);
            closeModal();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
                Number of dates expected per calendar month
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Stack spacing={1} alignItems="center">
                    {!(totalDateCount > 0) && isSubmitted && (
                        <GlobalError>
                            Total date count should be higher than 0.
                        </GlobalError>
                    )}
                    <Grid container spacing={2}>
                        {fields.map((calendar, index) => (
                            <Grid item xs={2} key={calendar.id}>
                                <Stack>
                                    <Typography>
                                        {`${calendar.date.substring(
                                            5,
                                            7
                                        )}/${calendar.date.substring(0, 4)}`}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        error={Boolean(
                                            errors.calendars?.[index]?.count
                                        )}
                                        helperText={
                                            errors.calendars?.[index]?.count
                                                ?.message
                                        }
                                        {...register(
                                            `calendars.${index}.count`,
                                            {
                                                required:
                                                    INPUT_VALIDATION.REQUIRED_SHORT,
                                            }
                                        )}
                                    />
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions sx={{ px: 2 }}>
                <Stack
                    sx={{ flexGrow: 1 }}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                >
                    <Typography>Total: </Typography>
                    <TextField
                        disabled
                        type="number"
                        value={totalDateCount.toString()}
                        sx={{ width: "5em" }}
                    />
                    <Typography>dates</Typography>
                </Stack>
                <Button onClick={closeModal}>Close</Button>
                <LoadingButton type="submit" variant="contained">
                    Save
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
