import { Box, Stack } from "@mui/system";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";

import { Draggable } from "react-beautiful-dnd";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { formatDateTimeUTC } from "../../../utils/formatTime";
import { objectArrayStringify } from "../../utils/objectArrayStringify";

const isMissionBeyondDeadline = (mission) => {
    const today = new Date();
    const missionDeadline = new Date(mission.date);
    missionDeadline.setDate(missionDeadline.getDate() + mission.deadline);
    if (missionDeadline < today) return true;
    else return false;
};

export function RoutingKanbanDraggable({
    mission,
    index,
    isDragDisabled,
    setSelectedMissionUuid,
    setIsOpenMissionDetails,
    grid,
}) {
    const onClickEdit = () => {
        setSelectedMissionUuid(mission.uuid);
        setIsOpenMissionDetails(true);
    };
    return (
        <Draggable
            draggableId={mission.uuid}
            index={index}
            isDragDisabled={isDragDisabled}
        >
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                        bgcolor: (theme) =>
                            isMissionBeyondDeadline(mission)
                                ? theme.palette.error.light
                                : theme.palette.success.light,
                        borderRadius: "16px",
                        border: snapshot.isDragging && 3,
                        userSelect: "none",
                        padding: `${grid * 2}px`,
                        margin: `0 0 ${grid}px 0`,
                    }}
                >
                    <Stack>
                        <Grid container alignItems="center">
                            <Grid item xs={11}>
                                <Typography variant="subtitle2">
                                    {formatDateTimeUTC(mission.date)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip
                                    slotProps={{
                                        tooltip: {
                                            sx: {
                                                maxWidth: "none",
                                            },
                                        },
                                    }}
                                    title={
                                        <Stack>
                                            <Typography variant="subtitle2">
                                                {
                                                    mission.Site.Contract
                                                        .Company.name
                                                }
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {mission.Site.Contract.name}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {mission.Site.name}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {formatDateTimeUTC(
                                                    mission.date
                                                )}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {mission.status} -{" "}
                                                {mission.subStatus}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {objectArrayStringify(
                                                    mission.AcquisitionVector
                                                        .SensorBundles,
                                                    ["Sensor", "dataType"]
                                                )}
                                            </Typography>
                                        </Stack>
                                    }
                                >
                                    <InfoIcon
                                        fontSize="small"
                                        sx={{ color: "grey.500" }}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle2">
                            {mission.Site.Contract.name}
                        </Typography>
                        <Grid container alignItems="center">
                            <Grid item xs={10}>
                                <Typography variant="subtitle2">
                                    {mission.Site.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <IconButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    onClick={onClickEdit}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            )}
        </Draggable>
    );
}
