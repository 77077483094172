import { Divider, Grid } from "@mui/material";

import { ExperimentTable } from "../experiment";
import { MissionTable } from "../mission";
import { SiteEditForm } from ".";
import { useParams } from "react-router-dom";

export const SiteDetails = () => {
    const { siteUuid } = useParams();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SiteEditForm siteUuid={siteUuid} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <ExperimentTable siteUuid={siteUuid} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <MissionTable siteUuid={siteUuid} />
            </Grid>
        </Grid>
    );
};
