import { Button, Grid, Stack } from "@mui/material";
import { useFetch, useSnackbar } from "../../../hooks";
import { useMemo, useState } from "react";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { ImageList } from "./ImageList";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import { compareAsc } from "date-fns";

export function ImageTransferList({
    mutateRawDataSets,
    rawDataSets,
    transferListImages,
    setTransferListImages,
    setDisplayedImage,
}) {
    const { patch } = useFetch();
    const { openSnackbar } = useSnackbar();
    const [checkedImages, setCheckedImages] = useState({ left: [], right: [] });

    const sortedSelectedImages = useMemo(
        () =>
            transferListImages.toSorted((a, b) => {
                const datetimeA = a.metadata?.datetime;
                const datetimeB = b.metadata?.datetime;

                if (!datetimeA && datetimeB) return 1;
                if (datetimeA && !datetimeB) return -1;
                if (!datetimeA && !datetimeB) return 0;

                return compareAsc(new Date(datetimeA), new Date(datetimeB));
            }),
        [transferListImages]
    );

    const quarantinedImages = useMemo(
        () => rawDataSets.filter(({ quarantine }) => quarantine),
        [rawDataSets]
    );

    const mutateRawDatasetsFactory = async (
        uuids,
        quarantine,
        errorMessage
    ) => {
        try {
            const uuidsSet = new Set(uuids);

            await mutateRawDataSets(
                patch(BACKEND_ROUTES.RAW_DATASET, {
                    body: { uuids, quarantine },
                    forwardError: true,
                }),
                {
                    optimisticData: (currentData) =>
                        currentData.map((dataset) => ({
                            ...dataset,
                            rows: dataset.rows.map((row) =>
                                uuidsSet.has(row.uuid)
                                    ? { ...row, quarantine }
                                    : row
                            ),
                        })),
                    rollbackOnError: true,
                    populateCache: false,
                    revalidate: false,
                }
            );
        } catch {
            setTransferListImages(sortedSelectedImages);
            setCheckedImages(checkedImages);
            openSnackbar(errorMessage, "error");
        }
    };

    const quarantineSelectedImages = async () => {
        const checkedQuarantinedImagesUuids = checkedImages.left.map(
            ({ uuid }) => uuid
        );

        setTransferListImages((prevState) =>
            prevState.filter(
                ({ uuid }) => !checkedQuarantinedImagesUuids.includes(uuid)
            )
        );
        setCheckedImages((prevState) => ({ ...prevState, left: [] }));

        await mutateRawDatasetsFactory(
            checkedQuarantinedImagesUuids,
            true,
            "An error occurred while quarantining image(s)"
        );
    };

    const unquarantineSelectedImages = async () => {
        const checkedSelectedImageUuids = checkedImages.right.map(
            (dataset) => dataset.uuid
        );

        setTransferListImages((prevState) => [
            ...prevState,
            ...checkedImages.right,
        ]);
        setCheckedImages((prevState) => ({ ...prevState, right: [] }));

        await mutateRawDatasetsFactory(
            checkedSelectedImageUuids,
            false,
            "An error occurred while unquarantining image(s)"
        );
    };

    const quarantineAllImages = async () => {
        const selectedImagesUuids = sortedSelectedImages.map(
            ({ uuid }) => uuid
        );

        setTransferListImages([]);
        setCheckedImages((prevState) => ({ ...prevState, left: [] }));

        await mutateRawDatasetsFactory(
            selectedImagesUuids,
            true,
            "An error occurred while quarantining all image(s)"
        );
    };

    const unquarantineAllImages = async () => {
        const quantinedImagesUuids = quarantinedImages.map(({ uuid }) => uuid);

        setTransferListImages([...sortedSelectedImages, ...quarantinedImages]);
        setCheckedImages((prevState) => ({ ...prevState, right: [] }));

        await mutateRawDatasetsFactory(
            quantinedImagesUuids,
            false,
            "An error occurred while unquarantining all image(s)"
        );
    };

    return (
        <Stack spacing={1} direction="row" height="100%" alignItems="center">
            <Grid item height="100%">
                <ImageList
                    checkedImages={checkedImages.left}
                    images={sortedSelectedImages}
                    listType="left"
                    setCheckedImages={setCheckedImages}
                    onImageSelect={setDisplayedImage}
                    title="Selected Images"
                />
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5, minWidth: 40 }}
                        variant="outlined"
                        size="small"
                        onClick={quarantineAllImages}
                        disabled={sortedSelectedImages.length === 0}
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5, minWidth: 40 }}
                        variant="outlined"
                        size="small"
                        onClick={quarantineSelectedImages}
                        disabled={checkedImages.left.length === 0}
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5, minWidth: 40 }}
                        variant="outlined"
                        size="small"
                        onClick={unquarantineSelectedImages}
                        disabled={checkedImages.right.length === 0}
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5, minWidth: 40 }}
                        variant="outlined"
                        size="small"
                        onClick={unquarantineAllImages}
                        disabled={quarantinedImages.length === 0}
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item height="100%">
                <ImageList
                    checkedImages={checkedImages.right}
                    images={quarantinedImages}
                    listType="right"
                    setCheckedImages={setCheckedImages}
                    onImageSelect={setDisplayedImage}
                    title="Quarantined Images"
                />
            </Grid>
        </Stack>
    );
}

ImageTransferList.propTypes = {
    mutateRawDataSets: PropTypes.func.isRequired,
    rawDataSets: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    transferListImages: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    setTransferListImages: PropTypes.func.isRequired,
    setDisplayedImage: PropTypes.func.isRequired,
};
