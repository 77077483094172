/**
 * Object for string translation.
 * @typedef {Object} StringTranslation
 * @property {string} key - the key is the old string, and the value is the new string
 */

/**
 * Translates a string into another one if the given object has the string as one of its keys.
 * Otherwise the original string is returned.
 * @param {string} stringToTranslate - the string that we might translate
 * @param {StringTranslation} dictionary - object where each key is a string to translate and the value of each key is its translated string
 * @returns {string} resulting string
 */
export function translateString(stringToTranslate, dictionary) {
    return dictionary[stringToTranslate] ?? stringToTranslate;
}
