import {
    Box,
    Card,
    CardContent,
    Divider,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { INPUT_VALIDATION, SYSTEMS } from "../../../constants";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { DataSourceFormSkeleton } from "./DataSourceFormSkeleton";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import PropTypes from "prop-types";
import { realFocalLengthFromFocalLength35 } from "../../utils/sopCalculation";
import { roundNumber } from "../../utils/roundNumber";
import useSWRImmutable from "swr/immutable";

const systemValues = Object.values(SYSTEMS);

/**
 * When setting dataSource, focalLength35mmManual is given only if sensor does not have a fixed focal length.
 */
DataSourceForm.propTypes = {
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.any.isRequired,
};

export function DataSourceForm({ control, register, setValue, errors }) {
    const [
        systemWatch,
        systemModelUuidWatch,
        acquisitionVectorUuidWatch,
        sensorUuidWatch,
    ] = useWatch({
        name: [
            "system",
            "systemModelUuid",
            "acquisitionVectorUuid",
            "sensorUuid",
        ],
        control,
    });

    const { data: systemModelsData, systemModelsFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.SYSTEM_MODEL}?sort=name`
    );
    const { data: acquisitionVectorData, acquisitionVectorFetchError } =
        useSWRImmutable(
            `${BACKEND_ROUTES.ACQUISITION_VECTOR}?sensor=true&systemModel=true`
        );

    const mergedFetchError =
        systemModelsFetchError ?? acquisitionVectorFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (!systemModelsData || !acquisitionVectorData)
        return <DataSourceFormSkeleton />;

    const selectedAcquisitionVector = acquisitionVectorData.rows.find(
        (AV) => AV.uuid === acquisitionVectorUuidWatch
    );

    const selectedSensor = selectedAcquisitionVector?.SensorBundles.find(
        (bundle) => bundle.sensorUuid === sensorUuidWatch
    );

    return (
        <Stack spacing={2}>
            <Card
                sx={{
                    bgcolor: (theme) => theme.palette.grey[210],
                }}
            >
                <CardContent>
                    <Stack spacing={2}>
                        <Box>
                            <Typography variant="h5">Data source</Typography>
                            <Divider />
                        </Box>

                        <Controller
                            control={control}
                            name="system"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="system"
                                    select
                                    label="System *"
                                    inputRef={ref}
                                    error={Boolean(errors.system)}
                                    helperText={errors.system?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        setValue("systemModelUuid", "");
                                        setValue("acquisitionVectorUuid", "");
                                        setValue("sensorUuid", "");
                                        setValue("focalLength35mmManual", null);
                                        onChange(event.target.value);
                                    }}
                                >
                                    {systemValues.map((system) => (
                                        <MenuItem key={system} value={system}>
                                            {system}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                        <Controller
                            control={control}
                            name="systemModelUuid"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="systemModelUuid"
                                    select
                                    label="System model *"
                                    inputRef={ref}
                                    error={Boolean(errors.systemModelUuid)}
                                    helperText={errors.systemModelUuid?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        setValue("acquisitionVectorUuid", "");
                                        setValue("sensorUuid", "");
                                        setValue("focalLength35mmManual", null);
                                        onChange(event.target.value);
                                    }}
                                >
                                    {systemModelsData.rows
                                        .filter(
                                            (systemModel) =>
                                                systemModel.system ===
                                                systemWatch
                                        )
                                        .map((systemModel) => (
                                            <MenuItem
                                                key={systemModel.uuid}
                                                value={systemModel.uuid}
                                            >
                                                {systemModel.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                        <Controller
                            control={control}
                            name="acquisitionVectorUuid"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="acquisitionVectorUuid"
                                    select
                                    label="Acquisition vector *"
                                    inputRef={ref}
                                    error={Boolean(
                                        errors.acquisitionVectorUuid
                                    )}
                                    helperText={
                                        errors.acquisitionVectorUuid?.message
                                    }
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        setValue("sensorUuid", "");
                                        setValue("focalLength35mmManual", null);
                                        onChange(event.target.value);
                                    }}
                                >
                                    {acquisitionVectorData.rows
                                        .filter(
                                            (acquisitionVector) =>
                                                acquisitionVector.systemModelUuid ===
                                                systemModelUuidWatch
                                        )
                                        .map((acquisitionVector) => (
                                            <MenuItem
                                                key={acquisitionVector.uuid}
                                                value={acquisitionVector.uuid}
                                            >
                                                {acquisitionVector.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                        <Controller
                            control={control}
                            name="sensorUuid"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="sensorUuid"
                                    select
                                    label="Sensor *"
                                    inputRef={ref}
                                    error={Boolean(errors.sensorUuid)}
                                    helperText={errors.sensorUuid?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        setValue("focalLength35mmManual", null);
                                        onChange(event.target.value);
                                    }}
                                >
                                    {selectedAcquisitionVector
                                        ? selectedAcquisitionVector.SensorBundles.map(
                                              (bundle) => (
                                                  <MenuItem
                                                      key={bundle.uuid}
                                                      value={bundle.sensorUuid}
                                                  >
                                                      {bundle.Sensor.name}
                                                  </MenuItem>
                                              )
                                          )
                                        : []}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />

                        <Divider />

                        <Stack direction="row" justifyContent="space-between">
                            <Typography>CMOS</Typography>

                            <Typography>
                                {selectedSensor
                                    ? `${selectedSensor.Sensor.cmos}`
                                    : "-"}
                            </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                            <Typography>Sensor size (mm)</Typography>

                            <Typography>
                                {selectedSensor
                                    ? `${selectedSensor.Sensor.sensorWidth} x ${selectedSensor.Sensor.sensorHeight}`
                                    : "-"}
                            </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                            <Typography>Picture size (pixels)</Typography>

                            <Typography>
                                {selectedSensor
                                    ? `${selectedSensor.Sensor.pixelWidth} x ${selectedSensor.Sensor.pixelHeight}`
                                    : "-"}
                            </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                            <Typography>Total pixels (MP)</Typography>

                            <Typography>
                                {selectedSensor
                                    ? `${selectedSensor.Sensor.megapixels}`
                                    : "-"}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography>Focal length eqv 35 (mm)</Typography>

                            {selectedSensor ? (
                                selectedSensor.Sensor.focalLength35mmEqv ? (
                                    <Typography>
                                        {
                                            selectedSensor.Sensor
                                                .focalLength35mmEqv
                                        }
                                    </Typography>
                                ) : (
                                    <TextField
                                        id="focalLength35mmManual"
                                        type="number"
                                        error={Boolean(
                                            errors.focalLength35mmManual
                                        )}
                                        helperText={
                                            errors.focalLength35mmManual
                                                ?.message
                                        }
                                        {...register("focalLength35mmManual", {
                                            required:
                                                INPUT_VALIDATION.REQUIRED_SHORT,
                                            valueAsNumber: true,
                                        })}
                                        inputProps={{
                                            step: "0.01",
                                            inputMode: "decimal",
                                            style: { textAlign: "end" },
                                        }}
                                        sx={{ width: "6em" }}
                                    />
                                )
                            ) : (
                                <Typography>-</Typography>
                            )}
                        </Stack>
                        {Boolean(selectedSensor?.Sensor.focalLength35mmEqv) && (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography>Real focal length (mm)</Typography>

                                <Typography>
                                    {roundNumber(
                                        realFocalLengthFromFocalLength35(
                                            selectedSensor.Sensor.sensorWidth,
                                            selectedSensor.Sensor.sensorHeight,
                                            selectedSensor.Sensor
                                                .focalLength35mmEqv
                                        ),
                                        1
                                    )}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
}
