export const typographyStyleOverride = {
    MuiTypography: {
        styleOverrides: {
            paragraph: ({ theme }) => ({
                marginBottom: theme.spacing(2),
            }),
            gutterBottom: ({ theme }) => ({
                marginBottom: theme.spacing(1),
            }),
        },
    },
};
