import { Card, Stack, Typography } from "@mui/material";

import { TrafficTable } from "./";
import { useCallback } from "react";

export const ProcessingTraffic = () => {
    const filter = useCallback(
        (experimentMission) => experimentMission.Mission.associationCompleted,
        []
    );

    return (
        <Stack spacing={2}>
            <Typography variant="h4">Processing Traffic</Typography>
            <Card>
                <TrafficTable filter={filter} />
            </Card>
        </Stack>
    );
};
