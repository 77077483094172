import { useEffect, useRef } from "react";

// This is a SWR middleware for keeping the data even if key changes.
export function laggy(useSWRNext) {
    return (key, fetcher, config) => {
        // Use a ref to store previous returned data.
        const laggyDataRef = useRef();

        const swr = useSWRNext(key, fetcher, config);

        useEffect(() => {
            // Update ref if data is not undefined.
            if (swr.data !== undefined) {
                laggyDataRef.current = swr.data;
            }
        }, [swr.data]);

        // Fallback to previous data if the current data is undefined.
        const dataOrLaggyData =
            swr.data === undefined ? laggyDataRef.current : swr.data;

        // Is it showing previous data?
        const isLagging =
            swr.data === undefined && laggyDataRef.current !== undefined;

        // Also add a `isLagging` field to SWR.
        return { ...swr, data: dataOrLaggyData, isLagging };
    };
}
