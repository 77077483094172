import { GlobalStyles } from "@mui/material";
import { editorTheme } from "./editorTheme";

export const GlobalEditorStyles = () => (
    <GlobalStyles
        styles={(theme) => ({
            [`.${editorTheme.characterLimit}`]: {
                display: "inline",
                backgroundColor: "#ffbbbb !important",
            },
            [`.${editorTheme.heading.h1}`]: theme.typography.h1,
            [`.${editorTheme.heading.h2}`]: theme.typography.h2,
            [`.${editorTheme.heading.h3}`]: theme.typography.h3,
            [`.${editorTheme.indent}`]: {
                "--lexical-indent-base-value": "40px",
            },
            [`.${editorTheme.list.listitem}`]: { margin: theme.spacing(0, 4) },
            [`.${editorTheme.list.ol}`]: {
                padding: 0,
                margin: 0,
                listStylePosition: "outside",
            },
            [`.${editorTheme.list.ul}`]: {
                padding: 0,
                margin: 0,
                listStylePosition: "outside",
            },
            [`.${editorTheme.ltr}`]: { textAlign: "left" },
            [`.${editorTheme.paragraph}`]: theme.typography.body1,
            [`.${editorTheme.quote}`]: {
                margin: 0,
                ml: 2.5,
                marginBottom: 1.25,
                fontSize: "15px",
                color: "rgb(101, 103, 107)",
                borderLeftColor: "rgb(206, 208, 212)",
                borderLeftWidth: "4px",
                borderLeftStyle: "solid",
                pl: 2,
            },
            [`.${editorTheme.rtl}`]: { textAlign: "right" },
            [`.${editorTheme.text.bold}`]: theme.typography.fontWeightBold,
            [`.${editorTheme.text.code}`]: {
                backgroundColor: "rgb(240, 242, 245)",
                fontFamily: "Menlo, Consolas, Monaco, monospace",
                fontSize: "94%",
                padding: "1px 0.25rem",
            },
            [`.${editorTheme.text.italic}`]: { fontStyle: "italic" },
            [`.${editorTheme.text.strikethrough}`]: {
                textDecoration: "line-through",
            },
            [`.${editorTheme.text.subscript}`]: {
                fontSize: "0.8em",
                verticalAlign: "sub !important",
            },
            [`.${editorTheme.text.superscript}`]: {
                fontSize: "0.8em",
                verticalAlign: "super",
            },
            [`.${editorTheme.text.underline}`]: { textDecoration: "underline" },
            [`.${editorTheme.text.underlineStrikethrough}`]: {
                textDecoration: "underline line-through",
            },
        })}
    />
);
