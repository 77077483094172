import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import PropTypes from "prop-types";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalIsTextContentEmpty } from "@lexical/react/useLexicalIsTextContentEmpty";

export const CustomOnChangePlugin = ({ onChange }) => {
    const [editor] = useLexicalComposerContext();
    const isEmpty = useLexicalIsTextContentEmpty(editor);

    return (
        onChange && (
            <OnChangePlugin
                onChange={(editorState) => {
                    onChange(isEmpty ? null : editorState.toJSON());
                }}
            />
        )
    );
};

CustomOnChangePlugin.propTypes = {
    onChange: PropTypes.func,
};
