import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { EVALUATION_ENTITY_LIST } from "../constants";
import { EntityVisualizer } from "./EntityVisualizer";
import { TRANSFER_LIST_ORIENTATION_THRESHOLD } from "../../../constants";
import { TransferList } from "./TransferList";

export function VisualisationSection({
    evaluationEntities,
    setEvaluationEntities,
    quarantinedDatasets,
    mutateMission,
    width,
}) {
    const [markedEvaluationEntities, setMarkedEvaluationEntities] = useState(
        []
    );
    const [markedQuarantinedDatasets, setMarkedQuarantinedDatasets] = useState(
        []
    );

    // In order for the entity visualizer to work for both evaluation entity list and selected entity list,
    // we use the last focused list of the two
    const [lastFocusedEntityList, setLastFocusedEntityList] = useState(null);

    // In order to prevent the situation that marked entities are not in current list
    useEffect(() => {
        const evaluationEntityUuids = evaluationEntities.map(
            (entity) => entity.uuid
        );
        setMarkedEvaluationEntities((previousList) =>
            previousList.filter((entity) =>
                evaluationEntityUuids.includes(entity.uuid)
            )
        );
    }, [evaluationEntities]);

    const clearSelection = () => {
        setEvaluationEntities([]);
    };

    const visualizedEntity =
        lastFocusedEntityList === EVALUATION_ENTITY_LIST
            ? markedEvaluationEntities[0]
            : markedQuarantinedDatasets[0];

    return (
        <>
            <TransferList
                evaluationEntities={evaluationEntities}
                setEvaluationEntities={setEvaluationEntities}
                quarantinedDatasets={quarantinedDatasets}
                markedEvaluationEntities={markedEvaluationEntities}
                setMarkedEvaluationEntities={setMarkedEvaluationEntities}
                markedQuarantinedDatasets={markedQuarantinedDatasets}
                setMarkedQuarantinedDatasets={setMarkedQuarantinedDatasets}
                setLastFocusedEntityList={setLastFocusedEntityList}
                mutateMission={mutateMission}
                orientation={
                    width < TRANSFER_LIST_ORIENTATION_THRESHOLD
                        ? "vertical"
                        : "horizontal"
                }
            />

            <Grid
                container
                sx={{
                    py: 1,
                }}
                spacing={1}
                justifyContent="space-between"
            >
                <Grid item xs={5.5}>
                    <Grid container spacing={0.5}>
                        <Grid item>
                            <Button
                                color="error"
                                variant="outlined"
                                onClick={clearSelection}
                                disabled={evaluationEntities.length === 0}
                                startIcon={<DeleteOutlinedIcon />}
                                size="small"
                            >
                                Empty all selected
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider sx={{ mb: 1 }} />

            {/* In order for the label selection state to persist, we put the undefined entity check inside the component */}
            <EntityVisualizer entity={visualizedEntity} initialWidth={width} />
        </>
    );
}
