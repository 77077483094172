import {
    Autocomplete,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { MISSION_STATUS, PAGINATION } from "../../../constants";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { RoutingFormSkeleton } from "./RoutingFormSkeleton";
import { RoutingKanban } from ".";
import useSWR from "swr";

export function RoutingForm() {
    const { control, setValue } = useForm({
        defaultValues: {
            year: new Date().getFullYear(),
            company: null,
            contract: null,
            site: null,
            status: null,
        },
    });

    const [yearWatch, companyWatch, contractWatch, siteWatch] = useWatch({
        name: ["year", "company", "contract", "site"],
        control,
    });

    const { data: companies, error: companiesFetchError } = useSWR(
        `${BACKEND_ROUTES.COMPANY}?sort=name&limit=${PAGINATION.COMPANY.LIMIT.MAX}`
    );
    const { data: contracts, error: contractsFetchError } = useSWR(
        `${BACKEND_ROUTES.CONTRACT}?sort=name&limit=${PAGINATION.CONTRACT.LIMIT.MAX}`
    );
    const { data: sites, error: sitesFetchError } = useSWR(
        contractWatch
            ? `${BACKEND_ROUTES.SITE}?sort=name&contractUuid=${contractWatch.uuid}`
            : null
    );

    const mergedFetchError =
        contractsFetchError ?? companiesFetchError ?? sitesFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!contracts || !companies) {
        return <RoutingFormSkeleton />;
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h4" gutterBottom>
                Routing
            </Typography>

            <Stack>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs="auto">
                        <Stack direction="row" alignItems="center">
                            <IconButton
                                size="large"
                                onClick={() => setValue("year", yearWatch - 1)}
                                disabled={yearWatch <= 2000}
                            >
                                <NavigateBeforeIcon
                                    fontSize="inherit"
                                    color={yearWatch <= 2000 ? "" : "primary"}
                                />
                            </IconButton>
                            <Typography variant="h5">{yearWatch}</Typography>
                            <IconButton
                                size="large"
                                onClick={() => setValue("year", yearWatch + 1)}
                                disabled={yearWatch >= 2999}
                            >
                                <NavigateNextIcon
                                    fontSize="inherit"
                                    color={yearWatch >= 2999 ? "" : "primary"}
                                />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="company"
                            render={({
                                field: { ref, onChange, name, ...fieldProps },
                            }) => (
                                <Autocomplete
                                    {...fieldProps}
                                    id="company"
                                    options={companies.rows}
                                    isOptionEqualToValue={(option, value) =>
                                        option.uuid === value.uuid
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        onChange(value);
                                        if (
                                            value &&
                                            contractWatch?.companyUuid !==
                                                value.uuid
                                        )
                                            setValue("contract", null);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={ref}
                                            name={name}
                                            label="Filter on company"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="contract"
                            render={({
                                field: { ref, onChange, name, ...fieldProps },
                            }) => (
                                <Autocomplete
                                    {...fieldProps}
                                    id="contract"
                                    options={contracts.rows.filter(
                                        (contract) =>
                                            companyWatch
                                                ? companyWatch.uuid ===
                                                  contract.companyUuid
                                                : true
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        option.uuid === value.uuid
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        onChange(value);
                                        if (
                                            siteWatch?.contractUuid !==
                                            value?.uuid
                                        )
                                            setValue("site", null);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={ref}
                                            name={name}
                                            label="Filter on campaign"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="site"
                            render={({
                                field: { ref, onChange, name, ...fieldProps },
                            }) => (
                                <Autocomplete
                                    {...fieldProps}
                                    id="site"
                                    options={contractWatch ? sites?.rows : []}
                                    isOptionEqualToValue={(option, value) =>
                                        option.uuid === value.uuid
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        onChange(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={ref}
                                            name={name}
                                            label="Filter on site"
                                        />
                                    )}
                                    disabled={!contractWatch || !sites}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="status"
                            render={({
                                field: { ref, onChange, name, ...fieldProps },
                            }) => (
                                <Autocomplete
                                    {...fieldProps}
                                    id="status"
                                    options={Object.values(MISSION_STATUS)}
                                    onChange={(event, value) => {
                                        onChange(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={ref}
                                            name={name}
                                            label="Filter on mission status"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <RoutingKanban control={control} />
        </Stack>
    );
}
