import { Box, Card, Stack, Typography } from "@mui/material";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { MissionTableSkeleton } from "./MissionTableSkeleton";
import { formatDateISO } from "../../utils/formatTime";
import { getDataGridHeight } from "../utils/getDataGridHeight";
import { objectArrayStringify } from "../utils/objectArrayStringify";
import { useCallback } from "react";
import useSWR from "swr";
import { userNamesComparator } from "../utils/userNamesComparator";

const pageItemCount = 100;

// this fake uuid doesn't meet the uuidv4 requirements on purpose so we are 100% sure that the fetched data cannot have this value by default
// it's used as a replacement to null for supervisorUuid and must be used because MUI has issues with values being '' for selects
const noUserUuidValue = "this solution works somehow!";

export function MissionTable({ siteUuid }) {
    const {
        data: missions,
        error: missionsFetchError,
        mutate,
    } = useSWR(
        `${BACKEND_ROUTES.MISSION}?parentInfo=true&acquisitionVector=true&siteUuid=${siteUuid}`
    );

    const { data: users, error: usersFetchError } = useSWR(
        `${BACKEND_ROUTES.USER}?sort=firstName`
    );

    const { openSnackbar } = useSnackbar();
    const { patch } = useFetch();

    const handleProcessRowUpdateError = useCallback(
        (error) => {
            openSnackbar(error.message, "error");
        },
        [openSnackbar]
    );

    const processRowUpdate = useCallback(
        async (newRow, oldRow) => {
            const updatedMission = await patch(
                `${BACKEND_ROUTES.MISSION}/${newRow.uuid}`,
                {
                    body: {
                        supervisorUuid:
                            newRow.supervisorUuid === noUserUuidValue
                                ? null
                                : newRow.supervisorUuid,
                    },
                    forwardError: true,
                }
            );
            if (updatedMission) {
                openSnackbar(
                    `Updated Mission of ${newRow.date} successfully.`,
                    "success"
                );
                mutate();
                return newRow;
            }
            return oldRow;
        },
        [mutate, openSnackbar, patch]
    );

    const mergedFetchError = usersFetchError ?? missionsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!missions || !users) {
        return <MissionTableSkeleton />;
    }

    const gridRows = missions.rows.map((mission) => {
        return {
            id: mission.uuid,
            uuid: mission.uuid,
            date: formatDateISO(mission.date),
            acquisitionVector: mission.AcquisitionVector.name,
            dataType: objectArrayStringify(
                mission.AcquisitionVector.SensorBundles,
                ["Sensor", "dataType"]
            ),
            supervisorUuid: mission.supervisorUuid ?? noUserUuidValue,
        };
    });

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "date",
            headerName: "Acquisition Date",
            minWidth: 100,
            flex: 2,
        },
        {
            field: "dataType",
            headerName: "Data Type",
            minWidth: 140,
            flex: 2,
        },
        {
            field: "acquisitionVector",
            headerName: "Acquisition Vector",
            minWidth: 320,
            flex: 3,
        },
        {
            field: "supervisorUuid",
            headerName: "Supervisor",
            minWidth: 260,
            flex: 2,
            editable: true,
            type: "singleSelect",
            sortComparator: (userUuidA, userUuidB) =>
                userNamesComparator(
                    userUuidA,
                    userUuidB,
                    users,
                    noUserUuidValue,
                    "No Supervisor"
                ),
            valueOptions: [
                ...users.map((user) => ({
                    value: user.uuid,
                    label: `${user.firstName} ${user.lastName}`,
                })),
                { value: noUserUuidValue, label: "No supervisor" },
            ],
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h6" gutterBottom>
                    Missions
                </Typography>
            </Stack>

            <Card>
                <Box
                    style={{
                        height: getDataGridHeight(400, missions.rows.length),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        rowCount={missions.count}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "date", sort: "asc" }],
                            },
                        }}
                    />
                </Box>
            </Card>
        </>
    );
}
