/* eslint-disable no-underscore-dangle */
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection,
    $isTextNode,
    FORMAT_TEXT_COMMAND,
} from "lexical";
import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text";
import {
    Input,
    ListItemIcon,
    Menu,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";

import { $getNearestBlockElementAncestorOrThrow } from "@lexical/utils";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $patchStyleText } from "@lexical/selection";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CodeIcon from "@mui/icons-material/Code";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { IS_APPLE } from "./constants";
import { ImageInsert } from "./ImageInsert";
import PropTypes from "prop-types";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import SubscriptIcon from "@mui/icons-material/Subscript";
import SuperscriptIcon from "@mui/icons-material/Superscript";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import { initialState } from "./reducer";

export const TextStyles = ({
    activeEditor,
    bgColor,
    disabled,
    fontColor,
    isBold,
    isCode,
    isItalic,
    isUnderline,
    isStrikethrough,
    isSubscript,
    isSuperscript,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const bgColorRef = useRef(null);
    const fontColorRef = useRef(null);
    const applyStyleText = useCallback(
        (styles) => {
            activeEditor.update(() => {
                const selection = $getSelection();
                if (selection !== null) {
                    $patchStyleText(selection, styles);
                }
            }, {});
        },
        [activeEditor]
    );

    const onFontColorSelect = useCallback(
        (event) => applyStyleText({ color: event.target.value }),
        [applyStyleText]
    );

    const onBgColorSelect = useCallback(
        (event) => applyStyleText({ "background-color": event.target.value }),
        [applyStyleText]
    );

    const clearFormatting = useCallback(() => {
        handleClose();
        activeEditor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchor = selection.anchor;
                const focus = selection.focus;
                const nodes = selection.getNodes();

                if (
                    anchor.key === focus.key &&
                    anchor.offset === focus.offset
                ) {
                    return;
                }

                nodes.forEach((node, idx) => {
                    // We split the first and last node by the selection
                    // So that we don't format unselected text inside those nodes
                    if ($isTextNode(node)) {
                        // Use a separate variable to ensure TS does not lose the refinement
                        let textNode = node;
                        if (idx === 0 && anchor.offset !== 0) {
                            textNode =
                                textNode.splitText(anchor.offset)[1] ||
                                textNode;
                        }
                        if (idx === nodes.length - 1) {
                            textNode =
                                textNode.splitText(focus.offset)[0] || textNode;
                        }

                        if (textNode.__style !== "") {
                            textNode.setStyle("");
                        }
                        if (textNode.__format !== 0) {
                            textNode.setFormat(0);
                            $getNearestBlockElementAncestorOrThrow(
                                textNode
                            ).setFormat("");
                        }
                        node = textNode;
                    } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
                        node.replace($createParagraphNode(), true);
                    } else if ($isDecoratorBlockNode(node)) {
                        node.setFormat("");
                    }
                });
            }
        });
    }, [activeEditor]);

    const textFormats = [
        isBold && "bold",
        isItalic && "italic",
        isUnderline && "underlined",
        isCode && "code",
        (isStrikethrough || isSubscript || isSuperscript) && "more-styles",
    ].filter(Boolean);

    const colorFormats = [
        fontColor !== initialState.fontColor && "font-color",
        bgColor !== initialState.bgColor && "bg-color",
    ].filter(Boolean);

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <ToggleButtonGroup
                disabled={disabled}
                value={textFormats}
                sx={{ height: 40, mr: 1 }}
            >
                <Tooltip title={IS_APPLE ? "Bold (⌘B)" : "Bold (Ctrl+B)"}>
                    <ToggleButton
                        aria-label="bold"
                        onClick={() => {
                            activeEditor.dispatchCommand(
                                FORMAT_TEXT_COMMAND,
                                "bold"
                            );
                        }}
                        value="bold"
                    >
                        <FormatBoldIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip title={IS_APPLE ? "Italic (⌘I)" : "Italic (Ctrl+I)"}>
                    <ToggleButton
                        aria-label="italic"
                        onClick={() => {
                            activeEditor.dispatchCommand(
                                FORMAT_TEXT_COMMAND,
                                "italic"
                            );
                        }}
                        value="italic"
                    >
                        <FormatItalicIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip
                    title={IS_APPLE ? "Underline (⌘U)" : "Underline (Ctrl+U)"}
                >
                    <ToggleButton
                        aria-label="underlined"
                        onClick={() => {
                            activeEditor.dispatchCommand(
                                FORMAT_TEXT_COMMAND,
                                "underline"
                            );
                        }}
                        value="underlined"
                    >
                        <FormatUnderlinedIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip title="Insert code block">
                    <ToggleButton
                        aria-label="code"
                        onClick={() => {
                            activeEditor.dispatchCommand(
                                FORMAT_TEXT_COMMAND,
                                "code"
                            );
                        }}
                        value="code"
                    >
                        <CodeIcon />
                    </ToggleButton>
                </Tooltip>
                <ToggleButton
                    aria-label="more styles"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    sx={toggleButtonSelectStyles}
                    value="more-styles"
                >
                    <TextFormatIcon />
                    <ArrowDropDownIcon />
                </ToggleButton>
            </ToggleButtonGroup>
            <Menu
                id="more-styles-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        activeEditor.dispatchCommand(
                            FORMAT_TEXT_COMMAND,
                            "strikethrough"
                        );
                        handleClose();
                    }}
                    selected={isStrikethrough}
                >
                    <ListItemIcon>
                        <StrikethroughSIcon />
                    </ListItemIcon>
                    Strikethrough
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        activeEditor.dispatchCommand(
                            FORMAT_TEXT_COMMAND,
                            "subscript"
                        );
                        handleClose();
                    }}
                    selected={isSubscript}
                >
                    <ListItemIcon>
                        <SubscriptIcon />
                    </ListItemIcon>
                    Subscript
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        activeEditor.dispatchCommand(
                            FORMAT_TEXT_COMMAND,
                            "superscript"
                        );
                        handleClose();
                    }}
                    selected={isSuperscript}
                >
                    <ListItemIcon>
                        <SuperscriptIcon />
                    </ListItemIcon>
                    Superscript
                </MenuItem>
                <MenuItem onClick={clearFormatting}>
                    <ListItemIcon>
                        <FormatClearIcon />
                    </ListItemIcon>
                    Clear Formatting
                </MenuItem>
            </Menu>
            <ToggleButtonGroup
                disabled={disabled}
                value={colorFormats}
                sx={{ height: 40, mr: 1 }}
            >
                <ToggleButton
                    aria-label="font color"
                    onClick={() => fontColorRef.current?.click()}
                    sx={toggleButtonSelectStyles}
                    value="font-color"
                >
                    <FormatColorTextIcon />
                    <Input
                        inputRef={fontColorRef}
                        onChange={onFontColorSelect}
                        value={fontColor}
                        sx={{ visibility: "hidden", width: "0px" }}
                        type="color"
                    />
                    <ArrowDropDownIcon />
                </ToggleButton>
                <ToggleButton
                    aria-label="background color"
                    onClick={() => bgColorRef.current?.click()}
                    sx={toggleButtonSelectStyles}
                    value="bg-color"
                >
                    <FormatColorFillIcon />
                    <Input
                        inputRef={bgColorRef}
                        onChange={onBgColorSelect}
                        value={bgColor}
                        sx={{ visibility: "hidden", width: "0px" }}
                        type="color"
                    />
                    <ArrowDropDownIcon />
                </ToggleButton>
                <ImageInsert />
            </ToggleButtonGroup>
        </>
    );
};

const toggleButtonSelectStyles = { pr: 0.5 };

TextStyles.propTypes = {
    // should be `PropTypes.instanceOf(LexicalEditor).isRequired` but the class is not exported
    activeEditor: PropTypes.object.isRequired,
    bgColor: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    fontColor: PropTypes.string.isRequired,
    isBold: PropTypes.bool.isRequired,
    isCode: PropTypes.bool.isRequired,
    isItalic: PropTypes.bool.isRequired,
    isUnderline: PropTypes.bool.isRequired,
    isStrikethrough: PropTypes.bool.isRequired,
    isSubscript: PropTypes.bool.isRequired,
    isSuperscript: PropTypes.bool.isRequired,
};
