import { ALLOWED_FACTOR_BELOW_MINIMUM_GSD } from "../../constants";
import { getMinGsd } from "./getMinGsd";
/**
 * gsdPerDataType is an object containing gsd per data type
 * @typedef {Object} gsdPerDataType
 * @property {number} DATATYPE - GSD for this data type, the data type being the key and the gsd being the value
 */

/**
 * gsdPerStage is an object containing a pair of bbch stage and gsdPerDataType
 * @typedef {Object} gsdPerStage
 * @property {number} order - BBCH stage order
 * @property {gsdPerDataType} gsd - An object containing one value of GSD per data type, cannot be null or 0, will be counted as null if lower than the minimum allowed gsd by more than 10%
 */

/**
 * Returns the minimal GSD per data type for all BBCH traits, for a specific pipeline
 * @param {Object} pipelineTemplate - Target pipeline template
 * @param {Object} bbchStageData - BBCH stage fetch data - usually has rows and count as its keys, and bbchStageData.rows contains all bbch stages
 * @return {gsdPerStage[]} results of the bulkCreate
 */
export const getGsdPerStage = (pipelineTemplate, bbchStageData) => {
    const minGSD = getMinGsd(pipelineTemplate);
    return bbchStageData.map((BBCH) => {
        const tempMin = pipelineTemplate.PipelineTemplateTraitGroups.filter(
            (PTTG) => PTTG.bbchStageUuid === BBCH.uuid
            // we filter all the pipeline template trait groups from the pipeline template that have the corresponding bbch stage
        )
            .map((PTTG) => {
                const gsdPTG = pipelineTemplate.gsdPerTraitGroupDataType.find(
                    (gsdPTG) =>
                        gsdPTG.traitGroupDataTypeUuid ===
                        PTTG.traitGroupDataTypeUuid
                ); // we find the only gsdPerTraitGroupDataType value that is tied to the trait group, for each PTTG
                return {
                    gsd:
                        gsdPTG.gsd === null ||
                        gsdPTG.gsd * ALLOWED_FACTOR_BELOW_MINIMUM_GSD <
                            minGSD[gsdPTG.dataType]
                            ? null
                            : gsdPTG.gsd,
                    dataType: gsdPTG.dataType,
                };
                // if gsd is null, we set it to Infinity so that it doesn't get picked over actual values for Math.min
            })
            .reduce((previous, current) => {
                if (
                    current.gsd &&
                    (!previous[current.dataType] ||
                        previous[current.dataType] >= current.gsd)
                ) {
                    previous[current.dataType] = current.gsd;
                }

                return previous;
            }, {});
        return {
            gsd: tempMin,
            order: BBCH.order,
        };
    });
};
