import {
    Box,
    Checkbox,
    Collapse,
    FormControl,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    RadioGroup,
} from "@mui/material";
import { compareDesc, parseISO } from "date-fns";
import { useMemo, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MODEL_PROPTYPES } from "../../../models";
import { PIPELINE_INSTANCE_STATUS } from "../../../constants";
import { PipelineInstanceListItem } from "./PipelineInstanceListItem";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/formatTime";
import { useParams } from "react-router-dom";

export const MissionListItem = ({
    experimentMissionTraitGroup: {
        Associable: {
            uuid: associableUuid,
            Mission: { date },
        },
        PipelineTraitGroups: pipelineTraitGroups,
        uuid,
        validatedPipelineInstanceUuid,
    },
    missionRowState: { checked, pipelineInstance },
    setDrawerFilter,
    showError,
}) => {
    const [open, setOpen] = useState(false);
    const { experimentMissionUuid } = useParams();

    const filteredPipelineTraitGroups = useMemo(() => {
        const statusList = showError
            ? [
                  PIPELINE_INSTANCE_STATUS.ERROR,
                  PIPELINE_INSTANCE_STATUS.FINISHED,
              ]
            : [PIPELINE_INSTANCE_STATUS.FINISHED];

        const tempSortedPI = pipelineTraitGroups
            .filter(({ PipelineInstance: { status } }) =>
                statusList.includes(status)
            )
            .sort(
                (
                    { PipelineInstance: { createdAt: createdAt1 } },
                    { PipelineInstance: { createdAt: createdAt2 } }
                ) => compareDesc(parseISO(createdAt1), parseISO(createdAt2))
            );

        if (validatedPipelineInstanceUuid) {
            const index = tempSortedPI.findIndex(
                ({ uuid }) => uuid === validatedPipelineInstanceUuid
            );

            // The validated pipeline instance should always be find in the pipeline instance list
            // Do not unshift if already at the top
            if (index > 0) {
                const pipelineInstanceToUnshift = tempSortedPI[index];

                tempSortedPI.splice(index, 1);
                tempSortedPI.unshift(pipelineInstanceToUnshift);
            }
        }

        return tempSortedPI;
    }, [pipelineTraitGroups, showError, validatedPipelineInstanceUuid]);

    const isValidationPageMission = experimentMissionUuid === associableUuid;

    const handleChangePipelineInstance = (event) =>
        setDrawerFilter((prevState) => ({
            ...prevState,
            [uuid]: {
                ...prevState[uuid],
                pipelineInstance: event.target.value,
            },
        }));

    const handleToggleMission = () =>
        setDrawerFilter((prevState) => ({
            ...prevState,
            [uuid]: { ...prevState[uuid], checked: !prevState[uuid].checked },
        }));

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => setOpen(!open)}
                    dense
                    disableGutters
                >
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            disabled={isValidationPageMission}
                            checked={checked}
                            tabIndex={-1}
                            onClick={handleToggleMission}
                            onMouseDown={(event) => event.stopPropagation()}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            fontWeight: isValidationPageMission
                                ? "bold"
                                : undefined,
                        }}
                        primary={formatDateTime(date)}
                    />
                    <Box pr={1} alignItems="center" display="flex">
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <FormControl disabled={!checked} sx={{ width: 1, pl: 3 }}>
                    <RadioGroup
                        value={pipelineInstance}
                        onChange={handleChangePipelineInstance}
                    >
                        {filteredPipelineTraitGroups.map(
                            (pipelineTraitGroup) => (
                                <PipelineInstanceListItem
                                    key={pipelineTraitGroup.uuid}
                                    isValidated={
                                        validatedPipelineInstanceUuid ===
                                        pipelineTraitGroup.PipelineInstance.uuid
                                    }
                                    pipelineTraitGroup={pipelineTraitGroup}
                                />
                            )
                        )}
                    </RadioGroup>
                </FormControl>
            </Collapse>
        </>
    );
};

MissionListItem.propTypes = {
    experimentMissionTraitGroup: PropTypes.shape(
        MODEL_PROPTYPES.ExperimentMissionTraitGroup
    ).isRequired,
    missionRowState: PropTypes.exact({
        checked: PropTypes.bool.isRequired,
        pipelineInstance: PropTypes.string.isRequired,
    }).isRequired,
    setDrawerFilter: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired,
};
