import {
    SensorSimulator35EqvFocalLengthFromReal,
    SensorSimulatorFOV,
    SensorSimulatorFootprint,
    SensorSimulatorGSD,
    SensorSimulatorRealFocalLengthFrom35Eqv,
    SensorSimulatorRealFocalLengthFromGSD,
} from ".";

import { Grid } from "@mui/material";

export function SensorSimulatorForm() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <SensorSimulatorGSD />
            </Grid>
            <Grid item xs={12}>
                <SensorSimulator35EqvFocalLengthFromReal />
            </Grid>
            <Grid item xs={12}>
                <SensorSimulatorRealFocalLengthFromGSD />
            </Grid>
            <Grid item xs={12}>
                <SensorSimulatorRealFocalLengthFrom35Eqv />
            </Grid>
            <Grid item xs={12}>
                <SensorSimulatorFOV />
            </Grid>
            <Grid item xs={12}>
                <SensorSimulatorFootprint />
            </Grid>
        </Grid>
    );
}
