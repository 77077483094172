import {
    getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
} from "@mui/x-data-grid";

/**
 * Construct an array of MUI DataGrid filters. Used for specifying available filters of a column of a specific type.
 * Each column type has a predefined set of filters, but an optional parameter can be used to customize it.
 * @param {string} type - type of the column (string, numeric, date, select)
 * @param {string[]} [filterArray] - custom array of operators to be selected
 * @returns {GridFilterOperator<any, number | string | null, any>[]} array of grid filter operators
 */
export function getGridOperators(type, filterArray = undefined) {
    let gridOperators = [];
    let defaultFilterArray = [];

    switch (type) {
        case "string":
            gridOperators = getGridStringOperators();
            defaultFilterArray = ["contains", "equals"];
            break;
        case "numeric":
            gridOperators = getGridNumericOperators();
            // we don't include "between" here because our "between" operator is custom-made
            defaultFilterArray = ["is", "not"];
            break;
        case "date":
            gridOperators = getGridDateOperators();
            // we don't include "between" here because our "between" operator is custom-made
            defaultFilterArray = ["is", "not", "before", "after"];
            break;
        case "select":
            gridOperators = getGridSingleSelectOperators();
            defaultFilterArray = ["is", "not"];
            break;
        default:
            throw new RangeError("Unknown column type.");
    }

    return gridOperators.filter((operator) =>
        filterArray
            ? filterArray.includes(operator.value)
            : defaultFilterArray.includes(operator.value)
    );
}
