import { Box, Grid, Stack, Typography } from "@mui/material";

import { FlagTooltipIcon } from "../../components/FlagTooltipIcon";
import { LabelStyle } from "../../components/LabelStyle";
import { MODEL_PROPTYPES } from "../../models";
import PropTypes from "prop-types";
import { formatDateTime } from "../../utils/formatTime";

ClientInfoGrid.propTypes = {
    mission: PropTypes.shape(MODEL_PROPTYPES.Mission).isRequired,
    flagsStringArray: PropTypes.array,
};

export function ClientInfoGrid({ mission, flagArray = [] }) {
    const [acquisitionDate, acquisitionTime] = formatDateTime(
        mission.date
    ).split(" ");

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
                <LabelStyle>Acquisition date</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                <Stack direction="row" spacing={3}>
                    <Box>{acquisitionDate}</Box>
                    <Typography sx={{ color: "text.secondary" }}>
                        {acquisitionTime}
                    </Typography>
                </Stack>
            </Grid>

            <Grid item xs={4}>
                <LabelStyle>Company</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {mission.Site.Contract.Company.name}
            </Grid>

            <Grid item xs={4}>
                <LabelStyle>Campaign</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {mission.Site.Contract.name}
            </Grid>

            <Grid item xs={4}>
                <LabelStyle>Site</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {mission.Site.name}
            </Grid>

            <Grid item xs={4}>
                <LabelStyle>Upload date</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {formatDateTime(mission.createdAt)}
            </Grid>

            <Grid item xs={4}>
                <LabelStyle>Comment</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {mission.comment ?? "-"}
            </Grid>

            {flagArray.length !== 0 && (
                <Grid container item spacing={1} xs={12} alignItems="center">
                    <Grid item xs={4}>
                        <LabelStyle>Quality flags</LabelStyle>
                    </Grid>{" "}
                    <Grid item xs={8}>
                        {flagArray.map((flag) => (
                            <FlagTooltipIcon
                                key={flag.value}
                                Icon={flag.icon}
                                message={flag.message}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
