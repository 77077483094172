import { Container } from "@mui/material";
import { LeafletMap } from "../features/parcellaire/LeafletMap";
import Page from "../components/Page";

export default function Parcellaire() {
    return (
        <Page title="Parcellaire | HCC">
            <Container maxWidth={false} disableGutters>
                <LeafletMap />
            </Container>
        </Page>
    );
}
