import {
    drawCanvas,
    getImageDataFromNativeImageUrl,
    readGrayscaleTifNormalized,
} from "../utils";
import { useEffect, useRef } from "react";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import useSWR from "swr";

// regex checking for strings ending in ".tif" or ".tiff" (ignoring case)
const tifRegex = /^(.*\.(tif|tiff))$/i;

export const VisualisationCanvas = ({
    entity,
    forcedWidth = null,
    mustRotate = false,
    ...rest
}) => {
    const canvasRef = useRef(null);

    // TODO: see if specific actions needed according to different file type
    // const { data: file, error: fileFetchError } = useSWR(
    //     `${BACKEND_ROUTES.FILES}/${entityFile.fileUuid}`
    // );
    const { data: presignedUrl, error: presignedUrlFetchError } = useSWR(
        `${BACKEND_ROUTES.PUBLIC_SIGNED_URL}?key=${entity.s3Location}`
    );

    useEffect(() => {
        async function fetchAndDraw(presignedUrl) {
            const imageData = entity.s3Location.match(tifRegex)
                ? await readGrayscaleTifNormalized(presignedUrl)
                : await getImageDataFromNativeImageUrl(presignedUrl);

            const canvas = canvasRef.current;
            const canvasWidth = forcedWidth ?? imageData.width;

            if (canvas)
                await drawCanvas(imageData, canvas, canvasWidth, mustRotate);

            return;
        }
        if (presignedUrl) fetchAndDraw(presignedUrl);
    }, [presignedUrl, canvasRef, forcedWidth, entity.s3Location, mustRotate]);

    const mergedFetchError = presignedUrlFetchError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    return <canvas ref={canvasRef} {...rest} />;
};
