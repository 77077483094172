import { Skeleton, Stack } from "@mui/material";

export const AdvancedAcquisitionReportSkeleton = () => {
    return (
        <Stack direction="column" spacing={1}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="20%"
                    height="3em"
                />

                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="20%"
                    height="3em"
                />
            </Stack>

            <Stack direction="row" spacing={1}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="20em"
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="20em"
                />
            </Stack>

            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="10em"
            />

            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="30em"
            />

            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="30em"
            />

            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="30em"
            />

            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="30em"
            />
        </Stack>
    );
};
