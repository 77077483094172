import { Container } from "@mui/material";
import Page from "../components/Page";
import { TraitTable } from "../features/trait";

export default function TraitGlossary() {
    return (
        <Page title="Trait glossary | HCC">
            <Container maxWidth="xl">
                <TraitTable />
            </Container>
        </Page>
    );
}
