import { CHART_MODEBAR_BUTTONS_TO_REMOVE } from "../constants";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { mean } from "../utils";

LinePlot.propTypes = {
    xValues: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ).isRequired,
    yValues: PropTypes.arrayOf(PropTypes.number).isRequired,
    threshold: PropTypes.number,
    showMeanLine: PropTypes.bool,
    traceName: PropTypes.string,
    color: PropTypes.string,
    onSelected: PropTypes.func,
    entities: (props, propName, componentName) => {
        if (Boolean(props.onSelected) && !Array.isArray(props[propName])) {
            return new Error(
                `Invalid prop '${propName}' supplied to '${componentName}'. Validation failed.`
            );
        }
    },
    uirevision: PropTypes.any,
};

export function LinePlot({
    xValues,
    yValues,
    threshold,
    showMeanLine = false,
    traceName,
    color,
    onSelected,
    entities,
    uirevision,
}) {
    const shapes = [];

    if (threshold)
        shapes.push({
            type: "line",
            x0: xValues.at(0),
            y0: threshold,
            x1: xValues.at(-1),
            y1: threshold,
            line: {
                color: "rgb(255, 0, 0)",
                dash: "dash",
                width: 1,
            },
            label: {
                text: "threshold",
                font: { color: "rgb(255, 0, 0)" },
                textposition: "end",
                yanchor: "top",
            },
        });

    if (showMeanLine) {
        const yMean = mean(yValues);
        shapes.push({
            type: "line",
            x0: xValues.at(0),
            y0: yMean,
            x1: xValues.at(-1),
            y1: yMean,
            line: {
                dash: "dot",
                width: 1,
            },
            label: {
                text: "mean",
                textposition: "end",
                yanchor: "top",
            },
        });
    }

    return (
        <Plot
            data={[
                {
                    x: xValues,
                    y: yValues,
                    type: "scatter",
                    mode: "lines+markers",
                    name: traceName,
                    marker: {
                        color: color,
                    },
                    line: {
                        color: color,
                    },
                },
            ]}
            layout={{
                dragmode: "select",
                autosize: true,
                showlegend: Boolean(traceName),
                legend: traceName
                    ? { orientation: "h", x: 0.5, xanchor: "center", y: 1.3 }
                    : undefined,
                uirevision: uirevision,
                shapes: shapes,
            }}
            onSelected={(eventData) => {
                if (onSelected) {
                    // eventData is undefined in the case of an "unselection" (a double-click for example)
                    if (eventData !== undefined) {
                        const selectedEntities = [];

                        eventData.points.forEach((point) => {
                            selectedEntities.push(entities[point.pointNumber]);
                        });

                        onSelected(selectedEntities);
                    } else onSelected(undefined);
                }
            }}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
            config={{
                displaylogo: false,
                modeBarButtonsToRemove: CHART_MODEBAR_BUTTONS_TO_REMOVE,
            }}
        />
    );
}
