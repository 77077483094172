import { CompanyTable } from "../features/company";
import { Container } from "@mui/material";
import Page from "../components/Page";

export default function Company() {
    return (
        <Page title="Companies | HCC">
            <Container maxWidth="xl">
                <CompanyTable />
            </Container>
        </Page>
    );
}
