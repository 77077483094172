import {
    Box,
    Breadcrumbs,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useFetch, useSnackbar } from "../../../hooks";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { BreadcrumbChip } from "../../../components/BreadcrumbChip";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { REPORT_STATUS } from "../../../constants";
import { RichTextEditor } from "../../../components/rich-text-editor";
import { formatDateISO } from "../../../utils/formatTime";
import { useMission } from "../useMission";
import { useParams } from "react-router-dom";

export const AcquisitionReportForm = ({ handleClose }) => {
    const { missionUuid } = useParams();
    const { openSnackbar } = useSnackbar();

    const { put } = useFetch();

    const { data: mission, mutate } = useMission(missionUuid);

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            reportStatus: mission.reportStatus,
            reportComment: mission.reportComment,
        },
    });

    const onSubmit = async (data) => {
        const body = {
            status: data.reportStatus,
            comment: data.reportComment && JSON.stringify(data.reportComment),
        };
        let updatedMission;
        try {
            updatedMission = await put(
                `${BACKEND_ROUTES.MISSION}/${missionUuid}/acquisition-report`,
                {
                    body: body,
                    forwardError: true,
                }
            );
            mutate({ ...mission, ...updatedMission }, { revalidate: false });
            handleClose();
            openSnackbar("Acquisition Report Sent", "success");
        } catch (error) {
            const message =
                error.errorCode === 12
                    ? "Images size exceeds the limit."
                    : "Something went wrong. Please reload the page and try again.";

            openSnackbar(message, "error");
        }
    };

    return (
        <>
            <DialogTitle display="flex">
                Flight Report
                <Breadcrumbs separator="/" ml="auto">
                    <BreadcrumbChip
                        label={mission.Site.Contract.Company.name}
                    />
                    <BreadcrumbChip label={mission.Site.Contract.name} />
                    <BreadcrumbChip label={mission.Site.name} />
                    <BreadcrumbChip label={formatDateISO(mission.date)} />
                </Breadcrumbs>
            </DialogTitle>

            <DialogContent>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    id="acquisition-report-form"
                >
                    <Typography variant="h6" gutterBottom>
                        Description
                    </Typography>
                    <Controller
                        name="reportComment"
                        control={control}
                        render={({ field }) => (
                            <Box mb={2.5}>
                                <RichTextEditor
                                    defaultEditorState={field.value}
                                    onChange={field.onChange}
                                />
                            </Box>
                        )}
                    />
                    <Controller
                        name="reportStatus"
                        control={control}
                        rules={{ required: "Quality Status is required" }}
                        render={({ field: { disabled, ...field } }) => (
                            <FormControl disabled={disabled}>
                                <FormLabel
                                    focused={false}
                                    sx={{
                                        color: "common.black",
                                        fontWeight: "bold",
                                        mb: 1,
                                    }}
                                >
                                    Quality Status
                                </FormLabel>
                                {errors.reportStatus && (
                                    <FormHelperText error>
                                        {errors.reportStatus.message}
                                    </FormHelperText>
                                )}

                                <RadioGroup row {...field}>
                                    <FormControlLabel
                                        value={REPORT_STATUS.VALIDATED}
                                        label={REPORT_STATUS.VALIDATED}
                                        labelPlacement="top"
                                        slotProps={{
                                            typography: {
                                                color:
                                                    field.value ===
                                                    REPORT_STATUS.VALIDATED
                                                        ? "success.main"
                                                        : "common.black",
                                            },
                                        }}
                                        control={
                                            <Radio
                                                color={
                                                    field.value ===
                                                    REPORT_STATUS.VALIDATED
                                                        ? "success"
                                                        : "default"
                                                }
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        value={REPORT_STATUS.WARNING}
                                        label={REPORT_STATUS.WARNING}
                                        labelPlacement="top"
                                        slotProps={{
                                            typography: {
                                                color:
                                                    field.value ===
                                                    REPORT_STATUS.WARNING
                                                        ? "warning.main"
                                                        : "common.black",
                                            },
                                        }}
                                        control={
                                            <Radio
                                                color={
                                                    field.value ===
                                                    REPORT_STATUS.WARNING
                                                        ? "warning"
                                                        : "default"
                                                }
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        value={REPORT_STATUS.REJECTED}
                                        label={REPORT_STATUS.REJECTED}
                                        labelPlacement="top"
                                        slotProps={{
                                            typography: {
                                                color:
                                                    field.value ===
                                                    REPORT_STATUS.REJECTED
                                                        ? "error.main"
                                                        : "common.black",
                                            },
                                        }}
                                        control={
                                            <Radio
                                                color={
                                                    field.value ===
                                                    REPORT_STATUS.REJECTED
                                                        ? "error"
                                                        : "default"
                                                }
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        value={REPORT_STATUS.UNKNOWN}
                                        label={REPORT_STATUS.UNKNOWN}
                                        labelPlacement="top"
                                        slotProps={{
                                            typography: {
                                                color:
                                                    field.value ===
                                                    REPORT_STATUS.UNKNOWN
                                                        ? "grey.600"
                                                        : "common.black",
                                            },
                                        }}
                                        control={<Radio color="default" />}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                    form="acquisition-report-form"
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </>
    );
};

AcquisitionReportForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
};
