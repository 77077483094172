import { translateString } from "./translateString";

const operatorTranslation = {
    is: "equals",
};

const complexOperators = ["between"];

/**
 * Construct an object that can be used to create the url search params for filtering on a field
 * An empty object is returned if the the argument value is undefined or null.
 * @param {string} field - name of the field the filtering will be applied to
 * @param {string} operator - value of the filter operator, will be adjusted to "equals" if its value is "is"
 * @param {*} value - value for filtering, should be either a single value or an array of 2 values depending on the operator
 * @returns {string} filtering object
 */
export function getFilterQueryObject(field, operator, value) {
    const translatedOperator = translateString(operator, operatorTranslation);

    if (complexOperators.includes(translatedOperator)) {
        if (
            Array.isArray(value) &&
            value[0] !== null &&
            value[0] !== undefined &&
            value[1] !== null &&
            value[1] !== undefined
        ) {
            return {
                [`${field}[0]`]: translatedOperator,
                [`${field}[1]`]: value[0],
                [`${field}[2]`]: value[1],
            };
        }
        return {};
    }

    if (value !== null && value !== undefined)
        return {
            [`${field}[0]`]: translatedOperator,
            [`${field}[1]`]: value,
        };
    return {};
}

export const createSortSearchParams = (sortModel, sortMap) => {
    if (!sortModel.length) return {};

    const [{ field, sort }] = sortModel;

    return { sort: `${sort === "desc" ? "-" : ""}${sortMap[field] ?? field}` };
};
