import { Card, Stack, Typography } from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { PAGINATION } from "../../constants";
import Scrollbar from "../../components/Scrollbar";
import { SystemModelTableSkeleton } from "./SystemModelTableSkeleton";
import { useMemo } from "react";
import useSWR from "swr";

const pageItemCount = 100;

export function SystemModelTable() {
    const { data: systemModels, error: systemModelsFetchError } = useSWR(
        `${BACKEND_ROUTES.SYSTEM_MODEL}?limit=${PAGINATION.GENERIC.LIMIT.MAX}`
    );
    const { data: acquisitionVectors, error: acquisitionVectorsFetchError } =
        useSWR(
            `${BACKEND_ROUTES.ACQUISITION_VECTOR}?sensor=true&systemModel=true&limit=${PAGINATION.ACQUISITION_VECTOR.LIMIT.MAX}`
        );

    const mergedFetchError =
        acquisitionVectorsFetchError ?? systemModelsFetchError;

    const gridRows = useMemo(
        () =>
            systemModels?.rows.map((systemModel) => {
                return {
                    id: systemModel.uuid,
                    uuid: systemModel.uuid,
                    name: systemModel.name,
                    system: systemModel.system,
                    sensors: [
                        ...new Set(
                            acquisitionVectors?.rows
                                .filter(
                                    (AV) =>
                                        AV.systemModelUuid === systemModel.uuid
                                )
                                .flatMap((AV) =>
                                    AV.SensorBundles.map(
                                        (sensorBundle) =>
                                            sensorBundle.Sensor.name
                                    )
                                )
                        ),
                    ]
                        .sort()
                        .join(", "),
                };
            }),
        [acquisitionVectors, systemModels]
    );

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!systemModels || !acquisitionVectors) {
        return <SystemModelTableSkeleton />;
    }

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 250,
            flex: 2,
        },
        {
            field: "system",
            headerName: "System",
            minWidth: 100,
            flex: 1,
        },
        {
            field: "sensors",
            headerName: "Compatible sensors",
            minWidth: 350,
            flex: 5,
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h4" gutterBottom>
                    System Models
                </Typography>
            </Stack>

            <Card>
                <Scrollbar>
                    <DataGrid
                        autoHeight
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        getRowHeight={() => "auto"}
                        sx={{
                            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell":
                                {
                                    py: "8px",
                                },
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "system", sort: "asc" }],
                            },
                        }}
                        rowCount={systemModels.count}
                    />
                </Scrollbar>
            </Card>
        </>
    );
}
