import {
    Card,
    CardActionArea,
    CardContent,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";

import { Gauge } from "./Gauge";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";

GaugeCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number,
    tooltip: PropTypes.node,
    unit: PropTypes.string.isRequired,
    valueCount: PropTypes.number.isRequired,
    datasetCount: PropTypes.number.isRequired,
    GaugeProps: PropTypes.exact({
        valueMin: PropTypes.number,
        valueMax: PropTypes.number,
        ranges: PropTypes.exact({
            warning: PropTypes.arrayOf(PropTypes.number.isRequired),
            success: PropTypes.arrayOf(PropTypes.number.isRequired),
        }).isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        startAngle: PropTypes.number,
        endAngle: PropTypes.number,
    }).isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export function GaugeCard({
    title,
    value,
    tooltip,
    unit,
    valueCount,
    datasetCount,
    GaugeProps,
    onClick,
    isSelected,
    disabled,
}) {
    const isCompleteDataset = valueCount === datasetCount;
    const countTooltipText = valueCount
        ? isCompleteDataset
            ? ""
            : `${title.toLowerCase()} data missing on ${datasetCount - valueCount} image(s)`
        : `No images with ${title.toLowerCase()} data`;

    return (
        <Card
            sx={{
                opacity: value == null ? 0.5 : 1,
                border: (theme) =>
                    isSelected
                        ? `3px solid ${theme.palette.primary.dark}`
                        : "none",
            }}
        >
            <CardActionArea disabled={disabled} onClick={onClick}>
                <CardContent sx={{ p: 1 }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={"space-between"}
                    >
                        <Typography variant="h6">
                            {value != null ? `${value} ${unit}` : "N/A"}
                        </Typography>
                        <Tooltip
                            title={
                                countTooltipText && (
                                    <Typography variant="subtitle2">
                                        {countTooltipText}
                                    </Typography>
                                )
                            }
                        >
                            <Typography
                                color={
                                    valueCount
                                        ? isCompleteDataset
                                            ? "success.main"
                                            : "warning.main"
                                        : "error.main"
                                }
                            >
                                {valueCount}/{datasetCount}
                            </Typography>
                        </Tooltip>
                        {tooltip && (
                            <Tooltip
                                title={
                                    <Typography variant="subtitle2">
                                        {tooltip}
                                    </Typography>
                                }
                            >
                                <InfoIcon sx={{ color: "grey.500" }} />
                            </Tooltip>
                        )}
                    </Stack>
                    <Stack alignItems="center" textAlign="center">
                        <Gauge value={value} {...GaugeProps} />
                        <Typography variant="h6">{title}</Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
