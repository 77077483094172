import { IconButton, TextField } from "@mui/material";

import { $getSelection } from "lexical";
import { $patchStyleText } from "@lexical/selection";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import RemoveIcon from "@mui/icons-material/Remove";

export const FontSize = ({ disabled, editor, fontSize, setFontSize }) => {
    const updateFontSizeInSelection = (updateType) => {
        editor.update(() => {
            if (editor.isEditable()) {
                const selection = $getSelection();
                if (selection !== null) {
                    $patchStyleText(selection, {
                        "font-size": `${calculateNextFontSize(
                            Number(fontSize),
                            updateType
                        )}px`,
                    });
                }
            }
        });
    };

    const handleKeyPress = (event) => {
        if (excludedKeys.includes(event.key) || isNaN(fontSize)) {
            event.preventDefault();
            setFontSize("");
        } else if (event.key === "Enter") {
            event.preventDefault();
            updateFontSizeInSelection(updateFontSizeType.selection);
        }
    };

    return (
        <>
            <IconButton
                disabled={
                    disabled ||
                    (fontSize !== "" && fontSize <= minAllowedFontSize)
                }
                onClick={() =>
                    updateFontSizeInSelection(updateFontSizeType.decrement)
                }
            >
                <RemoveIcon />
            </IconButton>
            <TextField
                id="font-size"
                type="number"
                value={fontSize}
                disabled={disabled}
                min={minAllowedFontSize}
                max={maxAllowedFontSize}
                onChange={(event) =>
                    setFontSize(event.target.value && `${event.target.value}px`)
                }
                onKeyDown={handleKeyPress}
                sx={{ width: "50px" }}
            />
            <IconButton
                disabled={
                    disabled ||
                    (fontSize !== "" && fontSize >= maxAllowedFontSize)
                }
                onClick={() =>
                    updateFontSizeInSelection(updateFontSizeType.increment)
                }
            >
                <AddIcon />
            </IconButton>
        </>
    );
};

const excludedKeys = ["e", "E", "+", "-"];

const calculateNextFontSize = (currentFontSize, updateType) => {
    if (!updateType) {
        return currentFontSize;
    }

    let updatedFontSize = currentFontSize;
    switch (updateType) {
        case updateFontSizeType.decrement:
            switch (true) {
                case currentFontSize > maxAllowedFontSize:
                    updatedFontSize = maxAllowedFontSize;
                    break;
                case currentFontSize >= 48:
                    updatedFontSize -= 12;
                    break;
                case currentFontSize >= 24:
                    updatedFontSize -= 4;
                    break;
                case currentFontSize >= 14:
                    updatedFontSize -= 2;
                    break;
                case currentFontSize >= 9:
                    updatedFontSize -= 1;
                    break;
                default:
                    updatedFontSize = minAllowedFontSize;
                    break;
            }
            break;

        case updateFontSizeType.increment:
            switch (true) {
                case currentFontSize < minAllowedFontSize:
                    updatedFontSize = minAllowedFontSize;
                    break;
                case currentFontSize < 12:
                    updatedFontSize += 1;
                    break;
                case currentFontSize < 20:
                    updatedFontSize += 2;
                    break;
                case currentFontSize < 36:
                    updatedFontSize += 4;
                    break;
                case currentFontSize <= 60:
                    updatedFontSize += 12;
                    break;
                default:
                    updatedFontSize = maxAllowedFontSize;
                    break;
            }
            break;

        case updateFontSizeType.selection:
            switch (true) {
                case currentFontSize > maxAllowedFontSize:
                    updatedFontSize = maxAllowedFontSize;
                    break;
                case currentFontSize < minAllowedFontSize:
                    updatedFontSize = minAllowedFontSize;
                    break;
                default:
                    updatedFontSize = currentFontSize;
            }
            break;

        default:
            break;
    }

    return updatedFontSize;
};

const minAllowedFontSize = 8;
const maxAllowedFontSize = 72;

const updateFontSizeType = Object.freeze({
    increment: 1,
    decrement: 2,
    selection: 3,
});

FontSize.propTypes = {
    disabled: PropTypes.bool.isRequired,
    // should be `PropTypes.instanceOf(LexicalEditor).isRequired` but the class is not exported
    editor: PropTypes.object.isRequired,
    fontSize: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.oneOf([""]),
    ]),
    setFontSize: PropTypes.func.isRequired,
};
