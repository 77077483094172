import { APPBAR_HEIGHT } from "./constants";
import OrdersNavbar from "./OrdersNavbar";
import OrdersSidebar from "./OrdersSidebar";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const RootStyle = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
});

const MainStyle = styled("div")(({ theme, noPadding }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: noPadding ? APPBAR_HEIGHT : APPBAR_HEIGHT + 24,
    paddingBottom: noPadding ? 0 : theme.spacing(10),
    paddingLeft: noPadding ? 0 : theme.spacing(2),
    paddingRight: noPadding ? 0 : theme.spacing(2),
}));

export default function OrdersLayout({ noPadding = false }) {
    const [open, setOpen] = useState(true);

    return (
        <RootStyle>
            <OrdersNavbar
                isOpenSidebar={open}
                onOpenSidebar={() => setOpen(true)}
            />
            <OrdersSidebar
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
            />
            <MainStyle noPadding={noPadding}>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
