import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import { getDataGridHeight, objectArrayStringify } from "../../utils";
import { useFetch, useSnackbar } from "../../../hooks";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { FRONTEND_ROUTES } from "../../../frontendRoutes";
import { FlagTooltipIcon } from "../../../components/FlagTooltipIcon";
import { MODEL_PROPTYPES } from "../../../models";
import { MODES } from "../../acquisition-report/constants";
import PropTypes from "prop-types";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { flagsSetter } from "./";
import { getPipelineTemplateGsdOfStage } from "../../acquisition-report/utils";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

AssociationTable.propTypes = {
    mission: PropTypes.shape(MODEL_PROPTYPES.Mission).isRequired,
    pipelineTemplates: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.PipelineTemplate).isRequired
    ).isRequired,
    mutateMission: PropTypes.func.isRequired,
};

export function AssociationTable({
    mission,
    pipelineTemplates,
    mutateMission,
}) {
    const navigate = useNavigate();
    const { destroy } = useFetch();
    const { openSnackbar } = useSnackbar();
    const {
        ExperimentMissions: experimentMissions,
        rawDatasetStatistics,
        associationCompleted,
    } = mission;

    const gridRows = useMemo(
        () =>
            experimentMissions.map((experimentMission) => {
                const usedPipelineTemplate = pipelineTemplates.find(
                    (pipelineTemplate) =>
                        pipelineTemplate.uuid ===
                        experimentMission.pipelineTemplateUuid
                );
                const usedDataTypes = objectArrayStringify(
                    usedPipelineTemplate.AcquisitionVector.SensorBundles,
                    ["Sensor", "dataType"]
                ).split(", ");
                const requiredGsd = {};
                usedDataTypes.forEach((dataType) => {
                    requiredGsd[dataType] = getPipelineTemplateGsdOfStage(
                        usedPipelineTemplate,
                        experimentMission.bbchStageUuid,
                        dataType
                    );
                });
                return {
                    id: experimentMission.uuid,
                    pipelineTemplate: experimentMission.PipelineTemplate.name,
                    bbch: `${experimentMission.BBCHStage.stage}: ${experimentMission.BBCHStage.label}`,
                    experiment: experimentMission.Experiment.name,
                    status: experimentMission.status,
                    missionUuid: experimentMission.missionUuid,
                    flags: flagsSetter(
                        mission,
                        rawDatasetStatistics,
                        usedPipelineTemplate,
                        requiredGsd,
                        experimentMission.Experiment
                    ),
                    statistics: rawDatasetStatistics,
                };
            }),
        [experimentMissions, mission, pipelineTemplates, rawDatasetStatistics]
    );

    const gridColDef = [
        {
            field: "actionButtons",
            headerName: "Actions",
            sortable: false,
            hideable: false,
            filterable: false,
            flex: 2,
            minWidth: 90,
            maxWidth: 90,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickReport = () => {
                    navigate(
                        `/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.ACQUISITION_REPORT}/${params.row.missionUuid}?experimentMissionUuid=${params.row.id}&mode=${MODES.DETAILED}`
                    );
                };
                const onClickDelete = async () => {
                    const deletePromise = destroy(
                        `${BACKEND_ROUTES.EXPERIMENT_MISSION}/${params.row.id}`
                    );
                    const deleteResult = await mutateMission(deletePromise, {
                        optimisticData: ({
                            ExperimentMissions,
                            ...mission
                        }) => ({
                            ...mission,
                            ExperimentMissions: ExperimentMissions.filter(
                                (EM) => EM.uuid !== params.row.id
                            ),
                        }),
                        populateCache: false,
                    });
                    if (deleteResult) {
                        openSnackbar(
                            `Association deleted successfully.`,
                            "success"
                        );
                    }
                };

                return (
                    <>
                        {params.row.statistics ? (
                            <Tooltip title="Acquisition report">
                                <span>
                                    <IconButton
                                        onClick={onClickReport}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor:
                                                    "primary.light",
                                            },
                                        }}
                                    >
                                        <QueryStatsIcon
                                            fontSize="small"
                                            color="primary"
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip title="No datasets found">
                                <span>
                                    <IconButton
                                        disabled
                                        onClick={onClickReport}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor:
                                                    "primary.light",
                                            },
                                        }}
                                    >
                                        <QueryStatsIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}

                        <Tooltip
                            title={
                                associationCompleted
                                    ? "Cannot delete"
                                    : "Delete"
                            }
                        >
                            <span>
                                <IconButton
                                    disabled={associationCompleted}
                                    onClick={onClickDelete}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "primary.lighter",
                                        },
                                    }}
                                >
                                    <DeleteIcon
                                        fontSize="small"
                                        color={
                                            associationCompleted
                                                ? ""
                                                : "primary"
                                        }
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                );
            },
        },
        {
            field: "experiment",
            headerName: "Experiment",
            minWidth: 220,
            flex: 2,
        },
        {
            field: "pipelineTemplate",
            headerName: "Assigned Pipeline Template",
            minWidth: 210,
            flex: 2,
        },
        {
            field: "bbch",
            headerName: "BBCH Stage",
            minWidth: 200,
            flex: 2,
        },
        {
            field: "flags",
            headerName: "Flags",
            minWidth: 220,
            flex: 2,
            renderCell: (param) =>
                param.value.length ? (
                    param.value.map((flag) => (
                        <FlagTooltipIcon
                            key={flag.value}
                            Icon={flag.icon}
                            message={flag.message}
                        />
                    ))
                ) : (
                    <Typography>-</Typography>
                ),
        },
    ];

    return (
        <Card>
            <Box
                sx={{
                    height: getDataGridHeight(400, experimentMissions.length),
                    width: "100%",
                }}
            >
                <DataGrid
                    rows={gridRows}
                    columns={gridColDef}
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: "pipelineTemplate", sort: "asc" },
                            ],
                        },
                    }}
                    pageSizeOptions={[100]}
                    localeText={{
                        noRowsLabel: "No association",
                    }}
                />
            </Box>
        </Card>
    );
}
