import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DoneIcon from "@mui/icons-material/Done";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { MODEL_PROPTYPES } from "../../../models";
import { REPORT_STATUS } from "../../../constants";
import { Tooltip } from "@mui/material";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";

export const ReportStatus = ({ status }) => {
    let Icon;
    let title;
    let color;

    switch (status) {
        case REPORT_STATUS.VALIDATED:
            Icon = DoneIcon;
            title = "Validated";
            color = "success";
            break;
        case REPORT_STATUS.REJECTED:
            Icon = CancelOutlinedIcon;
            title = "Rejected";
            color = "error";

            break;
        case REPORT_STATUS.WARNING:
            Icon = WarningOutlinedIcon;
            title = "Warning";
            color = "warning";

            break;
        case REPORT_STATUS.UNKNOWN:
        default:
            Icon = HelpOutlineOutlinedIcon;
            title = "Unknown";
            color = "grey";
            break;
    }

    return (
        <Tooltip title={title} placement="right">
            <Icon color={color} />
        </Tooltip>
    );
};

ReportStatus.propTypes = {
    status: MODEL_PROPTYPES.Mission.reportStatus,
};
