import { $createImageNode, ImageNode } from "./ImageNode";
import {
    $createParagraphNode,
    $insertNodes,
    $isRootOrShadowRoot,
    COMMAND_PRIORITY_EDITOR,
} from "lexical";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";

import { INSERT_IMAGE_COMMAND } from "./constant";
import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export function ImagePlugin() {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNodes([ImageNode])) {
            throw new Error("ImagesPlugin: ImageNode not registered on editor");
        }
        return mergeRegister(
            editor.registerCommand(
                INSERT_IMAGE_COMMAND,
                (payload) => {
                    const imageNode = $createImageNode(payload);
                    $insertNodes([imageNode]);
                    if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                        $wrapNodeInElement(
                            imageNode,
                            $createParagraphNode
                        ).selectEnd();
                    }
                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            )
        );
    }, [editor]);
    return null;
}
