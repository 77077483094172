import {
    Box,
    Chip,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { FRONTEND_ROUTES } from "../../../frontendRoutes";
import { Link } from "react-router-dom";
import { MODEL_PROPTYPES } from "../../../models";
import { PIPELINE_INSTANCE_STATUS } from "../../../constants";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/formatTime";

export const PipelineInstance = ({
    isValidated,
    pipelineInstance: {
        createdAt,
        PipelineTraitGroup: { comment, uuid: pipelineTraitGroupUuid },
        status,
    },
    pipeline,
}) => (
    <ListItemButton
        component={Link}
        to={`/${FRONTEND_ROUTES.VALIDATION}/${pipelineTraitGroupUuid}`}
    >
        <ListItemIcon>
            {status === PIPELINE_INSTANCE_STATUS.ERROR ? (
                <ErrorIcon color="error" />
            ) : (
                <CheckCircleIcon color="success" />
            )}
        </ListItemIcon>
        <ListItemText
            disableTypography
            secondary={
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography variant="subtitle1" color="text.primary">
                        {pipeline.mode}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        {` - v${pipeline.version}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 3 }}
                    >
                        {formatDateTime(createdAt)}
                    </Typography>
                    {isValidated && (
                        <Chip
                            sx={{ ml: 3 }}
                            size="small"
                            label="Validated"
                            variant="filled"
                            color="success"
                        />
                    )}
                </Box>
            }
        >
            <Tooltip title={comment}>
                <Typography noWrap sx={{ mr: 4 }}>
                    {comment}
                </Typography>
            </Tooltip>
        </ListItemText>
        <ListItemSecondaryAction sx={{ alignItems: "center", display: "flex" }}>
            <ArrowForwardIcon />
        </ListItemSecondaryAction>
    </ListItemButton>
);

PipelineInstance.propTypes = {
    isValidated: PropTypes.bool.isRequired,
    pipeline: PropTypes.shape(MODEL_PROPTYPES.Pipeline).isRequired,
    pipelineInstance: PropTypes.shape(MODEL_PROPTYPES.PipelineInstance)
        .isRequired,
};
