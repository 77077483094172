import { MenuItem, Stack, TextField } from "@mui/material";

import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

PipelineBBCHSelect.propTypes = {
    associationLocked: PropTypes.bool.isRequired,
    selectablePipelineTemplates: PropTypes.array.isRequired,
    pipelineTemplateUuid: PropTypes.string.isRequired,
    selectableBbchStages: PropTypes.array.isRequired,
    bbchStageUuid: PropTypes.string.isRequired,
};

export function PipelineBBCHSelect({
    associationLocked,
    pipelineTemplateUuid,
    selectablePipelineTemplates,
    bbchStageUuid,
    selectableBbchStages,
}) {
    const [, setSearchParams] = useSearchParams();

    const handlePipelineTemplateChange = (newUuid) => {
        setSearchParams((prevSearchParams) => {
            const params = new URLSearchParams(prevSearchParams);

            if (newUuid) {
                params.set("pipelineTemplateUuid", newUuid);
            } else {
                params.delete("pipelineTemplateUuid");
            }

            return params;
        });
    };

    const handleBbchStageChange = (newUuid) => {
        setSearchParams((prevSearchParams) => {
            const params = new URLSearchParams(prevSearchParams);

            if (newUuid) {
                params.set("bbchStageUuid", newUuid);
            } else {
                params.delete("bbchStageUuid");
            }

            return params;
        });
    };

    return (
        <Stack direction="row" spacing={1}>
            <TextField
                value={pipelineTemplateUuid}
                onChange={(event) => {
                    const newPipelineTemplateUuid = event.target.value;
                    const newPipelineTemplate =
                        selectablePipelineTemplates.find(
                            (pipelineTemplate) =>
                                pipelineTemplate.uuid ===
                                newPipelineTemplateUuid
                        );

                    if (newPipelineTemplate) {
                        const newSelectableBbchStageUuidSet = new Set(
                            newPipelineTemplate.PipelineTemplateTraitGroups.map(
                                (PTTG) => PTTG.bbchStageUuid
                            )
                        );

                        if (
                            bbchStageUuid &&
                            !newSelectableBbchStageUuidSet.has(bbchStageUuid)
                        ) {
                            handleBbchStageChange("");
                        }
                    }

                    handlePipelineTemplateChange(newPipelineTemplateUuid);
                }}
                fullWidth
                id="pipelineTemplateUuid"
                select
                label="Pipeline template"
                disabled={associationLocked}
            >
                {selectablePipelineTemplates.map((pipelineTemplate) => (
                    <MenuItem
                        key={pipelineTemplate.uuid}
                        value={pipelineTemplate.uuid}
                    >
                        {pipelineTemplate.name}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                value={bbchStageUuid}
                onChange={(event) => {
                    handleBbchStageChange(event.target.value);
                }}
                fullWidth
                id="bbchStageUuid"
                select
                label="BBCH stage"
                disabled={associationLocked}
            >
                {selectableBbchStages.map((bbchStage) => (
                    <MenuItem key={bbchStage.uuid} value={bbchStage.uuid}>
                        {`${bbchStage.stage} (${bbchStage.label})`}
                    </MenuItem>
                ))}
            </TextField>
        </Stack>
    );
}
