import { useCallback, useRef } from "react";

import { APPBAR_HEIGHT } from "../layouts/orders/constants";
// import HeightIcon from "@mui/icons-material/Height";
import MuiDrawer from "@mui/material/Drawer";
import Scrollbar from "./Scrollbar";
import { styled } from "@mui/material/styles";

// https://codesandbox.io/s/resizable-drawer-34ife?fontsize=14&hidenavigation=1&theme=dark&file=/src/CustomDrawer.js for reference

const DrawerHeader = styled("div")(({ theme }) => ({
    width: "14px",
    height: "100%",
    cursor: "ew-resize",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    "&:hover": {
        borderLeft: `solid 2px ${theme.palette.secondary.main}`,
    },
    "&.moving": {
        borderLeft: `solid 2px ${theme.palette.secondary.main}`,
    },
}));

const Drawer = styled(MuiDrawer)(() => ({
    whiteSpace: "nowrap",
    boxSizing: "border-box",
}));

export default function CustomDrawer({
    children,
    drawerWidth,
    setDrawerWidth,
    drawerWidthOptions,
}) {
    const drawerHeaderRef = useRef(null);

    const handleMouseDown = () => {
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("mousemove", handleMouseMove);
        drawerHeaderRef.current?.classList.add("moving");
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
        drawerHeaderRef.current?.classList.remove("moving");
    };

    const handleMouseMove = useCallback(
        (evt) => {
            evt.preventDefault(); // This prevents text selection when dragging

            const newWidth =
                evt.view.innerWidth -
                evt.clientX -
                7 -
                document.body.offsetLeft;
            if (
                newWidth > drawerWidthOptions.MIN &&
                newWidth < drawerWidthOptions.MAX
            ) {
                setDrawerWidth(newWidth);
            }
        },
        [drawerWidthOptions.MAX, drawerWidthOptions.MIN, setDrawerWidth]
    );

    return (
        <Drawer
            sx={{
                zIndex: (theme) => theme.zIndex.appBar - 1,
            }}
            PaperProps={{
                sx: {
                    width: drawerWidth,
                    mt: `${APPBAR_HEIGHT}px`,
                },
            }}
            variant="permanent"
            anchor="right"
        >
            <DrawerHeader onMouseDown={handleMouseDown} ref={drawerHeaderRef}>
                {/* TODO: <HeightIcon sx={{ transform: "rotate(90deg)" }} /> */}
            </DrawerHeader>
            <Scrollbar>{children}</Scrollbar>
        </Drawer>
    );
}
