import { Divider, Grid, Typography } from "@mui/material";

import { LabelStyle } from "../../components/LabelStyle";
import { MODEL_PROPTYPES } from "../../models";
import PropTypes from "prop-types";

SensorInfoGrid.propTypes = {
    acquisitionVector: PropTypes.shape(MODEL_PROPTYPES.AcquisitionVector)
        .isRequired,
    focalLength35mmEqv: PropTypes.number,
};

export function SensorInfoGrid({ acquisitionVector, focalLength35mmEqv }) {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
                <LabelStyle>Acquisition vector</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {acquisitionVector.name}
            </Grid>

            <Grid item xs={4}>
                <LabelStyle>System</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {acquisitionVector.SystemModel.system}
            </Grid>

            <Grid item xs={4}>
                <LabelStyle>System model</LabelStyle>
            </Grid>
            <Grid item xs={8}>
                {acquisitionVector.SystemModel.name}
            </Grid>

            {acquisitionVector.SensorBundles.length === 1 ? (
                <Grid container item spacing={1} alignItems="center">
                    <Grid item xs={4}>
                        <LabelStyle>Sensor</LabelStyle>
                    </Grid>
                    <Grid item xs={8}>
                        {acquisitionVector.SensorBundles[0].Sensor.name}
                    </Grid>

                    <Grid item xs={4}>
                        <LabelStyle>Data type</LabelStyle>
                    </Grid>
                    <Grid item xs={8}>
                        {acquisitionVector.SensorBundles[0].Sensor.dataType}
                    </Grid>

                    <Grid item xs={4}>
                        <LabelStyle>Focal length 35mm eqv</LabelStyle>
                    </Grid>
                    <Grid item xs={8}>
                        {acquisitionVector.SensorBundles[0].Sensor
                            .focalLength35mmEqv ?? focalLength35mmEqv}{" "}
                        mm
                    </Grid>
                </Grid>
            ) : (
                acquisitionVector.SensorBundles.map((bundle) => (
                    <Grid
                        container
                        item
                        spacing={1}
                        alignItems="center"
                        key={bundle.uuid}
                    >
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color={"black"} fontWeight={"bold"}>
                                {bundle.Sensor.name}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <LabelStyle>Data type</LabelStyle>
                        </Grid>
                        <Grid item xs={8}>
                            {bundle.Sensor.dataType}
                        </Grid>

                        <Grid item xs={4}>
                            <LabelStyle>Focal length 35mm eqv</LabelStyle>
                        </Grid>
                        <Grid item xs={8}>
                            {bundle.Sensor.focalLength35mmEqv ??
                                focalLength35mmEqv}{" "}
                            mm
                        </Grid>
                    </Grid>
                ))
            )}
        </Grid>
    );
}
