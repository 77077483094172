import { Divider, Stack, Tooltip, Typography } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { MODEL_PROPTYPES } from "../models";
import PropTypes from "prop-types";

BBCHStageTooltip.propTypes = {
    bbchStages: PropTypes.arrayOf(PropTypes.shape(MODEL_PROPTYPES.BBCHStage))
        .isRequired,
};
// bbchStages should preferably be sorted by ascending bbchStages.order
export function BBCHStageTooltip({ bbchStages }) {
    return (
        <Tooltip
            slotProps={{
                tooltip: {
                    sx: {
                        maxWidth: "none",
                    },
                },
            }}
            title={
                <Stack direction="column">
                    <Typography>BBCH phenological scale:</Typography>
                    <Divider />
                    {bbchStages.map((bbchStage) => (
                        <Typography key={bbchStage.order}>
                            {bbchStage.stage}: {bbchStage.label}
                        </Typography>
                    ))}
                </Stack>
            }
        >
            <InfoIcon fontSize="small" sx={{ color: "grey.500" }} />
        </Tooltip>
    );
}
