import { Container } from "@mui/material";
import Page from "../components/Page";
import { SensorTable } from "../features/sensor";

export default function SensorGlossary() {
    return (
        <Page title="Sensor glossary | HCC">
            <Container maxWidth="xl">
                <SensorTable />
            </Container>
        </Page>
    );
}
