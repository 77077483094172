import { AutoLinkNode, LinkNode } from "@lexical/link";
import { Box, styled } from "@mui/material";
import { GlobalEditorStyles, editorTheme } from "./theme";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ImageNode, ImagePlugin } from "./image";
import { ListItemNode, ListNode } from "@lexical/list";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { CodeNode } from "@lexical/code";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { CustomOnChangePlugin } from "./CustomOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import PropTypes from "prop-types";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { ToolbarPlugin } from "./toolbar";
import { useMemo } from "react";

const inputGlobalStyles = <GlobalEditorStyles />;

export const RichTextEditor = ({ onChange, defaultEditorState }) => {
    const initialConfig = useMemo(
        () => ({
            namespace: "RichTextEditor",
            theme: editorTheme,
            onError: (error) => console.error(error),
            editorState: defaultEditorState
                ? JSON.stringify(defaultEditorState)
                : null,
            nodes: [
                HeadingNode,
                ListNode,
                ListItemNode,
                QuoteNode,
                CodeNode,
                AutoLinkNode,
                LinkNode,
                ImageNode,
            ],
        }),
        [defaultEditorState]
    );

    return (
        <LexicalComposer initialConfig={initialConfig}>
            {inputGlobalStyles}
            <Box
                sx={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "divider",
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <ToolbarPlugin />
                <Box position="relative" backgroundColor="background.paper">
                    <RichTextPlugin
                        contentEditable={<MuiContentEditable />}
                        placeholder={
                            <Box
                                sx={(theme) => ({
                                    position: "absolute",
                                    top: theme.spacing(2),
                                    left: theme.spacing(1.5),
                                    userSelect: "none",
                                    pointerEvents: "none",
                                    color: theme.palette.text.disabled,
                                })}
                            >
                                Comment here...
                            </Box>
                        }
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <CustomOnChangePlugin onChange={onChange} />
                    <HistoryPlugin />
                    <ImagePlugin />

                    <ListPlugin />
                    <LinkPlugin />
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                    <AutoFocusPlugin />
                </Box>
            </Box>
        </LexicalComposer>
    );
};

const MuiContentEditable = styled(ContentEditable)(({ theme }) => ({
    minHeight: 200,
    padding: theme.spacing(2, 1.5),
    outline: 0,
}));

RichTextEditor.propTypes = {
    onChange: PropTypes.func,
    defaultEditorState: PropTypes.object,
};
