import { Dialog, Fab } from "@mui/material";

import { AcquisitionReportForm } from "./AcquisitionReportForm";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import PropTypes from "prop-types";
import { useState } from "react";

export function AcquisitionReportFormButton({ fabStyle }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Fab
                size="medium"
                color="primary"
                sx={fabStyle}
                onClick={handleClickOpen}
            >
                <FactCheckOutlinedIcon />
            </Fab>
            <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
                <AcquisitionReportForm handleClose={handleClose} />
            </Dialog>
        </>
    );
}
AcquisitionReportFormButton.propTypes = {
    fabStyle: PropTypes.object.isRequired,
};
