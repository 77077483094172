import { BACKEND_ROUTES } from "../../backendRoutes";
import useSWR from "swr";

export function useMission(missionUuid) {
    const response = useSWR(
        missionUuid
            ? `${BACKEND_ROUTES.MISSION}/${missionUuid}?parentInfo=true&acquisitionReport=true`
            : null
    );

    return response;
}
