import { Box, Card } from "@mui/material";
import { darken, lighten, styled } from "@mui/material/styles";

import { DataGrid } from "@mui/x-data-grid";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import { getDataGridHeight } from "../../utils/getDataGridHeight";

AssociationExperimentTable.propTypes = {
    experiments: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.shape(MODEL_PROPTYPES.Experiment))
            .isRequired,
        count: PropTypes.number.isRequired,
    }),
    selectedExperiments: PropTypes.arrayOf(PropTypes.string),
    setInboundStates: PropTypes.func.isRequired,
    experimentMissions: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.ExperimentMission)
    ).isRequired,
    pipelineTemplate: PropTypes.shape(MODEL_PROPTYPES.PipelineTemplate),
};

export function AssociationExperimentTable({
    experiments,
    selectedExperiments,
    setInboundStates,
    experimentMissions,
    pipelineTemplate,
}) {
    const experimentGridRows = experiments
        ? experiments?.rows
              .filter(
                  (experiment) =>
                      !experimentMissions ||
                      !experimentMissions.find(
                          (experimentMission) =>
                              experimentMission.experimentUuid ===
                              experiment.uuid
                      )
              )
              .map((experiment) => {
                  return {
                      id: experiment.uuid,
                      name: experiment.name,
                      crop: experiment.Crop.name,
                      cropUuid: experiment.cropUuid,
                  };
              })
        : [];

    const experimentGridColDef = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            flex: 3,
        },
        {
            field: "crop",
            headerName: "Crop",
            minWidth: 80,
            flex: 2,
        },
    ];

    return (
        <Card>
            <Box
                sx={{
                    height: getDataGridHeight(400, experimentGridRows.length),
                    width: "100%",
                }}
            >
                <StyledDataGrid
                    checkboxSelection
                    rows={experimentGridRows}
                    columns={experimentGridColDef}
                    rowSelectionModel={selectedExperiments}
                    onRowSelectionModelChange={(value) => {
                        setInboundStates((previousState) => ({
                            ...previousState,
                            selectedExperiments: value,
                        }));
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "crop", sort: "asc" }],
                        },
                    }}
                    getRowClassName={(params) =>
                        pipelineTemplate
                            ? params.row.cropUuid === pipelineTemplate?.cropUuid
                                ? "row-theme--matching"
                                : "row-theme--not-matching"
                            : ""
                    }
                    localeText={{
                        noRowsLabel: experiments.count
                            ? "All experiments have been associated for this site"
                            : "No experiments available for this site",
                    }}
                />
            </Box>
        </Card>
    );
}

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .row-theme--matching": {
        backgroundColor: getBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode
        ),
        "&:hover": {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
            ),
        },
        "&.Mui-selected": {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
            ),
            "&:hover": {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode
                ),
            },
        },
    },
    "& .row-theme--not-matching": {
        backgroundColor: getBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode
        ),
        "&:hover": {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
            ),
        },
        "&.Mui-selected": {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
            ),
            "&:hover": {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode
                ),
            },
        },
    },
}));
