import { Alert, Snackbar } from "@mui/material";
import { useCallback, useState } from "react";

import PropTypes from "prop-types";
import { SnackbarContext } from "./SnackbarContext";

export const SnackbarProvider = ({ children }) => {
    const [{ open, message, severity }, setOpen] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    const handleClose = () =>
        setOpen({ open: false, message: "", severity: "info" });
    const openSnackbar = useCallback(
        /**
         * Open the snackbar with the given message and severity color.
         * @param {string} message - Message to display in the snackbar.
         * @param {string} [severity=info] - Severity of the alert in the snackbar which determines the color.
         * Should be one of "error", "warning", "info" or "success".
         * @returns {undefined}
         */
        (message, severity = "info") =>
            setOpen({ open: true, message, severity }),
        []
    );

    return (
        <SnackbarContext.Provider value={{ openSnackbar }}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                key={message}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    variant="filled"
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
