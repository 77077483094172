import { Card, CardContent, CardHeader, Typography } from "@mui/material";

import { Droppable } from "react-beautiful-dnd";
import { RoutingKanbanDraggable } from ".";
import { Stack } from "@mui/system";

export function RoutingKanbanDroppable({
    dataPair,
    users,
    noUserUuidValue,
    isDragDisabled,
    setSelectedMissionUuid,
    setIsOpenMissionDetails,
    grid,
}) {
    return (
        <Droppable droppableId={dataPair[0]}>
            {(provided, snapshot) => {
                let foundUser = users.find((user) => user.uuid === dataPair[0]);
                if (!foundUser)
                    foundUser = {
                        uuid: noUserUuidValue,
                        firstName: "Unassigned",
                        lastName: "",
                    };
                return (
                    <Card
                        sx={{
                            width: 250,
                            padding: `${grid}px`, // "padding: grid" in style was automatically translated into "padding: ${grid}px", but not in sx
                            bgcolor: (theme) =>
                                snapshot.isDraggingOver
                                    ? theme.palette.info.lighter
                                    : theme.palette.grey[210],
                        }}
                    >
                        <CardHeader
                            title={
                                <Stack direction={"column"}>
                                    <Typography variant="h5">
                                        {foundUser.firstName}{" "}
                                        {foundUser.lastName}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {dataPair[1]?.length ?? 0} mission
                                        {dataPair[1]?.length > 1 && "s"}
                                    </Typography>
                                </Stack>
                            }
                            sx={{
                                padding: (theme) => theme.spacing(1),
                            }}
                        />
                        <CardContent
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{
                                height: "100%",
                                padding: (theme) => theme.spacing(1),
                            }}
                        >
                            {dataPair[1]?.map((mission, index) => (
                                <RoutingKanbanDraggable
                                    mission={mission}
                                    index={index}
                                    isDragDisabled={isDragDisabled}
                                    grid={grid}
                                    setSelectedMissionUuid={
                                        setSelectedMissionUuid
                                    }
                                    setIsOpenMissionDetails={
                                        setIsOpenMissionDetails
                                    }
                                    key={mission.uuid}
                                />
                            ))}
                            {provided.placeholder}
                        </CardContent>
                    </Card>
                );
            }}
        </Droppable>
    );
}
