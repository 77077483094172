import {
    Button,
    DialogActions,
    Divider,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { INPUT_VALIDATION } from "../../constants";
import PropTypes from "prop-types";
import { parcellaireCreationSubmitNoImport } from "./parcellaireCreationSubmitNoImport";
import { useForm } from "react-hook-form";

ParcellaireCreationFormNoImport.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    parcellaireDispatch: PropTypes.func.isRequired,
    mapRef: PropTypes.any.isRequired,
    setGeoJsonKey: PropTypes.func.isRequired,
    setBlueRectangleData: PropTypes.func.isRequired,
};

export function ParcellaireCreationFormNoImport({
    setIsOpen,
    parcellaireDispatch,
    mapRef,
    setGeoJsonKey,
    setBlueRectangleData,
}) {
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            xCount: "",
            yCount: "",
            latitude: "",
            longitude: "",
            xSize: "",
            ySize: "",
            xSpacing: "",
            ySpacing: "",
            angle: 0,
        },
    });

    const [xCountWatch, yCountWatch] = watch(["xCount", "yCount"]);

    const onSubmit = (payload) => {
        parcellaireCreationSubmitNoImport(
            payload,
            setIsOpen,
            parcellaireDispatch,
            mapRef,
            setGeoJsonKey
        );
        setBlueRectangleData(null);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography>Columns & Rows</Typography>
                    <Divider />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="X (rows)"
                            id="xCount"
                            error={Boolean(errors.xCount)}
                            helperText={errors.xCount?.message}
                            {...register("xCount", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value > 0 || INPUT_VALIDATION.POSITIVE,
                            })}
                            inputProps={{
                                step: "1",
                                inputMode: "decimal",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="Y (columns)"
                            id="yCount"
                            error={Boolean(errors.yCount)}
                            helperText={errors.yCount?.message}
                            {...register("yCount", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value > 0 || INPUT_VALIDATION.POSITIVE,
                            })}
                            inputProps={{
                                step: "1",
                                inputMode: "decimal",
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Total:{" "}
                        {(xCountWatch ? xCountWatch : 1) *
                            (yCountWatch ? yCountWatch : 1)}{" "}
                        plots
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Initial coordinates</Typography>
                    <Divider />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="Latitude"
                            id="latitude"
                            error={Boolean(errors.latitude)}
                            helperText={errors.latitude?.message}
                            {...register("latitude", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    (value >= -90 && value <= 90) ||
                                    INPUT_VALIDATION.LATITUDE_INVALID,
                            })}
                            inputProps={{
                                step: "0.00000000000001",
                                inputMode: "decimal",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="Longitude"
                            id="longitude"
                            error={Boolean(errors.longitude)}
                            helperText={errors.longitude?.message}
                            {...register("longitude", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    (value >= -180 && value <= 180) ||
                                    INPUT_VALIDATION.LONGITUDE_INVALID,
                            })}
                            inputProps={{
                                step: "0.00000000000001",
                                inputMode: "decimal",
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Plot geometry</Typography>
                    <Divider />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="X size"
                            id="xSize"
                            error={Boolean(errors.xSize)}
                            helperText={errors.xSize?.message}
                            {...register("xSize", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value > 0 || INPUT_VALIDATION.POSITIVE,
                            })}
                            inputProps={{
                                step: "0.01",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="Y size"
                            id="ySize"
                            error={Boolean(errors.ySize)}
                            helperText={errors.ySize?.message}
                            {...register("ySize", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value > 0 || INPUT_VALIDATION.POSITIVE,
                            })}
                            inputProps={{
                                step: "0.01",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="X spacing"
                            id="xSpacing"
                            error={Boolean(errors.xSpacing)}
                            helperText={errors.xSpacing?.message}
                            {...register("xSpacing", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value >= 0 || INPUT_VALIDATION.NON_NEGATIVE,
                            })}
                            inputProps={{
                                step: "0.01",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="Y spacing"
                            id="ySpacing"
                            error={Boolean(errors.ySpacing)}
                            helperText={errors.ySpacing?.message}
                            {...register("ySpacing", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value >= 0 || INPUT_VALIDATION.NON_NEGATIVE,
                            })}
                            inputProps={{
                                step: "0.01",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="Rotation angle"
                            id="angle"
                            error={Boolean(errors.angle)}
                            helperText={errors.angle?.message}
                            {...register("angle", {
                                valueAsNumber: true,
                                validate: (value) =>
                                    (value >= 0 && value <= 360) ||
                                    INPUT_VALIDATION.DEGREE_INVALID,
                            })}
                            inputProps={{
                                step: "0.0000000001",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        °
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions>
                <Button type="button" onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    type="submit"
                >
                    Create
                </Button>
            </DialogActions>
        </form>
    );
}
