import {
    Card,
    Dialog,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    Typography,
} from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { ModalTransition } from "../../components/ModalTransition";
import { PAGINATION } from "../../constants";
import Scrollbar from "../../components/Scrollbar";
import { TraitDetails } from ".";
import { TraitTableSkeleton } from "./TraitTableSkeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useSWR from "swr";
import { useState } from "react";

const pageItemCount = 100;

export function TraitTable() {
    const [displayLegacyTraits, setDisplayLegacyTraits] = useState(false);
    const [isOpenTraitDetails, setIsOpenTraitDetails] = useState(false);
    const [selectedTraitUuid, setSelectedTraitUuid] = useState("");

    const searchParams = new URLSearchParams({
        bbchStages: true,
        limit: PAGINATION.TRAIT.LIMIT.MAX,
        ...(displayLegacyTraits ? { traitGroup: true } : { deprecated: false }),
    });

    const { data: traits, error: traitsFetchError } = useSWR(
        `${BACKEND_ROUTES.TRAIT}?${searchParams}`
    );

    if (traitsFetchError) return <FetchErrorAlert error={traitsFetchError} />;

    if (!traits) {
        return <TraitTableSkeleton />;
    }

    const gridRows = traits.rows.map((trait) => {
        return {
            id: trait.uuid,
            uuid: trait.uuid,
            name: trait.name,
            traitGroup: trait.TraitGroup.name,
            category: trait.TraitGroup.category,
            type: trait.type,
            unit: trait.unit,
            technicalName: trait.technicalName,
        };
    });

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "editButton",
            headerName: "",
            sortable: false,
            hideable: false,
            filterable: false,
            minWidth: 65,
            flex: 0.5,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    setSelectedTraitUuid(params.row.id);
                    setIsOpenTraitDetails(true);
                };

                return (
                    <IconButton
                        onClick={onClick}
                        sx={{
                            "&:hover": {
                                backgroundColor: "primary.lighter",
                            },
                        }}
                    >
                        <VisibilityIcon fontSize="small" color="primary" />
                    </IconButton>
                );
            },
        },
        {
            field: "traitGroup",
            headerName: "Trait Group",
            minWidth: 280,
            flex: 3,
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 280,
            flex: 2,
        },
        {
            field: "category",
            headerName: "Category",
            minWidth: 180,
            flex: 2,
        },
        {
            field: "type",
            headerName: "Type",
            minWidth: 160,
            flex: 2,
        },
        {
            field: "unit",
            headerName: "Unit",
            minWidth: 100,
            flex: 2,
        },
        {
            field: "technicalName",
            headerName: "Technical Name",
            minWidth: 280,
            flex: 2,
        },
    ];

    const closeTraitDetailsModal = () => {
        setIsOpenTraitDetails(false);
    };

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h4" gutterBottom>
                    Traits
                </Typography>
                <FormControlLabel
                    id="displayLegacy"
                    name="displayLegacy"
                    control={<Switch />}
                    label="Show legacy traits"
                    labelPlacement="start"
                    checked={displayLegacyTraits}
                    onChange={(event) => {
                        setDisplayLegacyTraits(event.target.checked);
                    }}
                />
            </Stack>

            <Card>
                <Scrollbar>
                    <DataGrid
                        autoHeight
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    technicalName: false,
                                },
                            },
                            sorting: {
                                sortModel: [
                                    { field: "traitGroup", sort: "asc" },
                                ],
                            },
                        }}
                        rowCount={traits.count}
                    />
                </Scrollbar>
            </Card>
            <Dialog
                open={isOpenTraitDetails}
                fullWidth
                maxWidth="xl"
                TransitionComponent={ModalTransition}
            >
                <TraitDetails
                    uuid={selectedTraitUuid}
                    closeModal={closeTraitDetailsModal}
                />
            </Dialog>
        </>
    );
}
