import {
    Breadcrumbs,
    Button,
    Card,
    CircularProgress,
    Container,
    Stack,
    Typography,
} from "@mui/material";
import {
    PAGINATION,
    PROCESSING_MODULES,
    PROCESS_STATUS,
    VALIDATION_STATUS,
} from "../../constants";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { BreadcrumbChip } from "../../components/BreadcrumbChip";
import { DataGrid } from "@mui/x-data-grid";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import HomeIcon from "@mui/icons-material/Home";
import Label from "../../components/Label";
import Page from "../../components/Page";
import { ProcessTableSkeleton } from "./ProcessTableSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Scrollbar from "../../components/Scrollbar";
import { ValidationPageLink } from "./ValidationPageLink";
import { formatDateTime } from "../../utils/formatTime";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import { useSearchParams } from "react-router-dom";

// Supplementary front-end validation status
const SUPP_VALIDATION_STATUS = Object.freeze({
    TO_VALIDATE: "To validate",
    NA: "N/A",
});

// DataGrid column definitions
const gridColDef = [
    { field: "name", headerName: "Process", minWidth: 300, flex: 3 },
    { field: "start", headerName: "Start date", minWidth: 180, flex: 2 },
    { field: "end", headerName: "End date", minWidth: 180, flex: 2 },
    {
        field: "status",
        headerName: "Status",
        minWidth: 100,
        flex: 1.5,
        renderCell: ({ value }) => (
            <Label
                color={
                    value === PROCESS_STATUS.EXECUTING
                        ? "info"
                        : value === PROCESS_STATUS.FINISHED
                          ? "success"
                          : "error"
                }
            >
                {value}
            </Label>
        ),
    },
    {
        field: "validationStatus",
        headerName: "Validation",
        minWidth: 100,
        flex: 1.5,
        renderCell: ({ value }) => (
            <Label
                color={
                    value === SUPP_VALIDATION_STATUS.NA
                        ? "info"
                        : value === SUPP_VALIDATION_STATUS.TO_VALIDATE
                          ? "warning"
                          : value === VALIDATION_STATUS.VALIDATED
                            ? "success"
                            : "error"
                }
            >
                {value}
            </Label>
        ),
    },
    {
        field: "actions",
        headerName: "Actions",
        minWidth: 100,
        flex: 1.5,
        filterable: false,
        sortable: false,
        renderCell: ({ value }) => <ValidationPageLink process={value} />,
    },
];

// Definitions of columns for parent info.
// Used when table not filtered by missionModelUuid in query parameter.
const gridParentInfoColDef = [
    { field: "company", headerName: "Company", minWidth: 200, flex: 3 },
    { field: "contract", headerName: "Campaign", minWidth: 200, flex: 3 },
    { field: "site", headerName: "Site", minWidth: 200, flex: 3 },
    { field: "mission", headerName: "Mission", minWidth: 120, flex: 3 },
];

export function ProcessTable() {
    const [searchParams] = useSearchParams();

    const missionModelUuid = searchParams.get("missionModelUuid");

    // Fetch missionModel if missionModelUuid is given in query param.
    const { data: missionModel, error: missionModelFetchError } = useSWR(
        missionModelUuid
            ? `${BACKEND_ROUTES.MISSION_MODEL}/${missionModelUuid}?parentInfo=true`
            : null
    );

    // Process fetch url constructor with pagination
    const getKey = (pageIndex, previousPageData) => {
        // Reached the end
        if (
            previousPageData &&
            previousPageData.count < pageIndex * PAGINATION.PROCESS.LIMIT.MAX
        )
            return null;

        let fetchUrl = `${BACKEND_ROUTES.PROCESS}?moduleInfo=true&reviews=true&parentInfo=true&limit=${PAGINATION.PROCESS.LIMIT.MAX}`;

        if (missionModelUuid)
            fetchUrl += `&missionModelUuid=${missionModel.uuid}`;

        // First page, we don't have `previousPageData`
        if (pageIndex === 0) return fetchUrl;

        // Add the offset to the API endpoint
        return `${fetchUrl}&offset=${pageIndex * PAGINATION.PROCESS.LIMIT.MAX}`;
    };

    // Fetch processes with pagination
    const {
        data: processesDataLoadedPages,
        error: processesFetchError,
        size,
        setSize,
        isValidating,
    } = useSWRInfinite(getKey);

    const handleLoadMore = () => setSize(size + 1);

    if (missionModelFetchError || processesFetchError)
        return (
            <FetchErrorAlert
                error={missionModelFetchError || processesFetchError}
            />
        );
    if (!processesDataLoadedPages) return <ProcessTableSkeleton />;

    // Total number of rows in the database
    const dbRowCount = processesDataLoadedPages[0].count;

    // Gather the loaded rows
    const processes = processesDataLoadedPages.flatMap(
        (onePageData) => onePageData.rows
    );
    const loadedRowCount = processes.length;

    const gridRows = processes.map((process) => {
        const moduleName = process.ModuleVersion.Module.name;
        const start = process.startDatetime
            ? formatDateTime(process.startDatetime)
            : null;
        const end = process.endDatetime
            ? formatDateTime(process.endDatetime)
            : null;

        let validationStatus;
        // If a process is finished and has a validation page,
        // then its validation status will be one of "to validate", "validated" or "rejected".
        if (
            process.status === PROCESS_STATUS.FINISHED &&
            PROCESSING_MODULES[moduleName]?.VALIDATION_PAGE
        ) {
            // If the process does not have any review or its last review has a null status,
            // the process is to be validated. Otherwise the process uses its last review's status.
            validationStatus = process.Reviews[0]?.status
                ? process.Reviews[0].status
                : SUPP_VALIDATION_STATUS.TO_VALIDATE;
        } else {
            // If process does not have the status finished or
            // if process's module does not have a validation page, validation is not applicable.
            validationStatus = SUPP_VALIDATION_STATUS.NA;
        }

        return {
            id: process.uuid,
            name: moduleName,
            start,
            end,
            status: process.status,
            validationStatus,
            actions: process,
            company: process.MissionModel.Mission.Site.Contract.Company.name,
            contract: process.MissionModel.Mission.Site.Contract.name,
            site: process.MissionModel.Mission.Site.name,
            mission: process.MissionModel.Mission.name.split(" ")[0],
        };
    });

    return (
        <Page title="Process Table | HCC">
            <Container maxWidth="xl">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                >
                    <Typography variant="h4" gutterBottom>
                        Processes
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Typography
                            variant="body2"
                            sx={{ alignSelf: "flex-end" }}
                        >
                            {`${loadedRowCount} of ${dbRowCount} loaded`}
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={
                                isValidating ? (
                                    <CircularProgress size="1em" />
                                ) : (
                                    <RefreshIcon />
                                )
                            }
                            onClick={handleLoadMore}
                            disabled={
                                isValidating || loadedRowCount === dbRowCount
                            }
                        >
                            Load more
                        </Button>
                    </Stack>
                </Stack>

                {
                    // Breadcrumbs that show (and serve as link) the company, contract, site and mission
                    // of the processes in the table when missionModelUuid is present in query param.
                }
                {missionModelUuid && (
                    <Breadcrumbs separator="/" mb={1}>
                        <BreadcrumbChip
                            label={<HomeIcon fontSize="small" />}
                            destinationUrl={`/companies.html`}
                        />

                        <BreadcrumbChip
                            label={
                                missionModel.Mission.Site.Contract.Company.name
                            }
                            destinationUrl={`/contracts.html?companyUuid=${missionModel.Mission.Site.Contract.Company.uuid}`}
                        />
                        <BreadcrumbChip
                            label={missionModel.Mission.Site.Contract.name}
                            destinationUrl={`/sites.html?contractUuid=${missionModel.Mission.Site.Contract.uuid}`}
                        />
                        <BreadcrumbChip
                            label={missionModel.Mission.Site.name}
                            destinationUrl={`/missions.html?siteUuid=${missionModel.Mission.Site.uuid}`}
                        />
                        <BreadcrumbChip
                            label={missionModel.Mission.name.split(" ")[0]}
                        />
                    </Breadcrumbs>
                )}

                <Card>
                    <Scrollbar>
                        <DataGrid
                            autoHeight
                            rows={gridRows}
                            columns={
                                missionModelUuid
                                    ? gridColDef
                                    : gridParentInfoColDef.concat(gridColDef)
                            }
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "start", sort: "desc" },
                                    ],
                                },
                            }}
                        />
                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
}
