import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { LabelStyle } from "../../components/LabelStyle";
import PropTypes from "prop-types";
import { TraitDetailsSkeleton } from "./TraitDetailsSkeleton";
import useSWR from "swr";

TraitDetails.propTypes = {
    uuid: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export function TraitDetails({ uuid, closeModal }) {
    const { data: trait, error: traitFetchError } = useSWR(
        uuid
            ? `${BACKEND_ROUTES.TRAIT}/${uuid}?bbchStages=true&traitGroup=true`
            : null
    );
    if (traitFetchError) return <FetchErrorAlert error={traitFetchError} />;
    if (!trait) return <TraitDetailsSkeleton closeModal={closeModal} />;

    return (
        <>
            <DialogTitle>{trait.name}</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography>
                            {trait.description ?? "No description."}
                        </Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <LabelStyle>Trait group</LabelStyle>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{trait.TraitGroup.name}</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <LabelStyle>Minimum BBCH stage</LabelStyle>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{trait.MinBBCHStage.stage}</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <LabelStyle>Maximum BBCH stage</LabelStyle>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{trait.MaxBBCHStage.stage}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {Boolean(trait.illustration) && (
                        <Grid item xs={12}>
                            <img
                                src={trait.illustration}
                                alt={`${trait.name} illustration`}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}
