import {
    Card,
    Dialog,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { initialModalState, modalReducer } from "./tableModalReducer";
import { useFetch, useSnackbar } from "../../hooks";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { Box } from "@mui/system";
import { ConfirmModal } from "../../components/ConfirmModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ExploreIcon from "@mui/icons-material/Explore";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { MissionProtocol } from "../mission-protocol";
import { ModalTransition } from "../../components/ModalTransition";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { PAGINATION } from "../../constants";
import { PipelineTemplateDialog } from "./form";
import { PipelineTemplateTableSkeleton } from "./PipelineTemplateTableSkeleton";
import { getDataGridHeight } from "../utils/getDataGridHeight";
import { objectArrayStringify } from "../utils/objectArrayStringify";
import { useReducer } from "react";
import useSWR from "swr";

export function PipelineTemplateTable({ contractUuid }) {
    const { destroy } = useFetch();
    const { openSnackbar } = useSnackbar();

    const [modalState, modalDispatch] = useReducer(
        modalReducer,
        initialModalState
    );

    const {
        data: pipelineTemplateData,
        error: pipelineTemplateFetchError,
        mutate,
    } = useSWR(
        `${BACKEND_ROUTES.PIPELINE_TEMPLATE}?parentInfo=true&calendars=true&contractUuid=${contractUuid}&limit=${PAGINATION.GENERIC.LIMIT.MAX}`
    );

    if (pipelineTemplateFetchError)
        return <FetchErrorAlert error={pipelineTemplateFetchError} />;
    if (!pipelineTemplateData) return <PipelineTemplateTableSkeleton />;

    const gridColDef = [
        {
            field: "actionButtons",
            headerName: "Actions",
            sortable: false,
            hideable: false,
            filterable: false,
            flex: 2,
            minWidth: 150,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClickEdit = () => {
                    modalDispatch({
                        type: "edit",
                        pipelineTemplateUuid: params.row.id,
                    });
                };

                const onClickDelete = () => {
                    modalDispatch({
                        type: "delete",
                        pipelineTemplateUuid: params.row.id,
                        pipelineTemplateName: params.row.name,
                    });
                };

                const onClickSop = () => {
                    modalDispatch({
                        type: "sop",
                        pipelineTemplateUuid: params.row.id,
                    });
                };

                const onClickClone = () => {
                    modalDispatch({
                        type: "clone",
                        pipelineTemplateUuid: params.row.id,
                    });
                };

                return (
                    <>
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={onClickEdit}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "primary.lighter",
                                    },
                                }}
                            >
                                <ModeEditIcon
                                    fontSize="small"
                                    color="primary"
                                />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Protocol details">
                            <IconButton
                                onClick={onClickSop}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "primary.lighter",
                                    },
                                }}
                            >
                                <ExploreIcon fontSize="small" color="primary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Clone">
                            <IconButton
                                onClick={onClickClone}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "primary.lighter",
                                    },
                                }}
                            >
                                <ContentCopyIcon
                                    fontSize="small"
                                    color="primary"
                                />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <IconButton
                                onClick={onClickDelete}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "primary.lighter",
                                    },
                                }}
                            >
                                <DeleteIcon fontSize="small" color="primary" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 200,
            flex: 3,
        },
        {
            field: "crop",
            headerName: "Crop",
            minWidth: 120,
            flex: 2,
        },
        {
            field: "system",
            headerName: "System",
            minWidth: 120,
            flex: 2,
        },
        {
            field: "acquisitionVector",
            headerName: "Acquisition Vector",
            minWidth: 280,
            flex: 3,
        },
        {
            field: "dataType",
            headerName: "Data Type",
            minWidth: 150,
            flex: 2,
        },
        {
            field: "siteCount",
            headerName: "Sites",
            minWidth: 100,
            flex: 2,
        },
        {
            field: "dateCount",
            headerName: "Dates",
            minWidth: 100,
            flex: 2,
        },
        {
            field: "featureCount",
            headerName: "Plots",
            minWidth: 100,
            flex: 2,
        },
    ];

    const gridRows = pipelineTemplateData.rows.map((pipeline) => {
        return {
            id: pipeline.uuid,
            crop: pipeline.Crop.name,
            name: pipeline.name,
            system: pipeline.AcquisitionVector.SystemModel.system,
            dataType: objectArrayStringify(
                pipeline.AcquisitionVector.SensorBundles,
                ["Sensor", "dataType"]
            ),
            acquisitionVector: pipeline.AcquisitionVector.name,
            siteCount: pipeline.siteCount,
            dateCount: pipeline.PipelineTemplateCalendars.reduce(
                (total, calendar) => {
                    return calendar.count + total;
                },
                0
            ),
            featureCount: pipeline.featureCount,
        };
    });

    const closeModal = () => modalDispatch({ type: "close" });

    const onConfirmDelete = async () => {
        const deletingTemplateUuid = modalState.pipelineTemplateUuid;
        const deletingTemplateName = modalState.pipelineTemplateName;

        closeModal();

        const deleteResult = await destroy(
            `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${deletingTemplateUuid}`
        );

        if (deleteResult) {
            mutate();
            openSnackbar(
                `Template ${deletingTemplateName} deleted successfully.`,
                "success"
            );
        }
    };

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" gutterBottom>
                    Pipeline templates
                </Typography>

                <Stack direction="row" spacing={1}>
                    <IconButton
                        size="large"
                        onClick={() => {
                            modalDispatch({
                                type: "create",
                            });
                        }}
                    >
                        <AddCircleOutlinedIcon
                            fontSize="inherit"
                            color="primary"
                        />
                    </IconButton>
                    <Dialog
                        open={modalState.modal === "form"}
                        fullWidth
                        maxWidth="xl"
                        TransitionComponent={ModalTransition}
                    >
                        <PipelineTemplateDialog
                            uuid={modalState.pipelineTemplateUuid}
                            isCreation={modalState.isCreation}
                            contractUuid={contractUuid}
                            closeModal={closeModal}
                            mutateTable={mutate}
                        />
                    </Dialog>
                    <Dialog
                        open={modalState.modal === "sop"}
                        fullWidth
                        maxWidth="xl"
                        TransitionComponent={ModalTransition}
                    >
                        <MissionProtocol
                            pipelineTemplateUuid={
                                modalState.pipelineTemplateUuid
                            }
                            closeModal={closeModal}
                        />
                    </Dialog>
                </Stack>
            </Stack>
            <Card>
                <Box
                    sx={{
                        height: getDataGridHeight(
                            600,
                            pipelineTemplateData.rows.length
                        ),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={gridRows}
                        columns={gridColDef}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                        pageSizeOptions={[100]}
                    />
                </Box>
            </Card>
            <ConfirmModal
                isOpen={modalState.modal === "delete"}
                onCancel={closeModal}
                onConfirm={onConfirmDelete}
                title="Are you sure you want to delete the pipeline template?"
                contentText={
                    <>
                        All information of{" "}
                        <b>{modalState.pipelineTemplateName}</b> will be
                        deleted.
                    </>
                }
            />
        </>
    );
}
