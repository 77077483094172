import {
    Box,
    Button,
    Divider,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import CalculateIcon from "@mui/icons-material/Calculate";
import { DataSourceFormSkeleton } from "./DataSourceFormSkeleton";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { INPUT_VALIDATION } from "../../../constants";
import PropTypes from "prop-types";
import { fullSop } from "../../utils/sopCalculation";
import useSWRImmutable from "swr/immutable";
import { useSnackbar } from "../../../hooks";

/**
 * When dataSource.sensor is null, we disable this form.
 */
InputsForm.propTypes = {
    register: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.any.isRequired,
    setResults: PropTypes.func.isRequired,
};

export function InputsForm({ register, handleSubmit, errors, setResults }) {
    const { openSnackbar } = useSnackbar();

    const { data: acquisitionVectorData, acquisitionVectorFetchError } =
        useSWRImmutable(
            `${BACKEND_ROUTES.ACQUISITION_VECTOR}?sensor=true&systemModel=true`
        );

    if (acquisitionVectorFetchError)
        return <FetchErrorAlert error={acquisitionVectorFetchError} />;
    if (!acquisitionVectorData) return <DataSourceFormSkeleton />;

    const onSubmit = (payload) => {
        try {
            const selectedAcquisitionVector = acquisitionVectorData.rows.find(
                (AV) => AV.uuid === payload.acquisitionVectorUuid
            );

            const selectedSensor = selectedAcquisitionVector.SensorBundles.find(
                (bundle) => bundle.sensorUuid === payload.sensorUuid
            ).Sensor;

            const selectedFocalLength35mm = payload.focalLength35mmManual;
            const protocolResults = fullSop({
                sensor: selectedSensor,
                focalLength35mm:
                    selectedSensor.focalLength35mmEqv ??
                    selectedFocalLength35mm,
                ...payload,
            });

            if (protocolResults) {
                setResults({
                    dataType: selectedSensor.dataType,
                    height: protocolResults.height,
                    maxSpeedMS: protocolResults.maxSpeedMS,
                    maxSpeedMPH: protocolResults.maxSpeedMPH,
                    trackCount: protocolResults.trackCount,
                    imagesPerTrack: protocolResults.picsPerTrack,
                    minTotalImages: protocolResults.minTotalPics,
                    dataSizeMB: protocolResults.dataSize,
                    estimatedTime: protocolResults.estimatedTime,
                    estimatedBatteries: protocolResults.numberOfBatteries,
                });
            }
        } catch (error) {
            openSnackbar(error.message, "error");
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ p: 2 }}>
                <Box>
                    <Typography variant="h5">Inputs</Typography>
                    <Divider />
                </Box>

                <TextField
                    id="gsd"
                    fullWidth
                    type="number"
                    label="GSD *"
                    error={Boolean(errors.gsd)}
                    helperText={errors.gsd?.message}
                    {...register("gsd", {
                        required: INPUT_VALIDATION.REQUIRED,
                        valueAsNumber: true,
                        validate: (value) =>
                            value > 0 || INPUT_VALIDATION.POSITIVE,
                    })}
                    inputProps={{
                        step: "0.01",
                        inputMode: "decimal",
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">mm</InputAdornment>
                        ),
                    }}
                />

                <TextField
                    id="trialLength"
                    fullWidth
                    type="number"
                    label="Length of trial *"
                    error={Boolean(errors.trialLength)}
                    helperText={errors.trialLength?.message}
                    {...register("trialLength", {
                        required: INPUT_VALIDATION.REQUIRED,
                        valueAsNumber: true,
                        validate: (value) =>
                            value > 0 || INPUT_VALIDATION.POSITIVE,
                    })}
                    inputProps={{
                        step: "0.1",
                        inputMode: "decimal",
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">m</InputAdornment>
                        ),
                    }}
                />

                <TextField
                    id="trialWidth"
                    fullWidth
                    type="number"
                    label="Width of trial *"
                    error={Boolean(errors.trialWidth)}
                    helperText={errors.trialWidth?.message}
                    {...register("trialWidth", {
                        required: INPUT_VALIDATION.REQUIRED,
                        valueAsNumber: true,
                        validate: (value) =>
                            value > 0 || INPUT_VALIDATION.POSITIVE,
                    })}
                    inputProps={{
                        step: "0.1",
                        inputMode: "decimal",
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">m</InputAdornment>
                        ),
                    }}
                />

                <TextField
                    id="overlap"
                    fullWidth
                    type="number"
                    label="Overlap *"
                    error={Boolean(errors.overlap)}
                    helperText={errors.overlap?.message}
                    {...register("overlap", {
                        required: INPUT_VALIDATION.REQUIRED,
                        valueAsNumber: true,
                        validate: {
                            positive: (value) =>
                                value > 0 || INPUT_VALIDATION.POSITIVE,
                            lessThanHundred: (value) =>
                                value < 100 ||
                                INPUT_VALIDATION.LESS_THAN_A_HUNDRED,
                        },
                    })}
                    inputProps={{
                        step: "0.1",
                        inputMode: "decimal",
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                />

                <TextField
                    id="acquisitionPeriod"
                    fullWidth
                    type="number"
                    label="Time between two acquisitions *"
                    error={Boolean(errors.acquisitionPeriod)}
                    helperText={errors.acquisitionPeriod?.message}
                    {...register("acquisitionPeriod", {
                        required: INPUT_VALIDATION.REQUIRED,
                        valueAsNumber: true,
                        validate: (value) =>
                            value > 0 || INPUT_VALIDATION.POSITIVE,
                    })}
                    inputProps={{
                        step: "0.1",
                        inputMode: "decimal",
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">s</InputAdornment>
                        ),
                    }}
                />

                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        startIcon={<CalculateIcon />}
                        type="submit"
                    >
                        Compute
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
}
