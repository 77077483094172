export const editorTheme = {
    characterLimit: "PlaygroundEditorTheme__characterLimit",
    heading: {
        h1: "RichTextEditorTheme__h1",
        h2: "RichTextEditorTheme__h2",
        h3: "RichTextEditorTheme__h3",
    },
    indent: "RichTextEditorTheme__indent",
    list: {
        listitem: "RichTextEditorTheme__listItem",
        ol: "RichTextEditorTheme__ol",
        ul: "RichTextEditorTheme__ul",
    },
    ltr: "RichTextEditorTheme__ltr",
    paragraph: "RichTextEditorTheme__paragraph",
    quote: "RichTextEditorTheme__quote",
    rtl: "RichTextEditorTheme__rtl",
    text: {
        bold: "RichTextEditorTheme__textBold",
        code: "RichTextEditorTheme__textCode",
        italic: "RichTextEditorTheme__textItalic",
        strikethrough: "RichTextEditorTheme__textStrikethrough",
        subscript: "RichTextEditorTheme__textSubscript",
        superscript: "RichTextEditorTheme__textSuperscript",
        underline: "RichTextEditorTheme__textUnderline",
        underlineStrikethrough:
            "RichTextEditorTheme__textUnderlineStrikethrough",
    },
};
