import {
    Container,
    Drawer,
    FormControlLabel,
    Switch,
    Typography,
} from "@mui/material";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { MissionList } from "./MissionList";
import PropTypes from "prop-types";
import { TRAIT_GROUP_ASSOCIABLE_TYPES } from "../../../constants";
import styled from "@emotion/styled";
import useSWR from "swr";
import { useState } from "react";

export const FilteringDrawer = ({
    closeDrawer,
    experimentUuid,
    open,
    traitGroupDataTypeUuid,
}) => {
    const [showError, setShowError] = useState(false);

    const experimentMissionSearchParams = new URLSearchParams({
        associable: true,
        associableType: TRAIT_GROUP_ASSOCIABLE_TYPES.EXPERIMENT_MISSION,
        pipelineTraitGroup: true,
        traitGroupDataTypeUuid,
        ExperimentMission_experimentUuid: experimentUuid,
    });

    const {
        data: experimentMissionTraitGroups,
        error: EMTGDTFetchError,
        isLoading,
    } = useSWR(
        `${BACKEND_ROUTES.ASSOCIATED_TRAIT_GROUP}?${experimentMissionSearchParams}`
    );

    return (
        <Drawer
            open={open}
            onClose={closeDrawer}
            variant="persistent"
            PaperProps={{
                sx: { width: drawerWidth, bgcolor: "background.neutral" },
            }}
            anchor="right"
        >
            <Container>
                <DrawerHeader>
                    <Typography variant="h5">Mission filtering</Typography>
                </DrawerHeader>
                <FormControlLabel
                    sx={{ display: "flex", mr: "auto" }}
                    checked={showError}
                    onClick={() => setShowError(!showError)}
                    control={<Switch />}
                    label="Include pipelines with errors"
                    labelPlacement="start"
                />

                {EMTGDTFetchError ? (
                    <FetchErrorAlert error={EMTGDTFetchError} />
                ) : isLoading ? null : (
                    <MissionList
                        experimentMissionTraitGroups={
                            experimentMissionTraitGroups.rows
                        }
                        showError={showError}
                    />
                )}
            </Container>
        </Drawer>
    );
};

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
}));

const drawerWidth = 540;

FilteringDrawer.propTypes = {
    closeDrawer: PropTypes.func.isRequired,
    experimentUuid: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    traitGroupDataTypeUuid: PropTypes.string.isRequired,
};
