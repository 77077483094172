import { Box, Drawer, IconButton, Stack } from "@mui/material";

import { DRAWER_WIDTH } from "./constants";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import Logo from "../../components/Logo";
import NavSection from "../../components/NavSection";
import PropTypes from "prop-types";
import Scrollbar from "../../components/Scrollbar";
import sidebarConfig from "./SidebarConfig";
import { styled } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme, open }) => ({
    flexShrink: 0,
    width: open && DRAWER_WIDTH,
}));

OrdersSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function OrdersSidebar({ isOpenSidebar, onCloseSidebar }) {
    //const isDesktop = useResponsive("up", "lg");

    const renderContent = (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2.5, py: 3 }}
            >
                <div />
                <Logo />
                <IconButton
                    onClick={() => onCloseSidebar()}
                    sx={{ color: "text.primary" }}
                >
                    <FirstPageIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <Scrollbar
                sx={{
                    height: 1,
                    "& .simplebar-content": {
                        height: 1,
                        display: "flex",
                        flexDirection: "column",
                    },
                }}
            >
                <NavSection navConfig={sidebarConfig} />

                <Box sx={{ flexGrow: 1 }} />
            </Scrollbar>
        </>
    );

    return (
        <RootStyle open={isOpenSidebar}>
            <Drawer
                open={isOpenSidebar}
                onClose={onCloseSidebar}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: DRAWER_WIDTH,
                        bgcolor: "background.neutral",
                        borderRightStyle: "solid",
                    },
                }}
            >
                {renderContent}
            </Drawer>
        </RootStyle>
    );
}
