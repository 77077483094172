import {
    Card,
    CardContent,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";

import { MissionProtocolSummaryDataLine } from "./MissionProtocolSummaryDataLine";
import PropTypes from "prop-types";
import { useMemo } from "react";

MissionProtocolSummary.propTypes = {
    pipelineTemplate: PropTypes.object.isRequired,
    bbchStageData: PropTypes.object.isRequired,
    gsdPerStage: PropTypes.array.isRequired,
};

export function MissionProtocolSummary({
    pipelineTemplate,
    bbchStageData,
    gsdPerStage,
}) {
    const associatedGSDWithFlightSequences = useMemo(
        () =>
            gsdPerStage
                .map((gsd) => ({
                    // add flight sequence data to gsdPerStage for display purposes
                    ...gsd,
                    flightSequence: pipelineTemplate.FlightSequences.find(
                        (fs) =>
                            fs.bbchStageUuid ===
                            bbchStageData.rows.find(
                                (bbch) => bbch.order === gsd.order
                            )?.uuid
                    )?.sequence,
                }))
                .filter((gsd) => gsd.flightSequence), // remove stages with no flight sequence;
        // we could filter out when gsd.gsd doesn't exist if we want to remove flights that are too low to match protocol
        [bbchStageData.rows, gsdPerStage, pipelineTemplate.FlightSequences]
    );

    return (
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Data acquisition protocol summary
                        </Typography>
                        <Divider />

                        <Typography component="div">
                            <Typography>
                                Crop: {pipelineTemplate.Crop.name}
                            </Typography>
                            Model:{" "}
                            {
                                pipelineTemplate.AcquisitionVector.SystemModel
                                    .name
                            }
                        </Typography>
                    </Stack>

                    <Stack>
                        <Divider />
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography variant="h6">Flights</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">
                                    GSD (mm/px)
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6">
                                    Altitude (m)
                                </Typography>
                            </Grid>
                            {associatedGSDWithFlightSequences.map((gsd) => (
                                <MissionProtocolSummaryDataLine
                                    key={gsd.order}
                                    gsd={gsd}
                                    pipelineTemplate={pipelineTemplate}
                                />
                            ))}
                        </Grid>
                    </Stack>
                    {pipelineTemplate.comment && (
                        <Stack spacing={1}>
                            <Typography variant="h5">
                                Specific guidelines
                            </Typography>
                            <Divider />
                            <Typography style={{ wordWrap: "break-word" }}>
                                {pipelineTemplate.comment}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}
