import {
    Autocomplete,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_HANDLE_REGEX, INPUT_VALIDATION } from "../../constants";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CompanyFormSkeleton } from "./CompanyFormSkeleton";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import useSWRImmutable from "swr/immutable";

CompanyForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
};

export function CompanyForm({ closeModal, mutate }) {
    const { openSnackbar } = useSnackbar();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            name: "",
            segment: "",
            region: "",
            acceptedEmailHandles: [],
        },
    });

    const { post } = useFetch();

    const { data: regions, error: regionsFetchError } = useSWRImmutable(
        BACKEND_ROUTES.REGION
    );

    const { data: segments, error: segmentsFetchError } = useSWRImmutable(
        BACKEND_ROUTES.SEGMENT
    );

    const mergedFetchError = regionsFetchError ?? segmentsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!regions || !segments) return <CompanyFormSkeleton />;

    const onSubmit = async (payload) => {
        const createdCompany = await post(BACKEND_ROUTES.COMPANY, {
            body: {
                ...payload,
                regionUuid: payload.region,
                segmentUuid: payload.segment,
            },
        });

        if (createdCompany) {
            mutate();
            closeModal();
            openSnackbar(
                `Company ${createdCompany.name} created successfully.`,
                "success"
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create a new company</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            fullWidth
                            type="text"
                            label="Name *"
                            error={Boolean(errors.name)}
                            helperText={errors.name?.message}
                            {...register("name", {
                                required: INPUT_VALIDATION.REQUIRED,
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control={control}
                            name="segment"
                            render={({ field: { ref, ...fieldProps } }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="segment"
                                    select
                                    label="Segment *"
                                    inputRef={ref}
                                    error={Boolean(errors.segment)}
                                    helperText={errors.segment?.message}
                                >
                                    {segments.rows.map((segment) => (
                                        <MenuItem
                                            key={segment.uuid}
                                            value={segment.uuid}
                                        >
                                            {segment.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control={control}
                            name="region"
                            render={({ field: { ref, ...fieldProps } }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="region"
                                    select
                                    label="Region *"
                                    inputRef={ref}
                                    error={Boolean(errors.region)}
                                    helperText={errors.region?.message}
                                >
                                    {regions.rows.map((region) => (
                                        <MenuItem
                                            key={region.uuid}
                                            value={region.uuid}
                                        >
                                            {region.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="acceptedEmailHandles"
                            render={({
                                field: { ref, name, onChange, ...fieldProps },
                            }) => (
                                <Autocomplete
                                    {...fieldProps}
                                    multiple
                                    id="acceptedEmailHandles"
                                    options={[]}
                                    onChange={(_, value, reason, details) => {
                                        if (reason === "createOption") {
                                            // lower case last value
                                            const newValue = value
                                                .slice(0, -1)
                                                .concat([
                                                    details.option.toLowerCase(),
                                                ]);
                                            onChange(newValue);
                                        } else {
                                            onChange(value);
                                        }
                                    }}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                size="small"
                                                label={option}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            inputRef={ref}
                                            label="Accepted email handles *"
                                            placeholder="Email handle"
                                            name={name}
                                            error={Boolean(
                                                errors.acceptedEmailHandles
                                            )}
                                            helperText={`${
                                                errors.acceptedEmailHandles
                                                    ?.message ?? ""
                                            } Press ENTER to validate each handle. You can
                                                        input more than one handle.`}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <>
                                                        {
                                                            params.InputProps
                                                                .startAdornment
                                                        }
                                                        <InputAdornment position="start">
                                                            @
                                                        </InputAdornment>
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                                validate: (acceptedEmailHandles) =>
                                    acceptedEmailHandles.every((emailHandle) =>
                                        EMAIL_HANDLE_REGEX.test(emailHandle)
                                    ) || INPUT_VALIDATION.EMAIL_INVALID,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
