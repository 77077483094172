import {
    Breadcrumbs,
    Chip,
    FormControlLabel,
    Skeleton,
    Stack,
    Switch,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

import { AcquisitionReport } from "./normal-acquisition-report";
import { AdvancedAcquisitionReport } from "./advanced-acquisition-report";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { BreadcrumbChip } from "../../components/BreadcrumbChip";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { MODES } from "./constants";
import { REPORT_STATUS } from "../../constants";
import { formatDateISO } from "../../utils/formatTime";
import { useMission } from "./useMission";
import useSWR from "swr";

export function AcquisitionReportLayout() {
    const { missionUuid } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const experimentMissionUuid = searchParams.get("experimentMissionUuid");

    const {
        data: experimentMission,
        error: experimentMissionFetchError,
        isLoading: experimentMissionIsLoading,
    } = useSWR(
        experimentMissionUuid
            ? `${BACKEND_ROUTES.EXPERIMENT_MISSION}/${experimentMissionUuid}`
            : null
    );

    const isAdvancedMode = searchParams.get("mode") === MODES.ADVANCED;

    const {
        data: mission,
        error: missionFetchError,
        isLoading: missionLoading,
    } = useMission(missionUuid);

    const mergedFetchError = missionFetchError || experimentMissionFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    const handleSwitchChange = () => {
        setSearchParams((prevState) => {
            const newSearchParams = new URLSearchParams(prevState);
            newSearchParams.set(
                "mode",
                isAdvancedMode ? MODES.NORMAL : MODES.ADVANCED
            );
            return newSearchParams;
        });
    };

    return (
        <>
            {experimentMissionIsLoading || missionLoading ? (
                <Skeleton width="50%" height="5em" />
            ) : (
                <Stack direction="row" spacing={2} alignItems="center">
                    <Breadcrumbs separator="/" sx={{ mb: 1 }}>
                        <BreadcrumbChip
                            label={mission.Site.Contract.Company.name}
                        />
                        <BreadcrumbChip label={mission.Site.Contract.name} />
                        <BreadcrumbChip label={mission.Site.name} />
                        <BreadcrumbChip label={formatDateISO(mission.date)} />
                    </Breadcrumbs>
                    <Chip
                        label={
                            mission.reportStatus ?? "No report status available"
                        }
                        color={
                            reportStatusColors[mission.reportStatus] ||
                            "default"
                        }
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isAdvancedMode}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        id="advancedMode"
                        label="Advanced Mode"
                        labelPlacement="start"
                    />
                </Stack>
            )}
            {isAdvancedMode ? (
                <AdvancedAcquisitionReport
                    experimentMission={experimentMission}
                />
            ) : (
                <AcquisitionReport experimentMission={experimentMission} />
            )}
        </>
    );
}

const reportStatusColors = {
    [REPORT_STATUS.VALIDATED]: "success",
    [REPORT_STATUS.REJECTED]: "error",
    [REPORT_STATUS.WARNING]: "warning",
};
