import { Card, CardContent, Skeleton, Stack } from "@mui/material";

export function DataSourceFormSkeleton() {
    return (
        <Card
            sx={{
                bgcolor: (theme) => theme.palette.grey[210],
            }}
        >
            <CardContent>
                <Stack spacing={2}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="2em"
                    />
                </Stack>
            </CardContent>
        </Card>
    );
}
