import { SWRConfig } from "swr";
import { get } from "../../utils/fetchUtils";
import { useSnackbar } from "../../hooks";

export const CustomSWRConfig = ({ children }) => {
    const { openSnackbar } = useSnackbar();

    return (
        <SWRConfig
            value={{
                fetcher: get,
                onError: ({ message }) => openSnackbar(message, "error"),
            }}
        >
            {children}
        </SWRConfig>
    );
};
