import { Grid, Typography } from "@mui/material";
import { absoluteAggregatedHeight, roundNumber } from "../utils";

import { DATATYPES_ENUM } from "../../constants";
import { Fragment } from "react";
import PropTypes from "prop-types";

MissionProtocolSummaryDataLine.propTypes = {
    gsd: PropTypes.object.isRequired,
    pipelineTemplate: PropTypes.object.isRequired,
};

export function MissionProtocolSummaryDataLine({ gsd, pipelineTemplate }) {
    const displayedHeight = roundNumber(
        absoluteAggregatedHeight({
            gsd,
            pipelineTemplate,
        }),
        0
    );
    const displayedGSD = Math.min(...Object.values(gsd.gsd));
    const dataTypeOfGSD = Object.keys(gsd.gsd).find(
        (key) => gsd.gsd[key] === displayedGSD
    );
    return (
        <Fragment>
            <Grid item xs={4}>
                <Typography>{gsd.flightSequence}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>
                    {displayedGSD === Infinity
                        ? "N/A"
                        : dataTypeOfGSD === DATATYPES_ENUM.MULTISPECTRAL
                          ? `${displayedGSD} - MS`
                          : `${displayedGSD} - ${dataTypeOfGSD}`}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>
                    {displayedHeight === 0 ? "N/A" : displayedHeight}
                </Typography>
            </Grid>
        </Fragment>
    );
}
