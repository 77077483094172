import { Card, Stack, Typography } from "@mui/material";

import { EXPERIMENT_MISSION_STATUS } from "../../../constants";
import { TrafficTable } from "../";
import { useCallback } from "react";

const COMPLETED_STATUSES = Object.freeze([
    EXPERIMENT_MISSION_STATUS.PROCESS_ERROR,
    EXPERIMENT_MISSION_STATUS.PROCESS_ABORTED,
    EXPERIMENT_MISSION_STATUS.PROCESS_FINISHED,
]);

export const CompletedTraffic = () => {
    const filter = useCallback(
        (experimentMission) =>
            COMPLETED_STATUSES.includes(experimentMission.status),
        []
    );

    return (
        <Stack spacing={2}>
            <Typography variant="h4">Completed Traffic</Typography>

            <Card>
                <TrafficTable filter={filter} />
            </Card>
        </Stack>
    );
};
