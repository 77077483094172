import { APPBAR_HEIGHT, DRAWER_WIDTH } from "./constants";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    IconButton,
    Stack,
    Toolbar,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import AccountPopover from "./AccountPopover";
import ExploreIcon from "@mui/icons-material/Explore";
import LastPageIcon from "@mui/icons-material/LastPage";
import { ModalTransition } from "../../components/ModalTransition";
import PropTypes from "prop-types";
import SopSimulator from "../../pages/SopSimulator";
import { useState } from "react";

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "isOpenSidebar",
})(({ theme, isOpenSidebar }) => ({
    boxShadow: theme.customShadows.z2,
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [isOpenSidebar]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

OrdersNavbar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onOpenSidebar: PropTypes.func,
};

export default function OrdersNavbar({ isOpenSidebar, onOpenSidebar }) {
    const [isOpenProtocolSimulator, setIsOpenProtocolSimulator] =
        useState(false);

    return (
        <RootStyle isOpenSidebar={isOpenSidebar}>
            <Toolbar style={{ minHeight: APPBAR_HEIGHT }}>
                <IconButton
                    onClick={onOpenSidebar}
                    sx={{
                        mr: 1,
                        color: "text.primary",
                        display: isOpenSidebar ? "none" : true,
                    }}
                >
                    <LastPageIcon />
                </IconButton>

                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ xs: 4, sm: 5 }}
                >
                    <Button
                        variant="outlined"
                        endIcon={<ExploreIcon />}
                        onClick={() => setIsOpenProtocolSimulator(true)}
                    >
                        Protocol Simulator
                    </Button>
                    <AccountPopover />
                </Stack>

                <Dialog
                    open={isOpenProtocolSimulator}
                    fullWidth
                    maxWidth="xl"
                    TransitionComponent={ModalTransition}
                >
                    <SopSimulator
                        closeModal={() => setIsOpenProtocolSimulator(false)}
                    />
                </Dialog>
            </Toolbar>
        </RootStyle>
    );
}
