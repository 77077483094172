import { destroy, get, patch, post, put } from "../../utils/fetchUtils";

import { FetchContext } from "./FetchContext";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useSnackbar } from "../../hooks";

export const FetchProvider = ({ children }) => {
    const { openSnackbar } = useSnackbar();
    const createFetchMethod = useCallback(
        (method) =>
            (url, { forwardError = false, ...init } = {}) =>
                /**
                 * Returns a Promise that resolves to JSON returned by API if call successful.
                 * If call fails, depending on the value of forwardError, 2 possiblilities.
                 * If forwardError false, a snackbar is opened with message of the error and `undefined` is returned.
                 * If forwardError true, the error (contains fields: message, status?, errorCode?)
                 * is thrown so the caller can handle it.
                 */
                // `catch` is used instead of try/catch to avoid async function without `await`
                method(url, init).catch((error) => {
                    if (forwardError) throw error;
                    else openSnackbar(error.message, "error");
                }),
        [openSnackbar]
    );

    const methods = {
        get: useCallback(
            (url, init) => createFetchMethod(get)(url, init),
            [createFetchMethod]
        ),
        post: useCallback(
            (url, init) => createFetchMethod(post)(url, init),
            [createFetchMethod]
        ),
        put: useCallback(
            (url, init) => createFetchMethod(put)(url, init),
            [createFetchMethod]
        ),
        patch: useCallback(
            (url, init) => createFetchMethod(patch)(url, init),
            [createFetchMethod]
        ),
        destroy: useCallback(
            (url, init) => createFetchMethod(destroy)(url, init),
            [createFetchMethod]
        ),
    };

    return (
        <FetchContext.Provider value={methods}>
            {children}
        </FetchContext.Provider>
    );
};

FetchProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
