import { Box, Card, Radio, Stack, Tooltip, Typography } from "@mui/material";

import { ALLOWED_FACTOR_BELOW_MINIMUM_GSD } from "../../../constants";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DataGrid } from "@mui/x-data-grid";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";
import { getDataGridHeight } from "../../utils/getDataGridHeight";
import { getGsdPerStage } from "../../utils/getGsdPerStage";
import { useMemo } from "react";

AssociationPipelineTemplateTable.propTypes = {
    pipelineTemplates: PropTypes.shape({
        rows: PropTypes.arrayOf(
            PropTypes.shape(MODEL_PROPTYPES.PipelineTemplate)
        ).isRequired,
        count: PropTypes.number.isRequired,
    }).isRequired,
    bbchStages: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.shape(MODEL_PROPTYPES.BBCHStage))
            .isRequired,
        count: PropTypes.number.isRequired,
    }).isRequired,
    selectedPipelineTemplate: PropTypes.arrayOf(PropTypes.string),
    setInboundStates: PropTypes.func.isRequired,
    acquisitionGsdMax: PropTypes.object.isRequired,
};

export function AssociationPipelineTemplateTable({
    pipelineTemplates,
    bbchStages,
    selectedPipelineTemplate,
    setInboundStates,
    acquisitionGsdMax,
}) {
    const pipelineTemplateGridRows = useMemo(
        () =>
            pipelineTemplates.rows.map((pipelineTemplate) => {
                const gsdPerStage = pipelineTemplate.gsdPerTraitGroupDataType
                    ? getGsdPerStage(pipelineTemplate, bbchStages.rows)
                    : [];
                const formatedGsdPerStage = gsdPerStage.reduce(
                    (acc, { order, gsd }) => {
                        acc[`stage${order}`] = gsd;
                        return acc;
                    },
                    {}
                );

                return {
                    id: pipelineTemplate.uuid,
                    name: pipelineTemplate.name,
                    acquisitionVector: pipelineTemplate.AcquisitionVector.name,
                    crop: pipelineTemplate.Crop.name,
                    ...formatedGsdPerStage,
                };
            }),
        [bbchStages, pipelineTemplates]
    );

    const pipelineTemplateGridColDef = [
        {
            field: "Button",
            headerName: "",
            width: 65,
            sortable: false,
            hideable: false,
            filterable: false,
            renderCell: (params) => (
                <Radio
                    checked={selectedPipelineTemplate[0] === params.id}
                    value={params.id}
                />
            ),
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 160,
            flex: 3,
        },
        {
            field: "acquisitionVector",
            headerName: "Acquisition vector",
            minWidth: 160,
            flex: 2,
        },
        {
            field: "crop",
            headerName: "Crop",
            minWidth: 80,
            flex: 2,
        },
    ];

    bbchStages.rows.forEach((bbch) => {
        pipelineTemplateGridColDef.push({
            field: `stage${bbch.order}`,
            headerName: bbch.stage,
            width: 65,
            sortable: false,
            renderCell: (params) => (
                <GSDRenderCell
                    value={params.value}
                    acquisitionGsdMax={acquisitionGsdMax}
                />
            ),
        });
    });

    return (
        <Card>
            <Box
                sx={{
                    height: getDataGridHeight(
                        400,
                        pipelineTemplates.rows.length
                    ),
                    width: "100%",
                }}
            >
                <DataGrid
                    rows={pipelineTemplateGridRows}
                    columns={pipelineTemplateGridColDef}
                    rowSelectionModel={selectedPipelineTemplate}
                    onRowSelectionModelChange={(value) => {
                        setInboundStates((previousState) => ({
                            ...previousState,
                            selectedPipelineTemplate: value,
                            selectedBBCHStage: null,
                        }));
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "name", sort: "asc" }],
                        },
                    }}
                    disableColumnMenu
                />
            </Box>
        </Card>
    );
}

function GSDRenderCell({ value, acquisitionGsdMax }) {
    const valueIsEmpty = Math.min(...Object.values(value)); // if gsd is empty, Math.min(...Object.values(gsd)) returns Infinity
    return (
        valueIsEmpty !== Infinity && (
            <Tooltip
                title={
                    <Stack>
                        {Object.keys(value).map((dataType) => {
                            return (
                                <Typography key={dataType}>
                                    {dataType}: {value[dataType]}mm/pixel
                                </Typography>
                            );
                        })}
                    </Stack>
                }
            >
                {Object.keys(acquisitionGsdMax).every(
                    // If there is no intersection between the set of datatypes of the acquistion and that of the pipeline template
                    (dataType) => value[dataType] === undefined
                ) ? (
                    <DoDisturbOnIcon sx={{ color: "grey.500" }} />
                ) : Object.keys(acquisitionGsdMax).every(
                      (dataType) =>
                          acquisitionGsdMax[dataType] !== null &&
                          acquisitionGsdMax[dataType] <= value[dataType]
                  ) ? (
                    <CheckCircleIcon color="success" />
                ) : Object.keys(acquisitionGsdMax).every(
                      (dataType) =>
                          acquisitionGsdMax[dataType] <=
                          value[dataType] * ALLOWED_FACTOR_BELOW_MINIMUM_GSD
                  ) ? (
                    <WarningIcon color="warning" />
                ) : (
                    <CancelIcon color="error" />
                )}
            </Tooltip>
        )
    );
}
