import {
    Button,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CONTRACT_STATUS } from "../../constants";
import { ContractFormSkeleton } from "./ContractFormSkeleton";
import { DatePicker } from "@mui/x-date-pickers";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { INPUT_VALIDATION } from "../../constants";
import { LabelStyle } from "../../components/LabelStyle";
import { LoadingButton } from "@mui/lab";
import { PAGINATION } from "../../constants";
import { formatDateISO } from "../../utils/formatTime";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

export const ContractForm = ({ uuid }) => {
    // If isCreation is true, we are creating a new contract. Otherwise, we are modifying an existing one.
    const isCreation = !uuid;

    const [{ startDateError, endDateError, reportDateError }, setDateError] =
        useState({});
    const { openSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const {
        data: contract,
        error: contractFetchError,
        mutate,
    } = useSWR(
        isCreation ? null : `${BACKEND_ROUTES.CONTRACT}/${uuid}?parentInfo=true`
    );

    const { data: companies, error: companiesFetchError } = useSWR(
        isCreation
            ? `${BACKEND_ROUTES.COMPANY}?sort=name&limit=${PAGINATION.COMPANY.LIMIT.MAX}`
            : null
    );
    const { data: users, error: usersFetchError } = useSWR(
        `${BACKEND_ROUTES.USER}?sort=firstName`
    );

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isDirty },
    } = useForm({
        defaultValues: {
            name: "",
            companyUuid: "",
            startDate: null,
            endDate: null,
            supervisorUuid: "",
            salespersonUuid: "",
            reportDate: null,
            status: CONTRACT_STATUS.PRE_ORDERED,
        },
    });

    const [startDateWatch, endDateWatch] = useWatch({
        name: ["startDate", "endDate"],
        control,
    });

    useEffect(() => {
        if (contract) {
            reset({
                name: contract.name,
                companyUuid: contract.companyUuid,
                startDate: parseISO(contract.startDate),
                endDate: parseISO(contract.endDate),
                supervisorUuid: contract.supervisorUuid,
                salespersonUuid: contract.salespersonUuid,
                reportDate: contract.reportDate
                    ? parseISO(contract.reportDate)
                    : null,
                status: contract.status,
            });
        }
    }, [contract, reset]);

    const { post, patch } = useFetch();

    const mergedFetchError =
        contractFetchError ?? companiesFetchError ?? usersFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if ((!companies && isCreation) || !users || (!contract && !isCreation))
        return <ContractFormSkeleton />;

    const onSubmit = async (payload) => {
        const body = {
            ...payload,
            startDate: formatDateISO(payload.startDate),
            endDate: formatDateISO(payload.endDate),
            reportDate: payload.reportDate && formatDateISO(payload.reportDate),
        };

        if (!isCreation) body.companyUuid = undefined;

        const updatedContract = isCreation
            ? await post(BACKEND_ROUTES.CONTRACT, {
                  body: body,
              })
            : await patch(`${BACKEND_ROUTES.CONTRACT}/${uuid}`, {
                  body: body,
              });

        if (updatedContract) {
            openSnackbar(
                `Campaign ${updatedContract.name} ${
                    isCreation ? "created" : "updated"
                } successfully.`,
                "success"
            );

            isCreation
                ? navigate(
                      `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}`
                  )
                : mutate();
        }
    };

    const onCancel = () => {
        isCreation
            ? navigate(`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}`)
            : reset();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {isCreation
                            ? "Create a new campaign"
                            : "Campaign management"}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Company{isCreation ? " *" : ""}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    {isCreation ? (
                        <Controller
                            control={control}
                            name="companyUuid"
                            render={({ field: { ref, ...fieldProps } }) => (
                                <TextField
                                    {...fieldProps}
                                    disabled={!isCreation}
                                    fullWidth
                                    id="companyUuid"
                                    select
                                    inputRef={ref}
                                    error={Boolean(errors.companyUuid)}
                                    helperText={errors.companyUuid?.message}
                                >
                                    {companies.rows.map((company) => (
                                        <MenuItem
                                            key={company.uuid}
                                            value={company.uuid}
                                        >
                                            {company.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    ) : (
                        <Typography>{contract.Company.name}</Typography>
                    )}
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={2}>
                    <LabelStyle>Name{isCreation ? " *" : ""}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="name"
                        id="name"
                        fullWidth
                        type="text"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...register("name", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>
                        Starting Date{isCreation ? " *" : ""}
                    </LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { ref, ...fieldProps } }) => (
                            <DatePicker
                                {...fieldProps}
                                slotProps={{
                                    textField:
                                        !startDateWatch &&
                                        errors.startDate?.type === "required"
                                            ? {
                                                  helperText:
                                                      errors.startDate?.message,
                                                  error: true,
                                              }
                                            : {
                                                  helperText: startDateError,
                                                  // do not define "error" here because it would override the default "error"
                                              },
                                }}
                                inputRef={ref}
                                id="startDate"
                                disabled={!isCreation}
                                onError={(reason, value) => {
                                    switch (reason) {
                                        case "invalidDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                startDateError:
                                                    INPUT_VALIDATION.INVALID_DATE,
                                            }));
                                            break;

                                        case "maxDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                startDateError:
                                                    INPUT_VALIDATION.MAX_START_DATE_ERROR,
                                            }));
                                            break;

                                        default:
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                startDateError: undefined,
                                            }));
                                    }
                                }}
                                maxDate={endDateWatch ?? undefined}
                                format="yyyy-MM-dd"
                                mask={"____-__-__"}
                            />
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                            validate: () => !startDateError,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>
                        Campaign Manager{isCreation ? " *" : ""}
                    </LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        control={control}
                        name="supervisorUuid"
                        render={({ field: { ref, ...fieldProps } }) => (
                            <TextField
                                {...fieldProps}
                                fullWidth
                                id="supervisorUuid"
                                select
                                inputRef={ref}
                                error={Boolean(errors.supervisorUuid)}
                                helperText={errors.supervisorUuid?.message}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user.uuid} value={user.uuid}>
                                        {user.firstName} {user.lastName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Ending Date{isCreation ? " *" : ""}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { ref, ...fieldProps } }) => (
                            <DatePicker
                                {...fieldProps}
                                slotProps={{
                                    textField:
                                        !endDateWatch &&
                                        errors.endDate?.type === "required"
                                            ? {
                                                  helperText:
                                                      errors.endDate?.message,
                                                  error: true,
                                              }
                                            : {
                                                  helperText: endDateError,
                                                  // do not define "error" here because it would override the default "error"
                                              },
                                }}
                                format="yyyy-MM-dd"
                                mask={"____-__-__"}
                                inputRef={ref}
                                id="endDate"
                                disabled={!isCreation}
                                onError={(reason, value) => {
                                    switch (reason) {
                                        case "invalidDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                endDateError:
                                                    INPUT_VALIDATION.INVALID_DATE,
                                            }));
                                            break;

                                        case "minDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                endDateError:
                                                    INPUT_VALIDATION.MIN_END_DATE_ERROR,
                                            }));
                                            break;

                                        default:
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                endDateError: undefined,
                                            }));
                                    }
                                }}
                                minDate={startDateWatch ?? undefined}
                            />
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                            validate: () => !endDateError,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>
                        Project Lead{isCreation ? " *" : ""}
                    </LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        control={control}
                        name="salespersonUuid"
                        render={({ field: { ref, ...fieldProps } }) => (
                            <TextField
                                {...fieldProps}
                                fullWidth
                                id="salespersonUuid"
                                select
                                inputRef={ref}
                                error={Boolean(errors.salespersonUuid)}
                                helperText={errors.salespersonUuid?.message}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user.uuid} value={user.uuid}>
                                        {user.firstName} {user.lastName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Report Date</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        name="reportDate"
                        control={control}
                        render={({ field: { ref, ...fieldProps } }) => (
                            <DatePicker
                                {...fieldProps}
                                slotProps={{
                                    field: { clearable: true },
                                    textField: {
                                        helperText: reportDateError,
                                    },
                                }}
                                format="yyyy-MM-dd"
                                mask={"____-__-__"}
                                inputRef={ref}
                                id="reportDate"
                                onError={(reason, value) => {
                                    switch (reason) {
                                        case "invalidDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                reportDateError:
                                                    INPUT_VALIDATION.INVALID_DATE,
                                            }));
                                            break;

                                        default:
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                reportDateError: undefined,
                                            }));
                                    }
                                }}
                            />
                        )}
                        rules={{
                            validate: () => !reportDateError,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Status{isCreation ? " *" : ""}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { ref, ...fieldProps } }) => (
                            <TextField
                                {...fieldProps}
                                fullWidth
                                id="status"
                                select
                                inputRef={ref}
                                error={Boolean(errors.status)}
                                helperText={errors.status?.message}
                            >
                                {Object.values(CONTRACT_STATUS).map(
                                    (status) => (
                                        <MenuItem key={status} value={status}>
                                            {status}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                        }}
                    />
                </Grid>
                {(isCreation || isDirty) && (
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            spacing={3}
                            justifyContent="flex-end"
                        >
                            <Button type="button" onClick={onCancel}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                {isCreation ? "Create" : "Update"}
                            </LoadingButton>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};
