import { AcquisitionReportLayout } from "../features/acquisition-report";
import { Container } from "@mui/material";
import Page from "../components/Page";

export default function AcquisitionReportPage() {
    return (
        <Page title="Acquisition report | HCC">
            <Container maxWidth="xl">
                <AcquisitionReportLayout />
            </Container>
        </Page>
    );
}
