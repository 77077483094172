import { Box, useTheme } from "@mui/material";

import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

Logo.propTypes = {
    sx: PropTypes.object,
};

export default function Logo({ sx }) {
    const theme = useTheme();
    return (
        <RouterLink to="/">
            <Box
                component="img"
                src={
                    theme.custom.isSpooky
                        ? "/static/spooky_logo.png"
                        : "/static/logo.svg"
                }
                sx={{ width: 100, height: 60, ...sx }}
            />
        </RouterLink>
    );
}
