import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
    INPUT_VALIDATION,
    MISSION_STATUS,
    MISSION_SUB_STATUS,
    SYSTEMS,
} from "../../../constants";
import { useEffect, useMemo } from "react";
import { useFetch, useSnackbar } from "../../../hooks";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { DateTimePicker } from "@mui/x-date-pickers";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { formatDateTimeUTC } from "../../../utils/formatTime";
import { objectArrayStringify } from "../../utils/objectArrayStringify";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const systemValues = Object.values(SYSTEMS);
const statusValues = Object.values(MISSION_STATUS);

RoutingDialog.propTypes = {
    uuid: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    mutateTable: PropTypes.func.isRequired,
};

export function RoutingDialog({ uuid, closeModal, mutateTable }) {
    const { patch } = useFetch();
    const { openSnackbar } = useSnackbar();

    const {
        data: mission,
        error: missionFetchError,
        mutate,
    } = useSWR(uuid ? `${BACKEND_ROUTES.MISSION}/${uuid}` : null);

    const { data: acquisitionVectorsData, acquisitionVectorsFetchError } =
        useSWRImmutable(
            `${BACKEND_ROUTES.ACQUISITION_VECTOR}?sensor=true&systemModel=true&sort=name`
        );

    const { data: systemModelsData, systemModelsFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.SYSTEM_MODEL}?sort=name`
    );

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            date: "",
            system: "",
            systemModelUuid: "",
            acquisitionVectorUuid: "",
            focalLength35mmEqv: "",
            status: "",
            subStatus: "",
        },
    });

    const [
        acquisitionVectorUuidWatch,
        systemModelUuidWatch,
        systemWatch,
        statusWatch,
    ] = useWatch({
        name: ["acquisitionVectorUuid", "systemModelUuid", "system", "status"],
        control,
    });

    const selectedAcquisitionVector = useMemo(() => {
        if (acquisitionVectorUuidWatch && acquisitionVectorsData)
            return acquisitionVectorsData.rows.find(
                (acquisitionVector) =>
                    acquisitionVector.uuid === acquisitionVectorUuidWatch
            );
    }, [acquisitionVectorUuidWatch, acquisitionVectorsData]);

    useEffect(() => {
        if (mission && acquisitionVectorsData) {
            const acquisitionVector = acquisitionVectorsData.rows.find(
                (av) => av.uuid === mission.acquisitionVectorUuid
            );

            reset({
                date: offset(new Date(mission.date), true),
                system: acquisitionVector.SystemModel.system,
                systemModelUuid: acquisitionVector.systemModelUuid,
                focalLength35mmEqv: mission.focalLength35mmEqv,
                acquisitionVectorUuid: mission.acquisitionVectorUuid,
                status: mission.status,
                subStatus: mission.subStatus,
            });
        }
    }, [mission, acquisitionVectorsData, reset]);

    const mergedFetchError =
        acquisitionVectorsFetchError &&
        missionFetchError &&
        systemModelsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (!acquisitionVectorsData || !mission || !systemModelsData) return null;

    const needsFocalLengthInput = selectedAcquisitionVector?.SensorBundles.some(
        (bundle) => !bundle.Sensor.focalLength35mmEqv
    );

    const onSubmit = async (payload) => {
        const updatedMission = await patch(
            `${BACKEND_ROUTES.MISSION}/${uuid}`,
            {
                body: {
                    ...payload,
                    date: offset(new Date(payload.date), false),
                },
            }
        );

        if (updatedMission) {
            openSnackbar(
                `Mission ${mission.date} updated successfully.`,
                "success"
            );

            mutate();
            mutateTable();
            closeModal();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{formatDateTimeUTC(mission.date)}</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="date"
                            render={({ field: { ref, ...fieldProps } }) => (
                                <DateTimePicker
                                    {...fieldProps}
                                    fullWidth
                                    id="date"
                                    label="Date"
                                    inputRef={ref}
                                    error={Boolean(errors.date)}
                                    helperText={errors.date?.message}
                                />
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="system"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="system"
                                    select
                                    label="System"
                                    inputRef={ref}
                                    error={Boolean(errors.system)}
                                    helperText={errors.system?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        onChange(event.target.value);
                                        reset((formValues) => ({
                                            ...formValues,
                                            systemModelUuid: "",
                                            acquisitionVectorUuid: "",
                                        }));
                                    }}
                                >
                                    {systemValues.map((system) => (
                                        <MenuItem key={system} value={system}>
                                            {system}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="systemModelUuid"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="systemModelUuid"
                                    select
                                    disabled={!systemWatch}
                                    label="System model"
                                    inputRef={ref}
                                    error={Boolean(errors.systemModelUuid)}
                                    helperText={errors.systemModelUuid?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        onChange(event.target.value);
                                        reset((formValues) => ({
                                            ...formValues,
                                            acquisitionVectorUuid: "",
                                        }));
                                    }}
                                >
                                    {systemModelsData.rows
                                        .filter(
                                            (systemModel) =>
                                                systemModel.system ===
                                                systemWatch
                                        )
                                        .map((systemModel) => (
                                            <MenuItem
                                                key={systemModel.uuid}
                                                value={systemModel.uuid}
                                            >
                                                {systemModel.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={needsFocalLengthInput ? 7 : 12}>
                            <Controller
                                control={control}
                                name="acquisitionVectorUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="acquisitionVectorUuid"
                                        select
                                        disabled={!systemModelUuidWatch}
                                        label="Acquisition Vector"
                                        inputRef={ref}
                                        error={Boolean(
                                            errors.acquisitionVectorUuid
                                        )}
                                        helperText={
                                            errors.acquisitionVectorUuid
                                                ?.message
                                        }
                                    >
                                        {acquisitionVectorsData.rows
                                            .filter(
                                                (acquisitionVector) =>
                                                    acquisitionVector.systemModelUuid ===
                                                    systemModelUuidWatch
                                            )
                                            .map((acquisitionVector) => (
                                                <MenuItem
                                                    key={acquisitionVector.uuid}
                                                    value={
                                                        acquisitionVector.uuid
                                                    }
                                                >
                                                    {acquisitionVector.name} (
                                                    {objectArrayStringify(
                                                        acquisitionVector.SensorBundles,
                                                        ["Sensor", "dataType"]
                                                    )}
                                                    )
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                        {needsFocalLengthInput && (
                            <Grid item xs={5}>
                                <TextField
                                    id="focalLength35mmEqv"
                                    fullWidth
                                    type="number"
                                    label="Focal Length (35mm eqv)"
                                    error={Boolean(errors.focalLength35mmEqv)}
                                    helperText={
                                        errors.focalLength35mmEqv?.message
                                    }
                                    {...register("focalLength35mmEqv", {
                                        min: {
                                            value: 0.01,
                                            message: INPUT_VALIDATION.POSITIVE,
                                        },
                                        required:
                                            INPUT_VALIDATION.REQUIRED_SHORT,
                                        valueAsNumber: true,
                                        shouldUnregister: true,
                                    })}
                                    inputProps={{
                                        step: "0.01",
                                        inputMode: "decimal",
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="status"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="status"
                                    select
                                    label="Status"
                                    inputRef={ref}
                                    error={Boolean(errors.status)}
                                    helperText={errors.status?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        onChange(event.target.value);
                                        reset((formValues) => ({
                                            ...formValues,
                                            subStatus: "",
                                        }));
                                    }}
                                >
                                    {statusValues.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {status}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="subStatus"
                            render={({ field: { ref, ...fieldProps } }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="subStatus"
                                    select
                                    label="Sub Status"
                                    inputRef={ref}
                                    error={Boolean(errors.subStatus)}
                                    helperText={errors.subStatus?.message}
                                >
                                    {getSubStatuses(statusWatch).map(
                                        (subStatus) => (
                                            <MenuItem
                                                key={subStatus}
                                                value={subStatus}
                                            >
                                                {subStatus}
                                            </MenuItem>
                                        )
                                    )}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Close
                </Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </form>
    );
}

const offset = (utcTime, add) => {
    const tzoffset = utcTime.getTimezoneOffset() * 60000;
    const localTime = add
        ? new Date(utcTime.getTime() + tzoffset)
        : new Date(utcTime.getTime() - tzoffset);
    return localTime;
};

const getSubStatuses = (status) => {
    if (status === null) return [];
    if (status === MISSION_STATUS.ABORTED)
        return [
            MISSION_SUB_STATUS.AT_CLIENT_REQUEST,
            MISSION_SUB_STATUS.DATA_QUALITY_ISSUE,
            MISSION_SUB_STATUS.DATA_NOT_PROVIDED,
        ];
    if (status === MISSION_STATUS.DELIVERED)
        return [MISSION_SUB_STATUS.DELIVERED];
    if (status === MISSION_STATUS.ON_HOLD)
        return [
            MISSION_SUB_STATUS.CLIENT_ACTION_REQUIRED,
            MISSION_SUB_STATUS.PLOT_MAP_GENERATION,
        ];
    if (status === MISSION_STATUS.PROCESSING)
        return [MISSION_SUB_STATUS.IN_THE_QUEUE, MISSION_SUB_STATUS.ONGOING];
    return [];
};
