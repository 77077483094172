import { Container } from "@mui/material";
import Page from "../components/Page";
import { SystemModelTable } from "../features/system-model";

export default function SystemModelGlossary() {
    return (
        <Page title="System model glossary | HCC">
            <Container maxWidth="xl">
                <SystemModelTable />
            </Container>
        </Page>
    );
}
