import { geoJSONUtil } from "./geoJSONUtil";
import { transformGeoJsonFromVectors } from "./transformGeoJsonFromVectors";

export function applyTransform(state, toggles, usedTransformModeVectors) {
    const finalTransformModeVectors =
        usedTransformModeVectors ?? state.transformModeVectors;

    const temporaryTransformedGeoJson = transformGeoJsonFromVectors(
        {
            ...state.checkpointGeoJsonData,
            features: state.selectedLayers.length
                ? state.selectedLayers.map((layer) => layer.feature)
                : state.checkpointGeoJsonData.features.filter(
                      (feature) =>
                          !state.lockedLayers.find(
                              (lockedLayer) =>
                                  geoJSONUtil.getIdOfFeature(feature) ===
                                  geoJSONUtil.getIdOfFeature(
                                      lockedLayer.feature
                                  )
                          )
                  ),
        },
        finalTransformModeVectors,
        toggles
    );

    if (state.selectedLayers.length) {
        state.transformedGeoJson.features.forEach((oldFeature) => {
            if (
                !state.selectedLayers.find(
                    (selectedLayer) =>
                        geoJSONUtil.getIdOfFeature(selectedLayer.feature) ===
                        geoJSONUtil.getIdOfFeature(oldFeature)
                )
            )
                temporaryTransformedGeoJson.features.push(oldFeature);
        });
    } else if (state.lockedLayers.length) {
        state.transformedGeoJson.features.forEach((oldFeature) => {
            if (
                state.lockedLayers.find(
                    (lockedLayer) =>
                        geoJSONUtil.getIdOfFeature(lockedLayer.feature) ===
                        geoJSONUtil.getIdOfFeature(oldFeature)
                )
            )
                temporaryTransformedGeoJson.features.push(oldFeature);
        });
    }

    return {
        ...state,
        transformedGeoJson: temporaryTransformedGeoJson,
        transformModeVectors: finalTransformModeVectors,
    };
}
