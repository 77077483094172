import { useMemo, useState } from "react";

import { CARD_HEIGHT } from "../constants";
import { Grid } from "@mui/material";
import { ImageDisplay } from "./ImageDisplay";
import { ImageTransferList } from "./ImageTransferList";
import { MODEL_PROPTYPES } from "../../../models";
import { MetadataMetricsLayout } from "./MetadataMetricsLayout";
import { PipelineTemplateTraitCard } from "./PipelineTemplateTraitCard";
import PropTypes from "prop-types";
import { getPipelineTemplateGsdOfStage } from "../utils";
import { useMetricsValues } from "./useMetricsValues";
import { useSearchParams } from "react-router-dom";

export function AcquisitionReportCardLayout({
    bbchStageData,
    experimentMission,
    rawDataSets,
    pipelineTemplatesData,
    mutateRawDataSets,
}) {
    const [searchParams] = useSearchParams();
    const [transferListImages, setTransferListImages] = useState([]);
    const [includeQuarantine, setIncludeQuarantine] = useState(false);
    const [displayedImage, setDisplayedImage] = useState(null);

    const bbchStageUuid = searchParams.get("bbchStageUuid") || "";
    const pipelineTemplateUuid = searchParams.get("pipelineTemplateUuid") || "";
    const key = `${pipelineTemplateUuid}-${bbchStageUuid}`;

    const selectedPipelineTemplate = pipelineTemplatesData.find(
        (pipelineTemplate) => pipelineTemplate.uuid === pipelineTemplateUuid
    );

    const selectableBbchStages = useMemo(() => {
        const selectableBbchStageUuidSet = new Set(
            selectedPipelineTemplate?.PipelineTemplateTraitGroups.map(
                (PTTG) => PTTG.bbchStageUuid
            )
        );

        return bbchStageData.filter((bbchStage) =>
            selectableBbchStageUuidSet.has(bbchStage.uuid)
        );
    }, [bbchStageData, selectedPipelineTemplate]);

    const datasetsByDataType = useMemo(() => {
        const filteredDatasets = {};

        rawDataSets.forEach((dataset) => {
            const { dataType, quarantine, metadata } = dataset;

            if (!filteredDatasets[dataType]) {
                filteredDatasets[dataType] = [];
            }

            if (includeQuarantine || !quarantine) {
                filteredDatasets[dataType].push({
                    ...dataset,
                    metadata: metadata && {
                        ...metadata,
                        GSD: metadata.GSD && metadata.GSD * 10,
                    },
                });
            }
        });

        return filteredDatasets;
    }, [rawDataSets, includeQuarantine]);

    const requiredGSDByDatatype = useMemo(
        () =>
            selectedPipelineTemplate && bbchStageUuid
                ? Object.keys(datasetsByDataType).reduce(
                      (filteredDatasetsPerDataType, dataType) => {
                          filteredDatasetsPerDataType[dataType] =
                              getPipelineTemplateGsdOfStage(
                                  selectedPipelineTemplate,
                                  bbchStageUuid,
                                  dataType
                              );
                          return filteredDatasetsPerDataType;
                      },
                      {}
                  )
                : {},
        [datasetsByDataType, selectedPipelineTemplate, bbchStageUuid]
    );

    const filteredDataTypes = useMemo(
        () =>
            Object.keys(requiredGSDByDatatype).filter(
                (datatype) => requiredGSDByDatatype[datatype] != null
            ),
        [requiredGSDByDatatype]
    );

    const selectedBbchStage = selectableBbchStages.find(
        (stage) => stage.uuid === bbchStageUuid
    );

    const gaugeValues = useMetricsValues({
        datasetsByDataType,
        filteredDataTypes,
        requiredGSDByDatatype,
        selectedBbchStage,
        selectedPipelineTemplate,
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={4} height={CARD_HEIGHT}>
                <PipelineTemplateTraitCard
                    experimentMission={experimentMission}
                    pipelineTemplatesData={pipelineTemplatesData}
                    pipelineTemplateUuid={pipelineTemplateUuid}
                    bbchStageUuid={bbchStageUuid}
                    requiredGSDByDatatype={requiredGSDByDatatype}
                    selectableBbchStages={selectableBbchStages}
                    datasetsByDataType={datasetsByDataType}
                    selectedPipelineTemplate={selectedPipelineTemplate}
                    gaugeValues={gaugeValues}
                />
            </Grid>
            <Grid item xs height={CARD_HEIGHT}>
                <ImageTransferList
                    mutateRawDataSets={mutateRawDataSets}
                    rawDataSets={rawDataSets}
                    transferListImages={transferListImages}
                    setTransferListImages={setTransferListImages}
                    setDisplayedImage={setDisplayedImage}
                />
            </Grid>
            <Grid item xs height={CARD_HEIGHT}>
                {displayedImage && <ImageDisplay image={displayedImage} />}
            </Grid>

            {gaugeValues && (
                <MetadataMetricsLayout
                    key={key}
                    datasetsByDataType={datasetsByDataType}
                    filteredDataTypes={filteredDataTypes}
                    gaugeValues={gaugeValues}
                    includeQuarantine={includeQuarantine}
                    requiredGSDByDatatype={requiredGSDByDatatype}
                    transferListImages={transferListImages}
                    setIncludeQuarantine={setIncludeQuarantine}
                    setTransferListImages={setTransferListImages}
                />
            )}
        </Grid>
    );
}

AcquisitionReportCardLayout.propTypes = {
    bbchStageData: PropTypes.arrayOf(PropTypes.shape(MODEL_PROPTYPES.BBCHStage))
        .isRequired,
    rawDataSets: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    pipelineTemplatesData: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.PipelineTemplate).isRequired
    ).isRequired,
    experimentMission: PropTypes.shape(MODEL_PROPTYPES.ExperimentMission),
    mutateRawDataSets: PropTypes.func.isRequired,
};
