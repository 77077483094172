import { DYNAMIC_FEATURE_ID_FIELD_NAME } from "./constants";
import { PARCELLAIRE_REDUCER_ACTIONS } from "../parcellaireReducer";
import { geoJSONUtil } from "./geoJSONUtil";

export function readGeoJson(
    geoJsonFile,
    parcellaireDispatch,
    mapRef,
    setValue,
    openSnackbar,
    isBlueRectangle
) {
    if (geoJsonFile) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const geoJson = JSON.parse(event.target.result);

            if (Array.isArray(geoJson.features)) {
                // Dynamically add an unique id to each feature
                // The added field name is [DYNAMIC_FEATURE_ID_FIELD_NAME] which should not cause conflict with
                // existing field names.
                let missingGeometry = false;
                geoJson.features.forEach((feature, index) => {
                    if (!feature.geometry) missingGeometry = true;

                    if (!feature.properties) feature.properties = {};
                    feature.properties[DYNAMIC_FEATURE_ID_FIELD_NAME] =
                        `${index}`;
                });

                if (missingGeometry) {
                    openSnackbar(
                        "Invalid GeoJson: missing geometry for some feature(s).",
                        "error"
                    );
                } else {
                    isBlueRectangle
                        ? setValue("blueRectangle", geoJson)
                        : replaceMapDataGeoJson(
                              parcellaireDispatch,
                              geoJson,
                              mapRef
                          );
                }
            } else
                openSnackbar(
                    "The GeoJson must have an array of features.",
                    "error"
                );
        };

        reader.readAsText(geoJsonFile);
    }
}

export function replaceMapDataGeoJson(
    parcellaireDispatch,
    parcellaire,
    mapRef
) {
    parcellaireDispatch({
        type: PARCELLAIRE_REDUCER_ACTIONS.GEOJSON,
        geoJson: parcellaire,
    });

    const bounds = geoJSONUtil.getBoundsOfFeatures(parcellaire.features);

    if (bounds) {
        bounds.forEach((coord) => coord.reverse());
        mapRef.current?.fitBounds(bounds);
    }
}

export function readMetadata(event, parcellaireDispatch, setGeoJsonKey) {
    const metadataFile = event.target.files[0];

    if (metadataFile) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const jsonData = JSON.parse(event.target.result);

            if (jsonData?.site?.id && jsonData?.date) {
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.METADATA,
                    metadata: jsonData,
                });
                // We need to rerender the GeoJson layers so they have the latest
                // parcellaireState in their event handlers
                setGeoJsonKey(new Date().getTime());
            }
            // TODO: use snackbar instead of alert
            else alert("Seems not a valid session-metadata.json");
        };

        reader.readAsText(metadataFile);
    }
}

export function readCSVToArray(csvFile, setValue) {
    if (csvFile) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const strData = event.target.result;

            let tupleDelimiter = null;
            for (let i = 0; tupleDelimiter === null; i++) {
                if (strData[i] === "," || strData[i] === ";")
                    tupleDelimiter = strData[i];
                if (i + 1 === strData.length) tupleDelimiter = ",";
            }

            let lineDelimiter = null;
            for (let i = 0; lineDelimiter === null; i++) {
                if (
                    strData[i] === "\n" ||
                    strData[i] === "\r" ||
                    strData[i] === "\r\n"
                )
                    lineDelimiter = strData[i];
                else if (i + 1 === strData.length) lineDelimiter = "\n";
            }

            const arrData = strData.split(lineDelimiter);
            const csvResult = arrData.map((line) => line.split(tupleDelimiter));

            setValue("csvData", csvResult);
        };

        reader.readAsText(csvFile);
    }
}

// For now, only geoJson with an array of features is considered
// (since this should be the case when importing).
export function removeToolId(geoJson) {
    if (Array.isArray(geoJson?.features)) {
        const clonedGeoJson = geoJSONUtil.cloneGeoJSON(geoJson);

        clonedGeoJson.features.forEach((feature) => {
            delete feature.properties[DYNAMIC_FEATURE_ID_FIELD_NAME];
        });

        return clonedGeoJson;
    } else {
        return geoJson;
    }
}
