import { Container } from "@mui/material";
import Page from "../components/Page";

export default function ProcessingTrafficPage({ children }) {
    return (
        <Page title="Processing Traffic | HCC">
            <Container maxWidth="xl">{children}</Container>
        </Page>
    );
}
