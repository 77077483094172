import { ToggleButton, Tooltip } from "@mui/material";

import { INSERT_IMAGE_COMMAND } from "../image";
import ImageIcon from "@mui/icons-material/Image";
import { styled } from "@mui/material/styles";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export const ImageInsert = () => {
    const [editor] = useLexicalComposerContext();
    const loadImage = (event) => {
        const files = event.target.files;
        const reader = new FileReader();
        reader.onload = function () {
            if (typeof reader.result === "string") {
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                    src: reader.result,
                });
            }
            return "";
        };
        if (files && files.length > 0) {
            reader.readAsDataURL(files[0]);
        }
    };
    return (
        <Tooltip title="Upload image">
            <ToggleButton
                aria-label="upload-image"
                role={undefined}
                component="label"
                tabIndex={-1}
                value="upload-image"
            >
                <ImageIcon />
                <VisuallyHiddenInput type="file" onChange={loadImage} />
            </ToggleButton>
        </Tooltip>
    );
};
