import {
    Divider,
    Grid,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { FLIGHT_SEQUENCE_REGEX, INPUT_VALIDATION } from "../../../constants";

import { BBCHStageTooltip } from "../../../components/BBCHStageTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { PipelineTemplateBBCHRow } from ".";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";

PipelineTemplateBBCHForm.propTypes = {
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.any.isRequired,
    bbchStageData: PropTypes.object.isRequired,
    plantScaleData: PropTypes.object.isRequired,
    traitGroupDataTypeWatch: PropTypes.array.isRequired,
};

export function PipelineTemplateBBCHForm({
    control,
    register,
    errors,
    bbchStageData,
    plantScaleData,
    traitGroupDataTypeWatch,
}) {
    const cropUuidWatch = useWatch({
        name: "cropUuid",
        control,
    });

    const filteredPlantScaleData = useMemo(
        () =>
            plantScaleData.rows.filter(
                (plantScale) => plantScale.cropUuid === cropUuidWatch
            ),
        [cropUuidWatch, plantScaleData]
    );

    return (
        <Grid item xs={12} container spacing={1} alignContent="center">
            <Grid item xs={12}>
                <Typography>Order the traits per phenological stage</Typography>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontStyle: "italic", color: "grey" }}>
                    Acquisition timing
                </Typography>
                <Divider />
            </Grid>

            <Grid item xs={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>BBCH phenological stages</Typography>
                    <BBCHStageTooltip bbchStages={bbchStageData.rows} />
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    {bbchStageData.rows.map((bbchStage) => (
                        <Typography key={bbchStage.uuid}>
                            {bbchStage.stage}
                        </Typography>
                    ))}
                </Stack>
            </Grid>

            <Grid item xs={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Flight sequence</Typography>
                    <FlightSequenceTooltip />
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    {bbchStageData.rows.map((bbchStage) => (
                        <TextField
                            key={bbchStage.uuid}
                            fullWidth
                            error={Boolean(
                                errors.flightSequences?.[bbchStage.uuid]
                            )}
                            helperText={
                                errors.flightSequences?.[bbchStage.uuid]
                                    ?.message
                            }
                            {...register(`flightSequences.${bbchStage.uuid}`, {
                                pattern: {
                                    value: FLIGHT_SEQUENCE_REGEX,
                                    message: INPUT_VALIDATION.INVALID_SHORT,
                                },
                            })}
                        />
                    ))}
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Typography sx={{ fontStyle: "italic", color: "grey" }}>
                    Selected traits
                </Typography>
                <Divider />
            </Grid>
            {traitGroupDataTypeWatch.length > 0 ? (
                traitGroupDataTypeWatch.map((TGDT) => (
                    <PipelineTemplateBBCHRow
                        control={control}
                        bbchStageData={bbchStageData}
                        filteredPlantScaleData={filteredPlantScaleData}
                        traitGroupDataType={TGDT}
                        key={TGDT.uuid}
                    />
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontStyle: "italic",
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        Please select at least one trait.
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

function FlightSequenceTooltip() {
    return (
        <Tooltip
            title={
                <Stack>
                    <Typography>Must be a number or a number range.</Typography>

                    <Typography>Examples:</Typography>
                    <Typography sx={{ fontStyle: "italic" }}>- "2"</Typography>
                    <Typography sx={{ fontStyle: "italic" }}>
                        - "4-7"
                    </Typography>
                </Stack>
            }
        >
            <InfoIcon fontSize="small" sx={{ color: "grey.500" }} />
        </Tooltip>
    );
}
