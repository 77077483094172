import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
} from "@mui/material";
import {
    PipelineTemplateBBCHForm,
    PipelineTemplateCalendarForm,
    PipelineTemplateDataForm,
    PipelineTemplateTraitForm,
    useDefaultValues,
} from ".";
import { useFetch, useSnackbar } from "../../../hooks";
import { useMemo, useState } from "react";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { LoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

PipelineTemplateForm.propTypes = {
    pipelineTemplate: PropTypes.object,
    isCreation: PropTypes.bool.isRequired,
    contract: PropTypes.object.isRequired,
    cropsData: PropTypes.object.isRequired,
    systemModelsData: PropTypes.object.isRequired,
    acquisitionVectorsData: PropTypes.object.isRequired,
    traitGroupDataTypeData: PropTypes.object.isRequired,
    bbchStageData: PropTypes.object.isRequired,
    plantScaleData: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    setIsOpenErrorDialog: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    mutateTable: PropTypes.func.isRequired,
};

/**
 * If isCreation true and no pipelineTemplate: we are creating a new pipeline template.
 * If isCreation true and pipelineTemplate provided: we are cloning an existing pipeline template.
 * If isCreation false: pipelineTemplate must be provided and we are modifying an existing pipeline template.
 */
export function PipelineTemplateForm({
    pipelineTemplate,
    isCreation,
    contract,
    cropsData,
    systemModelsData,
    acquisitionVectorsData,
    traitGroupDataTypeData,
    bbchStageData,
    plantScaleData,
    closeModal,
    setIsOpenErrorDialog,
    mutateTable,
    mutate,
}) {
    // If isCreation false, pipelineTemplate must be provided
    if (!isCreation && !pipelineTemplate)
        throw new TypeError(
            "pipelineTemplate must be provided if isCreation is false"
        );

    const [isOpenCalendarForm, setIsOpenCalendarForm] = useState(false);

    const { openSnackbar } = useSnackbar();

    const defaultValues = useDefaultValues({
        pipelineTemplate,
        isCreation,
        traitGroupDataTypeData,
        bbchStageData,
        contract,
    });

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues,
    });

    const [
        acquisitionVectorUuidWatch,
        canopyWatch,
        biomassWatch,
        trialWatch,
        harvestWatch,
        plantWatch,
    ] = watch([
        "acquisitionVectorUuid",
        "canopyDevelopment",
        "biomassProxy",
        "trialQuality",
        "harvestIndex",
        "plantStress",
    ]);

    const traitGroupDataTypeWatch = useMemo(
        () =>
            [
                canopyWatch,
                biomassWatch,
                trialWatch,
                harvestWatch,
                plantWatch,
            ].flat(),
        [canopyWatch, biomassWatch, trialWatch, harvestWatch, plantWatch]
    );

    const { post, patch } = useFetch();

    const selectedAcquisitionVector = acquisitionVectorsData.rows.find(
        (acquisitionVector) =>
            acquisitionVector.uuid === acquisitionVectorUuidWatch
    );

    const onSubmit = async (payload) => {
        const hasNoFocalLength = selectedAcquisitionVector.SensorBundles.some(
            (bundle) => {
                return !bundle.Sensor.focalLength35mmEqv;
            }
        );

        const filteredBbchTraits = Object.keys(payload.bbchTraits).filter(
            (traitGroupDataTypeUuid) =>
                Boolean(payload.bbchTraits[traitGroupDataTypeUuid].length)
        );
        if (filteredBbchTraits.length === 0) {
            setIsOpenErrorDialog(true);
        } else {
            const bbchTraitArray = filteredBbchTraits.map(
                (traitGroupDataTypeUuid) => ({
                    traitGroupDataTypeUuid: traitGroupDataTypeUuid,
                    bbchStageUuids: payload.bbchTraits[traitGroupDataTypeUuid],
                })
            );

            const formattedCalendars = payload.calendars.map((calendar) => ({
                count: calendar.count,
                year: parseInt(calendar.date.substring(0, 4), 10),
                month: parseInt(calendar.date.substring(5, 7), 10),
            }));

            const formattedFlightSequences = Object.entries(
                payload.flightSequences
            ).map((pair) => ({ bbchStageUuid: pair[0], sequence: pair[1] }));

            const completePayload = {
                ...payload,
                focalLength35mmEqv: hasNoFocalLength
                    ? payload.focalLength35mmEqv
                    : null, // will be null if the acquisition vector already has a focal length (because you can't input a value in that case)
                contractUuid: contract.uuid,
                bbchTraits: bbchTraitArray,
                calendars: formattedCalendars,
                flightSequences: formattedFlightSequences,
            };

            const updatedPipelineTemplate = isCreation
                ? await post(BACKEND_ROUTES.PIPELINE_TEMPLATE, {
                      body: completePayload,
                  })
                : await patch(
                      `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${pipelineTemplate.uuid}`,
                      {
                          body: completePayload,
                      }
                  );
            if (updatedPipelineTemplate) {
                // two mutates because it caused issues when updating a pipeline and then instantly reopening it
                mutate();
                mutateTable();
                closeModal();
                openSnackbar(
                    `Template ${updatedPipelineTemplate.name} ${
                        isCreation ? "created" : "updated"
                    } successfully.`,
                    "success"
                );
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <PipelineTemplateDataForm
                            control={control}
                            setIsOpenCalendarForm={setIsOpenCalendarForm}
                            systemModelsData={systemModelsData}
                            acquisitionVectorsData={acquisitionVectorsData}
                            cropsData={cropsData}
                            selectedAcquisitionVector={
                                selectedAcquisitionVector
                            }
                            errors={errors}
                            register={register}
                            reset={reset}
                        />
                        <Grid container item xs={12} spacing={3}>
                            <PipelineTemplateTraitForm
                                control={control}
                                traitGroupDataTypeData={traitGroupDataTypeData}
                                selectedAcquisitionVector={
                                    selectedAcquisitionVector
                                }
                            />
                            <PipelineTemplateBBCHForm
                                control={control}
                                register={register}
                                errors={errors}
                                bbchStageData={bbchStageData}
                                plantScaleData={plantScaleData}
                                traitGroupDataTypeWatch={
                                    traitGroupDataTypeWatch
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={closeModal}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        {isCreation ? "Create" : "Update"}
                    </LoadingButton>
                </DialogActions>
            </form>
            <Dialog open={isOpenCalendarForm}>
                <PipelineTemplateCalendarForm
                    closeModal={() => setIsOpenCalendarForm(false)}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                />
            </Dialog>
        </>
    );
}
