import { FetchContext } from "../context/fetch/FetchContext";
import { useContext } from "react";

/**
 * @returns {{get: fetchCallback, post: fetchCallback, put: fetchCallback, patch: fetchCallback, destroy: fetchCallback}}
 */
export const useFetch = () => useContext(FetchContext);

/**
 * fetcher function
 * @callback fetchCallback
 * @param {string} url
 * @param {RequestInit} init - `headers` and `method` props cannot be overridden
 * @returns {Promise<any>}
 */
