import { CUSTOM_DRAWER_WIDTH } from "../../../constants";
import { Container } from "@mui/material";
import CustomDrawer from "../../../components/CustomDrawer";
import { VisualisationSection } from "./VisualisationSection";
import { useState } from "react";

export default function VisualisationDrawer({
    evaluationEntities,
    setEvaluationEntities,
    quarantinedDatasets,
    mutateMission,
}) {
    const [drawerWidth, setDrawerWidth] = useState(CUSTOM_DRAWER_WIDTH.DEFAULT);

    return (
        <CustomDrawer
            drawerWidth={drawerWidth}
            setDrawerWidth={setDrawerWidth}
            drawerWidthOptions={CUSTOM_DRAWER_WIDTH}
        >
            <Container maxWidth="xl">
                <VisualisationSection
                    evaluationEntities={evaluationEntities}
                    setEvaluationEntities={setEvaluationEntities}
                    quarantinedDatasets={quarantinedDatasets}
                    mutateMission={mutateMission}
                    width={drawerWidth - 40}
                />
            </Container>
        </CustomDrawer>
    );
}
