import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Stack,
    Typography,
    styled,
} from "@mui/material";
import { CUSTOM_DRAWER_WIDTH, RAW_DATASET_TYPES } from "../../../constants";
import { ClientInfoGrid, SensorInfoGrid } from "..";
import { useCallback, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { AcquisitionReportAltitudeCard } from "./AcquisitionReportAltitudeCard";
import { AcquisitionReportFormButton } from "../acquisition-report-form";
import { AdvancedAcquisitionReportSkeleton } from "./AdvancedAcquisitionReportSkeleton";
import { BACKEND_ROUTES } from "../../../backendRoutes";
import { BasicKpiTable } from "./BasicKpiTable";
import { BlurKpiTable } from "./BlurKpiTable";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { GraphActionTooltip } from "./GraphActionTooltip";
import { LinePlot } from "./LinePlot";
import { PipelineAssociation } from "../pipeline-association/PipelineAssociation";
import VisualisationDrawer from "./VisualisationDrawer";
import { getPipelineTemplateGsdOfStage } from "../utils";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const altitudeVariationThreshold = 2;
const saturationThreshold = 0.5;
const exposureTimeThreshold = 4;
const blurScoreThreshold = 0.33;
const blurPercentageThreshold = 5;

const Main = styled("main")(() => ({
    marginRight: CUSTOM_DRAWER_WIDTH.DEFAULT,
}));

/**
 * An acquisition report is based on a mission.
 * If the optional experimentMission is provided, it must be of the same mission of the mission provided,
 * in which case the Associated pipeline section is "locked" to the provided pipeline.
 */
export function AdvancedAcquisitionReport({ experimentMission }) {
    const { missionUuid } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        data: mission,
        error: missionFetchError,
        mutate,
    } = useSWR(
        `${BACKEND_ROUTES.MISSION}/${missionUuid}?parentInfo=true&acquisitionVector=true&rawDatasets=true&rawDatasetStatistics=true&acquisitionReport=true`
    );

    const { data: acquisitionVector, error: acquisitionVectorFetchError } =
        useSWR(
            mission
                ? `${BACKEND_ROUTES.ACQUISITION_VECTOR}/${mission.acquisitionVectorUuid}?sensor=true&systemModel=true`
                : null
        );

    const associationLocked = Boolean(experimentMission);

    const { data: pipelineTemplatesData, error: pipelineTemplatesFetchError } =
        useSWR(
            experimentMission
                ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${experimentMission.pipelineTemplateUuid}?traitGroups=true&parentInfo=true&sop=true`
                : mission
                  ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}?contractUuid=${mission.Site.contractUuid}&traitGroups=true&parentInfo=true&sop=true`
                  : null
        );

    const { data: bbchStageData, error: bbchStageFetchError } = useSWRImmutable(
        BACKEND_ROUTES.BBCH_STAGE
    );

    const { data: rawDatasetOverlap, error: rawDatasetOverlapFetchError } =
        useSWR(
            mission
                ? `${BACKEND_ROUTES.RAW_DATASET}?missionUuid=${mission.uuid}&type=${RAW_DATASET_TYPES.OVERLAP_GRAPH}&sort=-createdAt`
                : null
        );

    const pipelineTemplateUuid =
        experimentMission?.pipelineTemplateUuid ||
        searchParams.get("pipelineTemplateUuid") ||
        "";
    const bbchStageUuid =
        experimentMission?.bbchStageUuid ||
        searchParams.get("bbchStageUuid") ||
        "";

    const handlePipelineTemplateChange = (newUuid) => {
        setSearchParams((prevSearchParams) => {
            const params = new URLSearchParams(prevSearchParams);

            if (newUuid) {
                params.set("pipelineTemplateUuid", newUuid);
            } else {
                params.delete("pipelineTemplateUuid");
            }

            return params;
        });
    };

    const handleBbchStageChange = (newUuid) => {
        setSearchParams((prevSearchParams) => {
            const params = new URLSearchParams(prevSearchParams);

            if (newUuid) {
                params.set("bbchStageUuid", newUuid);
            } else {
                params.delete("bbchStageUuid");
            }

            return params;
        });
    };

    const [evaluationEntities, setEvaluationEntities] = useState([]); // List of entities selected on the graphs

    const [uirevision] = useState(Math.random());

    const { data: overlapGraphSignedUrl, overlapGraphSignedUrlFetchError } =
        useSWR(
            rawDatasetOverlap?.count > 0
                ? `${BACKEND_ROUTES.PUBLIC_SIGNED_URL}?key=${rawDatasetOverlap.rows[0].s3Location}`
                : null
        );

    // Filter datasets by "quarantine" and "type", and sort them by datetime
    const filteredDatasets = useMemo(() => {
        return mission
            ? mission.RawDatasets.filter((dataset) => {
                  return (
                      dataset.quarantine !== true &&
                      dataset.type === RAW_DATASET_TYPES.ACQUISITION_PICTURE
                  );
              }).sort((a, b) =>
                  a.metadata?.datetime.localeCompare(b.metadata?.datetime)
              )
            : [];
    }, [mission]);

    const quarantinedDatasets = useMemo(() => {
        return mission
            ? mission.RawDatasets.filter((dataset) => {
                  return (
                      dataset.quarantine === true &&
                      dataset.type === RAW_DATASET_TYPES.ACQUISITION_PICTURE
                  );
              }).sort((a, b) =>
                  a.metadata?.datetime.localeCompare(b.metadata?.datetime)
              )
            : [];
    }, [mission]);

    // TODO: all data might not be available for every acquisition report

    // When channel is null, use dataType as the channel.
    const allChannels = useMemo(
        () => [
            ...new Set(
                filteredDatasets.map(
                    (dataset) => dataset.channel ?? dataset.dataType
                )
            ),
        ],
        [filteredDatasets]
    );

    const filteredDatasetsPerDataType = useMemo(
        () =>
            filteredDatasets.reduce((previous, dataset) => {
                if (!previous[dataset.dataType])
                    previous[dataset.dataType] = {
                        datasets: [],
                        altitudesAGL: [],
                        altitudesMSL: [],
                        gsds: [],
                        datetimes: [],
                    };
                previous[dataset.dataType] = {
                    datasets: [
                        ...previous[dataset.dataType].datasets,
                        {
                            uuid: dataset.uuid,
                            name: dataset.name,
                            s3Location: dataset.s3Location,
                        },
                    ],
                    altitudesAGL: [
                        ...previous[dataset.dataType].altitudesAGL,
                        dataset.metadata?.altitude_agl,
                    ],
                    altitudesMSL: [
                        ...previous[dataset.dataType].altitudesMSL,
                        dataset.metadata?.altitude_msl,
                    ],
                    gsds: [
                        ...previous[dataset.dataType].gsds,
                        dataset.metadata?.GSD
                            ? dataset.metadata.GSD * 10
                            : null,
                    ],
                    datetimes: [
                        ...previous[dataset.dataType].datetimes,
                        dataset.metadata?.datetime,
                    ],
                };
                return previous;
            }, {}),
        [filteredDatasets]
    );

    const allDatatypes = useMemo(
        () => Object.keys(filteredDatasetsPerDataType),
        [filteredDatasetsPerDataType]
    );

    const filteredDatasetsPerChannel = useMemo(
        () =>
            filteredDatasets.reduce((previous, dataset) => {
                const usedChannel = dataset.channel
                    ? dataset.channel
                    : dataset.dataType;
                if (!previous[usedChannel])
                    previous[usedChannel] = {
                        datasets: [],
                        saturations: [],
                        exposureTimes: [],
                        blurScores: [],
                        datetimes: [],
                    };
                previous[usedChannel] = {
                    datasets: [
                        ...previous[usedChannel].datasets,
                        {
                            uuid: dataset.uuid,
                            name: dataset.name,
                            s3Location: dataset.s3Location,
                        },
                    ],
                    saturations: [
                        ...previous[usedChannel].saturations,
                        dataset.metadata?.saturation,
                    ],
                    exposureTimes: [
                        ...previous[usedChannel].exposureTimes,
                        dataset.metadata?.exposure_time,
                    ],
                    blurScores: [
                        ...previous[usedChannel].blurScores,
                        dataset.metadata?.blur,
                    ],
                    datetimes: [
                        ...previous[usedChannel].datetimes,
                        dataset.metadata?.datetime,
                    ],
                };
                return previous;
            }, {}),
        [filteredDatasets]
    );

    const onSelected = useCallback((newlySelectedEntities) => {
        // In case of an "unselection", newlySelectedEntities is undefined, in which case we do not update evaluation entities.
        // newlySelectedEntities can be an empty array because of an empty selection (and because of a plotly.js bug), in which case we do nothing.
        if (newlySelectedEntities?.length)
            setEvaluationEntities((prevState) => {
                const selectedEntitiesMap = new Map();
                prevState.concat(newlySelectedEntities).forEach((entity) => {
                    selectedEntitiesMap.set(entity.uuid, entity);
                });

                return [...selectedEntitiesMap.values()].sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
            });
    }, []);

    const selectablePipelineTemplates = pipelineTemplatesData
        ? experimentMission
            ? [pipelineTemplatesData]
            : pipelineTemplatesData.rows
        : [];

    const selectedPipelineTemplate = selectablePipelineTemplates.find(
        (pipelineTemplate) => pipelineTemplate.uuid === pipelineTemplateUuid
    );

    const requiredGSD = useMemo(
        () =>
            selectedPipelineTemplate && bbchStageUuid
                ? allDatatypes.reduce((previous, dataType) => {
                      previous[dataType] = getPipelineTemplateGsdOfStage(
                          selectedPipelineTemplate,
                          bbchStageUuid,
                          dataType
                      );
                      return previous;
                  }, {})
                : {},
        [allDatatypes, bbchStageUuid, selectedPipelineTemplate]
    );

    const mergedFetchError =
        pipelineTemplatesFetchError ??
        bbchStageFetchError ??
        acquisitionVectorFetchError ??
        rawDatasetOverlapFetchError ??
        overlapGraphSignedUrlFetchError ??
        missionFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (
        !acquisitionVector ||
        !pipelineTemplatesData ||
        !bbchStageData ||
        !rawDatasetOverlap ||
        (Boolean(rawDatasetOverlap.rows[0]?.s3Location) &&
            !overlapGraphSignedUrl)
    )
        return <AdvancedAcquisitionReportSkeleton />;

    if (!mission.RawDatasets.length)
        return <Typography>No datasets found.</Typography>;

    const gsdMax = {};
    Object.keys(filteredDatasetsPerDataType).forEach((dataType) => {
        if (
            filteredDatasetsPerDataType[dataType].gsds.some(
                (gsd) => gsd === null
            )
        )
            gsdMax[dataType] = NaN;
        else
            gsdMax[dataType] = Math.max(
                ...filteredDatasetsPerDataType[dataType].gsds
            );
    });

    const selectableBbchStageUuidSet = new Set(
        selectedPipelineTemplate?.PipelineTemplateTraitGroups.map(
            (PTTG) => PTTG.bbchStageUuid
        )
    );

    const selectableBbchStages = bbchStageData.rows.filter((bbchStage) =>
        selectableBbchStageUuidSet.has(bbchStage.uuid)
    );

    return (
        <>
            <AcquisitionReportFormButton
                fabStyle={{
                    right: CUSTOM_DRAWER_WIDTH.DEFAULT + 50,
                    position: "absolute",
                    top: 65,
                }}
            />
            <Main>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                        <Card sx={{ width: 1 }}>
                            <CardHeader title="Client information" />
                            <CardContent>
                                <ClientInfoGrid mission={mission} />
                            </CardContent>
                        </Card>
                        <Card sx={{ width: 1 }}>
                            <CardHeader title="Sensor information" />
                            <CardContent>
                                <SensorInfoGrid
                                    acquisitionVector={acquisitionVector}
                                    focalLength35mmEqv={
                                        mission.focalLength35mmEqv
                                    }
                                />
                            </CardContent>
                        </Card>
                    </Stack>

                    <PipelineAssociation
                        associationLocked={associationLocked}
                        selectablePipelineTemplates={
                            selectablePipelineTemplates
                        }
                        pipelineTemplateUuid={pipelineTemplateUuid}
                        setPipelineTemplateUuid={handlePipelineTemplateChange}
                        selectableBbchStages={selectableBbchStages}
                        bbchStageUuid={bbchStageUuid}
                        setBbchStageUuid={handleBbchStageChange}
                        mission={mission}
                        gsdMax={gsdMax}
                    />

                    <Card>
                        <CardHeader title="Quality KPIs" />
                        <CardContent>
                            <Stack direction="column" spacing={1}>
                                <BasicKpiTable
                                    filteredDatasetsPerDataType={
                                        filteredDatasetsPerDataType
                                    }
                                    filteredDatasetsPerChannel={
                                        filteredDatasetsPerChannel
                                    }
                                    requiredGSD={requiredGSD}
                                    saturationThreshold={saturationThreshold}
                                    exposureTimeThreshold={
                                        exposureTimeThreshold
                                    }
                                />

                                <BlurKpiTable
                                    filteredDatasetsPerChannel={
                                        filteredDatasetsPerChannel
                                    }
                                    blurScoreThreshold={blurScoreThreshold}
                                    blurPercentageThreshold={
                                        blurPercentageThreshold
                                    }
                                />
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader title="Images overlapping" />
                        <CardContent
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            {overlapGraphSignedUrl ? (
                                <Box sx={{ width: 0.75 }}>
                                    <img
                                        src={overlapGraphSignedUrl}
                                        alt="Overlapping Colormap"
                                    />
                                </Box>
                            ) : (
                                <Box sx={{ width: 0.95 }}>
                                    <Typography sx={{ color: "error.main" }}>
                                        Not found
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>

                    {allDatatypes.map((dataType) => (
                        <AcquisitionReportAltitudeCard
                            filteredDatasetsPerDataType={
                                filteredDatasetsPerDataType
                            }
                            dataType={dataType}
                            altitudeVariationThreshold={
                                altitudeVariationThreshold
                            }
                            onSelected={onSelected}
                            uirevision={uirevision}
                            key={dataType}
                        />
                    ))}

                    {allChannels.map((channel) => (
                        <Card key={channel}>
                            <CardHeader
                                title={
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <span>{`Saturation (%) (${channel})`}</span>
                                        <GraphActionTooltip />
                                    </Stack>
                                }
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        position: "relative",
                                        width: 1,
                                        height: "400px",
                                    }}
                                >
                                    <LinePlot
                                        xValues={
                                            filteredDatasetsPerChannel[channel]
                                                .datetimes
                                        }
                                        yValues={
                                            filteredDatasetsPerChannel[channel]
                                                .saturations
                                        }
                                        threshold={saturationThreshold}
                                        showMeanLine={true}
                                        onSelected={onSelected}
                                        entities={
                                            filteredDatasetsPerChannel[channel]
                                                .datasets
                                        }
                                        uirevision={uirevision}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    ))}

                    {allChannels.map((channel) => (
                        <Card key={channel}>
                            <CardHeader
                                title={
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <span>{`Exposure time (ms) (${channel})`}</span>
                                        <GraphActionTooltip />
                                    </Stack>
                                }
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        position: "relative",
                                        width: 1,
                                        height: "400px",
                                    }}
                                >
                                    <LinePlot
                                        xValues={
                                            filteredDatasetsPerChannel[channel]
                                                .datetimes
                                        }
                                        yValues={
                                            filteredDatasetsPerChannel[channel]
                                                .exposureTimes
                                        }
                                        threshold={exposureTimeThreshold}
                                        showMeanLine={true}
                                        onSelected={onSelected}
                                        entities={
                                            filteredDatasetsPerChannel[channel]
                                                .datasets
                                        }
                                        uirevision={uirevision}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    ))}

                    {allChannels.map((channel) => (
                        <Card key={channel}>
                            <CardHeader
                                title={
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <span>{`Blur score (${channel})`}</span>
                                        <GraphActionTooltip />
                                    </Stack>
                                }
                            />
                            <CardContent>
                                <Box
                                    sx={{
                                        position: "relative",
                                        width: 1,
                                        height: "400px",
                                    }}
                                >
                                    <LinePlot
                                        xValues={
                                            filteredDatasetsPerChannel[channel]
                                                .datetimes
                                        }
                                        yValues={
                                            filteredDatasetsPerChannel[channel]
                                                .blurScores
                                        }
                                        threshold={blurScoreThreshold}
                                        showMeanLine={true}
                                        onSelected={onSelected}
                                        entities={
                                            filteredDatasetsPerChannel[channel]
                                                .datasets
                                        }
                                        uirevision={uirevision}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            </Main>

            <VisualisationDrawer
                evaluationEntities={evaluationEntities}
                setEvaluationEntities={setEvaluationEntities}
                quarantinedDatasets={quarantinedDatasets}
                mutateMission={mutate}
            />
        </>
    );
}
