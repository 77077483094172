import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

import PropTypes from "prop-types";

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    contentText: PropTypes.node,
};

export function ConfirmModal({
    isOpen,
    onCancel,
    onConfirm,
    title,
    contentText,
}) {
    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                {title ?? "Are you sure you want to leave?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {contentText ?? "Your modifications will not be saved."}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={onCancel}>
                    No
                </Button>
                <Button type="button" variant="contained" onClick={onConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
