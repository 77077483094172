import { Breadcrumbs, Fab, Stack, Typography } from "@mui/material";

import { BreadcrumbChip } from "../../components/BreadcrumbChip";
import { FilteringDrawer } from "./drawer";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { MODEL_PROPTYPES } from "../../models";
import PropTypes from "prop-types";
import { formatDateISO } from "../../utils/formatTime";
import { useState } from "react";

export const ValidationPage = ({ experimentMission, pipeline }) => {
    const [open, setOpen] = useState(false);

    return (
        <Stack direction="column" spacing={3} sx={{ pb: 1 }}>
            <Breadcrumbs separator="/" mb={1}>
                <BreadcrumbChip
                    label={
                        experimentMission.Experiment.Site.Contract.Company.name
                    }
                />
                <BreadcrumbChip
                    label={experimentMission.Experiment.Site.Contract.name}
                />
                <BreadcrumbChip
                    label={experimentMission.Experiment.Site.name}
                />
                <BreadcrumbChip label={experimentMission.Experiment.name} />
                <BreadcrumbChip
                    label={formatDateISO(experimentMission.Mission.date)}
                />
            </Breadcrumbs>

            <Typography variant="h4" gutterBottom>
                {pipeline.ValidationPage?.name}
            </Typography>

            <FilteringDrawer
                closeDrawer={() => setOpen(false)}
                experimentUuid={experimentMission.experimentUuid}
                open={open}
                traitGroupDataTypeUuid={pipeline.traitGroupDataTypeUuid}
            />
            <Fab
                size="small"
                color="primary"
                sx={(theme) => ({
                    position: "fixed",
                    right:
                        drawerRightOffset +
                        (open ? drawerWidth - drawerRightOffset - 20 : 0),
                    top: 50,
                    zIndex: theme.zIndex.drawer,
                    transition: `${theme.transitions.create(["right"], {
                        duration: theme.transitions.duration.leavingScreen,
                    })}`,
                })}
                onClick={() => setOpen(!open)}
            >
                {open ? <LastPageIcon /> : <FirstPageIcon />}
            </Fab>
        </Stack>
    );
};

const drawerWidth = 540;
const drawerRightOffset = 32;

ValidationPage.propTypes = {
    experimentMission: PropTypes.shape(MODEL_PROPTYPES.ExperimentMission)
        .isRequired,
    pipeline: PropTypes.shape(MODEL_PROPTYPES.Pipeline).isRequired,
};
