export const CAN_USE_DOM =
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined";

export const IS_APPLE =
    CAN_USE_DOM && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

export const blockTypeToBlockName = {
    bullet: "Bulleted List",
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    number: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
};
