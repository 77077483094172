import { Box, Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";

import { ALLOWED_FACTOR_BELOW_MINIMUM_GSD } from "../../../constants";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";

PipelineTemplateTraitList.propTypes = {
    dataType: PropTypes.string.isRequired,
    traitGroupDataTypes: PropTypes.array.isRequired,
    requiredGSDByDatatype: PropTypes.object.isRequired,
    pipelineTemplate: PropTypes.object.isRequired,
    acquisitionGsdMax: PropTypes.number,
};

export function PipelineTemplateTraitList({
    dataType,
    traitGroupDataTypes,
    requiredGSDByDatatype,
    pipelineTemplate,
    acquisitionGsdMax,
}) {
    return (
        <Box>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h5">{dataType}</Typography>
                <Tooltip
                    title={
                        !requiredGSDByDatatype[dataType] && (
                            <Typography>
                                No available GSD for this datatype.
                            </Typography>
                        )
                    }
                >
                    <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        GSD:{" "}
                        {requiredGSDByDatatype[dataType] ? (
                            `${requiredGSDByDatatype[dataType]} mm/px`
                        ) : (
                            <WarningIcon color="warning" />
                        )}
                    </Typography>
                </Tooltip>
            </Stack>
            <Grid container spacing={1}>
                {traitGroupDataTypes.map((TGDT) => (
                    <TraitGroupChip
                        key={TGDT.uuid}
                        traitGroup={pipelineTemplate.gsdPerTraitGroupDataType.find(
                            (PTTG) => PTTG.traitGroupDataTypeUuid === TGDT.uuid
                        )}
                        acquisitionGsdMax={acquisitionGsdMax}
                        TGDT={TGDT}
                    />
                ))}
            </Grid>
        </Box>
    );
}

function TraitGroupChip({ traitGroup, acquisitionGsdMax, TGDT }) {
    let chipColor;

    if (acquisitionGsdMax == null) {
        chipColor = "default";
    } else if (acquisitionGsdMax <= traitGroup?.gsd) {
        chipColor = "success";
    } else if (
        Number.isNaN(acquisitionGsdMax) ||
        acquisitionGsdMax <= traitGroup?.gsd * ALLOWED_FACTOR_BELOW_MINIMUM_GSD
    ) {
        chipColor = "warning";
    } else {
        chipColor = "error";
    }

    return (
        <Grid item xs="auto">
            <Tooltip
                title={
                    <Typography>{`Required GSD: ${traitGroup.gsd} mm/px`}</Typography>
                }
            >
                <Chip label={TGDT.TraitGroup.name} color={chipColor} />
            </Tooltip>
        </Grid>
    );
}

TraitGroupChip.propTypes = {
    traitGroup: PropTypes.object.isRequired,
    acquisitionGsdMax: PropTypes.number,
    TGDT: PropTypes.object.isRequired,
};
