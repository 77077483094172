import {
    GaugeContainer,
    GaugeReferenceArc,
    GaugeValueArc,
    useGaugeState,
} from "@mui/x-charts/Gauge";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material";

function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    return (
        <g>
            <circle cx={cx} cy={cy} r={circleRadius} fill="black" />
            {valueAngle !== null && (
                <path
                    d={`M ${cx} ${cy} L ${cx + outerRadius * Math.sin(valueAngle)} ${cy - outerRadius * Math.cos(valueAngle)}`}
                    stroke="black"
                    strokeWidth={4}
                />
            )}
        </g>
    );
}

function GaugeThresholdMarker({
    range,
    startAngle,
    endAngle,
    valueMin,
    valueMax,
}) {
    const { outerRadius, innerRadius, cx, cy } = useGaugeState();
    const angleRange = endAngle - startAngle;

    const minMarkerAngle =
        ((startAngle +
            (angleRange * (range[0] - valueMin)) / (valueMax - valueMin)) *
            Math.PI) /
        180;
    const maxMarkerAngle =
        ((startAngle +
            (angleRange * (range[1] - valueMin)) / (valueMax - valueMin)) *
            Math.PI) /
        180;

    return (
        <g>
            {range[0] !== valueMin && (
                <line
                    x1={cx + innerRadius * Math.sin(minMarkerAngle)}
                    y1={cy - innerRadius * Math.cos(minMarkerAngle)}
                    x2={cx + outerRadius * Math.sin(minMarkerAngle)}
                    y2={cy - outerRadius * Math.cos(minMarkerAngle)}
                    stroke="black"
                    strokeWidth={1}
                />
            )}
            {range[1] !== valueMax && (
                <line
                    x1={cx + innerRadius * Math.sin(maxMarkerAngle)}
                    y1={cy - innerRadius * Math.cos(maxMarkerAngle)}
                    x2={cx + outerRadius * Math.sin(maxMarkerAngle)}
                    y2={cy - outerRadius * Math.cos(maxMarkerAngle)}
                    stroke="black"
                    strokeWidth={1}
                />
            )}
        </g>
    );
}

function GaugeMinMaxText({ valueMin, valueMax }) {
    const { outerRadius, innerRadius, cx, cy } = useGaugeState();

    return (
        <g>
            <text
                dominantBaseline="central"
                fill="black"
                textAnchor="middle"
                x={cx - (innerRadius + outerRadius) / 2}
                y={cy + circleRadius}
            >
                {valueMin}
            </text>
            <text
                dominantBaseline="central"
                fill="black"
                textAnchor="middle"
                x={cx + (innerRadius + outerRadius) / 2}
                y={cy + circleRadius}
            >
                {valueMax}
            </text>
        </g>
    );
}

export function Gauge({
    width = 150,
    height = 100,
    startAngle = -90,
    endAngle = 90,
    value,
    valueMin,
    valueMax,
    ranges,
}) {
    const theme = useTheme();

    const gaugeValue =
        value === null ? null : Math.max(valueMin, Math.min(value, valueMax));

    let arcColor = theme.palette.error.main;

    if (
        ranges.success &&
        gaugeValue >= ranges.success[0] &&
        gaugeValue <= ranges.success[1]
    ) {
        arcColor = theme.palette.success.main;
    } else if (
        ranges.warning &&
        gaugeValue >= ranges.warning[0] &&
        gaugeValue <= ranges.warning[1]
    ) {
        arcColor = theme.palette.warning.main;
    }

    return (
        <GaugeContainer
            width={width}
            height={height}
            startAngle={startAngle}
            endAngle={endAngle}
            value={gaugeValue}
            valueMin={valueMin}
            valueMax={valueMax}
            innerRadius={40}
        >
            <GaugeReferenceArc />
            <GaugeValueArc sx={{ fill: arcColor }} />
            <g>
                {Object.entries(ranges).map(([key, range]) => (
                    <GaugeThresholdMarker
                        key={key}
                        range={range}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        valueMin={valueMin}
                        valueMax={valueMax}
                    />
                ))}
            </g>

            <GaugePointer />
            <GaugeMinMaxText
                startAngle={startAngle}
                endAngle={endAngle}
                valueMin={valueMin}
                valueMax={valueMax}
            />
        </GaugeContainer>
    );
}

const circleRadius = 10;

Gauge.propTypes = {
    value: PropTypes.number,
    valueMin: PropTypes.number,
    valueMax: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    startAngle: PropTypes.number,
    endAngle: PropTypes.number,
    ranges: PropTypes.exact({
        warning: PropTypes.arrayOf(PropTypes.number.isRequired),
        success: PropTypes.arrayOf(PropTypes.number.isRequired),
    }).isRequired,
};

GaugeThresholdMarker.propTypes = {
    range: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    startAngle: PropTypes.number.isRequired,
    endAngle: PropTypes.number.isRequired,
    valueMin: PropTypes.number.isRequired,
    valueMax: PropTypes.number.isRequired,
};

GaugeMinMaxText.propTypes = {
    startAngle: PropTypes.number.isRequired,
    endAngle: PropTypes.number.isRequired,
    valueMin: PropTypes.number.isRequired,
    valueMax: PropTypes.number.isRequired,
};
