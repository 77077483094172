import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
    ENTER_KEY_NAME,
    ENTITY_SELECT_OPTION_FONT_FAMILY,
    ENTITY_SELECT_SIZE,
    EVALUATION_ENTITY_LIST,
    SELECTED_ENTITY_LIST,
} from "../constants";
import { useFetch, useSnackbar } from "../../../hooks";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { BACKEND_ROUTES } from "../../../backendRoutes";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import { useTheme } from "@emotion/react";

/* 
Unfortunately this way of defining StyledOption causes a warning from React
about the "unrecognized" prop isGrayedOut.
const SytledOption = styled("option")(({ theme, isGrayedOut }) => ({
    color: isGrayedOut
        ? theme.palette.text.disabled
        : theme.palette.text.primary,
}));
*/

const StyledOption = ({ id, value, label }) => {
    const theme = useTheme();
    const color = theme.palette.text.primary;
    return (
        <option
            key={id}
            value={value}
            style={{ color, fontFamily: ENTITY_SELECT_OPTION_FONT_FAMILY }}
        >
            {label}
        </option>
    );
};

function EntitySelect({
    title,
    entities,
    markedEntities,
    setMarkedEntities,
    onFocus,
    onDoubleClickOrEnter,
}) {
    const handleChangeMultiple = (event) => {
        const { options } = event.target;

        const newMarkedEntities = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                newMarkedEntities.push(
                    entities.find((entity) => entity.uuid === options[i].value)
                );
            }
        }

        setMarkedEntities(newMarkedEntities);
    };

    return (
        <FormControl sx={{ width: 1 }}>
            <Stack justifyContent="space-between">
                <Typography variant="subtitle1">{title}</Typography>
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                >{`Count: ${entities.length}`}</Typography>
            </Stack>

            <Select
                multiple
                native
                value={markedEntities.map((entity) => entity.uuid)}
                onChange={handleChangeMultiple}
                // Press Enter or double click on list items
                onDoubleClick={onDoubleClickOrEnter}
                onKeyDown={(event) => {
                    if (event.key === ENTER_KEY_NAME) {
                        onDoubleClickOrEnter();
                    }
                }}
                inputProps={{ size: ENTITY_SELECT_SIZE }}
                onFocus={onFocus}
            >
                {entities.map((entity) => (
                    <StyledOption
                        key={entity.uuid}
                        value={entity.uuid}
                        label={entity.name}
                    />
                ))}
            </Select>
        </FormControl>
    );
}

TransferList.propTypes = {
    orientation: PropTypes.oneOf(["horizontal", "vertical"]),
};

export function TransferList({
    evaluationEntities,
    setEvaluationEntities,
    quarantinedDatasets,
    markedEvaluationEntities,
    setMarkedEvaluationEntities,
    markedQuarantinedDatasets,
    setMarkedQuarantinedDatasets,
    setLastFocusedEntityList,
    mutateMission,
    orientation = "horizontal",
}) {
    const { openSnackbar } = useSnackbar();
    const { patch } = useFetch();
    const handleTransfert = async () => {
        const markedEvaluationEntitiesUuids = markedEvaluationEntities.map(
            (entity) => entity.uuid
        );

        const updatedRawDatasetCount = await patch(BACKEND_ROUTES.RAW_DATASET, {
            body: {
                uuids: markedEvaluationEntitiesUuids,
                quarantine: true,
            },
        });

        if (updatedRawDatasetCount) {
            openSnackbar(
                `${updatedRawDatasetCount} image(s) successfully quarantined.`,
                "success"
            );
            setEvaluationEntities(
                evaluationEntities.filter(
                    (entity) =>
                        !markedEvaluationEntitiesUuids.includes(entity.uuid)
                )
            );
            setMarkedEvaluationEntities([]);
            mutateMission();
        }
    };

    const handlePop = async () => {
        const updatedRawDatasetCount = await patch(BACKEND_ROUTES.RAW_DATASET, {
            body: {
                uuids: markedQuarantinedDatasets.map((entity) => entity.uuid),
                quarantine: false,
            },
        });

        if (updatedRawDatasetCount) {
            openSnackbar(
                `${updatedRawDatasetCount} image(s) successfully un-quarantined.`,
                "success"
            );
            // this shouldn't add duplicates as you shouldn't be able to select entities on graphs if they are already quarantined
            // this is especially true because we remove quarantined entities from the selected entities list after they are quarantined
            setEvaluationEntities([
                ...evaluationEntities,
                ...markedQuarantinedDatasets,
            ]);
            setMarkedQuarantinedDatasets([]);
            mutateMission();
        }
    };

    return (
        <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={orientation === "horizontal" ? 5.5 : 12}>
                <EntitySelect
                    title="Selected images"
                    entities={evaluationEntities}
                    markedEntities={markedEvaluationEntities}
                    setMarkedEntities={setMarkedEvaluationEntities}
                    onDoubleClickOrEnter={handleTransfert}
                    onFocus={() =>
                        setLastFocusedEntityList(EVALUATION_ENTITY_LIST)
                    }
                />
            </Grid>
            <Grid item xs={orientation === "horizontal" ? 1 : 12}>
                <Stack
                    spacing={1}
                    direction={orientation === "horizontal" ? "column" : "row"}
                    justifyContent="center"
                    sx={{ height: "100%" }}
                >
                    <Tooltip title={<Typography>Quarantine</Typography>}>
                        <span>
                            <Button
                                sx={{ width: 1, minWidth: 1 }}
                                disabled={!markedEvaluationEntities.length}
                                variant="contained"
                                color="error"
                                onClick={handleTransfert}
                                size="small"
                            >
                                {orientation === "horizontal" ? (
                                    <ArrowForwardIcon fontSize="small" />
                                ) : (
                                    <ArrowDownwardIcon fontSize="small" />
                                )}
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip title={<Typography>Un-Quarantine</Typography>}>
                        <span>
                            <Button
                                sx={{ width: 1, minWidth: 1 }}
                                disabled={!markedQuarantinedDatasets.length}
                                variant="contained"
                                onClick={handlePop}
                                size="small"
                            >
                                {orientation === "horizontal" ? (
                                    <ArrowBackIcon fontSize="small" />
                                ) : (
                                    <ArrowUpwardIcon fontSize="small" />
                                )}
                            </Button>
                        </span>
                    </Tooltip>
                </Stack>
            </Grid>
            <Grid item xs={orientation === "horizontal" ? 5.5 : 12}>
                <EntitySelect
                    title="Quarantined images"
                    entities={quarantinedDatasets}
                    markedEntities={markedQuarantinedDatasets}
                    setMarkedEntities={setMarkedQuarantinedDatasets}
                    onDoubleClickOrEnter={handlePop}
                    onFocus={() =>
                        setLastFocusedEntityList(SELECTED_ENTITY_LIST)
                    }
                />
            </Grid>
        </Grid>
    );
}
