import { autoCompleteStyleOverride } from "./Autocomplete";
import { backdropStyleOverride } from "./Backdrop";
import { buttonStyleOverride } from "./Button";
import { cardStyleOverride } from "./Card";
import { dataGridStyleOverride } from "./DataGrid";
import { iconButtonStyleOverride } from "./IconButton";
import { inputStyleOverride } from "./Input";
import { listsStyleOverride } from "./Lists";
import { paperStyleOverride } from "./Paper";
import { textFieldStyleOverride } from "./TextField";
import { tooltipStyleOverride } from "./Tooltip";
import { typographyStyleOverride } from "./Typography";

export const overrideComponentsStyle = {
    ...autoCompleteStyleOverride,
    ...backdropStyleOverride,
    ...buttonStyleOverride,
    ...cardStyleOverride,
    ...dataGridStyleOverride,
    ...iconButtonStyleOverride,
    ...inputStyleOverride,
    ...listsStyleOverride,
    ...paperStyleOverride,
    ...textFieldStyleOverride,
    ...tooltipStyleOverride,
    ...typographyStyleOverride,
};
