import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { BookingTableSkeleton } from "./BookingTableSkeleton";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { useMemo } from "react";
import useSWR from "swr";
import { useWatch } from "react-hook-form";

/**
 * Min flight number values for color-coded booking table
 */
const COLOR_LIMITS = Object.freeze({
    GREEN: 1,
    YELLOW: 15,
    RED: 30,
});

export function BookingTable({ control }) {
    const [
        yearWatch,
        companyWatch,
        contractWatch,
        supervisorWatch,
        deadlineWatch,
        numberDisplayedWatch,
        statusWatch,
    ] = useWatch({
        name: [
            "year",
            "company",
            "contract",
            "supervisor",
            "deadlineType",
            "numberDisplayed",
            "status",
        ],
        control,
    });
    const { data: bookingData, error: bookingDataFetchError } = useSWR(
        `${
            BACKEND_ROUTES.PIPELINE_TEMPLATE_CALENDAR
        }/booking?year=${yearWatch}${
            companyWatch ? `&companyUuid=${companyWatch.uuid}` : ""
        }${contractWatch ? `&contractUuid=${contractWatch.uuid}` : ""}${
            statusWatch ? `&status=${statusWatch}` : ""
        }${supervisorWatch ? `&supervisorUuid=${supervisorWatch.uuid}` : ""}${
            deadlineWatch ? `&deadlineType=${deadlineWatch.value}` : ""
        }`
    );

    const rows = useMemo(
        () => (bookingData ? createData(bookingData) : undefined),
        [bookingData]
    );

    const finalRow = useMemo(
        () => (rows ? dataSubTotals(rows) : undefined),
        [rows]
    );

    if (bookingDataFetchError)
        return <FetchErrorAlert error={bookingDataFetchError} />;

    if (!bookingData) {
        return <BookingTableSkeleton />;
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Crop
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Jan.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Feb.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Mar.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Apr.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            May.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Jun.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Jul.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Aug.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Sep.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Oct.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Nov.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Dec.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Sub-total
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.concat(finalRow).map((row) => (
                        <TableRow
                            key={row.crop}
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.crop}
                            </TableCell>
                            {[
                                "january",
                                "february",
                                "march",
                                "april",
                                "may",
                                "june",
                                "july",
                                "august",
                                "september",
                                "october",
                                "november",
                                "december",
                                "subtotal",
                            ].map((month) => {
                                return (
                                    <TableCell
                                        key={month}
                                        align="center"
                                        sx={{
                                            bgcolor: (theme) =>
                                                row[month] >= COLOR_LIMITS.RED
                                                    ? theme.palette.error.main
                                                    : row[month] >=
                                                      COLOR_LIMITS.YELLOW
                                                    ? theme.palette.warning.main
                                                    : row[month] >=
                                                      COLOR_LIMITS.GREEN
                                                    ? theme.palette.success.main
                                                    : theme.palette.grey[300],
                                        }}
                                    >
                                        {numberDisplayedWatch && row[month]}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function createData(bookingData) {
    const rows = [];
    Object.keys(bookingData).forEach((crop) => {
        rows.push({
            crop: crop,
            january: bookingData[crop][0],
            february: bookingData[crop][1],
            march: bookingData[crop][2],
            april: bookingData[crop][3],
            may: bookingData[crop][4],
            june: bookingData[crop][5],
            july: bookingData[crop][6],
            august: bookingData[crop][7],
            september: bookingData[crop][8],
            october: bookingData[crop][9],
            november: bookingData[crop][10],
            december: bookingData[crop][11],
            subtotal: bookingData[crop].reduce(
                (previous, current) => previous + current,
                0
            ),
        });
    });
    return rows;
}

function dataSubTotals(rows) {
    if (!rows.length)
        return {
            crop: "Sub-total",
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0,
            subtotal: 0,
        };
    const months = Object.keys(rows[0]);
    return months.reduce((finalRow, month) => {
        if (month === "crop") finalRow[month] = "Sub-total";
        else
            finalRow[month] = rows.reduce(
                (subTotal, row) => subTotal + row[month],
                0
            );
        return finalRow;
    }, {});
}
