import { PROCESSING_MODULES, PROCESS_STATUS } from "../../constants";

import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

ValidationPageLink.propTypes = {
    process: PropTypes.object.isRequired,
};

/**
 * Button link that allows user to go to the process's validation page.
 * The validation page depends on what module the process is.
 * If the process's module does not have a validation page, return null.
 * @param {object} props.process - A process object with parent info and module info.
 * @returns {ReactComponent|null}
 */
export function ValidationPageLink({ process }) {
    const moduleName = process.ModuleVersion.Module.name;
    const site = process.MissionModel.Mission.Site;

    const MODULE = PROCESSING_MODULES[moduleName];
    if (MODULE) {
        let destinationUrl = `/validation/${MODULE.VALIDATION_PAGE}?moduleNameToValidate=${MODULE.NAME}&processUuid=${process.uuid}`;

        if (MODULE.TREND_VALIDATION) destinationUrl += `&siteUuid=${site.uuid}`;

        return (
            <Button
                size="small"
                variant="contained"
                component={RouterLink}
                to={destinationUrl}
                disabled={
                    process.status === PROCESS_STATUS.UNPLANNED ||
                    process.status === PROCESS_STATUS.PLANNED ||
                    process.status === PROCESS_STATUS.EXECUTING
                }
                reloadDocument
            >
                Validation
            </Button>
        );
    } else {
        return null;
    }
}
