/**
 * This file is copied directly from the Slant Range repo,
 * except for the following name changes:
 * - GeoJSONUtil -> geoJSONUtil
 * - GeoJSONType -> GEO_JSON_TYPE (similarly for its keys)
 * - GeoJSONGeometryType -> GEO_JSON_GEOMETRY_TYPE (similarly for its keys)
 * - MOST IMPORTANTLY !!! getIdOfFeature returns the value of the field [DYNAMIC_FEATURE_ID_FIELD_NAME]
 * - Added new parameter "transformCenterOnly" to the following functions
 *      - transformGeoJSON
 *      - transformFeatures
 *      - transformFeature
 *      - and added a new function transformGeometryCenter
 */

import * as turf from "@turf/turf";

import { DYNAMIC_FEATURE_ID_FIELD_NAME } from "./constants";
import { transformUtil } from "./transformUtil";

export const EMPTY_GEO_JSON = { type: "FeatureCollection", features: [] };
export const MIN_LONGITUDE = -180;
export const MAX_LONGITUDE = 180;
export const MIN_LATITUDE = -90;
export const MAX_LATITUDE = 90;

export const GEO_JSON_TYPE = Object.freeze({
    FEATURE: "Feature",
    FEATURE_COLLECTION: "FeatureCollection",
});

export const GEO_JSON_GEOMETRY_TYPE = Object.freeze({
    GEOMETRY_COLLECTION: "GeometryCollection",
    LINE_STRING: "LineString",
    MULTI_LINE_STRING: "MultiLineString",
    MULTI_POINT: "MultiPoint",
    MULTI_POLYGON: "MultiPolygon",
    POINT: "Point",
    POLYGON: "Polygon",
});

export const geoJSONUtil = {
    cloneGeoJSON(geoJSON) {
        return geoJSON ? turf.clone(geoJSON) : geoJSON;
    },

    combineGeoJSONs(geoJSONs) {
        let result;

        if (Array.isArray(geoJSONs) && geoJSONs.length) {
            const features = geoJSONs.reduce((accumulator, geoJSON) => {
                if (
                    geoJSON &&
                    Array.isArray(geoJSON.features) &&
                    geoJSON.features.length
                ) {
                    accumulator = accumulator.concat(geoJSON.features);
                }
                return accumulator;
            }, []);

            if (features.length) {
                result = {
                    type: GEO_JSON_TYPE.FEATURE_COLLECTION,
                    features: features,
                };
                result = this.cloneGeoJSON(result);
            }
        }

        return result;
    },

    areEqual(featureOrGeometry1, featureOrGeometry2) {
        return turf.booleanEqual(featureOrGeometry1, featureOrGeometry2);
    },

    areValidCoordinates(longitude, latitude) {
        return (
            longitude <= MAX_LONGITUDE &&
            longitude >= MIN_LONGITUDE &&
            latitude <= MAX_LATITUDE &&
            latitude >= MIN_LATITUDE
        );
    },

    makeFeature(geometry, properties) {
        return turf.feature(geometry, properties);
    },

    makeFeatureCollection(features) {
        return turf.featureCollection(features);
    },

    makeLine(coords) {
        return turf.lineString(coords);
    },

    makePoint(coord) {
        return turf.point(coord);
    },

    tryExpandBounds(bounds, longitude, latitude) {
        if (bounds) {
            if (longitude < bounds[0][0]) bounds[0][0] = longitude;
            if (latitude < bounds[0][1]) bounds[0][1] = latitude;
            if (longitude > bounds[1][0]) bounds[1][0] = longitude;
            if (latitude > bounds[1][1]) bounds[1][1] = latitude;
        }
    },

    getBoundsOfCoordinates(coords) {
        let result;

        if (Array.isArray(coords) && coords.length > 0) {
            result = [
                [MAX_LONGITUDE, MAX_LATITUDE],
                [MIN_LONGITUDE, MIN_LATITUDE],
            ];
            coords.forEach((coord) => {
                this.tryExpandBounds(result, coord[0], coord[1]);
            });
        }

        return result;
    },

    getBoundsOfGeometry(geometry) {
        let result;

        if (geometry) {
            result = [
                [MAX_LONGITUDE, MAX_LATITUDE],
                [MIN_LONGITUDE, MIN_LATITUDE],
            ];
            turf.coordEach(geometry, (coord) => {
                this.tryExpandBounds(result, coord[0], coord[1]);
            });
        }

        return result;
    },

    getBoundsOfFeature(feature) {
        let result;

        if (feature) {
            result = this.getBoundsOfGeometry(feature.geometry);
        }

        return result;
    },

    getBoundsOfFeatures(features) {
        let result;

        if (Array.isArray(features)) {
            features.forEach((feature) => {
                const bounds = this.getBoundsOfFeature(feature);
                if (bounds) {
                    if (!result) {
                        result = [
                            [MAX_LONGITUDE, MAX_LATITUDE],
                            [MIN_LONGITUDE, MIN_LATITUDE],
                        ];
                    }

                    if (bounds[0][0] < result[0][0])
                        result[0][0] = bounds[0][0];
                    if (bounds[0][1] < result[0][1])
                        result[0][1] = bounds[0][1];
                    if (bounds[1][0] > result[1][0])
                        result[1][0] = bounds[1][0];
                    if (bounds[1][1] > result[1][1])
                        result[1][1] = bounds[1][1];
                }
            });
        }

        return result;
    },

    getCenterOfBounds(bounds) {
        let result;

        if (bounds) {
            const longitude = bounds[0][0] + (bounds[1][0] - bounds[0][0]) / 2;
            const latitude = bounds[0][1] + (bounds[1][1] - bounds[0][1]) / 2;
            result = [longitude, latitude];
        }

        return result;
    },

    getCenterOfGeometry(geometry) {
        return this.getCenterOfBounds(this.getBoundsOfGeometry(geometry));
    },

    getCenterOfFeature(feature) {
        return this.getCenterOfBounds(this.getBoundsOfFeature(feature));
    },

    getCenterOfFeatures(features) {
        return this.getCenterOfBounds(this.getBoundsOfFeatures(features));
    },

    doesFeatureContainPoint(feature, point) {
        let result = false;

        if (feature && feature.geometry) {
            switch (feature.geometry.type) {
                case GEO_JSON_GEOMETRY_TYPE.POINT: {
                    result = turf.booleanEqual(point, feature);
                    break;
                }
                case GEO_JSON_GEOMETRY_TYPE.LINE_STRING: {
                    result = turf.pointOnLine(point, feature);
                    break;
                }
                case GEO_JSON_GEOMETRY_TYPE.POLYGON: {
                    result = turf.booleanPointInPolygon(point, feature);
                    break;
                }
                case GEO_JSON_GEOMETRY_TYPE.MULTI_POLYGON: {
                    result = turf.booleanPointInPolygon(point, feature);
                    break;
                }
                default: {
                    window.console.warn(
                        `Cannot check if feature contains point; unsupported geometry type '${feature.geometry.type}'.`
                    );
                    result = undefined;
                }
            }
        }

        return result;
    },

    doesFeatureContainCoordinates(feature, longitude, latitude) {
        let result = false;

        if (
            feature &&
            feature.geometry &&
            this.areValidCoordinates(longitude, latitude)
        ) {
            const point = this.makePoint([longitude, latitude]);
            result = this.doesFeatureContainPoint(feature, point);
        }

        return result;
    },

    findFeatureByCoordinates(features, longitude, latitude) {
        let result;

        if (
            Array.isArray(features) &&
            this.areValidCoordinates(longitude, latitude)
        ) {
            const point = this.makePoint([longitude, latitude]);
            result = features.find((feature) =>
                this.doesFeatureContainPoint(feature, point)
            );
        }

        return result;
    },

    shiftGeometry(geometry, longitude, latitude) {
        let result;

        if (geometry) {
            result = this.cloneGeoJSON(geometry);
            turf.coordEach(result, (coord) => {
                coord[0] += longitude;
                coord[1] += latitude;
            });
        }

        return result;
    },

    shiftFeature(feature, longitude, latitude) {
        let result;

        if (feature) {
            result = this.makeFeature(
                this.shiftGeometry(feature.geometry, longitude, latitude),
                feature.properties
            );
        }

        return result;
    },

    shiftFeatures(features, longitude, latitude) {
        let result;

        if (Array.isArray(features)) {
            result = features.map((feature) =>
                this.shiftFeature(feature, longitude, latitude)
            );
        }

        return result;
    },

    shiftGeoJSON(geoJSON, longitude, latitude) {
        let result;

        if (geoJSON) {
            switch (geoJSON.type) {
                case GEO_JSON_TYPE.FEATURE_COLLECTION: {
                    const shiftedFeatures = this.shiftFeatures(
                        geoJSON.features,
                        longitude,
                        latitude
                    );
                    result = this.makeFeatureCollection(shiftedFeatures);
                    break;
                }
                case GEO_JSON_TYPE.FEATURE: {
                    result = this.shiftFeature(geoJSON, longitude, latitude);
                    break;
                }
                default: {
                    if (Array.isArray(geoJSON)) {
                        result = this.shiftFeatures(
                            geoJSON,
                            longitude,
                            latitude
                        );
                    } else {
                        result = this.shiftGeometry(
                            geoJSON,
                            longitude,
                            latitude
                        );
                    }
                    break;
                }
            }
        }

        return result;
    },

    transformGeometry(geometry, transform, referenceCoord) {
        let result;

        if (geometry) {
            result = this.cloneGeoJSON(geometry);
            turf.coordEach(result, (coord) => {
                let refCoord = new Array(2);
                if (
                    referenceCoord.longitude !== undefined &&
                    referenceCoord.latitude !== undefined
                ) {
                    refCoord[0] = referenceCoord.longitude;
                    refCoord[1] = referenceCoord.latitude;
                } else {
                    refCoord = referenceCoord;
                }
                const newCoord = transformUtil.transformCoord(
                    coord,
                    transform,
                    refCoord
                ); // referenceCoord)
                coord[0] = newCoord[0];
                coord[1] = newCoord[1];
            });
        }

        return result;
    },

    /**
     * Applies the transformation to the geometry center, and then redraws the geometry based on its new center
     */
    transformGeometryCenter(geometry, transform, referenceCoord) {
        let result;

        if (geometry) {
            result = this.cloneGeoJSON(geometry);
            const oldCenter = this.getCenterOfGeometry(result);
            const newCenterGeometry = this.transformGeometry(
                { type: "Point", coordinates: oldCenter },
                transform,
                referenceCoord
            );
            const shiftTransform = transformUtil.calculateTransform(
                [oldCenter],
                [newCenterGeometry.coordinates],
                null
            );
            result = this.transformGeometry(
                result,
                shiftTransform.transform,
                shiftTransform.referenceCoord
            );
        }

        return result;
    },

    transformFeature(
        feature,
        transform,
        referenceCoord,
        transformOnlyCenter = false
    ) {
        let result;

        if (feature) {
            result = this.makeFeature(
                transformOnlyCenter
                    ? this.transformGeometryCenter(
                          feature.geometry,
                          transform,
                          referenceCoord
                      )
                    : this.transformGeometry(
                          feature.geometry,
                          transform,
                          referenceCoord
                      ),
                feature.properties
            );
        }

        return result;
    },

    transformFeatures(
        features,
        transform,
        referenceCoord,
        transformOnlyCenter = false
    ) {
        let result;

        if (Array.isArray(features)) {
            result = features.map((feature) =>
                this.transformFeature(
                    feature,
                    transform,
                    referenceCoord,
                    transformOnlyCenter
                )
            );
        }

        return result;
    },

    transformGeoJSON(
        geoJSON,
        transform,
        referenceCoord,
        transformOnlyCenter = false
    ) {
        let result;

        if (geoJSON) {
            switch (geoJSON.type) {
                case GEO_JSON_TYPE.FEATURE_COLLECTION: {
                    const transformedFeatures = this.transformFeatures(
                        geoJSON.features,
                        transform,
                        referenceCoord,
                        transformOnlyCenter
                    );
                    result = this.makeFeatureCollection(transformedFeatures);
                    break;
                }
                case GEO_JSON_TYPE.FEATURE: {
                    result = this.transformFeature(
                        geoJSON,
                        transform,
                        referenceCoord,
                        transformOnlyCenter
                    );
                    break;
                }
                default: {
                    if (Array.isArray(geoJSON)) {
                        result = this.transformFeatures(
                            geoJSON,
                            transform,
                            referenceCoord,
                            transformOnlyCenter
                        );
                    } else if (transformOnlyCenter) {
                        result = this.transformGeometryCenter(
                            geoJSON,
                            transform,
                            referenceCoord
                        );
                    } else {
                        result = this.transformGeometry(
                            geoJSON,
                            transform,
                            referenceCoord
                        );
                    }
                    break;
                }
            }
        }

        return result;
    },

    getIdOfFeature(feature) {
        return feature && feature.properties
            ? feature.properties[DYNAMIC_FEATURE_ID_FIELD_NAME]
            : undefined;
    },

    findFeatureById(geoJSON, id) {
        let result;

        const features =
            geoJSON.type === GEO_JSON_TYPE.FEATURE_COLLECTION
                ? geoJSON.features
                : geoJSON;
        if (Array.isArray(features)) {
            result = features.find(
                (feature) => this.getIdOfFeature(feature) === id
            );
        }
        return result;
    },

    getValueOfFeature(feature) {
        return feature && feature.properties
            ? feature.properties.value
            : undefined;
    },

    getValuesOfFeatures(features, filtered = true) {
        let result = Array.isArray(features)
            ? features.map((feature) => this.getValueOfFeature(feature))
            : undefined;
        if (result && filtered) {
            result = result.filter(
                (value) => value !== null && value !== undefined
            );
        }
        return result;
    },

    makeSquareFeatureWithRadius(longitude, latitude, radius, units = "meters") {
        const degrees = turf.lengthToDegrees(radius, units);
        const coordinates = [
            [
                [longitude - degrees, latitude + degrees],
                [longitude + degrees, latitude + degrees],
                [longitude + degrees, latitude - degrees],
                [longitude - degrees, latitude - degrees],
                [longitude - degrees, latitude + degrees],
            ],
        ];
        const result = turf.polygon(coordinates);
        return result;
    },
};
