import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import {
    absoluteAggregatedHeight,
    absoluteGSD,
    absoluteHeight,
    roundNumber,
} from "../utils";

import { BBCHStageRowDisplay } from "./BBCHStageRowDisplay";
import { BBCHStageTooltip } from "../../components/BBCHStageTooltip";
import { Fragment } from "react";
import PropTypes from "prop-types";

MissionProtocolStageTable.propTypes = {
    pipelineTemplate: PropTypes.object.isRequired,
    bbchStageData: PropTypes.object.isRequired,
    traitGroupDataTypeArrays: PropTypes.object.isRequired,
    gsdPerStage: PropTypes.array.isRequired,
    minGSD: PropTypes.object,
    displayTraits: PropTypes.bool.isRequired,
    maxTraitCount: PropTypes.number.isRequired,
    printed: PropTypes.bool,
};

export function MissionProtocolStageTable({
    pipelineTemplate,
    bbchStageData,
    traitGroupDataTypeArrays,
    gsdPerStage,
    minGSD,
    displayTraits,
    maxTraitCount,
    printed = false,
}) {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography sx={{ fontStyle: "italic", color: "grey" }}>
                    <strong>Acquisition timing</strong>
                </Typography>
                <Divider />
            </Grid>

            <Grid item xs={3}>
                <Stack direction="row" spacing={1}>
                    <Typography>BBCH stages </Typography>
                    {!printed && (
                        <BBCHStageTooltip bbchStages={bbchStageData.rows} />
                    )}
                </Stack>
            </Grid>

            <Grid item xs={8} container columns={bbchStageData.count}>
                {bbchStageData.rows.map((bbchStage) => (
                    <Grid key={bbchStage.uuid} item xs={1}>
                        <Box display="flex" justifyContent="center">
                            <Typography>{bbchStage.stage}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ borderStyle: "dashed" }} />
            </Grid>

            <Grid item xs={3}>
                <Typography>Flight sequence</Typography>
            </Grid>

            <Grid item xs={8} container columns={bbchStageData.count}>
                {bbchStageData.rows.map((bbchStage) => (
                    <Grid key={bbchStage.uuid} item xs={1}>
                        <Box display="flex" justifyContent="center">
                            <Typography>
                                {
                                    pipelineTemplate.FlightSequences.find(
                                        (flightSequence) =>
                                            flightSequence.bbchStageUuid ===
                                            bbchStage.uuid
                                    )?.sequence
                                }
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            {Object.keys(traitGroupDataTypeArrays).map((dataType) => {
                return (
                    <Grid
                        key={dataType}
                        container
                        item
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Typography
                                sx={{ fontStyle: "italic", color: "grey" }}
                            >
                                <strong>{`Selected traits (${dataType})`}</strong>
                            </Typography>
                            <Divider />
                        </Grid>

                        {displayTraits || !printed ? (
                            traitGroupDataTypeArrays[dataType].map((TGDT) => (
                                <Fragment key={TGDT.uuid}>
                                    <Grid item xs={3}>
                                        <Typography>
                                            {TGDT.TraitGroup.name}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        container
                                        columns={bbchStageData.count}
                                    >
                                        {bbchStageData.rows.map((bbchStage) => (
                                            <Grid
                                                key={`${TGDT.uuid}_${bbchStage.uuid}`}
                                                item
                                                xs={1}
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <BBCHStageRowDisplay
                                                        pipelineTemplate={
                                                            pipelineTemplate
                                                        }
                                                        traitGroupDataType={
                                                            TGDT
                                                        }
                                                        bbchStage={bbchStage}
                                                        minGSD={minGSD}
                                                        hasTooltip={!printed}
                                                    />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Fragment>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontStyle: "italic",
                                        color: "darkgrey",
                                    }}
                                >
                                    More than {maxTraitCount} traits were
                                    selected, they will be displayed on an
                                    additional page.
                                </Typography>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Divider sx={{ borderStyle: "dashed" }} />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography>GSD (mm)</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            columns={bbchStageData.count}
                        >
                            {gsdPerStage.map((gsd) => (
                                <Grid key={`gsd_${gsd.order}`} item xs={1}>
                                    <Box display="flex" justifyContent="center">
                                        <Typography>
                                            {absoluteGSD({
                                                gsd: gsd.gsd[dataType],
                                                pipelineTemplate,
                                                dataType,
                                            }) ?? "-"}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item xs={3}>
                            <Typography>Altitude (m)</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            columns={bbchStageData.count}
                        >
                            {gsdPerStage.map((gsd) => {
                                const usedHeight = roundNumber(
                                    absoluteHeight({
                                        gsd: gsd.gsd[dataType],
                                        pipelineTemplate,
                                        dataType,
                                    }),
                                    0
                                );
                                return (
                                    <Grid
                                        key={`height_${gsd.order}`}
                                        item
                                        xs={1}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Typography>
                                                {usedHeight !== 0
                                                    ? usedHeight
                                                    : "-"}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}

            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid container item xs={12} spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <Typography>
                        <strong>Final Flight Altitude (m)</strong>
                    </Typography>
                </Grid>
                <Grid item xs={8} container columns={bbchStageData.count}>
                    {gsdPerStage.map((gsd) => {
                        const height = roundNumber(
                            absoluteAggregatedHeight({
                                gsd,
                                pipelineTemplate,
                            }),
                            0
                        );
                        return (
                            <Grid key={`gsd_${gsd.order}`} item xs={1}>
                                <Box display="flex" justifyContent="center">
                                    <Typography>
                                        <strong>
                                            {height !== 0 ? height : "-"}
                                        </strong>
                                    </Typography>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}
