import { Box, Card, Stack, Typography } from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { ExperimentTableSkeleton } from "./ExperimentTableSkeleton";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { getDataGridHeight } from "../utils/getDataGridHeight";
import useSWR from "swr";

const pageItemCount = 100;

export function ExperimentTable({ siteUuid }) {
    const { data: experiments, error: experimentsFetchError } = useSWR(
        `${BACKEND_ROUTES.EXPERIMENT}?parentInfo=true&siteUuid=${siteUuid}`
    );

    if (experimentsFetchError)
        return <FetchErrorAlert error={experimentsFetchError} />;

    if (!experiments) {
        return <ExperimentTableSkeleton />;
    }

    const gridRows = experiments.rows.map((experiment) => {
        return {
            id: experiment.uuid,
            uuid: experiment.uuid,
            name: experiment.name,
            crop: experiment.Crop.name,
            cropUuid: experiment.cropUuid,
        };
    });

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "name",
            headerName: "Experiment",
            minWidth: 300,
            flex: 3,
        },
        {
            field: "crop",
            headerName: "Crop",
            minWidth: 300,
            flex: 2,
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h6" gutterBottom>
                    Experiments
                </Typography>
            </Stack>

            <Card>
                <Box
                    style={{
                        height: getDataGridHeight(400, experiments.rows.length),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        rowCount={experiments.count}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                    />
                </Box>
            </Card>
        </>
    );
}
