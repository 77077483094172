import {
    CAMPAIGN_MANAGER_NAMES,
    EXPERIMENT_MISSION_STATUS,
} from "../../../constants";
import { Dialog, Stack } from "@mui/material";
import { RoutingDialog, RoutingKanbanDroppable } from ".";
import { useEffect, useState } from "react";
import { useFetch, useSnackbar } from "../../../hooks";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { DragDropContext } from "react-beautiful-dnd";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { ModalTransition } from "../../../components/ModalTransition";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useWatch } from "react-hook-form";

const noUserUuidValue = "this solution works somehow!";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const shapedData = (users, missions) => {
    // filteredMissions is all the missions that have no associations or unfinished/unuploaded associations
    const filteredMissions = missions?.rows.filter(
        (mission) =>
            !mission.ExperimentMissions.length ||
            mission.ExperimentMissions.find(
                (expMission) =>
                    expMission.status !==
                        EXPERIMENT_MISSION_STATUS.PROCESS_FINISHED ||
                    expMission.status !== EXPERIMENT_MISSION_STATUS.UPLOADED
            )
    );
    const result = {};

    result[noUserUuidValue] = filteredMissions?.filter(
        (mission) => !mission.supervisorUuid
    );

    users
        ?.filter((user) =>
            Object.values(CAMPAIGN_MANAGER_NAMES).includes(
                `${user.firstName} ${user.lastName}`.toLowerCase()
            )
        )
        .forEach(
            (user) =>
                (result[user.uuid] = filteredMissions?.filter(
                    (mission) => mission.supervisorUuid === user.uuid
                ))
        );

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

export function RoutingKanban({ control }) {
    const { patch } = useFetch();
    const { openSnackbar } = useSnackbar();
    const [kanbanData, setKanbanData] = useState({});
    const [isDragDisabled, setIsDragDisabled] = useState(false);
    const [isOpenMissionDetails, setIsOpenMissionDetails] = useState(false);
    const [selectedMissionUuid, setSelectedMissionUuid] = useState("");

    const [yearWatch, companyWatch, contractWatch, siteWatch, statusWatch] =
        useWatch({
            name: ["year", "company", "contract", "site", "status"],
            control,
        });

    const { data: users, error: usersFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.USER}?sort=firstName`
    );

    const {
        data: missions,
        error: missionsFetchError,
        mutate,
    } = useSWR(
        `${
            BACKEND_ROUTES.MISSION
        }?parentInfo=true&experimentMissions=true&acquisitionVector=true&sort=date${
            yearWatch
                ? `&dateAfter=${new Date(
                      yearWatch,
                      0,
                      1,
                      0,
                      0,
                      0,
                      0
                  ).toISOString()}&dateBefore=${new Date(
                      yearWatch,
                      11,
                      31,
                      23,
                      59,
                      59,
                      999
                  ).toISOString()}`
                : ""
        }${
            companyWatch
                ? `&Site_Contract_companyUuid=${companyWatch.uuid}`
                : ""
        }${contractWatch ? `&Site_contractUuid=${contractWatch.uuid}` : ""}${siteWatch ? `&siteUuid=${siteWatch.uuid}` : ""}${
            statusWatch ? `&status=${statusWatch}` : ""
        }`
    );
    useEffect(() => {
        setKanbanData(shapedData(users, missions));
    }, [users, missions]);

    const mergedFetchError = usersFetchError ?? missionsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!missions || !users) {
        return null;
    }

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            setIsDragDisabled(false);
            return;
        }
        const sourceId = source.droppableId;
        const destinationId = destination.droppableId;

        // same table
        if (sourceId === destinationId) {
            const items = reorder(
                kanbanData[sourceId],
                source.index,
                destination.index
            );
            const newState = { ...kanbanData };
            newState[sourceId] = items;
            setKanbanData(newState);
        } else {
            // different table
            const newResult = move(
                kanbanData[sourceId],
                kanbanData[destinationId],
                source,
                destination
            );
            const newState = { ...kanbanData };
            newState[sourceId] = newResult[sourceId];
            newState[destinationId] = newResult[destinationId];

            try {
                // because we prevent dragging onDragStart and re-enable it after the patch onDragEnd, we're already "awaiting" patch manually
                // also if we await, there will be a "jump" of the draggable
                patch(`${BACKEND_ROUTES.MISSION}/${result.draggableId}`, {
                    body: {
                        supervisorUuid:
                            destinationId === noUserUuidValue
                                ? null
                                : destinationId,
                    },
                    forwardError: true,
                });
            } catch (error) {
                openSnackbar(
                    "Something went wrong. Please reload the page and try again.",
                    "error"
                );
            }

            setKanbanData(newState);
        }
        setIsDragDisabled(false);
    }

    const closeModal = () => {
        setIsOpenMissionDetails(false);
    };

    return (
        <div
            style={{
                display: "flex",
            }} /* Makes all the kanban columns stay at the same size regardless of screen width */
        >
            <DragDropContext
                onDragStart={() => {
                    setIsDragDisabled(true);
                }}
                onDragEnd={onDragEnd}
            >
                <Stack direction="row" spacing={2}>
                    {
                        // Object.entries(a).map((b)) => b === [key, value]
                        Object.entries(kanbanData).map((dataPair) => (
                            <RoutingKanbanDroppable
                                dataPair={dataPair}
                                users={users}
                                noUserUuidValue={noUserUuidValue}
                                isDragDisabled={isDragDisabled}
                                grid={grid}
                                setSelectedMissionUuid={setSelectedMissionUuid}
                                setIsOpenMissionDetails={
                                    setIsOpenMissionDetails
                                }
                                key={dataPair[0]}
                            />
                        ))
                    }
                </Stack>
            </DragDropContext>
            <Dialog
                open={isOpenMissionDetails}
                fullWidth
                maxWidth="md"
                TransitionComponent={ModalTransition}
            >
                <RoutingDialog
                    uuid={selectedMissionUuid}
                    closeModal={closeModal}
                    mutateTable={mutate}
                />
            </Dialog>
        </div>
    );
}
