import {
    Box,
    Button,
    DialogActions,
    DialogTitle,
    Divider,
} from "@mui/material";
import {
    MissionProtocolDetails,
    MissionProtocolStageTable,
    MissionProtocolSummary,
} from ".";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DATATYPES_ENUM } from "../../constants";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { LoadingButton } from "@mui/lab";
import { MissionProtocolSkeleton } from "./MissionProtocolSkeleton";
import PropTypes from "prop-types";
import download from "downloadjs";
import { getGsdPerStage } from "../utils/getGsdPerStage";
import { getMinGsd } from "../utils/getMinGsd";
import html2canvas from "html2canvas";
import { useForm } from "react-hook-form";
import { useMemo } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const maxTraitCount = 7;

MissionProtocol.propTypes = {
    pipelineTemplateUuid: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export function MissionProtocol({ pipelineTemplateUuid, closeModal }) {
    const {
        data: pipelineTemplate,
        pipelineTemplateFetchError,
        mutate,
    } = useSWR(
        `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${pipelineTemplateUuid}?parentInfo=true&traitGroups=true&flightSequences=true&sop=true`
    );

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
    } = useForm({
        // values replaces useEffect (the form will use defaultValues until the object given to values is fetched by useSWR)
        // here, we could also replace "pipelineTemplate" by "{ comment: pipelineTemplate?.comment }"
        values: pipelineTemplate,
        defaultValues: {
            comment: "",
        },
    });

    const { openSnackbar } = useSnackbar();

    const { patch } = useFetch();

    const { data: bbchStageData, bbchStageFetchError } = useSWRImmutable(
        BACKEND_ROUTES.BBCH_STAGE
    );

    const mergedFetchError = bbchStageFetchError ?? pipelineTemplateFetchError;

    /**
     * traitGroupDataTypeArrays is an object array containing all distinct trait group data types for this specific pipeline template
     * the tgdt are stored in arrays, separated per dataTypes, and each dataType is the key of the object (like this: {RGB: [...], Multispectral: [...]})
     * traitGroupDataTypeLength is the total count of all of these arrays
     */
    const [traitGroupDataTypeArrays, traitGroupDataTypeLength] = useMemo(() => {
        // this part filters duplicates without actually filtering
        const traitGroupDataTypeMap = new Map();
        pipelineTemplate?.PipelineTemplateTraitGroups.forEach((PTTG) => {
            traitGroupDataTypeMap.set(
                PTTG.TraitGroupDataType.uuid,
                PTTG.TraitGroupDataType
            );
        });

        const returnedObject = {};

        const filteredTraitGroupDataTypes = [...traitGroupDataTypeMap.values()];

        // we store each TGDT in its dataType param of the final object
        filteredTraitGroupDataTypes.forEach((TGDT) => {
            returnedObject[TGDT.dataType]
                ? returnedObject[TGDT.dataType].push(TGDT)
                : (returnedObject[TGDT.dataType] = [TGDT]);
        });

        return [returnedObject, filteredTraitGroupDataTypes.length];
    }, [pipelineTemplate]);

    const minGSD = useMemo(
        () => (pipelineTemplate ? getMinGsd(pipelineTemplate) : {}),
        [pipelineTemplate]
    );

    const gsdPerStage = useMemo(
        () =>
            pipelineTemplate && bbchStageData
                ? getGsdPerStage(pipelineTemplate, bbchStageData.rows)
                : [],
        [pipelineTemplate, bbchStageData]
    );

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!pipelineTemplate || !bbchStageData) {
        return <MissionProtocolSkeleton />;
    }

    const displayTraits = !(traitGroupDataTypeLength > maxTraitCount);

    const onClickPrint = async () => {
        // please refer to https://pdf-lib.js.org/
        const canvas = await html2canvas(
            document.getElementById(pipelineTemplateUuid),
            {
                // onclone is a callback function which is called when the Document has been cloned for rendering
                // it can be used to modify the contents that will be rendered without affecting the original source document
                // here, we change the display of the cloned document so that html2canvas can render it on its side without rendering it for the user
                onclone: function (clonedDoc) {
                    clonedDoc.getElementById(
                        pipelineTemplateUuid
                    ).style.display = "block";
                },
                windowWidth: 1620,
                windowHeight: 920,
                scale: 1.25,
            }
        );

        const canvasSummary = await html2canvas(
            document.getElementById(`${pipelineTemplateUuid}_summary`),
            {
                // onClone can be used to update rendered content without updating the original document
                // here, we change the display of the cloned document so that html2canvas can render it on its side without rendering it for the user
                onclone: function (clonedDoc) {
                    clonedDoc.getElementById(
                        `${pipelineTemplateUuid}_summary`
                    ).style.display = "block";
                },
                windowWidth: 1620,
                windowHeight: 920,
                scale: 1.25,
            }
        );

        const pdfTemplateURL =
            pipelineTemplate.AcquisitionVector.SensorBundles.map(
                (bundle) => bundle.Sensor.dataType
            ).includes(DATATYPES_ENUM.MULTISPECTRAL)
                ? displayTraits
                    ? "/static/SOP_PDF_Template_MS_standard.pdf"
                    : "/static/SOP_PDF_Template_MS_extended.pdf"
                : displayTraits
                  ? "/static/SOP_PDF_Template_RGB_standard.pdf"
                  : "/static/SOP_PDF_Template_RGB_extended.pdf";

        const pdfTemplate = await (await fetch(pdfTemplateURL)).arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfTemplate);

        const pngImage = await pdfDoc.embedPng(canvas.toDataURL("image/png"));

        const pngImageSummary = await pdfDoc.embedPng(
            canvasSummary.toDataURL("image/png")
        );

        const pages = pdfDoc.getPages();

        // absoluteHeight is either two times the height of the image OR 1920 pixels (whichever is smaller) so the picture doesn't get stretched too much
        // values were picked through trial and error
        const absoluteHeight =
            pngImage.height * 2 < 1920 ? pngImage.height * 2 : 1920;

        pages[1].drawImage(pngImage, {
            x: pages[1].getWidth() / 2 - 3230 / 2,
            y: 200 + 1920 - absoluteHeight,
            width: 3230,
            height: absoluteHeight,
        });

        const absoluteHeightSummary =
            pngImageSummary.height * 2 < 1850
                ? pngImageSummary.height * 2
                : 1850;

        pages[0].drawImage(pngImageSummary, {
            x: (3 * pages[0].getWidth()) / 4 - 1500 / 2,
            y: 70 + 1920 - absoluteHeightSummary,
            width: 1500,
            height: absoluteHeightSummary,
        });

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const isCompanyNameTooLong = Boolean(
            pipelineTemplate.Contract.Company.name.length > 34
        );

        const isContractNameTooLong = Boolean(
            pipelineTemplate.Contract.name.length > 15
        );

        pages.forEach((page) => {
            const pageWidth = page.getWidth();

            page.drawText(pipelineTemplate.Contract.Company.name, {
                x: isCompanyNameTooLong
                    ? pageWidth / 2 - pageWidth / 6 - pageWidth / 27
                    : pageWidth / 2 -
                      pipelineTemplate.Contract.Company.name.length * 20 -
                      pipelineTemplate.Contract.name.length * 10,
                y: page.getHeight() - 170,
                size: 80,
                font: helveticaFont,
                lineHeight: 90,
                maxWidth: pageWidth / 3,
            });

            page.drawText(
                `Data Acquisition Protocol - ${pipelineTemplate.Contract.name}`,
                {
                    x:
                        (4 * pageWidth) / 5 -
                        150 -
                        (isContractNameTooLong
                            ? pageWidth / 9
                            : pipelineTemplate.Contract.name.length * 24),
                    y: page.getHeight() - 170,
                    size: 60,
                    font: helveticaFont,
                    lineHeight: 70,
                    maxWidth: pageWidth / 3,
                }
            );
        });

        if (!displayTraits) {
            const tableCanvas = await html2canvas(
                document.getElementById(`${pipelineTemplateUuid}_table`),
                {
                    onclone: function (clonedDoc) {
                        clonedDoc.getElementById(
                            `${pipelineTemplateUuid}_table`
                        ).style.display = "block";
                    },
                    windowWidth: 1620,
                    windowHeight: 920,
                    scale: 2.17,
                }
            );

            const tablePngImage = await pdfDoc.embedPng(
                tableCanvas.toDataURL("image/png")
            );

            const absoluteHeightTable =
                tablePngImage.height * 2 < 1920
                    ? tablePngImage.height * 2
                    : 1920;

            pages[2].drawImage(tablePngImage, {
                x: pages[1].getWidth() / 2 - 3230 / 2,
                y: 200 + 1920 - absoluteHeightTable,
                width: 3230,
                height: absoluteHeightTable,
            });
        }

        const savedPdf = await pdfDoc.save();

        // need to ask around to find out what template for the name of the file we want to use
        // for now it's MissionProtocol_CompanyName_ContractName_PipelineTemplateUuid.pdf
        download(
            savedPdf,
            `MissionProtocol_${pipelineTemplate.Contract.Company.name}_${pipelineTemplate.Contract.name}_${pipelineTemplateUuid}.pdf`,
            "application/pdf"
        );
    };

    const onSubmit = async (payload) => {
        const newPipelineTemplate = await mutate(
            patch(
                `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${pipelineTemplateUuid}`,
                {
                    body: { comment: payload.comment },
                }
            ),
            {
                populateCache: (
                    updatedPipelineTemplate,
                    currentPipelineTemplate
                ) => ({
                    ...currentPipelineTemplate,
                    comment: updatedPipelineTemplate.comment,
                }),
                optimisticData: {
                    ...pipelineTemplate,
                    comment: payload.comment,
                },
                /**
                 * revalidate: true if we want the cache to be revalidated after the update is resolved
                 * since the backend validates the data (and there's rollbackOnError if the backend doesn't validate the data),
                 * it would be pointless to revalidate a second time after the update is successful
                 */
                revalidate: false,
                rollbackOnError: true,
            }
        );
        if (newPipelineTemplate) {
            openSnackbar(
                `Comment for this pipeline template has been updated successfully.`,
                "success"
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
                Data Acquisition Protocol: {pipelineTemplate.name}
            </DialogTitle>
            <Divider variant="middle" />
            <MissionProtocolDetails
                pipelineTemplate={pipelineTemplate}
                bbchStageData={bbchStageData}
                traitGroupDataTypeArrays={traitGroupDataTypeArrays}
                gsdPerStage={gsdPerStage}
                minGSD={minGSD}
                displayTraits={displayTraits}
                maxTraitCount={maxTraitCount}
                errors={errors}
                register={register}
            />

            <div
                style={{
                    display: "none",
                }}
                id={pipelineTemplateUuid}
            >
                <MissionProtocolDetails
                    pipelineTemplate={pipelineTemplate}
                    bbchStageData={bbchStageData}
                    traitGroupDataTypeArrays={traitGroupDataTypeArrays}
                    gsdPerStage={gsdPerStage}
                    minGSD={minGSD}
                    displayTraits={displayTraits}
                    maxTraitCount={maxTraitCount}
                    errors={errors}
                    register={register}
                    printed
                />
            </div>

            <div
                style={{
                    display: "none",
                    width: 600,
                }}
                id={`${pipelineTemplateUuid}_summary`}
            >
                <MissionProtocolSummary
                    pipelineTemplate={pipelineTemplate}
                    bbchStageData={bbchStageData}
                    gsdPerStage={gsdPerStage}
                    minGSD={minGSD}
                />
            </div>

            <div
                style={{ display: "none" }}
                id={`${pipelineTemplateUuid}_table`}
            >
                <Box pb={2}>
                    <MissionProtocolStageTable
                        pipelineTemplate={pipelineTemplate}
                        bbchStageData={bbchStageData}
                        traitGroupDataTypeArrays={traitGroupDataTypeArrays}
                        gsdPerStage={gsdPerStage}
                        minGSD={minGSD}
                        displayTraits={true} // forced to true because this table should always display the traits
                        maxTraitCount={maxTraitCount}
                        printed
                    />
                </Box>
            </div>

            <Divider variant="middle" />
            <DialogActions sx={{ px: 2 }}>
                <Button type="button" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="outlined" onClick={onClickPrint}>
                    Export to PDF
                </Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
