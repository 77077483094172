import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Skeleton,
} from "@mui/material";

export const TraitDetailsSkeleton = ({ closeModal }) => {
    return (
        <>
            <DialogTitle>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="50%"
                    height="3em"
                />
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height="2em"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="50%"
                            height="5em"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};
