import {
    Checkbox,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Skeleton,
} from "@mui/material";

export const TraitGroupListSkeleton = () => (
    <List>
        {Array.from({ length: 3 }, (_, index) => (
            <Skeleton animation="wave" variant="text" width="100%" key={index}>
                <ListItem>
                    <ListItemButton dense>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </Skeleton>
        ))}
    </List>
);
