import "/node_modules/flag-icons/css/flag-icons.min.css";

import { Typography } from "@mui/material";

export const CountryFlag = ({ country }) => {
    const { alpha2 } = country ?? { alpha2: "xx" };
    return (
        <Typography
            sx={{
                minWidth: "24px",
            }}
            className={`fi fi-${alpha2.toLowerCase()}`}
        />
    );
};
