import {
    Card,
    CardContent,
    Stack,
    Typography,
    cardContentClasses,
} from "@mui/material";

import { MODEL_PROPTYPES } from "../../../models";
import { PipelineBBCHSelect } from "./PipelineBBCHSelect";
import { PipelineTemplateTraitList } from "./PipelineTemplateTraitList";
import PropTypes from "prop-types";
import Scrollbar from "../../../components/Scrollbar";
import { useMemo } from "react";

PipelineTemplateTraitCard.propTypes = {
    experimentMission: PropTypes.shape(MODEL_PROPTYPES.ExperimentMission),
    pipelineTemplatesData: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.PipelineTemplate).isRequired
    ).isRequired,
    pipelineTemplateUuid: PropTypes.string.isRequired,
    bbchStageUuid: PropTypes.string.isRequired,
    selectedPipelineTemplate: PropTypes.shape(MODEL_PROPTYPES.PipelineTemplate),
    selectableBbchStages: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.BBCHStage)
    ).isRequired,
    requiredGSDByDatatype: PropTypes.object.isRequired,
    gaugeValues: PropTypes.object,
};

export function PipelineTemplateTraitCard({
    pipelineTemplateUuid,
    bbchStageUuid,
    experimentMission,
    pipelineTemplatesData,
    requiredGSDByDatatype,
    selectedPipelineTemplate,
    selectableBbchStages,
    gaugeValues,
}) {
    const TGDTSortedByDatatypes = useMemo(() => {
        return selectedPipelineTemplate && bbchStageUuid
            ? selectedPipelineTemplate.PipelineTemplateTraitGroups.filter(
                  (PTTG) => PTTG.bbchStageUuid === bbchStageUuid
              ).reduce((groupedByDataType, { TraitGroupDataType: TGDT }) => {
                  if (groupedByDataType[TGDT.dataType]) {
                      groupedByDataType[TGDT.dataType].push(TGDT);
                  } else {
                      groupedByDataType[TGDT.dataType] = [TGDT];
                  }
                  return groupedByDataType;
              }, {})
            : {};
    }, [selectedPipelineTemplate, bbchStageUuid]);

    const dataTypes = Object.keys(TGDTSortedByDatatypes);

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent
                sx={{
                    height: "100%",
                    [`&.${cardContentClasses.root}`]: { paddingBottom: 1 },
                }}
            >
                <Stack spacing={1} height="100%">
                    <PipelineBBCHSelect
                        associationLocked={Boolean(experimentMission)}
                        selectablePipelineTemplates={pipelineTemplatesData}
                        pipelineTemplateUuid={pipelineTemplateUuid}
                        selectableBbchStages={selectableBbchStages}
                        bbchStageUuid={bbchStageUuid}
                    />
                    {bbchStageUuid && selectedPipelineTemplate ? (
                        <Scrollbar sx={{ px: 2 }}>
                            <Stack spacing={1}>
                                {dataTypes.map((dataType) => (
                                    <PipelineTemplateTraitList
                                        key={dataType}
                                        dataType={dataType}
                                        traitGroupDataTypes={
                                            TGDTSortedByDatatypes[dataType]
                                        }
                                        requiredGSDByDatatype={
                                            requiredGSDByDatatype
                                        }
                                        pipelineTemplate={
                                            selectedPipelineTemplate
                                        }
                                        acquisitionGsdMax={
                                            gaugeValues[dataType]?.GSD.max
                                        }
                                    />
                                ))}
                            </Stack>
                        </Scrollbar>
                    ) : (
                        <Typography align="center">
                            Please select a Pipeline template and BBCH stage.
                        </Typography>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}
