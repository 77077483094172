import {
    $findMatchingParent,
    $getNearestNodeOfType,
    mergeRegister,
} from "@lexical/utils";
import {
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    SELECTION_CHANGE_COMMAND,
} from "lexical";
import {
    $getSelectionStyleValueForProperty,
    $isAtNodeEnd,
} from "@lexical/selection";
import { $isListNode, ListNode } from "@lexical/list";
import { Divider, Stack } from "@mui/material";
import {
    SET_CAN_REDO,
    SET_CAN_UNDO,
    SET_FONT_SIZE,
    SET_STYLES,
    initialState,
    reducer,
} from "./reducer";
import { useCallback, useEffect, useReducer, useState } from "react";

import { $isHeadingNode } from "@lexical/rich-text";
import { BlockFormat } from "./BlockFormat";
import { FontSize } from "./FontSize";
import { HistoryButtons } from "./HistoryButtons";
import { TextAlignment } from "./TextAlignment";
import { TextStyles } from "./TextStyles";
import { blockTypeToBlockName } from "./constants";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export const ToolbarPlugin = () => {
    const [
        {
            alignment,
            bgColor,
            blockType,
            canRedo,
            canUndo,
            fontColor,
            fontSize,
            isBold,
            isCode,
            isItalic,
            isStrikethrough,
            isSubscript,
            isSuperscript,
            isUnderline,
        },
        dispatch,
    ] = useReducer(reducer, initialState);
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                          const parent = e.getParent();
                          return parent !== null && $isRootOrShadowRoot(parent);
                      });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const node = getSelectedNode(selection);
            const parent = node.getParent();

            const styles = {
                alignment:
                    ($isElementNode(node)
                        ? node.getFormatType()
                        : parent?.getFormatType()) || initialState.alignment,
                bgColor: $getSelectionStyleValueForProperty(
                    selection,
                    "background-color",
                    initialState.bgColor
                ),
                fontColor: $getSelectionStyleValueForProperty(
                    selection,
                    "color",
                    initialState.fontColor
                ),
                fontSize: $getSelectionStyleValueForProperty(
                    selection,
                    "font-size",
                    initialState.fontSize
                ),
                isBold: selection.hasFormat("bold"),
                isCode: selection.hasFormat("code"),
                isItalic: selection.hasFormat("italic"),
                isStrikethrough: selection.hasFormat("strikethrough"),
                isSubscript: selection.hasFormat("subscript"),
                isSuperscript: selection.hasFormat("superscript"),
                isUnderline: selection.hasFormat("underline"),
            };

            if (activeEditor.getElementByKey(element.getKey()) !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(
                        anchorNode,
                        ListNode
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    styles.blockType = type;
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        styles.blockType = type;
                    }
                }
            }

            dispatch({ type: SET_STYLES, styles });
        }
    }, [activeEditor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                $updateToolbar();
                setActiveEditor(newEditor);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener(setIsEditable),
            activeEditor.registerUpdateListener(({ editorState }) =>
                editorState.read($updateToolbar)
            ),
            activeEditor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    dispatch({ type: SET_CAN_UNDO, canUndo: payload });
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            ),
            activeEditor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    dispatch({ type: SET_CAN_REDO, canRedo: payload });
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            )
        );
    }, [$updateToolbar, activeEditor, editor]);

    const setFontSize = (fontSize) =>
        dispatch({ type: SET_FONT_SIZE, fontSize });

    return (
        <Stack
            direction="row"
            sx={{
                backgroundColor: "background.paper",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "divider",
                padding: 1,
            }}
        >
            <HistoryButtons
                activeEditor={activeEditor}
                canRedo={canRedo}
                canUndo={canUndo}
                disabled={!isEditable}
            />
            <Divider
                flexItem
                orientation="vertical"
                sx={{ mr: 1.5, ml: 0.5 }}
            />
            {blockType in blockTypeToBlockName && activeEditor === editor && (
                <>
                    <BlockFormat
                        blockType={blockType}
                        disabled={!isEditable}
                        editor={editor}
                    />
                    <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ ml: 1.5, mr: 0.5 }}
                    />
                </>
            )}
            <FontSize
                disabled={!isEditable}
                editor={editor}
                fontSize={fontSize && Number(fontSize.slice(0, -2))}
                setFontSize={setFontSize}
            />
            <Divider flexItem orientation="vertical" sx={{ ml: 0.5, mr: 1 }} />
            <TextStyles
                activeEditor={activeEditor}
                bgColor={bgColor}
                disabled={!isEditable}
                editor={editor}
                fontColor={fontColor}
                isBold={isBold}
                isCode={isCode}
                isItalic={isItalic}
                isStrikethrough={isStrikethrough}
                isSubscript={isSubscript}
                isSuperscript={isSuperscript}
                isUnderline={isUnderline}
            />
            <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
            <TextAlignment
                alignment={alignment}
                disabled={!isEditable}
                editor={editor}
            />
        </Stack>
    );
};

const getSelectedNode = (selection) => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = anchor.getNode();
    const focusNode = focus.getNode();

    if (anchorNode === focusNode) {
        return anchorNode;
    }

    return $isAtNodeEnd(selection.isBackward() ? focus : anchor)
        ? anchorNode
        : focusNode;
};
