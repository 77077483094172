import { Box, Container, IconButton, Stack, Tab, Tabs } from "@mui/material";
import {
    ParcellaireCreationFormImport,
    ParcellaireCreationFormNoImport,
} from ".";

import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useState } from "react";

// The tabs mechanism here is based on MUI's basic tab example
// https://mui.com/material-ui/react-tabs/#basic-tabs

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

ParcellaireCreationModal.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    parcellaireDispatch: PropTypes.func.isRequired,
    mapRef: PropTypes.any.isRequired,
    setGeoJsonKey: PropTypes.func.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    setBlueRectangleData: PropTypes.func.isRequired,
};

export function ParcellaireCreationModal({
    setIsOpen,
    parcellaireDispatch,
    mapRef,
    setGeoJsonKey,
    openSnackbar,
    setBlueRectangleData,
}) {
    const [value, setValue] = useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <Container maxWidth="xl" sx={{ bgcolor: "background.default" }}>
            <Stack
                direction="row"
                sx={{ borderBottom: 1, borderColor: "divider" }}
                alignItems="center"
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ flexGrow: 1 }}
                >
                    <Tab label="Import Data" />
                    <Tab label="From Scratch" />
                </Tabs>
                <IconButton
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            <TabPanel value={value} index={0}>
                <ParcellaireCreationFormImport
                    setIsOpen={setIsOpen}
                    parcellaireDispatch={parcellaireDispatch}
                    mapRef={mapRef}
                    setGeoJsonKey={setGeoJsonKey}
                    openSnackbar={openSnackbar}
                    setBlueRectangleData={setBlueRectangleData}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ParcellaireCreationFormNoImport
                    setIsOpen={setIsOpen}
                    parcellaireDispatch={parcellaireDispatch}
                    mapRef={mapRef}
                    setGeoJsonKey={setGeoJsonKey}
                    setBlueRectangleData={setBlueRectangleData}
                />
            </TabPanel>
        </Container>
    );
}
