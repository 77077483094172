import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputAdornment,
    Stack,
    TextField,
} from "@mui/material";

import CalculateIcon from "@mui/icons-material/Calculate";
import { INPUT_VALIDATION } from "../../../constants";
import { realFocalLenthFromGsd } from "../../utils/sopCalculation";
import { roundNumber } from "../../utils/roundNumber";
import { useForm } from "react-hook-form";
import { useState } from "react";

export function SensorSimulatorRealFocalLengthFromGSD() {
    const [realFocalLength, setRealFocalLength] = useState(null);

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: {
            sensorWidth: null,
            sensorHeight: null,
            pixelWidth: null,
            gsd: null,
            height: null,
        },
    });

    const onSubmit = (payload) => {
        const realFocalLengthResult = realFocalLenthFromGsd(
            payload.sensorWidth,
            payload.gsd,
            payload.height,
            payload.pixelWidth
        );

        setRealFocalLength(roundNumber(realFocalLengthResult, 1));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardHeader title="Real Focal Length (from GSD and Height)" />

                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={1.6}>
                            <TextField
                                id="sensorWidth"
                                fullWidth
                                type="number"
                                label="Sensor width"
                                error={Boolean(errors.sensorWidth)}
                                helperText={errors.sensorWidth?.message}
                                {...register("sensorWidth", {
                                    required: INPUT_VALIDATION.REQUIRED_SHORT,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value > 0 || INPUT_VALIDATION.POSITIVE,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            mm
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={1.6}>
                            <TextField
                                id="sensorHeight"
                                fullWidth
                                type="number"
                                label="Sensor height"
                                error={Boolean(errors.sensorHeight)}
                                helperText={errors.sensorHeight?.message}
                                {...register("sensorHeight", {
                                    required: INPUT_VALIDATION.REQUIRED_SHORT,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value > 0 || INPUT_VALIDATION.POSITIVE,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            mm
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={1.6}>
                            <TextField
                                id="pixelWidth"
                                fullWidth
                                type="number"
                                label="Picture width"
                                error={Boolean(errors.pixelWidth)}
                                helperText={errors.pixelWidth?.message}
                                {...register("pixelWidth", {
                                    required: INPUT_VALIDATION.REQUIRED_SHORT,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value > 0 || INPUT_VALIDATION.POSITIVE,
                                })}
                                inputProps={{
                                    inputMode: "numeric",
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            px
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={1.6}>
                            <TextField
                                id="height"
                                fullWidth
                                type="number"
                                label="Distance"
                                error={Boolean(errors.height)}
                                helperText={errors.height?.message}
                                {...register("height", {
                                    required: INPUT_VALIDATION.REQUIRED_SHORT,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value > 0 || INPUT_VALIDATION.POSITIVE,
                                })}
                                inputProps={{
                                    step: "0.1",
                                    inputMode: "decimal",
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={1.6}>
                            <TextField
                                id="gsd"
                                fullWidth
                                type="number"
                                label="GSD"
                                error={Boolean(errors.gsd)}
                                helperText={errors.gsd?.message}
                                {...register("gsd", {
                                    required: INPUT_VALIDATION.REQUIRED_SHORT,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value > 0 || INPUT_VALIDATION.POSITIVE,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            mm
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    startIcon={<CalculateIcon />}
                                    type="submit"
                                >
                                    Compute
                                </Button>
                            </Stack>
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                id="result"
                                fullWidth
                                type="text"
                                label="Result"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            mm
                                        </InputAdornment>
                                    ),
                                }}
                                value={realFocalLength ?? ""}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    );
}
