export const paperStyleOverride = {
    MuiPaper: {
        defaultProps: {
            elevation: 0,
        },

        styleOverrides: {
            root: {
                backgroundImage: "none",
            },
        },
    },
};
