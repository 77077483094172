import {
    ALLOWED_FACTOR_BELOW_MINIMUM_GSD,
    FLAGS_STRINGS,
    FLAGS_THRESHOLDS,
} from "../../../constants";

/**
 * Checks which flags are raised for a specific mission.
 * By default, can raise a flag for missing raw datasets, missing metadatas, and for saturation and exposure time thresholds.
 * If requiredGSD is provided, the flag for the GSD requirement can be raised.
 * If pipelineTemplate is provided, the flags for difference in acquisition vector, and focal length if acquisition vectors are the same, can be raised.
 * If both experiment and pipelineTemplate are provided, the flag for crop difference can be raised.
 * @param {object} mission - The target mission
 * @param {object} rawDatasetStatistics - Statistics for the target mission.
 * @param {object} [pipelineTemplate] - Pipeline template associated to the mission.
 * @param {object} [requiredGSD] - Required GSD for the mission.
 * @param {object} [experiment] - Experiment associated to the mission.
 * @returns {object[]} - Array of flag objects.
 */
export function flagsSetter(
    mission,
    rawDatasetStatistics,
    pipelineTemplate,
    requiredGSD,
    experiment
) {
    if (!rawDatasetStatistics) return [FLAGS_STRINGS.MISSING_RAWDATASET];

    const flagList = [];
    // TODO: Reconsider flags such as GSDDeviation and AltitudeDeviation

    if (
        Object.keys(rawDatasetStatistics).some((dataType) => {
            // checks if rawDatasetStatistics[dataType] has no null or undefined gsdMax
            // also checks if rawDatasetStatistics[channel] has no null or undefined saturationMax / exposureTimeMax
            return (
                (dataType.startsWith("DATATYPE_") &&
                    rawDatasetStatistics[dataType]?.gsdMax == null) ||
                (!dataType.startsWith("DATATYPE_") &&
                    (rawDatasetStatistics[dataType]?.saturationMax == null ||
                        rawDatasetStatistics[dataType]?.exposureTimeMax ==
                            null))
            );
        })
    ) {
        flagList.push(FLAGS_STRINGS.NULL_METADATA);
    }

    if (
        requiredGSD &&
        Object.keys(rawDatasetStatistics)
            .filter((dataType) => dataType.startsWith("DATATYPE_"))
            .some((dataType) => {
                const translatedDataType = dataType.split("_").pop();
                return (
                    requiredGSD[translatedDataType] &&
                    requiredGSD[translatedDataType] *
                        ALLOWED_FACTOR_BELOW_MINIMUM_GSD <
                        rawDatasetStatistics[dataType]?.gsdMax
                );
            })
    ) {
        flagList.push(FLAGS_STRINGS.GSD_NOT_MET);
    }

    let saturationLimitExceededFlag = false;
    let exposureTimeLimitExceededFlag = false;
    Object.keys(rawDatasetStatistics).forEach((channel) => {
        if (
            rawDatasetStatistics[channel].saturationMax >=
            FLAGS_THRESHOLDS.SATURATION
        )
            saturationLimitExceededFlag = true;

        if (
            rawDatasetStatistics[channel].exposureTimeMax >=
            FLAGS_THRESHOLDS.EXPOSURE_TIME
        )
            exposureTimeLimitExceededFlag = true;
    });
    if (saturationLimitExceededFlag) flagList.push(FLAGS_STRINGS.SATURATION);
    if (exposureTimeLimitExceededFlag)
        flagList.push(FLAGS_STRINGS.EXPOSURE_TIME);
    if (
        pipelineTemplate &&
        pipelineTemplate.acquisitionVectorUuid !== mission.acquisitionVectorUuid
    ) {
        flagList.push(FLAGS_STRINGS.ACQUISITION_VECTOR_DIFFERENT);
    } else if (
        pipelineTemplate &&
        mission.focalLength35mmEqv !== pipelineTemplate.focalLength35mmEqv
    ) {
        flagList.push(FLAGS_STRINGS.FOCAL_LENGTH_DIFFERENT);
    }

    if (
        experiment &&
        pipelineTemplate &&
        experiment.cropUuid !== pipelineTemplate.cropUuid
    )
        flagList.push(FLAGS_STRINGS.CROP_DIFFERENT);

    return flagList;
}
