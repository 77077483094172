/**
 * Rounds a number depending on the desired offset
 * @param {number} originalValue - value that we want to round up
 * @param {number} offset - how many decimals do we want
 * @returns {number} resulting number
 */
export function roundNumber(originalValue, offset) {
    if (offset < 0) return originalValue;
    const offsetProduct = Math.pow(10, offset);
    return Math.round(originalValue * offsetProduct) / offsetProduct;
}
