import { Container } from "@mui/material";
import { CropTable } from "../features/crop";
import Page from "../components/Page";

export default function CropGlossary() {
    return (
        <Page title="Crop glossary | HCC">
            <Container maxWidth="xl">
                <CropTable />
            </Container>
        </Page>
    );
}
