import { Card, Stack, Typography } from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { PAGINATION } from "../../constants";
import Scrollbar from "../../components/Scrollbar";
import { SensorTableSkeleton } from "./SensorTableSkeleton";
import useSWR from "swr";

const pageItemCount = 100;

export function SensorTable() {
    const { data: sensors, error: sensorsFetchError } = useSWR(
        `${BACKEND_ROUTES.SENSOR}?limit=${PAGINATION.SENSOR.LIMIT.DEFAULT}`
    );

    if (sensorsFetchError) return <FetchErrorAlert error={sensorsFetchError} />;

    if (!sensors) {
        return <SensorTableSkeleton />;
    }

    const gridRows = sensors.rows.map((sensor) => {
        return {
            id: sensor.uuid,
            uuid: sensor.uuid,
            name: sensor.name,
            dataType: sensor.dataType,
            size: `${sensor.sensorWidth} x ${sensor.sensorHeight} mm`,
            imageResolution: `${sensor.pixelWidth} x ${sensor.pixelHeight} (${sensor.megapixels}MP)`,
            focalLength35mmEqv: sensor.focalLength35mmEqv
                ? `${sensor.focalLength35mmEqv} mm`
                : "Variable",
            cmos: sensor.cmos,
        };
    });

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 340,
            flex: 2,
        },
        {
            field: "dataType",
            headerName: "Type",
            minWidth: 160,
            flex: 2,
        },
        {
            field: "size",
            headerName: "Size",
            minWidth: 160,
            flex: 2,
        },
        {
            field: "imageResolution",
            headerName: "Image resolution",
            minWidth: 220,
            flex: 2,
        },
        {
            field: "focalLength35mmEqv",
            headerName: "Focal length (35mm eqv)",
            minWidth: 100,
            flex: 2,
        },
        {
            field: "cmos",
            headerName: "CMOS",
            minWidth: 100,
            flex: 2,
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h4" gutterBottom>
                    Sensors
                </Typography>
            </Stack>

            <Card>
                <Scrollbar>
                    <DataGrid
                        autoHeight
                        rows={gridRows}
                        columns={gridColDef}
                        pageSizeOptions={[pageItemCount]}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                        rowCount={sensors.count}
                    />
                </Scrollbar>
            </Card>
        </>
    );
}
