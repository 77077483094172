import {
    gsdFromHeight,
    heightFromGsdAndPipelineTemplate,
    realFocalLengthFromFocalLength35,
} from "./sopCalculation";

import { roundNumber } from "./roundNumber";

/**
 * Calculates the height tied to a certain GSD, depending on the pipeline template and dataType. Will take into account the min and max height values.
 * @param {object} gsd - The GSD we want to use for height calculations.
 * @param {object} pipelineTemplate - The pipeline template for which we want to compute the height.
 * @param {string} dataType - Data type for the height.
 * @returns {number|null}
 */
export const absoluteHeight = ({ gsd, pipelineTemplate, dataType }) => {
    const calculatedHeight = gsd
        ? roundNumber(
              heightFromGsdAndPipelineTemplate(gsd, pipelineTemplate, dataType),
              1
          )
        : null;

    if (!calculatedHeight) return null;

    /**
     * if calculatedHeight doesn't exist, then we return null
     * if calculatedHeight is above max, then we return the max
     * if calculatedHeight is below min, then we return the min - we prefiltered it so it won't be more than 10% below min
     * if calculatedHeight is between min and max, then we return calculatedHeight
     */
    return pipelineTemplate.AcquisitionVector.minHeight &&
        calculatedHeight < pipelineTemplate.AcquisitionVector.minHeight
        ? pipelineTemplate.AcquisitionVector.minHeight
        : pipelineTemplate.AcquisitionVector.maxHeight &&
            calculatedHeight > pipelineTemplate.AcquisitionVector.maxHeight
          ? pipelineTemplate.AcquisitionVector.maxHeight
          : calculatedHeight;
};

/**
 * Calculates the height tied to a certain GSD, depending on the pipeline template. Will take into account the min and max height values.
 * @param {object} gsd - The GSD we want to use for height calculations, it should contain the gsd value under "gsd.gsd[dataType]".
 * @param {object} pipelineTemplate - The pipeline template for which we want to compute the height.
 * @returns {number|null}
 */
export const absoluteAggregatedHeight = ({ gsd, pipelineTemplate }) => {
    const calculatedHeight = roundNumber(
        Object.keys(gsd?.gsd).reduce((previous, dataType) => {
            const calculatedValue = heightFromGsdAndPipelineTemplate(
                gsd.gsd[dataType],
                pipelineTemplate,
                dataType
            );

            return calculatedValue < previous ? calculatedValue : previous;
        }, Infinity),
        1
    );

    if (!calculatedHeight || calculatedHeight === Infinity) return null;

    /**
     * if calculatedHeight doesn't exist, then we return null
     * if calculatedHeight is above max, then we return the max
     * if calculatedHeight is below min, then we return the min - we prefiltered it so it won't be more than 10% below min
     * if calculatedHeight is between min and max, then we return calculatedHeight
     */
    return pipelineTemplate.AcquisitionVector.minHeight &&
        calculatedHeight < pipelineTemplate.AcquisitionVector.minHeight
        ? pipelineTemplate.AcquisitionVector.minHeight
        : pipelineTemplate.AcquisitionVector.maxHeight &&
            calculatedHeight > pipelineTemplate.AcquisitionVector.maxHeight
          ? pipelineTemplate.AcquisitionVector.maxHeight
          : calculatedHeight;
};

/**
 * Calculates the absolute GSD based on the gsd object, and depending on the pipeline template and dataType. Will use absoluteHeight to achieve this.
 * @param {object} gsd - The GSD we want to use for height calculations.
 * @param {object} pipelineTemplate - The pipeline template for which we want to compute the absolute gsd.
 * @param {string} dataType - Data type for the gsd. Must be within pipelineTemplate.
 * @returns {number|null}
 */
export const absoluteGSD = ({ gsd, pipelineTemplate, dataType }) => {
    const usedHeight = absoluteHeight({ gsd, pipelineTemplate, dataType });

    if (!usedHeight) return null;

    const filteredSensors =
        pipelineTemplate.AcquisitionVector.SensorBundles?.filter(
            (bundle) => bundle.Sensor.dataType === dataType
        );

    if (!filteredSensors || filteredSensors.length === 0) return null;

    return roundNumber(
        // this reduce finds the most restrictive GSD by finding the minimum that can be achieved with the pipeline template's acquisition vector for this data type.
        filteredSensors.reduce((smallestGSD, bundle) => {
            const realFocalLengthPerPipeline = realFocalLengthFromFocalLength35(
                bundle.Sensor.sensorWidth,
                bundle.Sensor.sensorHeight,
                bundle.Sensor.focalLength35mmEqv ??
                    pipelineTemplate.focalLength35mmEqv
            );
            const thisGSD = gsdFromHeight(
                usedHeight,
                bundle.Sensor.sensorWidth,
                bundle.Sensor.pixelWidth,
                realFocalLengthPerPipeline
            );
            return thisGSD < smallestGSD ? thisGSD : smallestGSD;
        }, Infinity),
        1
    );
};
