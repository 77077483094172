import { alpha } from "@mui/material/styles";

export const backdropStyleOverride = {
    MuiBackdrop: {
        styleOverrides: {
            root: ({ theme }) => {
                // The original template has a gradient backdrop transparency
                // which is undesirable and we change it to one without gradient by
                // giving the High and Low the same level of transparency.
                const varLow = alpha(theme.palette.grey[900], 0.6); // originally 0.48
                const varHigh = alpha(theme.palette.grey[900], 0.6); // originally 1

                return {
                    background: [
                        `rgb(22,28,36)`,
                        `-moz-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
                        `-webkit-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
                        `linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
                    ],
                    "&.MuiBackdrop-invisible": {
                        background: "transparent",
                    },
                };
            },
        },
    },
};
