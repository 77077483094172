export const initialModalState = {
    modal: "", // Possible values: "" (not open), "form", "sop", "delete"
    isCreation: false, // Only pertinent when modal is "form".
    pipelineTemplateUuid: "",
    pipelineTemplateName: "",
};

export function modalReducer(state, action) {
    switch (action.type) {
        case "create":
            return {
                ...state,
                modal: "form",
                isCreation: true,
                pipelineTemplateUuid: "",
            };
        case "edit":
            // pipelineTemplateUuid is required when editing
            return {
                ...state,
                modal: "form",
                isCreation: false,
                pipelineTemplateUuid: action.pipelineTemplateUuid,
            };
        case "sop":
            // pipelineTemplateUuid is required when viewing sop
            return {
                ...state,
                modal: "sop",
                pipelineTemplateUuid: action.pipelineTemplateUuid,
            };
        case "clone":
            // pipelineTemplateUuid is required when cloning
            return {
                ...state,
                modal: "form",
                isCreation: true,
                pipelineTemplateUuid: action.pipelineTemplateUuid,
            };
        case "delete":
            // pipelineTemplateUuid and pipelineTemplateName are required when deleting
            return {
                ...state,
                modal: "delete",
                pipelineTemplateUuid: action.pipelineTemplateUuid,
                pipelineTemplateName: action.pipelineTemplateName,
            };
        case "close":
            return {
                ...state,
                modal: "",
            };
        default:
            throw new Error("Unknown action: " + action.type);
    }
}
