import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { MODEL_PROPTYPES } from "../../../models";
import { PIPELINE_INSTANCE_STATUS } from "../../../constants";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/formatTime";

export const PipelineInstanceListItem = ({
    pipelineTraitGroup: {
        comment,
        PipelineInstance: { uuid, status, createdAt },
        Pipeline: { mode, version },
    },
    isValidated,
}) => (
    <FormControlLabel
        control={<Radio size="small" />}
        disableTypography
        value={uuid}
        sx={{
            backgroundColor: isValidated ? "secondary.lighter" : "initial",
            borderRadius: 1,
            mr: 0,
        }}
        label={
            <>
                <Stack mr={1} width={1} display="grid">
                    <Typography noWrap title={comment}>
                        {comment}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                        <Typography
                            noWrap
                            variant="subtitle1"
                            color="text.primary"
                            title={mode}
                        >
                            {mode}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            {` - v${version}`}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            ml={3}
                        >
                            {formatDateTime(createdAt)}
                        </Typography>
                    </Stack>
                </Stack>
                <Box pr={1} alignItems="center" display="flex">
                    {status === PIPELINE_INSTANCE_STATUS.ERROR ? (
                        <ErrorIcon color="error" />
                    ) : (
                        <CheckCircleIcon color="success" />
                    )}
                </Box>
            </>
        }
    />
);

PipelineInstanceListItem.propTypes = {
    isValidated: PropTypes.bool.isRequired,
    pipelineTraitGroup: PropTypes.shape(MODEL_PROPTYPES.PipelineTraitGroup)
        .isRequired,
};
