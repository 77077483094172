import { Chip } from "@mui/material";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

BreadcrumbChip.propTypes = {
    label: PropTypes.node.isRequired,
    destinationUrl: PropTypes.string,
};

export function BreadcrumbChip({ label, destinationUrl }) {
    return destinationUrl ? (
        <Chip
            size="small"
            label={label}
            onClick={() => {}}
            component={RouterLink}
            to={destinationUrl}
            reloadDocument
        />
    ) : (
        <Chip size="small" label={label} variant="outlined" />
    );
}
