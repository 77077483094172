import { Card, Skeleton, Typography } from "@mui/material";

export function RoutingFormSkeleton() {
    return (
        <>
            <Typography variant="h4" gutterBottom>
                Routing
            </Typography>
            <Card>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="60vh"
                />
            </Card>
        </>
    );
}
