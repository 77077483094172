import {
    AssociationBBCHStageSelect,
    AssociationExperimentTable,
    AssociationPipelineTemplateTable,
    AssociationTable,
    InboundTrafficMissionData,
} from ".";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useFetch, useSnackbar } from "../../../hooks";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import CheckIcon from "@mui/icons-material/Check";
import { ConfirmModal } from "../../../components/ConfirmModal";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { EXPERIMENT_MISSION_STATUS } from "../../../constants";
import EditIcon from "@mui/icons-material/Edit";
import { FRONTEND_ROUTES } from "../../../frontendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { InboundTrafficAssociationSkeleton } from "./InboundTrafficAssociationSkeleton";
import { MODES } from "../../acquisition-report/constants";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Stack } from "@mui/system";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export const InboundTrafficAssociation = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [
        { selectedPipelineTemplate, selectedBBCHStage, selectedExperiments },
        setInboundStates,
    ] = useState({
        selectedPipelineTemplate: [],
        selectedBBCHStage: null,
        selectedExperiments: [],
    });

    const { patch, put } = useFetch();

    const { openSnackbar } = useSnackbar();

    const {
        data: mission,
        error: missionFetchError,
        mutate: mutateMission,
    } = useSWR(
        `${BACKEND_ROUTES.MISSION}/${uuid}?parentInfo=true&experimentMissions=true&acquisitionVector=true&rawDatasetStatistics=true`,
        { keepPreviousData: true }
    );

    const { data: pipelineTemplates, error: pipelineTemplatesFetchError } =
        useSWR(
            mission
                ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}?parentInfo=true&traitGroups=true&sop=true&contractUuid=${mission.Site.Contract.uuid}`
                : null
        );
    const { data: experiments, error: experimentsFetchError } = useSWR(
        mission
            ? `${BACKEND_ROUTES.EXPERIMENT}?parentInfo=true&siteUuid=${mission.Site.uuid}`
            : null
    );
    const { data: bbchStages, error: bbchStagesFetchError } = useSWRImmutable(
        BACKEND_ROUTES.BBCH_STAGE
    );

    const acquisitionGsdMax = useMemo(() => {
        const result = {};
        if (mission?.rawDatasetStatistics) {
            Object.keys(mission.rawDatasetStatistics)
                .filter((key) => key.startsWith("DATATYPE_"))
                .forEach((dataTypeString) => {
                    const translatedDataType = dataTypeString.split("_").pop();
                    result[translatedDataType] =
                        mission.rawDatasetStatistics[dataTypeString].gsdMax;
                });
        }
        return result;
    }, [mission]);

    const mergedFetchError =
        missionFetchError ??
        pipelineTemplatesFetchError ??
        bbchStagesFetchError ??
        experimentsFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (!pipelineTemplates || !bbchStages || !mission || !experiments)
        return <InboundTrafficAssociationSkeleton />;

    const onAssociationCompleted = async () => {
        const associationCompletionStatusBeforeUpdate =
            mission.associationCompleted;
        const patchResult = await patch(`${BACKEND_ROUTES.MISSION}/${uuid}`, {
            body: {
                associationCompleted: !associationCompletionStatusBeforeUpdate,
            },
        });

        if (patchResult) {
            mutateMission();
            setIsOpen(false);
            openSnackbar(
                associationCompletionStatusBeforeUpdate
                    ? `Associations for this mission can now be properly edited.`
                    : `Associations for this mission are now considered completed.`,
                "success"
            );
        } else {
            setIsOpen(false);
        }
    };

    const onSubmit = async () => {
        const payload = selectedExperiments.map((experiment) => ({
            missionUuid: uuid,
            experimentUuid: experiment,
            pipelineTemplateUuid: selectedPipelineTemplate[0],
            bbchStageUuid: selectedBBCHStage.uuid,
            deadline: 30,
            status: EXPERIMENT_MISSION_STATUS.WAITING_FOR_RAW_DATA,
        }));

        const putResult = await put(
            `${BACKEND_ROUTES.MISSION}/${uuid}/experiment-missions`,
            {
                body: {
                    experimentMissions: payload,
                },
            }
        );

        if (putResult) {
            mutateMission();
            openSnackbar(
                `The associations have been created succesfully.`,
                "success"
            );
        }
    };

    return (
        <Stack spacing={2}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="flex-start"
            >
                <Grid item xs="auto">
                    <Typography variant="h4" gutterBottom>
                        Mission association
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant="outlined"
                        startIcon={<QueryStatsIcon />}
                        disabled={!mission.rawDatasetStatistics}
                        onClick={() =>
                            navigate(
                                `/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.ACQUISITION_REPORT}/${uuid}?mode=${MODES.DETAILED}`
                            )
                        }
                    >
                        Acquisition Report
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant="outlined"
                        startIcon={<CropOriginalIcon />}
                        disabled
                        //onClick={() => navigate("/")}
                        // TODO: https://hiphen.atlassian.net/browse/SE-594?atlOrigin=eyJpIjoiOWRjNDcwN2EyMGFkNDViMGJkN2QyNmEwZGJmYmU4ZDciLCJwIjoiaiJ9
                    >
                        Alignment report
                    </Button>
                </Grid>
            </Grid>
            <InboundTrafficMissionData mission={mission} />
            <Divider />
            {!mission.associationCompleted && (
                <Stack spacing={2}>
                    <Stack>
                        <Typography variant="h6" gutterBottom>
                            Pipeline templates
                        </Typography>
                        <AssociationPipelineTemplateTable
                            pipelineTemplates={pipelineTemplates}
                            bbchStages={bbchStages}
                            selectedPipelineTemplate={selectedPipelineTemplate}
                            setInboundStates={setInboundStates}
                            acquisitionGsdMax={acquisitionGsdMax}
                        />
                    </Stack>
                    <Stack>
                        <Typography variant="h6" gutterBottom>
                            BBCH Stages
                        </Typography>
                        <AssociationBBCHStageSelect
                            pipelineTemplates={pipelineTemplates}
                            bbchStages={bbchStages}
                            selectedPipelineTemplate={selectedPipelineTemplate}
                            selectedBBCHStage={selectedBBCHStage}
                            setInboundStates={setInboundStates}
                        />
                    </Stack>
                    <Stack>
                        <Typography variant="h6" gutterBottom>
                            Experiments
                        </Typography>
                        <AssociationExperimentTable
                            experiments={experiments}
                            selectedExperiments={selectedExperiments}
                            experimentMissions={mission.ExperimentMissions}
                            pipelineTemplate={
                                selectedPipelineTemplate[0]
                                    ? pipelineTemplates.rows.find(
                                          (pipelineTemplate) =>
                                              pipelineTemplate.uuid ===
                                              selectedPipelineTemplate[0]
                                      )
                                    : null
                            }
                            setInboundStates={setInboundStates}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end">
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={onSubmit}
                            disabled={
                                !selectedPipelineTemplate[0] ||
                                !selectedBBCHStage ||
                                !selectedExperiments[0]
                            }
                            startIcon={<CheckIcon />}
                        >
                            Associate
                        </Button>
                    </Stack>
                    <Divider />
                </Stack>
            )}
            <Stack>
                <Typography variant="h6" gutterBottom>
                    Associations
                </Typography>
                <AssociationTable
                    mission={mission}
                    pipelineTemplates={pipelineTemplates.rows}
                    mutateMission={mutateMission}
                />
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        setIsOpen(true);
                    }}
                    startIcon={
                        mission.associationCompleted ? (
                            <EditIcon />
                        ) : (
                            <CheckIcon />
                        )
                    }
                    disabled={
                        !mission.associationCompleted &&
                        !mission.ExperimentMissions.length
                    }
                >
                    {mission.associationCompleted
                        ? "Allow association edition"
                        : "Complete association"}
                </Button>
            </Stack>
            <ConfirmModal
                isOpen={isOpen}
                onCancel={() => {
                    setIsOpen(false);
                }}
                onConfirm={onAssociationCompleted}
                title={
                    mission.associationCompleted
                        ? "Are you sure you want to allow association edition for this mission?"
                        : "Are you sure you want to complete association for this mission?"
                }
                contentText={
                    mission.associationCompleted ? (
                        <>This will add this mission to the inbound traffic.</>
                    ) : (
                        <>
                            This will remove this mission from the inbound
                            traffic.
                        </>
                    )
                }
            />
        </Stack>
    );
};
