import { Container } from "@mui/material";
import Page from "../components/Page";
import { RoutingForm } from "../features/monitoring/routing/RoutingForm";

export default function Routing() {
    return (
        <Page title="Routing | HCC">
            <Container maxWidth="xl">
                <RoutingForm />
            </Container>
        </Page>
    );
}
