import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from "@mui/material";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { PAGINATION } from "../../../constants";
import { PipelineTemplateForm } from ".";
import { PipelineTemplateFormSkeleton } from "./PipelineTemplateFormSkeleton";
import PropTypes from "prop-types";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useState } from "react";

PipelineTemplateDialog.propTypes = {
    uuid: PropTypes.string,
    isCreation: PropTypes.bool.isRequired,
    contractUuid: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    mutateTable: PropTypes.func.isRequired,
};

/**
 * If isCreation true and no uuid: we are creating a new pipeline template.
 * If isCreation true and uuid provided: we are cloning an existing pipeline template.
 * If isCreation false: uuid must be provided and we are modifying an existing pipeline template.
 */
export function PipelineTemplateDialog({
    uuid,
    isCreation,
    contractUuid,
    closeModal,
    mutateTable,
}) {
    // If isCreation false, uuid must be provided
    if (!isCreation && !uuid)
        throw new TypeError("uuid must be provided if isCreation is false");

    const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);

    const {
        data: pipelineTemplate,
        error: pipelineTemplateFetchError,
        mutate,
    } = useSWR(
        uuid
            ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${uuid}?parentInfo=true&traitGroups=true&calendars=true&flightSequences=true`
            : null
    );

    const { data: contract, contractFetchError } = useSWR(
        `${BACKEND_ROUTES.CONTRACT}/${contractUuid}?parentInfo=true`
    );
    const { data: cropsData, cropsFetchError } = useSWRImmutable(
        BACKEND_ROUTES.CROP
    );
    const { data: systemModelsData, systemModelsFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.SYSTEM_MODEL}?sort=name`
    );
    const { data: acquisitionVectorsData, acquisitionVectorsFetchError } =
        useSWRImmutable(
            `${BACKEND_ROUTES.ACQUISITION_VECTOR}?sensor=true&systemModel=true&sort=name`
        );

    const { data: traitGroupDataTypeData, traitGroupDataTypeFetchError } =
        useSWR(
            `${BACKEND_ROUTES.TRAIT_GROUP_DATA_TYPE}?parentInfo=true&deprecated=false`
        );

    const { data: bbchStageData, bbchStageFetchError } = useSWRImmutable(
        BACKEND_ROUTES.BBCH_STAGE
    );

    const { data: plantScaleData, plantScaleFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.PLANT_SCALE}?limit=${PAGINATION.PLANT_SCALE.LIMIT.MAX}`
    );

    const mergedFetchError =
        contractFetchError ??
        cropsFetchError ??
        systemModelsFetchError ??
        acquisitionVectorsFetchError ??
        pipelineTemplateFetchError ??
        traitGroupDataTypeFetchError ??
        bbchStageFetchError ??
        plantScaleFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (
        !contract ||
        !cropsData ||
        !systemModelsData ||
        !acquisitionVectorsData ||
        (uuid && !pipelineTemplate) ||
        !traitGroupDataTypeData ||
        !bbchStageData ||
        !plantScaleData
    )
        return <PipelineTemplateFormSkeleton />;

    const onClickErrorDialog = () => {
        setIsOpenErrorDialog(false);
    };

    return (
        <>
            <DialogTitle>
                {isCreation
                    ? "Create a new pipeline template"
                    : "Edit the pipeline template"}
            </DialogTitle>
            <Divider variant="middle" />
            <PipelineTemplateForm
                pipelineTemplate={pipelineTemplate}
                isCreation={isCreation}
                contract={contract}
                cropsData={cropsData}
                systemModelsData={systemModelsData}
                acquisitionVectorsData={acquisitionVectorsData}
                traitGroupDataTypeData={traitGroupDataTypeData}
                bbchStageData={bbchStageData}
                plantScaleData={plantScaleData}
                closeModal={closeModal}
                setIsOpenErrorDialog={setIsOpenErrorDialog}
                mutateTable={mutateTable}
                mutate={mutate}
            />
            <Dialog open={isOpenErrorDialog}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Any pipeline template must contain at least one trait
                        group with at least one BBCH stage selected.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={onClickErrorDialog}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
