import { GaugeTabs } from "./GaugeTabs";
import { Grid } from "@mui/material";
import { MODEL_PROPTYPES } from "../../../models";
import { MetadataCharts } from "./MetadataCharts";
import PropTypes from "prop-types";
import { useState } from "react";

export const MetadataMetricsLayout = ({
    datasetsByDataType,
    filteredDataTypes,
    gaugeValues,
    includeQuarantine,
    requiredGSDByDatatype,
    transferListImages,
    setIncludeQuarantine,
    setTransferListImages,
}) => {
    const [selectedGauge, setSelectedGauge] = useState(null);
    const [selectedTab, setSelectedTab] = useState(filteredDataTypes[0]);

    return (
        <>
            <Grid item xs={6}>
                <GaugeTabs
                    datasetsByDataType={datasetsByDataType}
                    requiredGSDByDatatype={requiredGSDByDatatype}
                    selectedGauge={selectedGauge}
                    setSelectedGauge={setSelectedGauge}
                    filteredDataTypes={filteredDataTypes}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    gaugeValues={gaugeValues}
                />
            </Grid>
            <Grid item xs={6}>
                {selectedGauge && (
                    <MetadataCharts
                        dataset={datasetsByDataType[selectedTab]}
                        includeQuarantine={includeQuarantine}
                        selectedGauge={selectedGauge}
                        transferListImages={transferListImages}
                        setIncludeQuarantine={setIncludeQuarantine}
                        setTransferListImages={setTransferListImages}
                    />
                )}
            </Grid>
        </>
    );
};

MetadataMetricsLayout.propTypes = {
    datasetsByDataType: PropTypes.objectOf(
        PropTypes.arrayOf(
            PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
        ).isRequired
    ).isRequired,
    filteredDataTypes: PropTypes.arrayOf(PropTypes.string.isRequired)
        .isRequired,
    gaugeValues: PropTypes.object.isRequired,
    includeQuarantine: PropTypes.bool.isRequired,
    requiredGSDByDatatype: PropTypes.objectOf(PropTypes.number).isRequired,
    transferListImages: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
    ).isRequired,
    setIncludeQuarantine: PropTypes.func.isRequired,
    setTransferListImages: PropTypes.func.isRequired,
};
