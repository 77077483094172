import { createContext } from "react";

const defaultSnackbarContextFunction = () =>
    console.error(
        "SnackbarContext provider not implemented or values not supplied"
    );

export const SnackbarContext = createContext({
    openSnackbar: defaultSnackbarContextFunction,
});
