import { BACKEND_ROUTES } from "../backendRoutes";
import { Container } from "@mui/material";
import { FetchErrorAlert } from "../components/FetchErrorAlert";
import Page from "../components/Page";
import { ValidationPage } from "../features/validation";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export default function Validation() {
    const { experimentMissionUuid, pipelineUuid } = useParams();

    const { data: experimentMission, error: experimentMissionFetchError } =
        useSWR(
            experimentMissionUuid
                ? `${BACKEND_ROUTES.EXPERIMENT_MISSION}/${experimentMissionUuid}?parentInfo=true`
                : null
        );

    const { data: pipeline, error: pipelineFetchError } = useSWR(
        pipelineUuid
            ? `${BACKEND_ROUTES.PIPELINE}/${pipelineUuid}?parentInfo=true`
            : null
    );

    const mergedFetchError = experimentMissionFetchError ?? pipelineFetchError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (!experimentMission || !pipeline) return null; // TODO: skeleton

    return (
        <Page title="Validation Page | HCC">
            <Container maxWidth="xl">
                <ValidationPage
                    experimentMission={experimentMission}
                    pipeline={pipeline}
                />
            </Container>
        </Page>
    );
}
