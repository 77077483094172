import { ACCESS_ROLE, RESOURCE_ACCESS } from "../constants";
import { OidcSecure, useOidcAccessToken } from "@axa-fr/react-oidc";

import AccessRefusedPage from "../pages/AccessRefusedPage";
import PropTypes from "prop-types";
import { useMemo } from "react";

export const RequireAuth = ({ children }) => {
    const { accessTokenPayload } = useOidcAccessToken();
    const userHasAccess = useMemo(() => {
        return accessTokenPayload?.resource_access[
            RESOURCE_ACCESS
        ]?.roles.includes(ACCESS_ROLE);
    }, [accessTokenPayload?.resource_access]);

    return (
        <OidcSecure>
            {userHasAccess ? children : <AccessRefusedPage />}
        </OidcSecure>
    );
};

RequireAuth.propTypes = {
    children: PropTypes.node.isRequired,
};
