import { Skeleton } from "@mui/material";

export const AcquisitionReportSkeleton = () => {
    return (
        <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100em"
        />
    );
};
