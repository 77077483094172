import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    Grid,
    Slider,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import CircularProgress from "@mui/material/CircularProgress";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { Stack } from "@mui/system";
import { useRef } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const width = 800;
const height = 600;

const previewCanvasWidth = width / 2;
const previewCanvasHeight = height / 2;

const detailedImage = new Image();
const previewImage = new Image();

export function ResolutionSimulator() {
    const zoomCanvasRef = useRef(null);
    const detailedCanvasRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const { control, setValue } = useForm({
        defaultValues: {
            gsd: 1,
            selectedCrop: null,
        },
    });

    const [gsdWatch, selectedCropWatch] = useWatch({
        name: ["gsd", "selectedCrop"],
        control,
    });

    const { data: fileList, fileListFetchError } = useSWRImmutable(
        `${BACKEND_ROUTES.PUBLIC_FILE_LIST}`
    );

    const { data: detailedImageSignedUrl, detailedImageSignedUrlFetchError } =
        useSWR(
            selectedCropWatch
                ? `${BACKEND_ROUTES.PUBLIC_SIGNED_URL}?key=${selectedCropWatch.croppedImage}`
                : null
        );

    const { data: previewImageSignedUrl, previewImageSignedUrlFetchError } =
        useSWR(
            selectedCropWatch
                ? `${BACKEND_ROUTES.PUBLIC_SIGNED_URL}?key=${selectedCropWatch.originalImage}`
                : null
        );

    detailedImage.onload = () =>
        drawDetailedCanvas(
            zoomCanvasRef,
            detailedCanvasRef,
            selectedCropWatch,
            gsdWatch
        );
    if (detailedImageSignedUrl) detailedImage.src = detailedImageSignedUrl;

    previewImage.onload = () => {
        if (selectedCropWatch) drawPreviewCanvas(previewCanvasRef);
    };
    if (previewImageSignedUrl) previewImage.src = previewImageSignedUrl;

    const mergedFetchError =
        detailedImageSignedUrlFetchError ??
        previewImageSignedUrlFetchError ??
        fileListFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    return (
        <form>
            <div hidden>
                <canvas ref={zoomCanvasRef} width={width} height={height} />
            </div>
            <Card>
                <CardContent>
                    <Grid container justifyContent="space-evenly" spacing={2}>
                        <Grid item>
                            {selectedCropWatch ? (
                                <canvas
                                    ref={detailedCanvasRef}
                                    width={width}
                                    height={height}
                                />
                            ) : (
                                <Box
                                    sx={{ width, height }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {!fileList ? (
                                        <CircularProgress />
                                    ) : (
                                        <Typography>
                                            Please select an image
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Grid>
                        <Grid item>
                            <Stack spacing={4}>
                                <Controller
                                    control={control}
                                    name="selectedCrop"
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            name,
                                            ...fieldProps
                                        },
                                    }) => (
                                        <Autocomplete
                                            {...fieldProps}
                                            id="selectedCrop"
                                            options={
                                                fileList
                                                    ? Object.values(
                                                          fileList.RESOLUTION_SIMULATOR_FILES
                                                      ).sort((a, b) =>
                                                          a.name
                                                              .toLowerCase()
                                                              .localeCompare(
                                                                  b.name.toLowerCase()
                                                              )
                                                      )
                                                    : []
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.name === value.name}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            disableClearable
                                            disabled={!fileList}
                                            onChange={(_, value) => {
                                                onChange(value);
                                                setValue(
                                                    "gsd",
                                                    value ? value.gsd : 1
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputRef={ref}
                                                    name={name}
                                                    label="Select crop image"
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <Stack>
                                    <Typography>GSD Slider</Typography>

                                    <Box sx={{ px: 1 }}>
                                        <Controller
                                            control={control}
                                            name="gsd"
                                            id="gsd"
                                            render={({ field }) => (
                                                <Slider
                                                    {...field}
                                                    disabled={
                                                        !selectedCropWatch
                                                    }
                                                    orientation="horizontal"
                                                    valueLabelDisplay="auto"
                                                    min={
                                                        selectedCropWatch
                                                            ? selectedCropWatch.gsd
                                                            : 1
                                                    }
                                                    max={
                                                        selectedCropWatch
                                                            ? Math.max(
                                                                  selectedCropWatch.gsd *
                                                                      10,
                                                                  20
                                                              )
                                                            : 10
                                                    }
                                                    step={0.1}
                                                    onChange={(_, value) => {
                                                        field.onChange(value);
                                                        drawDetailedCanvas(
                                                            zoomCanvasRef,
                                                            detailedCanvasRef,
                                                            selectedCropWatch,
                                                            gsdWatch
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <Typography>{gsdWatch} mm/pixel</Typography>
                                </Stack>
                                <div>
                                    <canvas
                                        ref={previewCanvasRef}
                                        width={previewCanvasWidth}
                                        height={previewCanvasHeight}
                                    />
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    );
}

function drawDetailedCanvas(
    zoomCanvasRef,
    detailedCanvasRef,
    selectedCropWatch,
    gsdWatch
) {
    const zoomCanvas = zoomCanvasRef.current;
    const zoomCanvasCtx = zoomCanvas.getContext("2d");
    const detailedCanvas = detailedCanvasRef.current;
    const detailedCanvasCtx = detailedCanvas.getContext("2d");

    if (selectedCropWatch) {
        zoomCanvasCtx.imageSmoothingEnabled = false;
        detailedCanvasCtx.imageSmoothingEnabled = false;

        zoomCanvasCtx.drawImage(
            detailedImage,
            0,
            0,
            width * (selectedCropWatch.gsd / gsdWatch),
            height * (selectedCropWatch.gsd / gsdWatch)
        );

        detailedCanvasCtx.drawImage(
            zoomCanvas,
            0,
            0,
            width * (selectedCropWatch.gsd / gsdWatch),
            height * (selectedCropWatch.gsd / gsdWatch),
            0,
            0,
            width,
            height
        );
    }
}

function drawPreviewCanvas(previewCanvasRef) {
    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(previewImage, 0, 0, canvas.width, canvas.height);
}
