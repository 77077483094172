import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Skeleton,
} from "@mui/material";

export const PipelineTemplateFormSkeleton = () => {
    return (
        <>
            <DialogTitle>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="50%"
                    height="2em"
                />
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height="2em"
                        />
                    </Grid>
                    <Grid item xs={8} />

                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="2em"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="2em"
                />
            </DialogActions>
        </>
    );
};
