export const iconButtonStyleOverride = {
    MuiIconButton: {
        variants: [
            {
                props: { color: "default" },
                style: ({ theme }) => ({
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                }),
            },
            {
                props: { color: "inherit" },
                style: ({ theme }) => ({
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                }),
            },
        ],

        styleOverrides: {
            root: {},
        },
    },
};
