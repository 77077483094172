import { DATATYPES_ENUM } from "../../constants";
import { roundNumber } from "./roundNumber";

function diagonal(sensorSideA, sensorSideB) {
    return Math.sqrt(Math.pow(sensorSideA, 2) + Math.pow(sensorSideB, 2));
}

function cropFactor(sensorSideA, sensorSideB) {
    return diagonal(36, 24) / diagonal(sensorSideA, sensorSideB);
}

// the real focal length is simply the 35mm equivalent focal length divided by the crop factor
// focalLengthEqv and realFocalLength are both in millimeters
export function realFocalLengthFromFocalLength35(
    sensorSideA,
    sensorSideB,
    focalLength35
) {
    return focalLength35 / cropFactor(sensorSideA, sensorSideB);
}

// the 35mm equivalent focal length is simply the real focal length multiplied by the crop factor
// focalLengthEqv and realFocalLength are both in millimeters
export function focalLength35mmEqvFromRealFocalLength(
    sensorSideA,
    sensorSideB,
    realFocalLength
) {
    return realFocalLength * cropFactor(sensorSideA, sensorSideB);
}

// the FOVs are used for the footprints equation, and the FOV along is used specifically for the GSD or height equation
// FOVs of certain sides are in radians
export function fovSide(realFocalLength, sensorSide) {
    return 2 * Math.atan(sensorSide / (2 * realFocalLength));
}

// this FOV is calculated for display purposes, so it's in degrees instead of radians
export function fovFull(realFocalLength, sensorWidth, sensorHeight) {
    return (
        (2 *
            Math.atan(
                Math.sqrt(
                    Math.pow(sensorWidth, 2) + Math.pow(sensorHeight, 2)
                ) /
                    (2 * realFocalLength)
            ) *
            180) /
        Math.PI
    );
}

// The height is one of the most important piece of information that the SOP will provide, and is calculated from the gsd, the pixel width, and the fov along
// GSD is in mm, height is in meters
export function heightFromGsd(gsd, pixelWidth, fovAlong) {
    return (gsd * 0.001 * pixelWidth) / (2 * Math.tan(fovAlong / 2));
}

// footprint along is used to calculate the maximum speed the drone should fly for a good acquisition
// both footprints are also used to calculate the number of tracks and the number of pics per track
// footprints are in meters
export function footprintSide(height, fovSide) {
    return Math.tan(fovSide / 2) * height * 2;
}

// this footprint is the area of a full footprint, so it's in meters²
export function footprintFull(
    height,
    realFocalLength,
    sensorWidth,
    sensorHeight
) {
    return (
        footprintSide(height, fovSide(realFocalLength, sensorWidth)) *
        footprintSide(height, fovSide(realFocalLength, sensorHeight))
    );
}

// max speed is the fastest the drone can go while still guaranteeing a good acquisition (taking overlap and the time between two pictures into account)
// maxSpeedMeterPerSecond is in meter/second, and maxSpeedMilesPerHour is in miles per hour
export function maxSpeedMeterPerSecond(
    overlap,
    footprintAlong,
    acquisitionPeriod
) {
    return ((100 - overlap) / 100) * (footprintAlong / acquisitionPeriod);
}

export function maxSpeedMilesPerHour(maxSpeedMeterSecond) {
    return maxSpeedMeterSecond * 2.2369;
}

export function maxSpeedKilometerPerHour(
    overlap,
    footprintAlong,
    acquisitionPeriod
) {
    return (
        maxSpeedMeterPerSecond(overlap, footprintAlong, acquisitionPeriod) * 3.6
    );
}

// tracks count is the number of tracks the drone will do, and it is needed for the total number of pictures
export function numberOfTracks(trialWidth, overlap, footprintAlong) {
    return Math.ceil(
        2 + trialWidth / (((100 - overlap) / 100) * footprintAlong)
    );
}

// pics per track is the number of pics taken for each track, and is also needed for the total number of pictures
export function picsPerTrack(trialLength, overlap, footprintAcross) {
    return Math.ceil(
        2 + trialLength / (((100 - overlap) / 100) * footprintAcross)
    );
}

// min total pics is the minimum number of pictures that will be needed for a good acquisition
// it is called "min total" because more pictures can technically be taken while still being within the SOPs guidelines
export function minTotalPicsCalc(trackCount, picsPerTrack) {
    return trackCount * picsPerTrack;
}

// this function is based on Raphael's excel file
// in meters
export function traveledDistance(trialLength, trialWidth, trackCount) {
    return trialLength * trackCount + trialWidth * 2;
}

// this function is based on Raphael's excel file
export function optimalTimeInMinutes(distanceToTravel, speedInKmH) {
    return distanceToTravel / (speedInKmH / (60 / 1000));
}

// this function is based on Raphael's excel file
export function estimatedTimeInMinutes(
    distanceToTravel,
    speedInKmH,
    trackCount
) {
    return (
        optimalTimeInMinutes(distanceToTravel, speedInKmH) + trackCount * 0.3
    );
}

// this function is based on an average battery life of 20 minutes, with ceil() to return the smaller integer greater than the calculated amount
export function estimatedNumberOfBatteries(timeInMinutes) {
    return Math.ceil(timeInMinutes / 20);
}

// this is basically the reversed equation for height, but this time we want gsd
// gsd is in millimeters
export function gsdFromHeight(
    height,
    sensorWidth,
    pixelWidth,
    realFocalLength
) {
    return ((height * sensorWidth) / (pixelWidth * realFocalLength)) * 1000;
}

// another way of finding the real focal length, this time without the 35mm equivalent
export function realFocalLenthFromGsd(sensorWidth, gsd, height, pixelWidth) {
    return ((sensorWidth * height) / (gsd * pixelWidth)) * 1000;
}

/**
 * Calculates the estimated file size of a jpg image
 * Empirically,
 * 12 megapixels => around 8.6 MB per image
 * 20 megapixels => around 11 MB per image
 * 45 megapixels => around 25 MB per image
 * 100 megapixels => around 100 MB per image (For now, only Phase One cameras, which is an exceptional case)
 * Value returned is in megabytes
 */
export function rgbImageSize(pixelWidth, pixelHeight) {
    const nbMPs = (pixelWidth * pixelHeight) / 1000000;
    if (nbMPs < 15) return nbMPs * 0.75;
    else if (nbMPs < 80) return nbMPs * 0.6;
    else return nbMPs;
}

/**
 * Calculates the estimated file size of a multispectral tif image
 * Empirically,
 * 1.2 megapixels => around 2.5 MB per image (per band)
 * 5 megapixels => around 10 MB per image (per band)
 * Value returned is in megabytes
 */
export function msImageSize(pixelWidth, pixelHeight) {
    return (pixelWidth * pixelHeight * 2.1) / 1000000;
}

// Calculates the height tied to a certain GSD, for a specific acquisition vector in a pipeline template, and for a specific data type.
// If there's more than one sensor for the specific data type, we take the minimum height
export function heightFromGsdAndPipelineTemplate(
    gsd,
    pipelineTemplate,
    dataType
) {
    return Math.min(
        ...pipelineTemplate.AcquisitionVector.SensorBundles.filter(
            (bundle) => bundle.Sensor.dataType === dataType
        ).map((bundle) =>
            heightFromGsd(
                gsd,
                bundle.Sensor.pixelWidth,
                fovSide(
                    realFocalLengthFromFocalLength35(
                        bundle.Sensor.sensorWidth,
                        bundle.Sensor.sensorHeight,
                        bundle.Sensor.focalLength35mmEqv ??
                            pipelineTemplate.focalLength35mmEqv
                    ),
                    bundle.Sensor.sensorWidth
                )
            )
        )
    );
}

export function fullSop({
    sensor,
    focalLength35mm,
    overlap,
    acquisitionPeriod,
    trialLength,
    trialWidth,
    gsd,
}) {
    // here, we check that there's a focal length 35mm equivalent in the query, and select the sensor's data if it's not the case
    const focalLengthEqv = focalLength35mm
        ? focalLength35mm
        : sensor.focalLength35mmEqv;
    // following this, we also check that the value we use is above 0, and if it isn't, we return an error
    if (focalLengthEqv <= 0)
        throw new RangeError(
            "Focal length must be either present in sensor or provided manually. It must also be higher than 0."
        );

    // the real focal length is simply the 35mm equivalent focal length divided by the crop factor, and is used in the FOV equation
    // focalLengthEqv and realFocalLength are both in millimeters
    const realFocalLength = realFocalLengthFromFocalLength35(
        sensor.sensorWidth,
        sensor.sensorHeight,
        focalLengthEqv
    );

    // the FOVs are used for the footprints equation, and the FOV along is used specifically for the GSD or height equation
    // fovAlong and fovAcross are in radians
    const fovAlong = fovSide(realFocalLength, sensor.sensorWidth);
    const fovAcross = fovSide(realFocalLength, sensor.sensorHeight);

    // The height is one of the most important piece of information that the SOP will provide, and it requires
    // GSD is in mm, height is in meters
    const height = heightFromGsd(gsd, sensor.pixelWidth, fovAlong);

    // footprint along is used to calculate the maximum speed the drone should fly for a good acquisition
    // both footprints are also used to calculate the number of tracks and the number of pics per track
    // footprintAlong and footprintAcross are in meters
    const footprintAlong = footprintSide(height, fovAlong);

    const footprintAcross = footprintSide(height, fovAcross);

    // max speed is the fastest the drone can go while still guaranteeing a good acquisition (taking overlap and the time between two pictures into account)
    // maxSpeedMPS is in meter/second, and maxSpeedMPH is in miles per hour
    const maxSpeedMPS = maxSpeedMeterPerSecond(
        overlap,
        footprintAlong,
        acquisitionPeriod
    );
    const maxSpeedMPH = maxSpeedMilesPerHour(maxSpeedMPS);

    // track count is the number of tracks the drone will do, and it is needed for the total number of pictures
    const trackCount = numberOfTracks(trialWidth, overlap, footprintAlong);

    // pics foreach track is the number of pics taken for each track, and is also needed for the total number of pictures
    const picsForEachTrack = picsPerTrack(
        trialLength,
        overlap,
        footprintAcross
    );

    // min total pics is the minimum number of pictures that will be needed for a good acquisition
    const minTotalPics = minTotalPicsCalc(trackCount, picsForEachTrack);

    const dataSizeMB =
        sensor.dataType === DATATYPES_ENUM.RGB
            ? minTotalPics * rgbImageSize(sensor.pixelWidth, sensor.pixelHeight)
            : sensor.dataType === DATATYPES_ENUM.MULTISPECTRAL
              ? minTotalPics *
                msImageSize(sensor.pixelWidth, sensor.pixelHeight)
              : null;

    const distanceToTravel = traveledDistance(
        trialLength,
        trialWidth,
        trackCount
    );

    const estimatedTime = estimatedTimeInMinutes(
        distanceToTravel,
        maxSpeedKilometerPerHour(overlap, footprintAlong, acquisitionPeriod),
        trackCount
    );

    const numberOfBatteries = estimatedNumberOfBatteries(estimatedTime);

    const results = {
        Sensor: {
            ...sensor,
            focalLength35mmEqv: roundNumber(focalLengthEqv, 1),
        },
        height: roundNumber(height, 0), // in meters
        maxSpeedMS: roundNumber(maxSpeedMPS, 1), // in meters per second
        maxSpeedMPH: roundNumber(maxSpeedMPH, 1), // in miles per hoour
        overlap: roundNumber(overlap, 1), // in %
        timeBetweenAcquisitions: roundNumber(acquisitionPeriod, 1), // in seconds
        trialLength: roundNumber(trialLength, 1), // in meters
        trialWidth: roundNumber(trialWidth, 1), // in meters
        trackCount: trackCount, // it's an amount
        picsPerTrack: picsForEachTrack, // it's an amount
        minTotalPics, // it's an amount
        dataSize: roundNumber(dataSizeMB, 0), // in megabytes
        estimatedTime: roundNumber(estimatedTime, 1), // in minutes
        numberOfBatteries, // it's an amount
    };

    return results;
}
