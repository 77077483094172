import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

const blurGridColDef = [
    {
        field: "kpiName",
        headerName: "",
        width: 240,
        sortable: false,
    },
    {
        field: "blurryImageCount",
        headerName: "Blurry image count",
        width: 200,
        sortable: false,
    },
    {
        field: "limitValue",
        headerName: "Limit value",
        width: 120,
        sortable: false,
    },
    {
        field: "validated",
        headerName: "Validated",
        width: 100,
        renderCell: ({ value }) =>
            value === true ? (
                <CheckCircleIcon color="success" />
            ) : value === false ? (
                <CancelIcon color="error" />
            ) : null,
        sortable: false,
    },
];

BlurKpiTable.propTypes = {
    filteredDatasetsPerChannel: PropTypes.object.isRequired,
    blurScoreThreshold: PropTypes.number.isRequired,
    blurPercentageThreshold: PropTypes.number.isRequired,
};

export function BlurKpiTable({
    filteredDatasetsPerChannel,
    blurScoreThreshold,
    blurPercentageThreshold,
}) {
    const blurGridRows = [];
    Object.keys(filteredDatasetsPerChannel).forEach((channel) => {
        const blurryList = filteredDatasetsPerChannel[channel].blurScores.map(
            (score) => score > blurScoreThreshold
        );
        const blurryCount = blurryList.reduce((acc, cur) => acc + cur, 0);
        const blurryPercentage =
            (blurryCount /
                filteredDatasetsPerChannel[channel].blurScores.length) *
            100;

        blurGridRows.push({
            id: `blurryImages${channel}`,
            kpiName: `Blurry images (${channel})`,
            blurryImageCount: `${blurryCount}/${
                filteredDatasetsPerChannel[channel].blurScores.length
            } (${blurryPercentage.toFixed(2)} %)`,
            limitValue: `${blurPercentageThreshold} %`,
            validated: Boolean(blurryPercentage <= blurPercentageThreshold),
        });
    });

    return (
        <DataGrid
            autoHeight
            rows={blurGridRows}
            columns={blurGridColDef}
            disableColumnMenu
            hideFooter
        />
    );
}
