import {
    ALTUTUDE_OUTLIER_VALUE_LIMIT,
    AlTITUDE_VALUE_LIMIT,
    BLACK_SATURATION_VALUE_LIMIT,
    GSD_VALUE_LIMIT,
    SATURATION_VALUE_LIMIT,
} from "../constants";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import PropTypes, { object } from "prop-types";

import { GaugeCard } from "../gauge";
import { MODEL_PROPTYPES } from "../../../models";
import WarningIcon from "@mui/icons-material/Warning";

export const GaugeTabs = ({
    datasetsByDataType,
    requiredGSDByDatatype,
    selectedGauge,
    setSelectedGauge,
    filteredDataTypes,
    selectedTab,
    setSelectedTab,
    gaugeValues,
}) => {
    const handleGaugeClick = (gaugeId) => {
        setSelectedGauge(selectedGauge === gaugeId ? null : gaugeId);
    };

    const selectedGaugeValues = gaugeValues[selectedTab];

    const handleTabChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const isAllQuarantine = datasetsByDataType[selectedTab]?.length === 0;

    return (
        <Card>
            <CardContent sx={{ paddingTop: 0 }}>
                <Box borderBottom={1} borderColor="divider" mb={1}>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        {filteredDataTypes.map((dataType) => (
                            <Tab
                                key={dataType}
                                value={dataType}
                                label={dataType}
                            />
                        ))}
                    </Tabs>
                </Box>
                {filteredDataTypes.length === 0 ? (
                    <Typography variant="body1" sx={{ m: 1 }}>
                        No datasets for the expected datatypes
                    </Typography>
                ) : isAllQuarantine ? (
                    <Typography variant="body1" sx={{ m: 1, display: "flex" }}>
                        <WarningIcon color="warning" sx={{ mr: 1 }} />
                        Include quarantine images to view the gauges
                    </Typography>
                ) : (
                    <Grid container spacing={1}>
                        <Grid item lg={4}>
                            <GaugeCard
                                datasetCount={
                                    datasetsByDataType[selectedTab].length
                                }
                                title="Resolution"
                                tooltip={
                                    <>
                                        % of images with GSD value below the GSD
                                        value limit (
                                        {requiredGSDByDatatype[selectedTab]}
                                        mm/px).
                                        <br />
                                        {"Thresholds: Red < 80% < Green"}
                                    </>
                                }
                                unit="%"
                                value={selectedGaugeValues.GSD.average}
                                valueCount={selectedGaugeValues.GSD.count}
                                GaugeProps={{
                                    ranges: { success: [80, 100] },
                                    valueMin: GSD_VALUE_LIMIT[0],
                                    valueMax: GSD_VALUE_LIMIT[1],
                                }}
                                isSelected={selectedGauge === "GSD"}
                                onClick={() => handleGaugeClick("GSD")}
                                disabled={
                                    selectedGaugeValues.GSD.average === null
                                }
                            />
                        </Grid>
                        <Grid item lg={4}>
                            <GaugeCard
                                datasetCount={
                                    datasetsByDataType[selectedTab].length
                                }
                                title="Altitude"
                                tooltip={
                                    <>
                                        Average deviation of the altitude from
                                        the target altitude
                                        <br />
                                        {
                                            "Thresholds: Red < -10% < Green < 10% < Red"
                                        }
                                    </>
                                }
                                unit="%"
                                value={selectedGaugeValues.altitude.average}
                                valueCount={selectedGaugeValues.altitude.count}
                                GaugeProps={{
                                    ranges: { success: [-10, 10] },
                                    valueMin: AlTITUDE_VALUE_LIMIT[0],
                                    valueMax: AlTITUDE_VALUE_LIMIT[1],
                                }}
                                isSelected={selectedGauge === "altitude_agl"}
                                onClick={() => handleGaugeClick("altitude_agl")}
                                disabled={
                                    selectedGaugeValues.altitude.average ===
                                    null
                                }
                            />
                        </Grid>
                        <Grid item lg={4}>
                            <GaugeCard
                                datasetCount={
                                    datasetsByDataType[selectedTab].length
                                }
                                title="Altitude Outlier"
                                tooltip={
                                    <>
                                        Percentage of deviation outside altitude
                                        thresholds
                                        <br />
                                        {
                                            "Thresholds: Green < 5% < Yellow  < 10% < Red"
                                        }
                                    </>
                                }
                                unit="%"
                                value={
                                    selectedGaugeValues.altitudeOutlier.average
                                }
                                valueCount={selectedGaugeValues.altitude.count}
                                GaugeProps={{
                                    ranges: {
                                        success: [0, 5],
                                        warning: [5, 15],
                                    },
                                    valueMin: ALTUTUDE_OUTLIER_VALUE_LIMIT[0],
                                    valueMax: ALTUTUDE_OUTLIER_VALUE_LIMIT[1],
                                }}
                                isSelected={
                                    selectedGauge === "altitude outlier"
                                }
                                onClick={() =>
                                    handleGaugeClick("altitude outlier")
                                }
                                disabled={
                                    selectedGaugeValues.altitudeOutlier
                                        .average === null
                                }
                            />
                        </Grid>
                        <Grid item lg={4}>
                            <GaugeCard
                                datasetCount={
                                    datasetsByDataType[selectedTab].length
                                }
                                title="White Pixel"
                                tooltip={
                                    <SaturationTooltip
                                        channels={
                                            selectedGaugeValues.saturation
                                                .channels
                                        }
                                        dataType={selectedTab}
                                        tooltipType="saturation"
                                    />
                                }
                                unit="%"
                                value={selectedGaugeValues.saturation.average}
                                valueCount={
                                    selectedGaugeValues.saturation.count
                                }
                                GaugeProps={{
                                    ranges: {
                                        success: [0, 5],
                                        warning: [5, 15],
                                    },
                                    valueMin: SATURATION_VALUE_LIMIT[0],
                                    valueMax: SATURATION_VALUE_LIMIT[1],
                                }}
                                isSelected={selectedGauge === "saturation"}
                                onClick={() => handleGaugeClick("saturation")}
                                disabled={
                                    selectedGaugeValues.saturation.average ===
                                    null
                                }
                            />
                        </Grid>
                        <Grid item lg={4}>
                            <GaugeCard
                                datasetCount={
                                    datasetsByDataType[selectedTab].length
                                }
                                title="Black Pixel"
                                tooltip={
                                    <SaturationTooltip
                                        channels={
                                            selectedGaugeValues.blackSaturation
                                                .channels
                                        }
                                        dataType={selectedTab}
                                        tooltipType="blackSaturation"
                                    />
                                }
                                unit="%"
                                value={
                                    selectedGaugeValues.blackSaturation.average
                                }
                                valueCount={
                                    selectedGaugeValues.blackSaturation.count
                                }
                                GaugeProps={{
                                    ranges: {
                                        success: [0, 5],
                                        warning: [5, 15],
                                    },
                                    valueMin: BLACK_SATURATION_VALUE_LIMIT[0],
                                    valueMax: BLACK_SATURATION_VALUE_LIMIT[1],
                                }}
                                isSelected={
                                    selectedGauge === "black_saturation"
                                }
                                onClick={() =>
                                    handleGaugeClick("black_saturation")
                                }
                                disabled={
                                    selectedGaugeValues.blackSaturation
                                        .average === null
                                }
                            />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

const SaturationTooltip = ({ channels, dataType, tooltipType }) => {
    const channelEntries = channels && Object.entries(channels);

    return (
        <Stack>
            {`${tooltipType === "saturation" ? "Saturation" : "Black Saturation"} average for ${dataType} ${channelEntries && channelEntries.length ? "channels:" : ""}`}

            {channelEntries && channelEntries.length > 0 && (
                <ul>
                    {channelEntries.map(([channel, { average }]) => (
                        <li key={channel}>{`${channel}: ${average}%`}</li>
                    ))}
                </ul>
            )}
            <span>{"Thresholds: Green < 5% < Yellow < 15% < Red."}</span>
        </Stack>
    );
};

SaturationTooltip.propTypes = {
    channels: PropTypes.objectOf(
        PropTypes.exact({
            sum: PropTypes.number.isRequired,
            count: PropTypes.number.isRequired,
            average: PropTypes.number,
        }).isRequired
    ),
    dataType: PropTypes.string.isRequired,
    tooltipType: PropTypes.string.isRequired,
};

GaugeTabs.propTypes = {
    datasetsByDataType: PropTypes.objectOf(
        PropTypes.arrayOf(
            PropTypes.shape(MODEL_PROPTYPES.RawDatasets).isRequired
        ).isRequired
    ).isRequired,
    filteredDataTypes: PropTypes.arrayOf(PropTypes.string.isRequired)
        .isRequired,
    requiredGSDByDatatype: PropTypes.objectOf(PropTypes.number).isRequired,
    selectedGauge: PropTypes.string,
    setselectedGauge: PropTypes.func,
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func.isRequired,
    gaugeValues: PropTypes.objectOf(object).isRequired,
};
