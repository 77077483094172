import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

/**
 * IMPORTANT: An ISO date-only string ("yyyy-MM-dd") is not compatible with these functions,
 * since the Javascript's "new Date" will parse to UTC time while the function "format" of date-fns
 * works in local time zone.
 */

export function formatDate(date) {
    return format(new Date(date), "dd MMMM yyyy");
}

export function formatDateISO(date) {
    return format(new Date(date), "yyyy-MM-dd");
}

export function formatDateTime(date) {
    return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
}

export function formatDateTimeUTC(date) {
    return formatInTimeZone(new Date(date), "UTC", "yyyy-MM-dd HH:mm:ss");
}

export function formatDateMonthYearOnly(date) {
    return format(new Date(date), "MM/yyyy");
}

export function monthCountBetween(startDate, endDate) {
    return (
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear()) +
        1
    );
}

export function formatDateTimeShort(date) {
    return format(new Date(date), "MM-dd HH:mm");
}
