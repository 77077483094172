import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";

import { MODEL_PROPTYPES } from "../../../models";
import { PipelineTemplateTraitTable } from ".";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";

PipelineAssociation.propTypes = {
    associationLocked: PropTypes.bool.isRequired,
    selectablePipelineTemplates: PropTypes.array.isRequired,
    pipelineTemplateUuid: PropTypes.string.isRequired,
    setPipelineTemplateUuid: PropTypes.func.isRequired,
    selectableBbchStages: PropTypes.array.isRequired,
    bbchStageUuid: PropTypes.string.isRequired,
    setBbchStageUuid: PropTypes.func.isRequired,
    mission: PropTypes.shape(MODEL_PROPTYPES.Mission).isRequired,
    gsdMax: PropTypes.object.isRequired,
};

export function PipelineAssociation({
    associationLocked,
    selectablePipelineTemplates,
    pipelineTemplateUuid,
    setPipelineTemplateUuid,
    selectableBbchStages,
    bbchStageUuid,
    setBbchStageUuid,
    mission,
    gsdMax,
}) {
    const bbchStageSelectRef = useRef(null);
    const [toReselectBbchStage, setToReselectBbchStage] = useState(false);

    const selectedPipelineTemplate = selectablePipelineTemplates.find(
        (pipelineTemplate) => pipelineTemplate.uuid === pipelineTemplateUuid
    );

    const acquisitionVectorInconsistentFlag =
        selectedPipelineTemplate &&
        mission.acquisitionVectorUuid !==
            selectedPipelineTemplate.acquisitionVectorUuid;

    const focalLength35mmEqvInconsistentFlag = useMemo(() => {
        return !acquisitionVectorInconsistentFlag && selectedPipelineTemplate
            ? mission.focalLength35mmEqv !==
                  selectedPipelineTemplate.focalLength35mmEqv
            : false;
    }, [acquisitionVectorInconsistentFlag, mission, selectedPipelineTemplate]);

    useEffect(() => {
        if (toReselectBbchStage) bbchStageSelectRef.current.focus();
    }, [toReselectBbchStage]);

    return (
        <Card>
            <CardHeader
                title={
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            Associated pipeline{" "}
                            {!associationLocked && "(simulation)"}
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={pipelineTemplateUuid}
                                onChange={(event) => {
                                    // Clear the currently selected bbchStageUuid if it is not selectable
                                    // with the new pipeline template. Focus on it afterwards.
                                    const newPipelineTemplateUuid =
                                        event.target.value;
                                    const newPipelineTemplate =
                                        selectablePipelineTemplates.find(
                                            (pipelineTemplate) =>
                                                pipelineTemplate.uuid ===
                                                newPipelineTemplateUuid
                                        );
                                    const newSelectableBbchStageUuidSet =
                                        new Set(
                                            newPipelineTemplate.PipelineTemplateTraitGroups.map(
                                                (PTTG) => PTTG.bbchStageUuid
                                            )
                                        );

                                    if (
                                        bbchStageUuid &&
                                        !newSelectableBbchStageUuidSet.has(
                                            bbchStageUuid
                                        )
                                    ) {
                                        setBbchStageUuid("");
                                        setToReselectBbchStage(true);
                                    }

                                    setPipelineTemplateUuid(
                                        newPipelineTemplateUuid
                                    );
                                }}
                                fullWidth
                                id="pipelineTemplateUuid"
                                select
                                label="Pipeline template"
                                disabled={associationLocked}
                            >
                                {selectablePipelineTemplates.map(
                                    (pipelineTemplate) => (
                                        <MenuItem
                                            key={pipelineTemplate.uuid}
                                            value={pipelineTemplate.uuid}
                                        >
                                            {pipelineTemplate.name}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={bbchStageUuid}
                                onChange={(event) => {
                                    setToReselectBbchStage(false);
                                    setBbchStageUuid(event.target.value);
                                }}
                                fullWidth
                                id="bbchStageUuid"
                                select
                                label="BBCH stage"
                                disabled={associationLocked}
                                inputRef={bbchStageSelectRef}
                                helperText={
                                    toReselectBbchStage &&
                                    "Please reselect a valid BBCH stage."
                                }
                            >
                                {selectableBbchStages.map((bbchStage) => (
                                    <MenuItem
                                        key={bbchStage.uuid}
                                        value={bbchStage.uuid}
                                    >
                                        {`${bbchStage.stage} (${bbchStage.label})`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                }
            />
            <CardContent>
                {/* TODO: able to hide/show details */}
                {Boolean(selectedPipelineTemplate) && (
                    <Stack direction="column" spacing={1}>
                        {acquisitionVectorInconsistentFlag && (
                            <Stack direction="row" spacing={1}>
                                <WarningIcon color="warning" />
                                <Typography>
                                    System model and/or sensor inconsistent
                                </Typography>
                            </Stack>
                        )}
                        {focalLength35mmEqvInconsistentFlag && (
                            <Stack direction="row" spacing={1}>
                                <WarningIcon color="warning" />
                                <Typography>
                                    35-mm equivalent focal length inconsistent
                                </Typography>
                            </Stack>
                        )}
                        {/* TODO: Add crop info ? */}
                        {/* TODO: Add datatype check ? */}
                        {[
                            ...new Set(
                                selectedPipelineTemplate.PipelineTemplateTraitGroups.map(
                                    (PTTG) => PTTG.TraitGroupDataType.dataType
                                )
                            ),
                        ]
                            .sort()
                            .map((dataType) => (
                                <PipelineTemplateTraitTable
                                    pipelineTemplate={selectedPipelineTemplate}
                                    acquisitionGsdMax={gsdMax}
                                    dataType={dataType}
                                    selectedBbchStageUuid={bbchStageUuid}
                                    key={dataType}
                                />
                            ))}
                    </Stack>
                )}
            </CardContent>
        </Card>
    );
}
